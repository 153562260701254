<script>
  import VueSlickCarousel from 'vue-slick-carousel'

  /** A Reusable slide carousel using slots to render slides. Uses VueSlickCarousel for interactions.
   * See: https://github.com/gs-shop/vue-slick-carousel
   */

  export default {
    name: 'BaseTabsNavSlider',

    components: {
      VueSlickCarousel,
    },

    data() {
      return {
        reset: 0,
        settings: {
          arrows: false,
          dots: false,
          infinite: false,
          swipe: true,
          accessible: true,
          responsive: [
            {
              breakpoint: 9999,
              settings: 'unslick',
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                draggable: true,
                variableWidth: true,
              },
            },
          ],
        },
        currentSlideIndex: 0,
      }
    },

    methods: {
      /** Goto active tab slide when active tab changes */
      onInitCarousel() {
        const el = document.querySelector('.base-tabs-slider  .tabs__btn--active')
        const index = el.parentNode.parentNode.getAttribute('data-index')

        if (this.currentSlideIndex !== index) {
          this.$refs.slider.goTo(index)
          this.currentSlideIndex = index
        }
      },
    },
  }
</script>

<template>
  <div class="base-tabs-slider relative overflow-hidden w-full">
    <VueSlickCarousel ref="slider" :key="reset" v-bind="settings" @reInit="onInitCarousel">
      <slot></slot>
    </VueSlickCarousel>
  </div>
</template>

<style lang="postcss">
  .base-tabs-slider {
    .slick-slider {
      overflow-x: visible;

      .slick-arrow {
        position: absolute;
        left: -9999px;
      }

      .slick-list {
        .slick-track {
          display: flex;

          .slick-slide {
            box-sizing: border-box;
            padding-right: 10px;

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }

    div {
      @screen lg {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
</style>
