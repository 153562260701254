<script>
  import { createNamespacedHelpers } from 'vuex'
  import { Card } from 'vue-stripe-elements-plus'
  import { ValidationObserver } from 'vee-validate'
  import { eventBus } from '@/main.js'
  import BaseCheckbox from '@/components/BaseCheckbox'
  import BaseError from '@/components/BaseError'
  import CheckoutAddressForm from '@/components/checkout/CheckoutAddressForm'
  import GlobalAcceptedPayments from '@/components/global/GlobalAcceptedPayments.vue'
  import IconCheck from '@/images/icons/check.svg'

  const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers('checkout')

  /**
   * Payment details step of the checkout flow
   */
  export default {
    name: 'CheckoutStepPayment',

    components: {
      Card,
      CheckoutAddressForm,
      BaseCheckbox,
      BaseError,
      GlobalAcceptedPayments,
      ValidationObserver,
      IconCheck,
    },

    props: {
      /** confirmationCheckbox contains default properties 'checked, required, label' */
      confirmationCheckbox: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        stripeOptions: {
          hidePostalCode: true,
          style: {
            base: {
              fontSize: '15px',
              fontWeight: '300',
              fontFamily: 'Unica77, "Helvetica Neue", Helvetica, Arial, sans-serif',
              color: 'rgb(68,68,68)',
              '::placeholder': {
                color: '#cdcdcd',
              },
            },
          },
        },
        cardComplete: false,
        billingSameAsShipping: null,
        billingAddress: null,
        billingAddressError: null,
        submitting: false,
        confirmTradeUserCheckbox: this.confirmationCheckbox.checked,
      }
    },

    computed: {
      /** Vuex getters */
      ...mapGetters([
        'getStripeKey',
        'getProcessingPayment',
        'getPaymentError',
        'getOrderComplete',
        'getBillingSameAsShipping',
        'getBillingAddress',
        'getCardComplete',
        'getPaymentIsSubmitting',
        'getConfirmTradeUserCheckbox',
      ]),
    },

    watch: {
      getBillingSameAsShipping: {
        immediate: true,
        handler() {
          this.billingSameAsShipping = this.getBillingSameAsShipping
        },
      },
      getBillingAddress: {
        immediate: true,
        handler() {
          this.billingAddress = this.getBillingAddress
        },
      },
      billingSameAsShipping() {
        this.setBillingSameAsShipping(this.billingSameAsShipping)
      },
      confirmTradeUserCheckbox(value) {
        this.setConfirmTradeUserCheckbox({
          checked: value,
          required: this.getConfirmTradeUserCheckbox.required,
          label: this.getConfirmTradeUserCheckbox.label,
        })
      },
    },

    created() {
      eventBus.$on('submit-payment-details', () => {
        this.submit()
      })
    },

    methods: {
      /** Vuex actions */
      ...mapActions(['saveBillingAddress', 'pay']),
      ...mapMutations([
        'setCardComplete',
        'setBillingSameAsShipping',
        'setConfirmTradeUserCheckbox',
      ]),
      /** Saves the billing address and completes the order */
      async submit() {
        if (this.getPaymentIsSubmitting) {
          return
        }
        if ((await this.$refs.billingValidationObserver.validate()) !== true) {
          return
        }
        this.$store.commit('checkout/setPaymentIsSubmitting', true)
        this.billingAddressError = null
        try {
          await this.saveBillingAddress({
            billingSameAsShipping: this.billingSameAsShipping,
            billingAddress: this.billingAddress,
          })
          await this.pay()
          this.$store.commit('checkout/setPaymentIsSubmitting', false)
        } catch (error) {
          this.$store.commit('checkout/setPaymentIsSubmitting', false)
          this.billingAddressError = error
        }
      },

      async formUpdating() {
        const isValid = await this.$refs.billingValidationObserver.validate({ silent: true })

        this.$store.commit('checkout/setIsPaymentValid', isValid)

        if (this.getBillingSameAsShipping) {
          this.$store.commit('checkout/setIsPaymentValid', this.getBillingSameAsShipping)
        }
      },
    },
  }
</script>

<template>
  <ValidationObserver ref="billingValidationObserver">
    <form @change="formUpdating" @submit.prevent="submit">
      <div class="mb-7 pb-7 border-b border-gray-300">
        <h2 class="text-xs text-gray-700 uppercase tracking-widest mb-2">Credit Card</h2>

        <div class="relative">
          <Card
            class="border border-gray-300 px-5 py-3"
            :stripe="getStripeKey"
            :options="stripeOptions"
            @change="setCardComplete($event.complete)"
          />
          <IconCheck
            v-show="getCardComplete"
            class="absolute fill-current || check check--verify"
          />
        </div>

        <BaseError v-if="getPaymentError" :error="getPaymentError" />
      </div>

      <div class="mb-7 pb-7 border-b border-gray-300">
        <h2 class="text-sm mb-4">Billing Details</h2>

        <BaseCheckbox v-model="billingSameAsShipping" label="Use same address as shipping" />

        <div
          :class="`${
            !billingSameAsShipping
              ? 'h-auto opacity-100'
              : 'invisible h-0 opacity-0 -translate-y-6 pointer-events-none'
          } mt-7 transform transition duration-150 ease-in-out`"
        >
          <CheckoutAddressForm v-model="billingAddress" type="billing" />
          <BaseError v-if="billingAddressError" :error="billingAddressError" />
        </div>
      </div>

      <div class="mb-7 text-sm md:w-1/2">
        <p class="mb-4">
          For your safety and security we require that you enter your card verification number (also
          called card security code, CVV, CVV2, or CID). This is the last 3 digits printed on the
          back of your card.
        </p>
        <GlobalAcceptedPayments />
      </div>
      <div class="mt-4">
        <BaseError
          v-if="!getConfirmTradeUserCheckbox.checked && getConfirmTradeUserCheckbox.required"
          error="Field is required"
        />
        <BaseCheckbox
          v-if="getConfirmTradeUserCheckbox.label !== ''"
          v-model="confirmTradeUserCheckbox"
          :label="getConfirmTradeUserCheckbox.label"
        />
      </div>
    </form>
  </ValidationObserver>
</template>

<style scoped lang="postcss">
  .check,
  >>> .check {
    width: 10px;
    height: 10px;
  }

  .spinner,
  >>> .spinner {
    width: 10px;
    height: 10px;
    transform: scale(1.5);
    transform-origin: center;
  }

  .check--verify {
    top: 50%;
    right: 15px;
    margin-top: -5px;
    pointer-events: none;
  }
</style>
