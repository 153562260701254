<script>
  /**
   * Links for the different steps of the checkout flow (i.e.: Contact, Address, Payment)
   */
  export default {
    name: 'CheckoutLink',

    props: {
      /** Whether this link represents the current active step */
      active: {
        type: Boolean,
        default: false,
      },
      /** Whether this link is clickable */
      clickable: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<template>
  <component
    :is="clickable ? 'button' : 'div'"
    :type="clickable ? 'button' : undefined"
    :class="`${active ? 'text-black' : 'text-gray-500'} ${
      clickable ? 'hover:text-black' : ''
    } uppercase font-bold text-md transition ease-in-out duration-300 tracking-widest truncate`"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>
