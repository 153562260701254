<script>
  import AccountDashboardLayout from '@/components/layouts/AccountDashboardLayout'

  /*
   * Renders the account dashboard for logged in users
   */

  export default {
    name: 'AccountDashboard',
    components: { AccountDashboardLayout },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<template>
  <AccountDashboardLayout :user="user"> </AccountDashboardLayout>
</template>
