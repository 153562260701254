<script>
  /*
   * The thank you page on a successful user sign up
   */

  export default {
    name: 'AppointmentThanks',
    data() {
      return {
        firstName: '',
        location: ''
      }
    },
    mounted() {
      this.firstName = localStorage.getItem('name')
      this.location = localStorage.getItem('location')
    },
    destroyed() {
      localStorage.removeItem('name')
      localStorage.removeItem('message')
    },
  }
</script>

<template>
  <div class="max-w-6xl container mx-auto mt-32 mb-36 px-4 flex flex-col items-center justify-start">
    <h1 class="text-2xl lg:text-4xl text-center mb-12 lg:mb-16">FORM SUBMITTED SUCCESSFULLY</h1>

    <p class="text-center text-xl lg:text-2xl leading-tight mb-8">
      Thank you, <span id="firstName" class="font-bold">{{ firstName }}</span>, for your submission.
    </p>

    <p class="text-center text-xl lg:text-2xl leading-tight font-medium mb-8">
      A design specialist will confirm your appointment soon.
    </p>

    <a
      v-if="location"
      class="font-semibold text-sm block text-center button-height md:px-26 md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 tracking-wider"
      :href="`${ location === 'NYC' ? '/modern-furniture?s=newyork' : '/modern-furniture?s=chicago' }`"
    >
      See What's On Display In {{ location }}
    </a>

  </div>
</template>
