<script>
  /** These are a group of buttons that function as radio buttons. */
  export default {
    name: 'BaseToggleButtons',

    model: {
      prop: 'value',
      event: 'change',
    },

    props: {
      /** Label for the field */
      label: {
        type: String,
        default: '',
      },
      /** Whether this field is required */
      isRequired: {
        type: Boolean,
        default: false,
      },
      /** Options for the toggle */
      options: {
        type: Array,
        required: true,
      },
      /** For binding the value */
      value: {
        type: [String, Number],
        required: true,
      },
    },

    computed: {
      /** Create a unique label id for matching the label and field */
      labelId() {
        const slugify = require('@sindresorhus/slugify')
        return slugify(this.label) + '_options'
      },
    },

    methods: {
      /** Match the current option's id against the v-model to see if it's selected */
      isSelected(option) {
        return this.value === option.id
      },
      /** Pass the option's id or label to the parent */
      updateSelectedOption(option) {
        this.$emit('change', option.id)
        this.$refs.toggleButton.forEach(function (button) {
          button.blur()
        })
      },
    },
  }
</script>

<template>
  <div>
    <label
      v-if="label"
      :id="labelId"
      class="text-sm leading-none uppercase block mb-3 font-semibold"
      >{{ label }}</label
    >

    <div role="radiogroup" :aria-labelledby="labelId" class="buttons--grid">
      <button
        v-for="option in options"
        ref="toggleButton"
        :key="option.id"
        role="radio"
        :aria-checked="isSelected(option)"
        class="filter--buttons"
        :class="{
          'bg-black text-white': isSelected(option),
          ' text-black bg-transparent': !isSelected(option),
        }"
        @click="updateSelectedOption(option)"
      >
        {{ option.label }}
        <span v-if="option.description" class="normal-case">({{ option.description }})</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .buttons--grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
  }

  .filter--buttons {
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 14px;
    line-height: 1;
    border: 1px solid #000;
  }

  .button-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
</style>
