<script>
  import BaseTag from '@/components/BaseTag'

  /** A reusable tag group component for using multiple Base Tags components */

  export default {
    name: 'BaseTagGroup',

    components: {
      BaseTag,
    },

    props: {
      /** An array of tag data for the tags to be displayed */
      tags: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    computed: {
      // TODO: This needs to be dynamic!
      temporaryTags() {
        const tags = []
        let hasExcludedTag = false

        for (const tag of this.tags) {
          hasExcludedTag = hasExcludedTag || tag.label === 'New York' || tag.label === 'Chicago'
        }

        for (const tag of this.tags) {
          if (tag.label === 'In Showroom' && !hasExcludedTag) {
            tags.push(tag)
          } else if (tag.label !== 'In Showroom') {
            tags.push(tag)
          }
        }

        return tags
      },
    },
  }
</script>

<template>
  <ul v-if="temporaryTags.length" class="w-full flex flex-col md:flex-row flex-wrap">
    <li v-for="(tag, index) in temporaryTags" :key="index">
      <BaseTag v-bind="tag"></BaseTag>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
  ul {
    padding: 0;
    margin: 0 -5px;
  }

  li {
    margin: 0 5px;
    list-style: none;
  }
</style>
