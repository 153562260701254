<script>
  /** Builder Section heading */

  export default {
    name: 'BuilderHeading',

    props: {
      /** The text of the heading */
      heading: {
        type: String,
        required: true,
      },
      /** The tailwind font size */
      fontSize: {
        type: String,
        required: true,
      },
      /** The heading element */
      headingElement: {
        type: String,
        required: true,
      },
      /** The vertical padding */
      padding: {
        type: String,
        required: true,
      },
    },
    render: function (createElement) {
      return createElement(
        this.headingElement, // tag name
        {
          class: `${
            this.headingElement === 'h2'
              ? 'pt-2 pb-4 font-bold font-unica77 uppercase'
              : 'pt-2 pb-4 font-bold'
          }`,
        },
        this.heading
      )
    },
  }
</script>

<style lang="postcss" scoped>
  h2 {
    font-size: 19px;
    letter-spacing: 0.05em;
  }
  h3 {
    @apply font-bold;
    font-size: 16px;
    line-height: 120%;
    @apply pb-2;
  }
</style>
