<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseFilterButton from '@/components/BaseFilterButton.vue'
  import BaseFilterSelectSearchable from '@/components/BaseFilterSelectSearchable.vue'
  import BaseFilterCheckbox from '@/components/BaseFilterCheckbox.vue'
  import BaseFilterSelect from '@/components/BaseFilterSelect.vue'
  import IconFilters from '@/images/icons/filters.svg'

  const { mapState, mapGetters, mapActions } = createNamespacedHelpers('products')

  /** The bar of filters used in the PLP */

  export default {
    components: {
      BaseFilterButton,
      BaseFilterSelectSearchable,
      BaseFilterCheckbox,
      BaseFilterSelect,
      IconFilters,
    },

    props: {
      /** Whether the filter bar is open or not (mobile view) */
      open: {
        type: Boolean,
        required: false,
      },
      /** The context in which this filter is being used */
      context: {
        type: String,
        required: false,
        default: 'plp',
        validator: (propValue) => {
          const validTypes = ['plp', 'search']
          return validTypes.indexOf(propValue) > -1
        },
      },
    },

    data() {
      return {
        isOpen: this.open,
      }
    },

    computed: {
      ...mapState(['filterValues', 'sortOptions', 'sort']),
      ...mapGetters([
        'getFilters',
        'getUsedFilters',
        'getFacetedFilterOptions',
        'getFilterEnabledStates',
        'getFilteredProducts',
      ]),
      toggleButtonAriaLabel() {
        return this.isOpen ? 'Close filters' : 'Open filters'
      },
    },

    watch: {
      open() {
        this.isOpen = this.open
      },
    },

    methods: {
      ...mapActions(['setFilterValue', 'clearFilters', 'setSort']),
      toggle() {
        this.isOpen = !this.isOpen
        this.$emit('toggle', this.isOpen)
      },
    },
  }
</script>

<template>
  <div
    class="z-30 sticky bg-white"
    :class="{ 'bar-search': context === 'search', bar: context === 'plp' }"
  >
    <div class="lg:hidden border-b border-gray-300">
      <BaseFilterButton
        label="Filters"
        :aria-label="toggleButtonAriaLabel"
        :emphasis="getUsedFilters.length > 0"
        @click="toggle"
      >
        <template v-slot:icon>
          <IconFilters class="mr-2 w-3 h-3 flex-shrink-0 fill-current" />
        </template>
      </BaseFilterButton>
    </div>
    <div :class="`${isOpen ? 'flex' : 'hidden'} lg:flex flex-wrap lg:flex-no-wrap`">
      <div v-for="(filter, filterIndex) in getFilters" :key="filter.handle" class="bar-filter">
        <BaseFilterSelectSearchable
          v-if="filter.type === 'select'"
          :label="filter.label"
          :options="getFacetedFilterOptions[filter.handle]"
          :value="filterValues[filter.handle] ? filterValues[filter.handle] : []"
          :disabled="getFilterEnabledStates[filter.handle] === false"
          :button-classes="filterIndex === 0 ? 'lg:pl-6' : undefined"
          @change="setFilterValue({ filter: filter.handle, value: $event })"
        />
        <BaseFilterCheckbox
          v-if="filter.type === 'checkbox'"
          :label="filter.label"
          :checked="
            filterValues[filter.handle] ||
            (getFilterEnabledStates[filter.handle] === false &&
              getFilteredProducts.length > 0 &&
              getFilteredProducts[0][filter.property] === true)
          "
          :disabled="getFilterEnabledStates[filter.handle] === false"
          :class="filterIndex === 0 ? 'lg:pl-6' : undefined"
          @change="setFilterValue({ filter: filter.handle, value: $event })"
        />
      </div>
      <div class="flex w-full lg:w-auto min-w-0 flex-auto lg:border-l border-gray-300">
        <div class="bar-filter">
          <BaseFilterButton
            label="Clear filters"
            text-align="center"
            :disabled="getUsedFilters.length === 0"
            @click="clearFilters"
          />
        </div>
        <div class="bar-filter">
          <BaseFilterSelect
            label="Sort by"
            :options="sortOptions"
            :value="sort"
            class="lg:pr-6"
            @change="setSort($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .bar {
    top: theme('spacing.header-mobile');
    transition: top 300ms;

    @screen lg {
      top: theme('spacing.header-desktop');
    }
  }

  .bar-search {
    top: calc(theme('spacing.header-mobile') + 92px);

    @screen lg {
      top: calc(theme('spacing.header-desktop') + 46px);
    }
  }

  .bar-filter {
    @apply min-w-1/2 flex-1 border-b border-gray-300;

    @screen xs {
      @apply min-w-1/3;
    }

    @screen lg {
      @apply min-w-0 flex-auto;
    }

    & + .bar-filter {
      @apply border-l;
    }
  }
</style>
