<script>
  import { createNamespacedHelpers } from 'vuex'
  import { ValidationObserver } from 'vee-validate'
  import { eventBus } from '@/main.js'
  import CheckoutAddressForm from '@/components/checkout/CheckoutAddressForm'
  import api from '@/api'

  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')
  const {
    mapGetters: mapCheckoutGetters,
    mapActions: mapCheckoutActions,
    mapMutations: mapCheckoutMutations,
  } = createNamespacedHelpers('checkout')

  const zipCodesAreCloseEnough = (zipCode1, zipCode2) =>
    zipCode1.replace(/\s/g, '').slice(0, 5) === zipCode2.replace(/\s/g, '').slice(0, 5)

  /**
   * Shipping address step of the checkout flow
   */
  export default {
    name: 'CheckoutStepShipping',

    components: {
      ValidationObserver,
      CheckoutAddressForm,
    },

    data() {
      return {
        loginPassword: '',
        loginSubmitting: false,
        loginError: null,
        shippingAddress: null,
        shippingSubmitting: false,
        shippingError: null,
      }
    },

    computed: {
      ...mapGlobalGetters(['loggedIn']),
      ...mapCheckoutGetters(['getEmail', 'getHasAccount', 'getShippingAddress', 'getStates']),
    },

    watch: {
      getShippingAddress: {
        immediate: true,
        handler() {
          this.shippingAddress = this.getShippingAddress
        },
      },
    },
    created() {
      eventBus.$on('submit-shipping', () => {
        this.submitShipping()
      })
    },
    mounted() {
      if (this.loggedIn) {
        this.formUpdating()
      }
    },
    methods: {
      ...mapCheckoutActions(['logIn', 'saveShippingAddress']),
      ...mapCheckoutMutations(['setCurrentStep', 'setIsShippingValid', 'setShippingIsSubmitting']),
      async submitLogin() {
        // Return if it's already submitting, or there's an empty password
        if (this.loginSubmitting || this.loginPassword === '') {
          return
        }
        this.loginSubmitting = true
        this.loginError = null
        try {
          await this.logIn({
            email: this.getEmail,
            password: this.loginPassword,
          })
          this.loginSubmitting = false
        } catch (error) {
          this.loginSubmitting = false
          this.loginError = error
        }
      },
      async submitShipping() {
        if (this.shippingSubmitting) {
          return
        }
        if ((await this.$refs.shippingValidationObserver.validate()) !== true) {
          return
        }
        this.$store.commit('checkout/setShippingIsSubmitting', true)
        this.shippingError = null
        try {
          // Validate the address
          const validationResponse = await api.post('avatax/json/validate-address', {
            address1: this.shippingAddress.address1,
            address2: this.shippingAddress.address2,
            city: this.shippingAddress.city,
            stateValue: this.getStates.find((state) => state.id === this.shippingAddress.stateId)
              ?.name,
            zipCode: this.shippingAddress.zipCode,
            countryId: 233, // United States
          })
          const validationSuccess = validationResponse.data?.success ?? false
          const submittedAddress = validationResponse.data?.response?.address
          const validatedAddress = validationResponse.data?.response?.validatedAddresses?.[0]
          const validationMessages = validationResponse.data?.response?.messages ?? []
          if (
            !validationSuccess ||
            !submittedAddress ||
            !validatedAddress ||
            validationMessages.some(
              // Ignoring "address number is out of range" errors because there can be false positives (I've seen valid addresses return this error)
              (message) => message.severity === 'Error' && !message.summary.includes('out of range')
            ) ||
            submittedAddress.region !== validatedAddress.region ||
            !zipCodesAreCloseEnough(submittedAddress.postalCode, validatedAddress.postalCode)
          ) {
            throw new Error()
          }
          // Save the address if it's valid
          await this.saveShippingAddress(this.shippingAddress)
          this.$store.commit('checkout/setShippingIsSubmitting', false)
          this.setCurrentStep('payment')
        } catch (error) {
          this.$store.commit('checkout/setShippingIsSubmitting', false)
          this.shippingError = 'This address looks invalid. Please try again!'
        }
      },

      async formUpdating() {
        const isValid = await this.$refs.shippingValidationObserver.validate({ silent: true })
        if (isValid) {
          this.$store.commit('checkout/setIsShippingValid', isValid)
        } else {
          this.$store.commit('checkout/setIsShippingValid', isValid)
        }
      },
    },
  }
</script>

<template>
  <div>
    <h1 class="mb-4 text-base font-normal">Welcome, {{ getEmail }}.</h1>

    <!--@TODO: Change this to use a logout action instead and reload the page.-->
    <div v-if="loggedIn" class="mb-10">
      <p class="mb-4 text-base font-light">
        Don't want to use this account? <a href="/logout">Log out.</a>
      </p>
    </div>

    <div v-if="!loggedIn && getHasAccount" class="mb-10">
      <p class="text-base font-light my-5">
        You have an account with us. Sign in to speed up checkout and apply any trade discounts.
      </p>

      <ValidationObserver v-slot="{ invalid }">
        <form
          aria-label="Sign in form"
          class-="md:flex justify-between gap-6"
          @submit.prevent="submitLogin"
        >
          <div class="mb-4 lg:mb-6 md:w-1/2">
            <BaseInputText
              v-model="loginPassword"
              label="Password"
              type="password"
              class="whitespace-nowrap"
            />
            <BaseError v-if="loginPassword && loginError" :error="loginError" />
          </div>
          <div class="mb-4 lg:mb-6 flex flex-wrap items-center md:w-1/2">
            <BaseButton
              color="gray"
              type="submit"
              :disabled="invalid"
              :loading="loginSubmitting"
              class="mr-6"
            >
              Log in
            </BaseButton>
            <a
              :href="`/forgotpassword?username=${getEmail}&redirect=shop/checkout`"
              class="text-xs tracking-widest uppercase hover:text-gray-700"
            >
              Forgot your password?
            </a>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <h1 class="mb-4 text-base font-normal">Shipping Address</h1>
    <ValidationObserver ref="shippingValidationObserver">
      <form aria-label="Shipping Address" @change="formUpdating" @submit.prevent="submitShipping">
        <CheckoutAddressForm v-model="shippingAddress" type="shipping" />
        <BaseError v-if="shippingError" :error="shippingError" />
      </form>
    </ValidationObserver>
  </div>
</template>
