<script>
  /*
   * The confirmation page after a user has placed an order
   */
  import { createNamespacedHelpers } from 'vuex'
  import ConfirmationLayout from '@/components/layouts/ConfirmationLayout'
  import CartLineItemsFullConfirmation from '@/components/cart/CartLineItemsFullConfirmation'
  import Print from '@/images/icons/print.svg'
  import IconSpinner from '@/images/icons/spinner.svg'
  import { formatCurrency } from '@/utilities/strings'

  const { mapGetters: mapOrderGetters } = createNamespacedHelpers('order')
  const { mapState: mapGlobalState } = createNamespacedHelpers('global')

  export default {
    name: 'ConfirmationPage',
    components: {
      ConfirmationLayout,
      CartLineItemsFullConfirmation,
      IconSpinner,
      Print,
    },
    computed: {
      ...mapOrderGetters(['getOrder', 'getIsLoading']),
      ...mapGlobalState(['orderNumber']),
      order() {
        return this.getOrder
      },
      orderPdfFilename() {
        return `Order-${this.order.shortNumber}.pdf`
      },
      orderPdfHref() {
        return `data:application/octet-stream;charset=utf-16le;base64,${this.order.orderPdf}`
      },
    },
    created() {
      this.$store.dispatch('order/loadOrder', this.orderNumber).then(() => {
        if (this.$gtm.enabled()) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'Purchase',
            email: this.$store.getters['global/currentUser']
              ? this.$store.getters['global/currentUser'].email
              : '',
            order_quantity: this.order.qty,
            order_id: this.order.number,
            value: this.order.total,
          })
        }
      })

      if (window.location.search === '') {
        window.location.href = '/shop/cart'
      }
    },
    mounted() {
      // Send the appropriate GTM event
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'Purchase',
          email: this.$store.getters['global/currentUser']
            ? this.$store.getters['global/currentUser'].email
            : '',
          value: this.order.total,
          order_quantity: this.order.qty,
          order_id: this.order.number,
        })
      }
    },
    methods: {
      formatCurrency,
    },
  }
</script>

<template>
  <div>
    <div v-if="getIsLoading" class="lg:flex-grow lg:min-h-0 fixed z-50 w-full h-full bg-white">
      <IconSpinner class="absolute -inset-1/4 m-auto stroke-current transform scale-200" />
    </div>
    <div v-else class="lg:flex lg:flex-col lg:flex-grow lg:min-h-0">
      <div class="bg-orange-500 px-4 lg:px-6 py-12 text-white">
        <h1 class="text-3xl">Thank you for your order.</h1>
        <p class="tracking-wide"> It's on its way. We've sent you a confirmation email. </p>
      </div>

      <ConfirmationLayout>
        <template v-slot:top>
          <div class="hidden lg:flex flex-grow">
            <h3 class="w-1/3 font-bold">Order details</h3>

            <div class="w-2/3 grid grid-cols-4 pl-3">
              <p class="break-words">
                <span class="mr-1">Order Number</span>
                <span class="font-light tracking-wide">{{ order.number }}</span>
              </p>

              <div></div>

              <p class="break-words flex flex-col">
                <span class="mr-1">Order Date</span>
                <span class="font-light tracking-wide">{{ order.date }}</span>
              </p>

              <div class="flex justify-end">
                <a :href="orderPdfHref" :download="orderPdfFilename" class="flex">
                  <Print class="mr-1 mt-1" /> Print Page
                </a>
              </div>
            </div>
          </div>
          <div class="flex flex-col lg:hidden">
            <div class="flex flex-row justify-between px-4 pb-6">
              <p class="uppercase text-black-700 text-base font-bold tracking-widest">
                Order Details
              </p>
              <a
                :href="orderPdfHref"
                :download="orderPdfFilename"
                class="flex items-center text-xs"
              >
                <Print class="mr-1" /> Print Page
              </a>
            </div>
            <div class="border-t border-gray-300 px-4 lg:px-6 pt-6">
              <p class="text-base font-formal text-black flex flex-row">
                Order Number &nbsp; <span class="font-light">{{ order.number }}</span>
              </p>
              <p class="text-base font-formal text-black flex flex-row">
                Order Date &nbsp; <span class="font-light">{{ order.date }}</span>
              </p>
            </div>
          </div>
        </template>

        <template v-slot:left>
          <div class="border-b border-gray-300 mb-4 pb-4">
            <h4 class="mb-1">Email</h4>
            <p class="font-light">{{ order.email }} </p>
          </div>

          <div class="border-b border-gray-300 mb-4 pb-4">
            <h4 class="mb-1">Shipping</h4>

            <div class="mb-4 font-light leading-6">
              <p>{{ order.shippingAddress.firstName }} {{ order.shippingAddress.lastName }}</p>
              <p>{{ order.shippingAddress.phone }}</p>
            </div>

            <!-- <p class="mb-4 font-light">
              Method: {{ order.paymentMethod.cardType }} ends with
              {{ order.paymentMethod.endsWith }}
            </p> -->

            <address class="mb-4 font-light not-italic leading-6">
              <p>{{ order.shippingAddress.street }}</p>
              <p>{{ order.shippingAddress.city }}</p>
              <p> {{ order.shippingAddress.state }}</p>
              <p>{{ order.shippingAddress.zip }}</p>
              <p>{{ order.shippingAddress.country }} </p>
            </address>

            <div class="flex justify-between font-light">
              <p>Fees</p>
              <p>{{
                formatCurrency(order.shippingCost, 0)
              }}</p>
            </div>
          </div>

          <div class="border-b border-gray-300 mb-4 pb-4">
            <h4 class="mb-1">Billing</h4>

            <div class="mb-4 font-light leading-6">
              <p>{{ order.billingAddress.firstName }} {{ order.billingAddress.lastName }}</p>
              <p>{{ order.billingAddress.phone }}</p>
            </div>

            <!-- <p class="mb-4 font-light">
              Method: {{ order.paymentMethod.cardType }} ends with
              {{ order.paymentMethod.endsWith }}
            </p> -->

            <address class="mb-4 font-light not-italic leading-6">
              <p>{{ order.billingAddress.city }}</p>
              <p> {{ order.shippingAddress.state }}</p>
              <p>{{ order.billingAddress.zip }}</p>
              <p>{{ order.billingAddress.country }} </p>
            </address>

            <div class="flex justify-between font-light">
              <p>Taxes</p>
              <p>{{
                formatCurrency(order.tax, 0)
              }}</p>
            </div>
          </div>

          <div>
            <h4 class="mb-1">Discount</h4>
            <div class="flex justify-between font-light">
              <p>Discount</p>
              <p> -{{
                formatCurrency(order.discount, 0)
              }}</p>
            </div>
          </div>
        </template>

        <template v-slot:right>
          <header class="py-6 border-t border-gray-300 lg:hidden">
            <p class="text-black-700 text-base"> Order Summary </p>
          </header>
          <CartLineItemsFullConfirmation :items="order.lineItems" />
        </template>
        <template v-slot:bottom>
          <p class="flex justify-between lg:justify-end text-base text-black-500 font-medium">
            Grand Total &nbsp;<span class="lg:font-normal">{{
              formatCurrency(order.total, 0)
            }}</span>
          </p>
        </template>
      </ConfirmationLayout>
    </div>
  </div>
</template>
