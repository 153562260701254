<script>
  // eslint-disable-next-line import/no-relative-parent-imports
  import { createNamespacedHelpers } from 'vuex'
  import { eventBus } from '@/main.js'
  import api from '@/api'
  import BaseSlidePanel from '@/components/BaseSlidePanel'
  import BaseConfigPanel from '@/components/BaseConfigPanel'
  import BaseLink from '@/components/BaseLink'
  import ProductQuoteForm from '@/components/products/ProductQuoteForm'
  import CartLineItemsCompact from '@/components/cart/CartLineItemsCompact'
  import IconAccount from '@/images/icons/account.svg'

  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')
  const { mapGetters: mapListGetters } = createNamespacedHelpers('lists')

  export default {
    name: 'ProductActions',

    components: {
      BaseLink,
      BaseSlidePanel,
      ProductQuoteForm,
      CartLineItemsCompact,
      IconAccount,
      BaseConfigPanel,
    },

    props: {
      popupIframe: {
        type:String,
        default:''
      },
      /** Whether the entire product is available for purchase */
      isAvailableForPurchase: {
        type: Boolean,
        required: true,
        default: false,
      },
      /** The product object */
      product: {
        type: Object,
        required: true,
      },
      thumb: {
        type: String,
        default: '',
      },
      /** The selected variant (purchasable) */
      variant: {
        type: Object,
        default: undefined,
      },
      /** The products categories */
      categories: {
        type: Array,
        default: () => [],
      },
      /** Selected Product Attribute options */
      allOptions: {
        type: Object,
        required: false,
        default: () => {},
      },
      /** Selected swatches */
      selectedSwatches: {
        type: Array,
        required: false,
        default: () => {},
      },
      /** Product brand (passed to quote form) */
      brand: {
        type: Object,
        required: true,
      },
      /** Form data for quote request */
      form: {
        type: Object,
        required: true,
      },
      priceForm: {
        type: Object,
        required: true,
      },
      /** URLs for redirects */
      urls: {
        type: Object,
        required: true,
      },
      /** Array of previously saved products */
      savedProducts: {
        type: Array,
        default: () => [],
      },
      /** Returns true if current variant/options have been saved previously */
      isSaved: {
        type: Boolean,
        default: false,
      },
      variants: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        cartMessageOpen: false,
        quotePanelOpen: false,
        configPanel:false,
        isAddingToCart: false,
        addedToCart: false,
        quantity: 1,
        cart: {},
        hasPrice: false,
      }
    },

    created() {
      const defaults = this.variants.filter((item) => item.isDefault)
      if (defaults.length) {
        if (defaults[0].basePrice > 0) {
          this.hasPrice = true
        }
      }
    },

    computed: {
      ...mapGlobalGetters(['currentUser', 'loggedIn', 'isTradeAccount']),
      ...mapListGetters(['getIsSaving', 'getIsRemoving', 'getHasLoaded']),
      /** True if the variant stock is 0 and not unlimited. */
      isOutOfStock: function () {
        return this.variant && this.variant.stock.qty === '0' && !this.variant.stock.unlimited
      },

      /** True if variant is out of stock, product is not available for purchase, or variant price is 0. */
      isNotAvailableOnline: function () {
        if (!this.variant) {
          return true
        }
        const basePrice = parseInt(this.variant.basePrice)
        return !this.isAvailableForPurchase || basePrice === 0 || this.isOutOfStock
      },

      /** True if not available online or currently adding to cart. */
      disableCartButton: function () {
        return this.isAddingToCart || this.isNotAvailableOnline
      },

      /** Title attribute text for disabled add to cart button. */
      addToCartTitle: function () {
        if (!this.variant) {
          return 'This combination is not available.'
        }
        return this.isNotAvailableOnline ? 'Product not available for online purchase.' : null
      },
      saveProductLabel: function () {
        if (this.getIsRemoving) {
          return 'Removing...'
        } else if (this.getIsSaving) {
          return 'Saving...'
        } else if (!this.isSaved) {
          return 'Save To Favorites'
        } else {
          return '✔️ Saved'
        }
      },
      categoryString: function () {
        const catArray = []
        this.categories.forEach((category) => {
          catArray.push(category.title)
        })
        return catArray.join('/')
      },
    },

    watch: {
      quantity(item) {
        if (item <= 0) {
          this.quantity = 1
        }
      },
    },

    methods: {
      closeConfigPanel() {
        this.configPanel = false
        document.querySelector('body').classList.remove('overflow-hidden')
      },
      openConfigPanel() {
        this.configPanel = true
        document.querySelector('body').classList.add('overflow-hidden')
      },
      quantityUp() {
        this.quantity = this.quantity + 1
      },
      quantityDown() {
        this.quantity = this.quantity - 1
        if (this.quantity <= 0) {
          this.quantity = 1
        }
      },
      changeLineItemQty(event) {
        this.$store.dispatch('cart/changeItemQty', { id: event.id, qty: this.quantity })
      },
      addToCart() {
        // Blur cart button
        this.$refs.cartButton.blur()

        // Set the 'adding' state
        this.isAddingToCart = true

        // Make the request
        // new submit form request
        api
          .post('commerce/cart/update-cart', {
            purchasableId: this.variant.id,
            options: this.allOptions,
            qty: this.quantity,
          })
          .then((response) => {
            if (response.status === 200) {
              this.addedToCart = true
              this.cart = response.data.cart
              eventBus.$emit('cart-updated')
              // Send the appropriate GTM event
              if (this.$gtm.enabled()) {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'Add_To_Cart',
                  email: this.currentUser ? this.currentUser.email : '',
                  value: this.variant.basePrice,
                  order_quantity: 1,
                  order_id: this.cart.id,
                  product_id: this.variant.id,
                  product_category: this.categoryString,
                  product_name: this.variant.title,
                  product_sku: this.variant.sku,
                })
              }

              // Load the user’s cart
              this.$store.dispatch('cart/loadCart')
            } else {
              this.addedToCart = false
            }
            this.cartMessageOpen = true
            this.isAddingToCart = false
          })
      },

      closeCartMessage() {
        this.cartMessageOpen = false
      },

      openQuotePanel() {
        this.quotePanelOpen = true
        this.$refs.quoteButton.blur()
        // Hide the notification element if it exists
        const noticeElem = document.getElementById('notice')
        if (noticeElem) {
          noticeElem.classList.add('is-hidden')
        }
      },

      closeQuotePanel() {
        this.quotePanelOpen = false
        this.$refs.quoteForm.resetForm()
        // Show the notification element if it exists
        const noticeElem = document.getElementById('notice')
        if (noticeElem) {
          noticeElem.classList.remove('is-hidden')
        }
      },

      saveOrRemoveProduct() {
        if (!this.currentUser) {
          window.location.href = '/login'
        } else {
          if (!this.isSaved) {
            this.$store.dispatch('lists/addItem', {
              variantId: this.variant.id,
              options: this.allOptions,
            })
          } else {
            this.$store.dispatch('lists/removeItem', {
              variantId: this.variant.id,
              options: this.allOptions,
            })
          }
        }
      },

      // Syncs the 'Started Checkout' event with the user profile in Klaviyo
      proceedWithCheckout() {
        if (this.loggedIn) {
          api.startedCheckout(this.currentUser.email).then(() => {
            window.location.href = '/shop/checkout'
          })
        } else {
          window.location.href = '/shop/checkout'
        }
      },
    },
  }
</script>

<template>
  <div>
    <div
      v-if="product.isAvailable"
      class="flex flex-no-wrap flex-col xl:flex-row justify-start xl:justify-end items-stretch md:border-0 md:pb-0"
      :class="`${product.isAvailable ? 'mb-6' : 'mb-12'}`"
    >
      <div class="addQuantityInput w-full xl:w-3/12 mb-3 xl:mb-0 pr-0 xl:pr-4">
        <button class="quantBtn btnMinus" @click="quantityDown">-</button>
        <input v-model="quantity" type="number" class="block w-full" />
        <button class="quantBtn btnPlus" @click="quantityUp">+</button>
      </div>

      <button
        ref="cartButton"
        class="btn btn--black w-full xl:w-9/12 py-0 button-height flex justify-center uppercase font-bold items-center tracking-widest"
        type="button"
        data-cy="cart-button"
        :disabled="disableCartButton"
        :title="addToCartTitle"
        @click="addToCart"
      >
        <span class="text-sm">{{ isAddingToCart ? 'Adding To Cart' : 'Add To Cart' }}</span>
        <!--<span class="w-3 h-3">
          <IconPlus class="fill-current" />
        </span>-->
      </button>
    </div>

    <div class="flex flex-wrap flex-col lg:flex-row w-full mb-12">
      <button
        ref="quoteButton"
        class="btn btn--black w-full flex justify-center uppercase font-bold items-center tracking-widest py-0 button-height xl:w-6/12"
        type="button"
        data-cy="quote-button"
        @click="openQuotePanel"
      >
        <span class="text-sm">Inquire</span>
        <!--<span class="w-3 h-3">
          <IconPlus class="fill-current" />
        </span>-->
      </button>

      <!-- <BaseLink
        v-if="!currentUser"
        href="/login"
        class="opacity-50 hover:opacity-100 underline transition duration-75 ease-in-out hidden w-full xl:w-6/12"
      >
        Log in to save
      </BaseLink> -->

      <button
        v-if="getHasLoaded"
        type="button"
        class="btn w-full flex justify-center uppercase font-bold items-center tracking-wide text-sm xl:w-6/12 px-0 py-0 button-height loginToSaveBtn"
        :class="`transition duration-75 ease-in-out ${
          getIsSaving ? 'opacity-25 pointer-events-none' : 'opacity-75 hover:opacity-100 '
        }`"
        :disabled="getIsSaving"
        @click="saveOrRemoveProduct"
      >
        <IconAccount class="w-5 h-5 mr-2 relative -top-0.5" />
        {{ !currentUser ? 'Log in to save' : saveProductLabel }}
      </button>
    </div>

    <div
      v-if="popupIframe"
      class="w-full mb-8 text-center"
    >
      <button
        class="btn btn--black w-full flex justify-center uppercase font-bold items-center tracking-widest py-0 button-height"
        @click="openConfigPanel"
      >
        <span>Configure</span>
      </button>
    </div>

    <BaseSlidePanel v-show="cartMessageOpen" :transparent="false" @close="closeCartMessage">
      <template v-slot:header>
        <h3 class="flex justify-start items-center">
          <span>{{ addedToCart ? 'Added To Cart' : 'Error: Item Not Added To Cart' }}</span>

          <span v-if="addedToCart" class="ml-2 text-xs">
            (
            <a :href="urls.cart" class="inline-block border-b border-dotted border-gray-400"
              >View Cart</a
            >
            )
          </span>
        </h3>
      </template>

      <template v-slot:body>
        <!-- NOTE: We could easily add a cart summary in this panel
        <table class="w-full">
          <thead>
            <tr>
              <th class="text-left text-xs uppercase tracking-widest align-top font-normal border-b pb-2 pr-1">Item</th>
              <th class="text-center text-xs uppercase tracking-widest align-top font-normal border-b pb-2 pl-1 pr-1">Qty.</th>
              <th class="text-right text-xs uppercase tracking-widest align-top font-normal border-b pb-2 pl-1">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left text-sm text-gray-600 align-top pt-1 pb-1 pr-1">Product Name here - Variant Name Here</td>
              <td class="text-center text-sm text-gray-600 align-top p-1">3</td>
              <td class="text-right text-sm text-gray-600 align-top pt-1 pb-1 pl-1">$1,500.00</td>
            </tr>
            <tr>
              <td class="text-left text-sm text-gray-600 align-top pt-1 pb-1 pr-1">Product Name here - Variant Name Here</td>
              <td class="text-center text-sm text-gray-600 align-top p-1">3</td>
              <td class="text-right text-sm text-gray-600 align-top pt-1 pb-1 pl-1">$1,500.00</td>
            </tr>
            <tr>
              <td class="text-left text-sm text-gray-600 align-top pt-1 pb-1 pr-1">Product Name here - Variant Name Here</td>
              <td class="text-center text-sm text-gray-600 align-top p-1">3</td>
              <td class="text-right text-sm text-gray-600 align-top pt-1 pb-1 pl-1">$1,500.00</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td class="text-left text-sm pt-4 pr-1">Cart Total</td>
              <td class="text-right text-sm pt-4 pl-1" colspan="2">$4,500.00</td>
            </tr>
          </tfoot>
        </table>
        -->
        <CartLineItemsCompact :editable="true" />

        <BaseButton
          class="mt-8"
          color="black"
          full-width
          capitalize
          data-cy="checkout-button"
          @button-click="proceedWithCheckout"
          >Proceed To Checkout</BaseButton
        >
      </template>
    </BaseSlidePanel>

    <BaseSlidePanel v-show="quotePanelOpen" @close="closeQuotePanel">
      <template v-slot:header>
        <h3 id="quote-form-title" class="uppercase">Request A Quote</h3>
      </template>

      <template v-slot:body>
        <ProductQuoteForm
          ref="quoteForm"
          :form="form"
          :variants="variants"
          :price-form="priceForm"
          :product-id="variant ? variant.id : product.id"
          :product-title="variant ? variant.title : product.title"
          :brand-title="brand.title"
          :product-url="urls.product"
          :variant-sku="variant ? variant.sku : '(unknown variant)'"
          :all-options="allOptions"
          :base-price="variants[0].basePrice"
          :purchasable="isAvailableForPurchase"
          :thumb="thumb"
        />
      </template>
    </BaseSlidePanel>
    <BaseConfigPanel v-show="configPanel" @close="closeConfigPanel" :item-class="'noScroll'">
      <template v-slot:header>
        <h3 id="quote-form-title" class="uppercase">Configure</h3>
      </template>

      <template v-slot:body>
        <div v-if="popupIframe" v-html="popupIframe" class="popup--iframe"></div>
      </template>
    </BaseConfigPanel>
  </div>
</template>

<style scoped lang="postcss">
  .popup--iframe {
    height: calc(100% - 75px);
    >>> iframe {
      width: 100% !important;
      height: 100% !important;
    }
    >>> body {
      overflow: hidden;
    }
    @media(max-width:768px) {
      overflow-y: auto;
      >>> iframe {
        height: auto !important;
        min-height: 900px;
      }
    }
  }
  .configure--btn {
    cursor: pointer;
    color: #000;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
      }
  }
  .addQuantityInput {
    position: relative;

    input {
      height: 55px;
      line-height: 55px;
      text-align: center;
      background: transparent;
      border: 2px solid #000;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    .quantBtn {
      position: absolute;
      top: 50%;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      color: #6f6f6f;
      transform: translateY(-50%);

      &.btnMinus {
        left: 21px;
      }

      &.btnPlus {
        right: 35px;
      }
    }
  }

  .loginToSaveBtn {
    font-weight: 600;
    letter-spacing: 0.05em;

    &:hover {
      @apply text-yellows;
    }
  }
</style>
