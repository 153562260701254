<script>
  import BaseSwatch from '@/components/BaseSwatch'
  import BaseModal from '@/components/BaseModal'

  /** Modal to display all available swatches, grouped by collection.
   * Called from ProductSwatchOptions.
   */
  export default {
    name: 'ProductSwatchModal',

    components: {
      BaseSwatch,
      BaseModal,
    },

    props: {
      /** The type of options passed; either `single` swatches or `collections` of swatches */
      type: {
        type: String,
        required: true,
        validator: (value) => ['single', 'collections'].includes(value),
      },
      /** Field label, for header */
      attributeLabel: {
        type: String,
        required: true,
      },
      /** All the swatches in an array */
      allSwatches: {
        type: Array,
        required: true,
      },
      /** All the swatches, organized by collection; required if `type` is `collections` */
      swatchCollections: {
        type: Array,
        default: null,
      },
      /** Whether the field is required */
      isRequired: {
        type: Boolean,
        default: false,
      },
      /** Whether we should show the Option labels */
      showOptionLabels: {
        type: Boolean,
        default: false,
      },
      /** Which swatch is selected */
      selectedSwatchId: {
        type: [String, Number],
        default: '',
      },
    },

    data() {
      return {
        innerSelectedSwatchId: this.selectedSwatchId,
      }
    },

    computed: {
      /** Whether the options are single swatches */
      optionsAreSingleSwatches() {
        return this.type === 'single'
      },

      /** Whether the options are collections of swatches */
      optionsAreCollectionsOfSwatches() {
        return this.type === 'collections'
      },

      /** Full swatch object based on the ID */
      selectedSwatch() {
        return this.allSwatches.find((swatch) => swatch.swatchId === this.innerSelectedSwatchId)
      },

      /** Formatted display title for the selected swatch */
      selectedSwatchTitle() {
        if (this.optionsAreSingleSwatches) {
          return this.selectedSwatch.label
        } else {
          return (
            this.selectedSwatch.collectionLabel +
            ' in ' +
            this.selectedSwatch.label +
            (this.showOptionLabels ? ' (' + this.selectedSwatch.optionLabel + ')' : '')
          )
        }
      },

      /** Formatted display description for the selected swatch */
      selectedSwatchDescription() {
        if (this.optionsAreSingleSwatches) {
          return this.selectedSwatch.description
        } else {
          return this.selectedSwatch.collectionDescription
        }
      },
    },

    created() {
      /* Let users close the modal with the Escape key */
      const escapeHandler = (event) => {
        if (event.key === 'Escape') {
          this.closeModal()
        }
      }
      document.addEventListener('keydown', escapeHandler)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', escapeHandler)
      })
    },

    methods: {
      /** Set the formatted heading for a passed collection */
      setCollectionHeading(collection) {
        if (this.showOptionLabels) {
          return (
            collection.collectionLabel +
            (collection.manufacturer ? ' by ' + collection.manufacturer : '') +
            ' (' +
            collection.optionLabel +
            ')'
          )
        }
        return (
          collection.collectionLabel +
          (collection.manufacturer ? ' by ' + collection.manufacturer : '')
        )
      },

      /** Tells the parent which swatch is selected, focuses the "Add to Product" button */
      setSelected() {
        this.$emit('update:selectedSwatchId', this.innerSelectedSwatchId)
        this.$nextTick(() => this.$refs.modal.$slots.footer[0].context.$refs.addToProduct.focus())
      },

      /** Tells the parent to close the modal */
      closeModal() {
        this.$emit('close')
      },
    },
  }
</script>

<template>
  <BaseModal ref="modal" data-cy="productSwatchModal" @close="closeModal">
    <template v-slot:header>
      <h2 id="modalTitle">Select {{ attributeLabel }}</h2>
    </template>

    <!--suppress XmlInvalidId -->
    <template
      v-slot:body
      role="radiogroup"
      :aria-labelledby="labelId"
      :aria-activedescendant="innerSelectedSwatchId"
    >
      <section v-if="optionsAreSingleSwatches">
        <ul class="swatch-grid">
          <BaseSwatch
            v-for="swatch in allSwatches"
            :id="swatch.swatchId"
            :key="'modal' + swatch.swatchId"
            v-model="innerSelectedSwatchId"
            :label="swatch.label"
            :image="swatch.image"
            context="modal"
            :tabindex="0"
            @change="setSelected"
          />
        </ul>
      </section>

      <template v-else>
        <section
          v-for="(collection, collectionIndex) in swatchCollections"
          :id="'collection_' + collectionIndex"
          :key="collection.collectionId"
          :class="{ 'pt-6 mt-4 border-t border-gray-200': collectionIndex > 0 }"
        >
          <header class="text-xs mb-3">
            <h4>{{ setCollectionHeading(collection) }}</h4>
            <a :href="'#collection_' + (collectionIndex + 1)" class="sr-only focus:not-sr-only">
              Skip to next collection
            </a>
            <p v-if="collection.description" class="text-gray-600">{{
              collection.collectionDescription
            }}</p>
          </header>

          <ul class="swatch-grid">
            <BaseSwatch
              v-for="swatch in collection.swatches"
              :id="swatch.swatchId"
              :key="'modal' + swatch.swatchId"
              v-model="innerSelectedSwatchId"
              :label="swatch.label"
              :image="swatch.image"
              context="modal"
              :tabindex="0"
              @change="setSelected"
            />
          </ul>
        </section>
      </template>
    </template>

    <template v-slot:footer>
      <div v-if="innerSelectedSwatchId" class="w-full sm:flex sm:justify-between sm:items-center">
        <div class="text-xs">
          <h4>{{ selectedSwatchTitle }}</h4>
          <p v-if="selectedSwatchDescription" class="text-gray-600">
            {{ selectedSwatchDescription }}
          </p>
        </div>

        <button
          ref="addToProduct"
          class="flex justify-between items-center pr-5 py-1 rounded-full bg-gray-200 mt-1 sm:mt-0"
          @click="closeModal"
        >
          <div class="inline-block ml-1 mr-3 rounded-full overflow-hidden border-2 border-black">
            <img
              class="object-cover object-center rounded-full"
              :src="
                selectedSwatch.image.urls.length
                  ? selectedSwatch.image.urls[0]
                  : 'https://via.placeholder.com/90x90.png'
              "
              width="24"
              height="24"
              alt=""
            />
          </div>
          <span class="text-xxs uppercase sm:text-xs">Add to product</span>
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<style scoped lang="postcss">
  .swatch-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;

    > * {
      max-width: 114px;
      padding: 10px;
    }
  }
</style>
