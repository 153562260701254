export default {
  namespaced: true,

  state: {
    query: '',
    view: 'products',
    viewSelected: false,
  },

  mutations: {
    setView(state, value) {
      state.view = value
    },
    setViewSelected(state, value) {
      state.viewSelected = value
    },
  },

  getters: {
    getView(state) {
      return state.view
    },
    getViewSelected(state) {
      return state.viewSelected
    },
  },
}
