<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'
  /** Collection header logic 2 */
  export default {
    name: 'CollectionHeader',

    props: {
      /** The title/name of the category */
      title: {
        type: String,
        required: true,
      },
      brand: {
        type: Object,
        default: () => {},
      },
    },
    methods: {},
  }
</script>

<template>
  <header class="z-15 py-8 lg:py-16 mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
    <div class="flex flex-column xl:flex-row flex-wrap">
      <div class="w-full xl:w-1/3">
        <h1 class="block w-full uppercase font-bold mb-0 font-bold font-unica77">
          <span class="uppercase tracking-tight leading-tight"> {{ title }} Collection </span>
        </h1>
        <span class="text-gray-600 ml-1 inline-block pt-4 text-md md:text-xl" v-if="brand"
          >By <a :href="'/' + brand.uri" class="text-black">{{ brand.title }}</a></span
        >
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .heading {
    max-width: 480px;
  }

  a:hover {
    color: rgb(202, 24, 24);
  }

  h1 {
    span {
      font-size: 60px;
      line-height: 72px;
    }

    span.break-words {
      font-size: 24px;
      line-height: 28px;

      span {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: 1320px) {
    h1 {
      span {
        font-size: 48px;
        line-height: 54px;
      }
    }
  }

  .summary {
    max-width: 1040px;
    font-size: 18px;
    line-height: 22px;

    >>> p {
      display: inline-block;
      margin-bottom: 20px;
    }
  }

  .summary-expanded {
    >>> p {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .summary >>> * > * {
    @apply mb-6;

    &:last-child {
      @apply mb-0;
    }
  }

  @screen md {
    .summary-expanded {
      columns: 2;
      column-gap: 20px;

      p {
        break-inside: avoid;
        page-break-inside: avoid;
      }
    }
  }

  /* purgecss end ignore */
</style>
