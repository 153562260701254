import algoliasearch from 'algoliasearch/lite'
import moment from 'moment'
import { compInt, compStr } from '@/utilities/comparators'
import api from '@/api'
moment().format()

export const FILTER_TYPE_SELECT = 'select'
export const FILTER_TYPE_CHECKBOX = 'checkbox'

const getFilteredProducts = function ({ state, getters, skipFilterHandle = null } = {}) {
  const filteredProducts = getters.getProducts.filter((product) => {
    for (const filter of getters.getFilters) {
      if (skipFilterHandle && filter.handle === skipFilterHandle) {
        continue
      }
      const filterValue = state.filterValues[filter.handle]
      const productValue = product[filter.property]
      // Select filter type
      if (filter.type === FILTER_TYPE_SELECT) {
        // Only consider the filter if at least one option is selected
        if (!Array.isArray(filterValue) || filterValue.length === 0) {
          continue
        }
        let foundMatch = false
        const productValueIsArray = Array.isArray(productValue)
        for (const singleValue of filterValue) {
          if (
            (productValueIsArray && productValue.includes(singleValue)) ||
            productValue === singleValue
          ) {
            foundMatch = true
            break
          }
        }
        // If none of the selected options are present on this product, filter it out
        if (!foundMatch) {
          return false
        }
        continue
      }
      // Checkbox filter type
      if (filter.type === FILTER_TYPE_CHECKBOX) {
        if (filterValue === true && !productValue) {
          return false
        }
        continue
      }
    }
    return true
  })
  if (Object.keys(state.priceElement).length !== 0) {
    return filteredProducts.filter((product) => {
      if (!state.priceElement.min && state.priceElement.max) {
        if (!state.tradeAccount) {
          return product.prices.prices.normal <= state.priceElement.max
        } else {
          return product.prices.prices.trade <= state.priceElement.max
        }
      } else if (state.priceElement.min && state.priceElement.max) {
        if (!state.tradeAccount) {
          return (
            product.prices.prices.normal >= state.priceElement.min &&
            product.prices.prices.normal <= state.priceElement.max
          )
        } else {
          return (
            product.prices.prices.trade >= state.priceElement.min &&
            product.prices.prices.trade <= state.priceElement.max
          )
        }
      } else if (state.priceElement.min && !state.priceElement.max) {
        if (!state.tradeAccount) {
          return product.prices.prices.normal >= state.priceElement.min
        } else {
          return product.prices.prices.trade >= state.priceElement.min
        }
      }
    })
  }
  if (
    ('Height' in state.filterValues && state.filterValues.Height.length !== 0) ||
    ('Length / Width' in state.filterValues && state.filterValues['Length / Width'].length !== 0) ||
    ('Diameter' in state.filterValues && state.filterValues.Diameter.length !== 0) ||
    ('Depth' in state.filterValues && state.filterValues.Depth.length !== 0)
  ) {
    return filteredProducts.filter((product) => {
      let matched = false
      product.newDimensions.forEach((n) => {
        n.dimensions.forEach((j) => {
          for (const [key, value] of Object.entries(state.filterValues)) {
            if (
              key === 'Height' ||
              key === 'Length / Width' ||
              key === 'Diameter' ||
              key === 'Depth'
            ) {
              if (value.length !== 0) {
                value.forEach((val) => {
                  const [min, max] = val.value.split('_').map(Number)
                  if (parseInt(j[key]) > min && parseInt(j[key]) < max) {
                    matched = true
                  }
                })
              }
            }
          }
        })
      })
      if (matched) {
        return product
      }
    })
  }
  return filteredProducts
}

const differenceInDays = (product) => {
  const productCreationDate = moment(new Date(product.order.postDate * 1000))
  const today = moment(new Date())
  return today.diff(productCreationDate, 'days')
}

export default {
  namespaced: true,
  state: {
    selectedArray: [],
    allArray: [],
    isLoading: false,
    firstLoad: false,
    filters: {
      type: {
        type: FILTER_TYPE_SELECT,
        label: 'Type',
        property: 'categoryIds',
      },
      brand: {
        type: FILTER_TYPE_SELECT,
        label: 'Brand',
        property: 'brandId',
      },
      designer: {
        type: FILTER_TYPE_SELECT,
        label: 'Designer',
        property: 'designerId',
      },
      materials: {
        type: FILTER_TYPE_SELECT,
        label: 'Materials',
        property: 'materials',
      },
    },
    filterOptions: {},
    filterValues: {},
    hiddenFilters: [],
    tradeAccount: false,
    sortOptions: [
      {
        label: 'Recommended',
        value: 'recommended',
      },
      {
        label: 'Price: low to high',
        value: 'priceAsc',
        show: false, // new URLSearchParams(location.search).get('s') === 'buynow'
      },
      {
        label: 'Price: high to low',
        value: 'priceDesc',
        show: false, // new URLSearchParams(location.search).get('s') === 'buynow'
      },
      {
        label: 'Name: A-Z',
        value: 'nameAsc',
      },
      {
        label: 'Name: Z-A',
        value: 'nameDesc',
      },
    ],
    sort: 'recommended',
    products: [],
    pagination: {
      currentPage: 1,
      currentCuratedPage: 1,
      itemSize: 4,
      pageSize: 92,
    },
    curated: [],
    productCategories: [],
    scrollPosition: null,
    appendFilters: [],
    currentIndex: null,
    brandFaceds: [],
    firstSelectedBrand: {},
    selectedLabel: '',
    firstOptionSelected: null,
    selectedOptionInfo: {},
    priceElement: {},
    priceFilters: [],
    isLightning: false,
    selectedAvailability: '',
    productCount: 0,
    isRandomized: false,
    curatedCategories: [],
    isBrandPage: false,
    segmentCount: 1,
    isCollection: false,
    curatedCollections: [],
    featuredCollections: [],
    featuredProducts: [],
    featuredCombined:[],
    childCategories: [],
    itemType: '',
  },

  mutations: {
    setFeaturedCombined(state, data) {
      state.featuredCombined = data
    },
    setType(state, data) {
      state.itemType = data
    },
    setChildCategories(state, data) {
      state.childCategories = data
    },
    setCuratedCollections(state, data) {
      state.curatedCollections = data
    },
    setCollectionPage(state, data) {
      state.isCollection = data
    },
    setSegmentCount(state, data) {
      state.segmentCount = data
    },
    isBrandPage(state, data) {
      state.isBrandPage = data
    },
    setCuratedCategories(state, data) {
      state.curatedCategories = data
    },
    setProductsLength(state, data) {
      state.productCount = data
    },
    isRandomized(state, data) {
      state.isRandomized = data
    },
    setChosen(state, data) {
      state.selectedAvailability = data
    },
    getCurrentCategory(state, data) {
      state.isLightning = data
    },
    initPriceFilters(state, data) {
      state.priceFilters = data
    },
    setPriceFilters(state, data) {
      state.priceElement = data
    },
    setFirstOptionSelected(state, data) {
      state.firstOptionSelected = data
    },
    selectedLabel(state, data) {
      state.selectedLabel = data
    },
    setColorInfo(state, data) {
      state.selectedOptionInfo = data
    },
    setFirstBrandSelected(state, brand) {
      state.firstSelectedBrand = brand
    },
    removeSingleSelected(state, index) {
      state.currentIndex = index
    },
    setAppendedFilters(state, filters) {
      state.appendFilters = filters
    },
    setBrandsFaceds(state, brands) {
      state.brandsFaced = brands
    },
    clearAppendFilters(state, payload) {
      state.appendFilters = payload
    },
    remoteSelectedFilter(state, payload) {
      state.appendFilters = payload
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setFirstLoad(state, value) {
      state.firstLoad = value
    },
    setFilters(state, filters) {
      for (const item in filters) {
        filters[item].type = FILTER_TYPE_CHECKBOX
        state.filters[item] = filters[item]
      }
    },
    setFilterOptions(state, filterOptions) {
      state.filterOptions = filterOptions
    },
    setFilterValues(state, filterValues) {
      state.filterValues = filterValues
      if (state.filterValues.quickShip) {
        if (state.selectedAvailability !== '') {
          state.filterValues[state.selectedAvailability] = false
        }
      }
      if (state.filterValues[state.selectedAvailability]) {
        state.filterValues.quickShip = false
      }
    },
    setHiddenFilters(state, hiddenFilters) {
      state.hiddenFilters = hiddenFilters
    },
    setTradeAccount(state, isTradeAccount) {
      state.tradeAccount = isTradeAccount
      if (state.tradeAccount || state.isLightning) {
        for (let i = 0; i < state.sortOptions.length; i++) {
          if (
            state.sortOptions[i].value.includes('priceAsc') ||
            state.sortOptions[i].value.includes('priceDesc')
          ) {
            state.sortOptions[i].show = true
          }
        }
      }
    },
    setSortOptions(state, sortOptions) {
      state.sortOptions = sortOptions
    },
    setSort(state, sort) {
      state.sort = sort
    },
    setProducts(state, products) {
      let allProducts = []
      if (state.filterOptions.brand) {
        allProducts = products.filter((item) =>
          state.filterOptions.brand.some((brand) => brand.label === item.brand)
        )
        state.products = allProducts
      } else {
        state.products = products
      }
    },
    appendProducts(state, products) {
      const currentProducts = state.products
      const appendedProducts = currentProducts.concat(products)
      if (state.filterOptions.brand) {
        state.products = appendedProducts.filter((item) =>
          state.filterOptions.brand.some((brand) => brand.label === item.brand)
        )
      } else {
        state.products = appendedProducts
      }
    },
    setRelevanceOrder(state) {
      // Returned hits from Algolia are ordered by their relevance, so lets loop through the
      // products and set/increment the order.relevance value accordingly
      let productIndex = 0
      state.products = state.products.map((product) => {
        product.order.relevance = productIndex + 1
        productIndex++
        return product
      })
    },
    setPageSize(state, size) {
      state.pagination.pageSize = size
    },
    setCurrentPage(state, page) {
      state.pagination.currentPage = page
    },
    incrementPage(state) {
      state.pagination.currentPage++
    },
    incrementCuratedPage(state) {
      state.pagination.currentCuratedPage++
    },
    incrementFeaturedPage(state) {
      state.pagination.currentCuratedPage++
    },
    dicrementFeaturedPage(state) {
      state.pagination.currentCuratedPage = 1
    },
    decrementPage(state) {
      state.pagination.currentPage--
    },
    setCurated(state, curatedIds) {
      state.curated = curatedIds
    },
    setProductCategories(state, categories) {
      state.productCategories = categories
    },
    setScrollPosition(state, value) {
      state.scrollPosition = value
    },
    clearFilter(state, data) {
      state.clearedFilter = data
    },
    selectedArray(state, data) {
      state.selectedArray = data
    },
    allArray(state, data) {
      state.allArray = data
    },
  },

  getters: {
    getChildCategories(state) {
      return state.childCategories
    },
    getCuratedCollections(state) {
      return state.featuredCollections
    },
    getFeaturedProducts(state) {
      return state.featuredProducts
    },
    getFeaturedCollections(state) {
      return state.featuredCollections
    },
    getFeaturedCombinedProductsAndCollections(state) {
      return [...state.featuredCollections, ...state.featuredProducts]
    },
    getFeaturedCombined(state) {
      return state.featuredCombined
    },
    getSelectedOption(state) {
      return state.appendFilters
    },
    getIsLoading(state) {
      return state.isLoading
    },

    getFirstLoad(state) {
      return state.firstLoad
    },

    getTradeAccount(state) {
      return state.tradeAccount
    },

    getFilters(state, getters) {
      const refactorFilters = Object.entries(state.filters)
        .map(([filterHandle, filter]) => ({
          ...filter,
          handle: filterHandle,
        }))
        .filter((filter) => {
          // Don't include filters that are in the `hiddenFilters` array
          return state.hiddenFilters.includes(filter.handle) === false
        })
      return refactorFilters
    },

    getUsedFilters(state, getters) {
      return getters.getFilters.filter((filter) => {
        if (filter.type === FILTER_TYPE_SELECT) {
          return (state.filterValues[filter.handle] ?? []).length > 0
        }
        if (filter.type === FILTER_TYPE_CHECKBOX) {
          return state.filterValues[filter.handle] ?? false
        }
      })
    },

    getFacetedFilterOptions(state, getters) {
      const objectFromFilters = Object.fromEntries(
        getters.getFilters
          .filter((filter) => filter.type === FILTER_TYPE_SELECT)
          .map((filter) => {
            const filterOptions = state.filterOptions[filter.handle] ?? []
            const filterValues = state.filterValues[filter.handle] ?? []
            const filteredProducts = getFilteredProducts({
              state,
              getters,
              skipFilterHandle: filter.handle,
            })
            const filteredProductValues = []
            filteredProducts.forEach((product) => {
              const productValue = product[filter.property]
              const productValues = Array.isArray(productValue) ? productValue : [productValue]
              filteredProductValues.push(...productValues)
            })
            const facetedFilterOptions = filterOptions.filter((option) => {
              return (
                filterValues.includes(option.value) || filteredProductValues.includes(option.value)
              )
            })
            return [filter.handle, facetedFilterOptions]
          })
      )
      return objectFromFilters
    },

    getFilterEnabledStates(state, getters) {
      return Object.fromEntries(
        getters.getFilters.map((filter) => {
          const enabledState = (() => {
            if (filter.type === FILTER_TYPE_SELECT) {
              return getters.getFacetedFilterOptions[filter.handle].length > 0
            }
            if (filter.type === FILTER_TYPE_CHECKBOX) {
              const filteredProducts = getFilteredProducts({
                state,
                getters,
                skipFilterHandle: filter.handle,
              })
              let trueCount = 0
              let falseCount = 0
              filteredProducts.forEach((product) => {
                const productValue = product[filter.property]
                if (productValue) {
                  trueCount++
                } else {
                  falseCount++
                }
              })
              if (trueCount === 0 || falseCount === 0) {
                return false
              }
              return true
            }
          })()
          return [filter.handle, enabledState]
        })
      )
    },

    getFilterValues(state) {
      return state.filterValues
    },

    getSort(state) {
      return state.sort
    },

    getSortOptions(state) {
      return state.sortOptions
    },

    getTotalItems(state) {
      return state.products.length
    },

    getCurated(state) {
      return state.curated
    },
    getProductCategories(state) {
      return state.productCategories
    },

    getFilteredProducts(state, getters) {
      const products = getFilteredProducts({ state, getters })
      return products
    },

    getSortedProducts(state, getters) {
      if (state.sort === 'recommended') {
        const curatedIds = getters.getCurated.map((cat) => parseInt(cat))

        // Filter based on the curated product IDs
        const curatedProducts = getters.getFilteredProducts.filter(
          (product) => curatedIds.indexOf(parseInt(product.objectID)) !== -1
        )

        // Order the curatedProducts by the order they were added in Craft
        const orderedCuratedProducts = []
        for (let i = 0; i < curatedIds.length; i++) {
          const product = curatedProducts.find(
            (product) => parseInt(product.objectID) === curatedIds[i]
          )
          if (product) {
            orderedCuratedProducts.push(product)
          }
        }

        // Get the rest of the products that are not curated
        let uncuratedProducts = []
        if (state.curatedCategories.length !== 0) {
          uncuratedProducts = getters.getFilteredProducts
        } else {
          uncuratedProducts = getters.getFilteredProducts
            .filter((product) => curatedIds.indexOf(parseInt(product.objectID)) === -1)
            .sort((a, b) => {
              return compInt(a.order.postDate, b.order.postDate, 'desc')
            })
        }

        const productsSortByCategories = []
        const productIds = []

        const productsSortByCurated = []

        if (getters.getProductCategories.length > 0) {
          getters.getProductCategories.forEach((category) => {
            uncuratedProducts.forEach((product) => {
              let primarySortCategory = null
              if (product.categoryIds.length === 1) {
                primarySortCategory = product.categoryIds[0]
              } else if (product.categoryIds.length === 2) {
                primarySortCategory = product.categoryIds[1]
              } else if (product.categoryIds.length >= 3) {
                primarySortCategory = product.categoryIds[2]
              }
              if (
                primarySortCategory &&
                primarySortCategory === category &&
                !productIds.includes(product.id)
              ) {
                productsSortByCategories.push(product)
                productIds.push(product.id)
              }
            })
          })
        }

        const curatedProductIds = []
        let matchingElements = []

        if (state.curatedCategories.length > 0) {
          state.curatedCategories.forEach((category) => {
            uncuratedProducts.forEach((product) => {
              let primarySortCategory = null
              if (product.categoryIds.length === 1) {
                primarySortCategory = product.categoryIds[0]
              } else if (product.categoryIds.length === 2) {
                primarySortCategory = product.categoryIds[1]
              } else if (product.categoryIds.length >= 3) {
                primarySortCategory = product.categoryIds[2]
              }
              if (primarySortCategory && primarySortCategory === category) {
                productsSortByCurated.push(product)
                curatedProductIds.push(product.id)
              }
            })
          })

          matchingElements = getters.getFilteredProducts.filter(
            (obj1) => !productsSortByCurated.some((obj2) => obj1.id === obj2.id)
          )
        }

        const finalUncuratedProducts =
          productsSortByCurated.length > 0 ? productsSortByCurated : uncuratedProducts

        const finalCuratedProducts =
          productsSortByCurated.length > 0 ? productsSortByCurated : orderedCuratedProducts

        const sortedProductsByBrand = []
        const leftProductsByBrand = []
        const sortedProducts = [...finalCuratedProducts, ...finalUncuratedProducts]
        const brandIdOnly = sortedProducts
          .sort((a, b) => a.brand.localeCompare(b.brand))
          .map(item => item.brand);
        const noDublicatedBrandIds = [...new Set(brandIdOnly)]
        noDublicatedBrandIds.forEach((brand) => {
          const gotProducts = []
          sortedProducts.forEach((prod) => {
            if (prod.brand === brand) {
              gotProducts.push(prod)
            }
          })
          const slicedProducts = gotProducts.length >= 3 ? gotProducts.slice(0, 3) : gotProducts
          const leftProducts = gotProducts.length >= 3 ? gotProducts.slice(3) : []
          slicedProducts.forEach((item) => {
            sortedProductsByBrand.push(item)
          })
          if (leftProducts.length > 0) {
            leftProducts.forEach((item) => {
              leftProductsByBrand.push(item)
            })
          }
        })

        const mergerProductsByBrands = [...sortedProductsByBrand, ...leftProductsByBrand]

        let productsByCategory = []
        const categoryLevel = state.isLightning ? 1 : 2
        const productsOrderByCategory = []
        const productsLeftAfterOrder = []
        sortedProducts.forEach((item) => {
          const excludeCats = item.categories.filter(
            (cat) => cat !== 'Modern Lighting' || cat !== 'Modern Furniture'
          )
          if (excludeCats.length > 0) {
            excludeCats.forEach((cat) => {
              productsByCategory.push(cat)
            })
          }
        })
        productsByCategory = [...new Set(productsByCategory)]
        productsByCategory = productsByCategory
          .sort((a, b) => a.localeCompare(b))
        productsByCategory.forEach((cat) => {
          const threeProductsByCat = []
          sortedProducts.forEach((item) => {
            if (item.categories[categoryLevel] && item.categories[categoryLevel] === cat) {
              threeProductsByCat.push(item)
            }
          })
          const firstThreeProducts =
            threeProductsByCat.length >= 3 ? threeProductsByCat.slice(0, 3) : threeProductsByCat
          const leftProducts = threeProductsByCat.length >= 3 ? threeProductsByCat.slice(3) : []
          firstThreeProducts.forEach((item) => {
            productsOrderByCategory.push(item)
          })
          if (leftProducts.length > 0) {
            leftProducts.forEach((item) => productsLeftAfterOrder.push(item))
          }
        })

        const mergerProductsByCategory = [...productsOrderByCategory, ...productsLeftAfterOrder]

        if (Object.entries(state.priceElement).length !== 0) {
          let productsWithPrices = []
          let productsWithoutPrices = []
          if (state.tradeAccount) {
            productsWithPrices = sortedProducts
              .filter((item) => item.prices.prices.trade > 0)
              .sort((a, b) => {
                return compInt(a.prices.prices.trade, b.prices.prices.trade, 'asc')
              })
            productsWithoutPrices = sortedProducts
              .filter((item) => item.prices.prices.trade === 0)
              .sort((a, b) => {
                return compInt(a.order.latest, b.order.latest, 'asc')
              })
          } else {
            productsWithPrices = sortedProducts
              .filter((item) => item.prices.prices.normal > 0)
              .sort((a, b) => {
                return compInt(a.prices.prices.normal, b.prices.prices.normal, 'asc')
              })
            productsWithoutPrices = sortedProducts
              .filter((item) => item.prices.prices.normal === 0)
              .sort((a, b) => {
                return compInt(a.order.latest, b.order.latest, 'asc')
              })
          }
          return [...productsWithPrices, ...productsWithoutPrices]
        }

        if (state.isBrandPage) {
          if (state.curatedCategories.length > 0) {
            return [...finalUncuratedProducts, ...matchingElements]
          } else {
            return [...finalCuratedProducts, ...finalUncuratedProducts]
          }
        } else {
          if (state.isCollection) {
            return uncuratedProducts
          } else {
            if (state.segmentCount > 2) {
              return mergerProductsByBrands
            } else {
              // let temporaryValue
              // let randomIndex
              // let currentIndex = finalUncuratedProducts.length
              // const products = finalUncuratedProducts
              // if (!state.isRandomized) {
              //   // While there remain elements to shuffle...
              //   while (currentIndex !== 0) {
              //     // Pick a remaining element...
              //     randomIndex = Math.floor(Math.random() * currentIndex)
              //     currentIndex -= 1
              //     // And swap it with the current element.
              //     temporaryValue = products[currentIndex]
              //     products[currentIndex] = products[randomIndex]
              //     products[randomIndex] = temporaryValue
              //   }
              // }

              return mergerProductsByCategory
            }
          }
        }
      }

      if (state.sort === 'relevance') {
        return getters.getFilteredProducts.sort((a, b) => {
          return compInt(a.order.relevance, b.order.relevance, 'asc')
        })
      }

      if (state.sort === 'priceAsc' || state.sort === 'priceDesc') {
        let productsWithPrices = []
        let productsWithoutPrices = []

        if (state.tradeAccount) {
          productsWithPrices = getters.getFilteredProducts
            .filter((product) => product.prices.prices.trade > 0)
            .sort((a, b) => {
              return compInt(
                a.prices.prices.trade,
                b.prices.prices.trade,
                state.sort === 'priceAsc' ? 'asc' : 'desc'
              )
            })
          productsWithoutPrices = getters.getFilteredProducts
            .filter((product) => product.prices.prices.trade === 0)
            .sort((a, b) => {
              return compInt(a.order.latest, b.order.latest, 'asc')
            })
        } else {
          productsWithPrices = getters.getFilteredProducts
            .filter((product) => product.prices.prices.normal > 0)
            .sort((a, b) => {
              return compInt(
                a.prices.prices.normal,
                b.prices.prices.normal,
                state.sort === 'priceAsc' ? 'asc' : 'desc'
              )
            })
          productsWithoutPrices = getters.getFilteredProducts
            .filter((product) => product.prices.prices.normal === 0)
            .sort((a, b) => {
              return compInt(a.order.latest, b.order.latest, 'asc')
            })
        }
        return [...productsWithPrices, ...productsWithoutPrices]
      }

      if (state.sort === 'nameAsc' || state.sort === 'nameDesc') {
        return getters.getFilteredProducts.sort((a, b) => {
          return compStr(a.title, b.title, state.sort === 'nameAsc' ? 'asc' : 'desc')
        })
      }

      return getters.getFilteredProducts
    },

    getProducts(state, getters) {
      const mappedProducts = state.products.map((product) => {
        return {
          ...product,
          new: differenceInDays(product) <= 30,
          tags:
            differenceInDays(product) <= 30 ? [...product.tags, { label: 'New' }] : product.tags,
        }
      })
      return mappedProducts
    },

    getPaginatedProducts(state, getters) {
      const size = state.pagination.pageSize
      const current = state.pagination.currentPage
      const last = current * size
      const products = getters.getSortedProducts.slice(0, last)
      return products
    },

    getPaginatedCuratedProducts(state, getters) {
      const size = state.pagination.itemSize
      const current = state.pagination.currentCuratedPage
      const last = current * size
      let products = []
      if (state.curatedCategories.length > 0) {
        const curatedIds = getters.getCurated.map((cat) => parseInt(cat))
        const curatedProducts = getters.getProducts.filter(
          (product) => curatedIds.indexOf(parseInt(product.objectID)) !== -1
        )
        const orderedCuratedProducts = []
        for (let i = 0; i < curatedIds.length; i++) {
          const product = curatedProducts.find(
            (product) => parseInt(product.objectID) === curatedIds[i]
          )
          if (product) {
            orderedCuratedProducts.push(product)
          }
        }
        const curatedCollectionsAndProducts = [
          ...getters.getCuratedCollections,
          ...orderedCuratedProducts,
        ]
        products = curatedCollectionsAndProducts.slice(0, last)
      }
      return products
    },

    getCuratedCollectionsAndProducts(state, getters) {
      return [...getters.getCuratedCollections, ...getters.getCurated]
    },

    getPaginationData(state, getters) {
      const current = getters.getPaginatedProducts.length
      const total = getters.getSortedProducts.length
      const remaining = total - current
      const toLoad = remaining < state.pagination.pageSize ? remaining : state.pagination.pageSize

      return {
        current: current,
        total: total,
        remaining: remaining,
        toLoad: toLoad,
      }
    },

    getPaginationCuratedData(state, getters) {
      const current = getters.getPaginatedCuratedProducts.length
      const total = getters.getCuratedCollectionsAndProducts.length
      const remaining = total - current
      const toLoad = remaining < state.pagination.itemSize ? remaining : state.pagination.itemSize
      return {
        current: current,
        total: total,
        remaining: remaining,
        toLoad: toLoad,
      }
    },

    getPaginatedFeaturedProducts(state, getters) {
      const size = state.pagination.itemSize
      const current = state.pagination.currentCuratedPage
      const last = current * size
      if (state.itemType === 'parentCategory') {
        return getters.getChildCategories.slice(0, last)
      } else {
        return getters.getFeaturedCombined.slice(0, last)
      }
    },

    getPaginationFeaturedData(state, getters) {
      const current = getters.getPaginatedFeaturedProducts.length
      const total = state.itemType === 'parentCategory' ? getters.getChildCategories.length : getters.getFeaturedCombined.length
      const remaining = total - current
      const toLoad = remaining < state.pagination.itemSize ? remaining : state.pagination.itemSize
      return {
        current: current,
        total: total,
        remaining: remaining,
        toLoad: toLoad,
      }
    },

    getShuffledProducts(state, getters) {
      /** Fisher–Yates Shuffle see https://bost.ocks.org/mike/shuffle/ */
      let temporaryValue
      let randomIndex
      let currentIndex = getters.getFilteredProducts.length
      const products = getters.getFilteredProducts
      const size = state.pagination.pageSize
      const current = state.pagination.currentPage
      const last = current * size

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        // And swap it with the current element.
        temporaryValue = products[currentIndex]
        products[currentIndex] = products[randomIndex]
        products[randomIndex] = temporaryValue
      }

      return products.slice(0, last)
    },

    getCurrentPage(state) {
      return state.pagination.currentPage
    },

    isLastPage(state, getters) {
      const total = getters.getSortedProducts.length
      const pageSize = state.pagination.pageSize
      const current = state.pagination.currentPage

      return pageSize * current >= total
    },

    isLastCuratedPage(state, getters) {
      const total = getters.getCuratedCollectionsAndProducts.length
      const pageSize = state.pagination.itemSize
      const current = state.pagination.currentCuratedPage
      return pageSize * current >= total
    },

    isLastFeaturedPage(state, getters) {
      const total = state.itemType === 'parentCategory' ? getters.getChildCategories.length : getters.getFeaturedCombined.length
      const pageSize = state.pagination.itemSize
      const current = state.pagination.currentCuratedPage
      return pageSize * current >= total
    },

    getScrollPosition(state) {
      return state.scrollPosition
    },

    getBrowsingSnapshot() {
      return JSON.parse(localStorage.getItem('catalogSnapshot'))
    },

    getHasBuyNowProducts(state) {
      for (const product of state.products) {
        if (product.buyNow) {
          return true
        }
      }
      return false
    },
  },

  actions: {
    setFeaturedCombined({ commit }, data) {
      commit('setFeaturedCombined', data)
    },
    setCuratedCollections({ commit }, data) {
      commit('setCuratedCollections', data)
    },
    setChildCategories({ commit }, data) {
      commit('setChildCategories', data)
    },
    setType({ commit }, data) {
      commit('setType', data)
    },
    setCollectionPage({ commit }, data) {
      commit('setCollectionPage', data)
    },
    setSegmentCount({ commit }, data) {
      commit('setSegmentCount', data)
    },
    isBrandPage({ commit }, data) {
      commit('isBrandPage', data)
    },
    setCuratedCategories({ commit }, data) {
      commit('setCuratedCategories', data)
    },
    isRandomized({ commit }, data) {
      commit('isRandomized', data)
    },
    setProductsLength({ commit }, data) {
      commit('setProductsLength', data)
    },
    getCurrentCategory({ commit }, data) {
      commit('getCurrentCategory', data)
    },
    initPriceFilters({ commit }, data) {
      commit('initPriceFilters', data)
    },
    setPriceFilters({ commit }, data) {
      commit('setPriceFilters', { ...data })
    },
    setFirstOptionSelected({ commit }, data) {
      commit('setFirstOptionSelected', data)
    },
    setSelectedLabel({ commit }, data) {
      commit('selectedLabel', data)
    },
    setColorInfo({ commit }, data) {
      commit('setColorInfo', data)
    },
    async initFilters(context, { filterOptions, hiddenFilters }) {
      const { data: response } = await api.post('hauteliving/site/get-product-categories')
      context.commit('setFilters', response || {})
      context.commit('setFilterOptions', { ...filterOptions, ...response } || {})
      context.commit('setHiddenFilters', hiddenFilters || [])
    },

    /* appended filters functions start */

    setAppendedFilters({ commit, state }, { label, index, type, slug }) {
      let newList = []
      if (type === 'checkbox') {
        newList = [
          ...state.appendFilters.filter((item) => item.type !== 'checkbox'),
          {
            label: label,
            index: index,
            type: type,
            slug: slug,
          },
        ]
      } else {
        newList = [
          ...state.appendFilters,
          {
            label: label,
            index: index,
            type: type,
            slug: slug,
          },
        ]
      }
      commit('setAppendedFilters', newList)
      const url = new URL(window.location)
      let newParamString = ''
      const onlyBrands = state.appendFilters.filter((x) => x.type === 'Brand')
      if (type === 'Brand') {
        commit('setFirstBrandSelected', { label, index, type, slug })
        url.pathname = url.pathname.split('/brand')[0]
        url.pathname = url.pathname + '/brand/' + slug
        let newParts = ''
        setTimeout(function () {
          let urlParts = url.pathname.split('/')
          urlParts = urlParts.slice(1)
          if (urlParts.length > 3) {
            const idx = urlParts.findIndex((idx) => idx === 'modern-furniture')
            if (idx !== -1) {
              urlParts.splice(idx, 1)
              newParts = urlParts.join('/')
              url.pathname = newParts
            }
          }
          const onlyIndexes = onlyBrands.map((x) => x.index)
          if (onlyIndexes.length > 1) {
            newParamString = onlyIndexes.join(',')
            url.searchParams.set('brands', newParamString.toString())
          }
          localStorage.setItem('brands', JSON.stringify(onlyIndexes))
          window.history.pushState({}, '', url)
        }, 100)
      }
    },

    setChosen({ commit }, data) {
      commit('setChosen', data)
    },

    remoteSelectedFilter({ commit, state }, index) {
      const url = new URL(window.location)
      const params = url.searchParams.get('brands')
      let storage = []
      const fromStorage = localStorage.getItem('brands')
      if (fromStorage) {
        storage = JSON.parse(fromStorage)
      }
      let paramsSplit = []
      if (params) {
        paramsSplit = params.split(',')
        paramsSplit = [...paramsSplit]
      }
      const newArray = [...state.appendFilters]
      const idx = newArray.findIndex((x) => x.index === index)
      const paramIdx = paramsSplit.findIndex((x) => parseInt(x) === index)
      if (idx !== -1) {
        if (newArray[idx].type === 'checkbox') {
          url.searchParams.delete('s')
          window.history.pushState({}, '', url)
          commit('setFilterValues', {
            ...state.filterValues,
            [newArray[idx].label]: false,
          })
        }
        setTimeout(() => {
          newArray.splice(idx, 1)
          storage.splice(idx, 1)
          localStorage.setItem('brands', JSON.stringify(storage))
          if (storage.length === 0) {
            localStorage.removeItem('brands')
          }
          if (paramsSplit.length > 1) {
            paramsSplit.splice(paramIdx, 1)
            const newParamString = paramsSplit.join(',')
            url.searchParams.set('brands', newParamString.toString())
            window.history.pushState({}, '', url)
          } else {
            url.searchParams.delete('brands')
            url.pathname = url.pathname.split('/brand')[0]
            window.history.pushState({}, '', url)
            commit('setFirstBrandSelected', {})
          }
        }, 100)
      }

      commit('remoteSelectedFilter', newArray)
      commit('removeSingleSelected', index)

      if (index === state.firstSelectedBrand.index) {
        url.pathname = url.pathname.split(`/brand/${state.firstSelectedBrand.slug}`)[0]
        window.history.pushState({}, '', url)
      }
    },

    /* appended filters functions end */

    setFilterValue({ commit, state }, { filter, value }) {
      commit('setFilterValues', {
        ...state.filterValues,
        [filter]: value,
      })
      commit('setSortOptions', [
        {
          label: 'Recommended',
          value: 'recommended',
        },
        {
          label: 'Price: low to high',
          value: 'priceAsc',
          show: state.tradeAccount || state.isLightning, // new URLSearchParams(location.search).get('s') === 'buynow'
        },
        {
          label: 'Price: high to low',
          value: 'priceDesc',
          show: state.tradeAccount || state.isLightning, // new URLSearchParams(location.search).get('s') === 'buynow'
        },
        {
          label: 'Name: A-Z',
          value: 'nameAsc',
        },
        {
          label: 'Name: Z-A',
          value: 'nameDesc',
        },
      ])
    },

    clearAllFilters({ commit, state }) {
      commit('clearAppendFilters', [])
      commit('setFilterValues', {})
      const url = new URL(window.location)
      url.searchParams.delete('brands')
      url.searchParams.delete('s')
      localStorage.removeItem('brands')
      if (url.pathname.indexOf('/brands') === -1) {
        url.pathname = url.pathname.split(`/brand`)[0]
      }
      window.history.pushState({}, '', url)
    },

    clearFilters({ commit }, data) {
      commit('setFilterValues', {})
    },

    setSort({ commit }, sort) {
      commit('setSort', sort)
    },

    initProducts(
      context,
      {
        category,
        categoryId,
        brand,
        designer,
        prices,
        status,
        buyNow,
        collection,
        curated,
        productCategories,
        excludeProductId,
        isTradeAccount,
        searchQuery,
      }
    ) {
      searchQuery = searchQuery ?? ''
      // Check the arguments to filter on
      const filtersObjArr = [
        {
          filter: 'prices',
          value: prices || null,
        },
        {
          filter: 'categories',
          value: category || null,
        },
        {
          filter: 'categoryIds',
          value: categoryId || null,
        },
        {
          filter: 'brand',
          value: brand || null,
        },
        {
          filter: 'designer',
          value: designer || null,
        },
        {
          filter: 'status',
          value: status || null,
        },
        {
          filter: 'buyNow',
          value: buyNow || null,
        },
        {
          filter: 'collections',
          value: collection || null,
        },
      ]
      // Create an array of strings for those arguments
      const filtersArr = []
      filtersObjArr.forEach(function (filterObj) {
        if (filterObj.value) {
          if (filterObj.filter !== 'collections') {
            filtersArr.push(filterObj.filter + ':' + "'" + filterObj.value + "'")
          } else {
            filtersArr.push(`${filterObj.filter}: "${filterObj.value}"`)
          }
        }
      })
      // Check for the excluded product ID to add it to the array
      if (excludeProductId) {
        filtersArr.push(`id != ${excludeProductId}`)
      }

      // Join the array as a string to create the filters query
      const filtersQuery = filtersArr.join(' AND ')

      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)

      // Run the search and then set the results in the products state
      index
        .search(searchQuery, {
          filters: filtersQuery,
          hitsPerPage: 1000,
        })
        .then(({ hits, nbPages }) => {
          // Set the first batch of 1000 products
          context.commit('setProducts', hits)
          context.commit('setFirstLoad', true)
          context.commit('setIsLoading', false)

          // If there are more pages loop through them and append them in batches of 1000
          for (let i = 1; i < nbPages; i++) {
            index
              .search(searchQuery, {
                filters: filtersQuery,
                hitsPerPage: 1000,
                page: i,
              })
              .then(({ hits }) => {
                // Append the additional products to state and re-run setting the sort on 'recommend'
                // to take into account the new products that have been appended
                context.commit('appendProducts', hits)
                context.commit('setSort', 'recommended')
                context.commit('setIsLoading', false)
              })
          }
        })

      // Set the trade account boolean in state
      context.commit('setTradeAccount', isTradeAccount || false)

      // Set the curated product IDs in state
      context.commit('setCurated', curated || [])

      // Set the curated product IDs in state
      context.commit('setProductCategories', productCategories || [])
    },

    searchProducts(context, { query }) {
      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX
      const isCollection = /[?&]collection=/.test(location.search)

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Set the soring options for search results (remove recommended and add relevance)
      context.commit('setSortOptions', [
        {
          label: 'Recommended',
          value: 'recommended',
        },
        {
          label: 'Price: low to high',
          value: 'priceAsc',
          show: new URLSearchParams(location.search).get('s') === 'buynow',
        },
        {
          label: 'Price: high to low',
          value: 'priceDesc',
          show: new URLSearchParams(location.search).get('s') === 'buynow',
        },
        {
          label: 'Name: A-Z',
          value: 'nameAsc',
        },
        {
          label: 'Name: Z-A',
          value: 'nameDesc',
        },
      ])
      context.commit('setSort', 'relevance')

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)
      const options = {
        hitsPerPage: 1000,
        getRankingInfo: true,
      }

      if (isCollection) {
        options.filters = `collections: "${query}"`
      }

      // Run the search and then set the results in the products state
      index.search(query, options).then(({ hits, nbPages }) => {
        // Set the first batch of 1000 products
        context.commit('setProducts', hits)
        context.commit('setRelevanceOrder')
        context.commit('setIsLoading', false)
        context.commit('setFirstLoad', true)

        // If there are more pages loop through them and append them in batches of 1000
        for (let i = 1; i < nbPages; i++) {
          index
            .search(query, {
              hitsPerPage: 1000,
              getRankingInfo: true,
              page: i,
            })
            .then(({ hits }) => {
              context.commit('appendProducts', hits)
              context.commit('setRelevanceOrder')
            })
        }
      })
    },

    setPaginationPageSize({ commit }, size) {
      commit('setPageSize', size)
    },

    nextPage({ commit, getters }) {
      if (!getters.isLastPage) {
        commit('incrementPage')
      }
    },

    nextCuratedPage({ commit, getters }) {
      if (!getters.isLastCuratedPage) {
        commit('incrementCuratedPage')
      }
    },

    nextFeaturedPage({ commit, getters }) {
      if (!getters.isLastFeaturedPage) {
        commit('incrementFeaturedPage')
      } else {
        commit('dicrementFeaturedPage')
      }
    },

    saveBrowsingSnapshot({ commit, getters }) {
      // Get and set the current scroll position
      const scrollPosition = document.getElementsByTagName('html')[0].scrollTop
      commit('setScrollPosition', parseFloat(scrollPosition))
      // Create a snapshot with the current filter, sort, page and scroll position
      const snapshot = {
        filterValues: getters.getFilterValues,
        sort: getters.getSort,
        currentPage: getters.getCurrentPage,
        scrollPosition: getters.getScrollPosition,
      }
      // Save it into local storage
      localStorage.setItem('catalogSnapshot', JSON.stringify(snapshot))
    },

    applyBrowsingSnapshot(context) {
      // Check if it exists in local storage
      if (localStorage.getItem('catalogSnapshot') !== null) {
        // If the page call is from the back button, apply the values in local storage, else remove it
        if (
          window.performance.navigation?.type ===
          window.performance.navigation?.TYPE_BACK_FORWARD ||
          window.performance.getEntriesByType('navigation')[0]?.type === 'back_forward'
        ) {
          const snapshot = JSON.parse(localStorage.getItem('catalogSnapshot'))
          context.commit('setFilterValues', snapshot.filterValues)
          context.commit('setSort', snapshot.sort)
          context.commit('setCurrentPage', snapshot.currentPage)
          context.commit('setScrollPosition', snapshot.scrollPosition)
        } else {
          localStorage.removeItem('catalogSnapshot')
        }
      }
    },

    clearBrowsingSnapshot() {
      // If it exists in local storage, remove it
      if (localStorage.getItem('catalogSnapshot') !== null) {
        localStorage.removeItem('catalogSnapshot')
      }
    },
  },
}
