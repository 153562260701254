<script>
  import VLazyImage from 'v-lazy-image'

  /** A reusable article tile preview. Makes use of VLazyImage to load preview images when they are in the viewport and
   * provides a loading animated SVG in base64 before the image loads to prevent layout jank.
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'ArticleTile',

    components: {
      VLazyImage,
    },

    props: {
      /** The articles URL */
      url: {
        type: String,
        required: true,
      },
      /** The articles title */
      title: {
        type: String,
        required: true,
      },
      /** The articles preview image as an object including params for alternate text, low rez and high rez image URLs */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The articles summary text */
      summary: {
        type: String,
        required: false,
        default: null,
      },
      /** The name of the category the article is associated to */
      categories: {
        type: String,
        required: false,
        default: null,
      },
      /** The articles post date (takes in a Unix timestamp or a javascript Date object */
      date: {
        type: [Number, Date],
        required: false,
        default: null,
      },
    },

    computed: {
      /** Formats the post date timestamp or JS Date object for output as a string */
      dateString: function () {
        let formattedDate = ''
        if (this.date) {
          if (this.date instanceof Date) {
            formattedDate =
              this.date.getMonth() + 1 + '.' + this.date.getDate() + '.' + this.date.getFullYear()
          } else {
            const convertedDate = new Date(this.date)
            formattedDate =
              convertedDate.getMonth() +
              1 +
              '.' +
              convertedDate.getDate() +
              '.' +
              convertedDate.getFullYear()
          }
        }
        return formattedDate
      },
      /** Creates the image srcset string based on the high and low rez images provided */
      srcSet: function () {
        let srcString = ''
        if (this.image.urls[0] && this.image.urls[1]) {
          srcString = this.image.urls[0] + ' 1x, ' + this.image.urls[1] + ' 2x '
        }
        return srcString
      },
      /** Create the image sizes string */
      srcSizes: function () {
        return '50vw, (min-width: 768px) 33vw, (min-width: 1024px) 25vw, (min-width: 1560px) 20vw, (min-width: 1920px) 17vw'
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMTUgMzE1IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KICA8cmVjdCBjbGlwLXBhdGg9InVybCgjY2xpcFBhdGgtMTMpIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzE1IiBoZWlnaHQ9IjMxNSIgc3R5bGU9ImZpbGw6IHVybCgmcXVvdDsjZ3JhZGllbnQtMTMmcXVvdDspOyI+PC9yZWN0PgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwUGF0aC0xMyI+CiAgICAgIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIzMTUiIGhlaWdodD0iMzE1Ij48L3JlY3Q+CiAgICA8L2NsaXBQYXRoPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC0xMyI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMjsgMSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IiB2YWx1ZXM9Ii0xLjU7IDEuNSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMTsgMiIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+Cjwvc3ZnPgo='
      },
    },
  }
</script>

<template>
  <a :href="url" class="block focus:outline-none || anchor" :aria-label="title">
    <article>
      <figure
        v-if="image.urls.length"
        class="relative block border border-gray-200 mb-3 | md:mb-4 || image"
      >
        <VLazyImage
          :srcset="srcSet"
          :src="image.urls[0]"
          :src-placeholder="srcPlaceholder"
          :sizes="srcSizes"
          :alt="image.altText"
        />
      </figure>

      <header class="space-y-1">
        <h4 v-if="categories || date" class="text-gray-600 leading-snug">
          <span v-if="categories">{{ categories }}</span>
          <span v-if="categories && date">—</span>
          <span v-if="date">{{ dateString }}</span>
        </h4>
        <h3 class="text-black leading-snug">
          <span class="inline align-middle mr-2">{{ title }}</span>
          <span
            class="inline-block align-middle bg-black opacity-0 transition-opacity duration-300 || indicator"
          ></span>
        </h3>
      </header>

      <div v-if="summary" class="mt-1 text-xs leading-snug text-gray-600 | md:text-sm">
        <p>{{ summary }}</p>
      </div>
    </article>
  </a>
</template>

<style scoped lang="postcss">
  .image img {
    @apply min-w-full;
  }

  .image::after {
    content: '';
    @apply absolute inset-0 block bg-gray-900 opacity-0 transition-opacity duration-300;
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }

  .anchor {
    &:hover,
    &:focus {
      .image::after {
        @apply opacity-25;
      }

      .indicator {
        @apply opacity-100;
      }
    }
  }
</style>
