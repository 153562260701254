<script>
  import { createNamespacedHelpers } from 'vuex'
  import { ValidationProvider } from 'vee-validate'
  import { ADDRESS_FIELDS } from '@/modules/checkout'
  import BaseInputText from '@/components/BaseInputText'
  import BaseSelect from '@/components/BaseSelect'
  import BaseError from '@/components/BaseError'

  const { mapGetters } = createNamespacedHelpers('checkout')

  /**
   * A form to fill in an address (either shipping or billing), with fields such as first name, last name, phone number, etc.
   */
  export default {
    name: 'CheckoutAddressForm',

    components: {
      BaseInputText,
      BaseSelect,
      BaseError,
      ValidationProvider,
    },

    model: {
      prop: 'address',
      event: 'input',
    },

    props: {
      /** The current address as an object with keys such as `firstName`, `lastName`, etc. */
      address: {
        type: Object,
        required: true,
      },
      /** The type of address, either `shipping` or `billing` */
      type: {
        type: String,
        required: true,
        validator: (value) => ['shipping', 'billing'].includes(value),
      },
    },

    data() {
      return {
        innerAddress: this.normalizeAddress(this.address, true),
      }
    },

    computed: {
      ...mapGetters(['getStates']),
      stateOptions() {
        return this.getStates.map((state) => ({
          id: state.id,
          label: state.name,
        }))
      },
    },

    watch: {
      innerAddress: {
        deep: true,
        handler() {
          this.$emit('input', this.normalizeAddress(this.innerAddress))
        },
      },
    },

    methods: {
      /** Normalizes an address into the expected format */
      normalizeAddress(address, forInner = false) {
        return ADDRESS_FIELDS.reduce((normalizedAddress, field) => {
          // eslint-disable-next-line prefer-const
          let fieldValue = (address ? address[field] : undefined) ?? ''
          // TODO: Remove this commented out code if we decide we don't need the dialing code selects
          /*
          if (['phone', 'alternativePhone'].includes(field)) {
            const currentFormat = typeof fieldValue === 'string' ? 'string' : 'object'
            const expectedFormat = forInner ? 'object' : 'string'
            if (currentFormat === 'string' && expectedFormat === 'object') {
              const countryCodeRegex = /^([\S]+)/
              const countryCode = fieldValue.match(countryCodeRegex)
              fieldValue = {
                countryCode: countryCode,
                number: fieldValue.substring(countryCode ? countryCode.length : 0).trim(),
              }
            }
            if (currentFormat === 'object' && expectedFormat === 'string') {
              fieldValue = `${fieldValue.countryCode} ${fieldValue.number}`
            }
          }
          */
          return {
            ...normalizedAddress,
            [field]: fieldValue,
          }
        }, {})
      },
    },
  }
</script>

<template>
  <div>
    <div class="md:flex justify-between gap-6">
      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="First Name" rules="required">
          <BaseInputText v-model="innerAddress.firstName" label="First Name" type="text" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="Last Name" rules="required">
          <BaseInputText v-model="innerAddress.lastName" label="Last Name" type="text" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>
    </div>

    <div class="md:flex justify-between gap-6">
      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="Primary Phone Number" rules="required">
          <!--
          <div class="flex justify-between gap-4">
            <BaseDialingCodeSelect
              v-model="innerAddress.phone.countryCode"
              label="Primary Phone Number"
              class="flex flex-col justify-around w-1/3"
            ></BaseDialingCodeSelect>
            <BaseInputText
              v-model="innerAddress.phone.number"
              type="tel"
              class="flex flex-col justify-end font-base w-2/3"
            />
          </div>
          -->
          <BaseInputText v-model="innerAddress.phone" label="Primary Phone Number" type="tel" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="Secondary Phone Number" rules="">
          <!--
          <div class="flex justify-between gap-4">
            <BaseDialingCodeSelect
              v-model="innerAddress.alternativePhone.countryCode"
              label="Secondary Phone Number (Optional)"
              class="flex flex-col justify-around w-1/3"
            ></BaseDialingCodeSelect>
            <BaseInputText
              v-model="innerAddress.alternativePhone.number"
              type="tel"
              class="flex flex-col justify-end font-base w-2/3"
            />
          </div>
          -->
          <BaseInputText
            v-model="innerAddress.alternativePhone"
            label="Secondary Phone Number (Optional)"
            type="tel"
          />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>
    </div>

    <div class="md:flex justify-between gap-6">
      <div class="mb-4 lg:mb-6 md:w-full">
        <ValidationProvider v-slot="{ errors }" name="Address Line 1" rules="required">
          <BaseInputText v-model="innerAddress.address1" label="Address Line 1" type="text" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>
    </div>

    <div class="md:flex justify-between gap-6">
      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="Address Line 2" rules="">
          <BaseInputText
            v-model="innerAddress.address2"
            label="Address Line 2 (Optional)"
            type="text"
          />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="City" rules="required">
          <BaseInputText v-model="innerAddress.city" label="City" type="text" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>
    </div>

    <div class="md:flex justify-between gap-6">
      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="State" rules="required">
          <BaseSelect
            v-model="innerAddress.stateId"
            label="State"
            :options="stateOptions"
            class="select"
          ></BaseSelect>
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>

      <div class="mb-4 lg:mb-6 md:w-1/2">
        <ValidationProvider v-slot="{ errors }" name="ZIP Code" rules="required">
          <BaseInputText v-model="innerAddress.zipCode" label="ZIP Code" type="text" />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
      </div>
    </div>

    <div>
      <p>Shipping to continental US only at this time.</p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .select {
    & >>> label {
      @apply mb-2 text-xs text-gray-700 uppercase tracking-widest;
    }

    & >>> select {
      @apply block w-full px-5 py-3 border border-gray-300 bg-white shadow-none font-light text-base leading-normal;

      &:focus {
        @apply shadow-outline;
      }
    }
  }
</style>
