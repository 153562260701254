<script>
  export default {
    name: 'BuilderHeroImage',

    props: {
      /** Small mobile image object */
      smallImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** Large image object */
      largeImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** An optional hero heading */
      heading: {
        type: String,
        required: false,
        default: '',
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: '',
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: '',
      },
    },
  }
</script>

<template>
  <section :id="anchor" class="section w-full relative" :aria-label="label">
    <div class="bg-black opacity-25 w-full h-full absolute inset-0"></div>
    <h2
      class="text-3xl md:text-4xl lg:text-5xl text-white absolute top-10 lg:top-16 left-5 lg:left-12"
      >{{ heading }}</h2
    >
    <picture class="w-full h-auto lg:h-96 object-cover object-center">
      <source media="(max-width:560px)" :srcset="smallImage.urls[0]" />
      <source media="(min-width:561px)" :srcset="largeImage.urls[0]" />
      <img
        :src="largeImage.urls[0]"
        :alt="smallImage.alt"
        class="w-full h-auto lg:h-96 object-cover object-center"
      />
    </picture>
    <div
      v-if="largeImage.attribution"
      class="absolute bottom-0 right-0 py-1 text-xs inline px-4 rounded-tl z-10"
      :style="{ color: largeImage.attributionColor }"
      v-html="largeImage.attribution"
    ></div>
  </section>
</template>
