<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import BuilderBaseImage from '@/components/builder/BuilderBaseImage'
  import IconChevronLeft from '@/images/icons/chevron-left.svg'
  import IconChevronRight from '@/images/icons/chevron-right.svg'

  export default {
    name: 'BuilderImageSlider',

    components: {
      VueSlickCarousel,
      BuilderBaseImage,
      IconChevronLeft,
      IconChevronRight,
    },

    props: {
      /** An array of objects of slides to display */
      slides: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** Whether or not to autoplay the slider on load */
      autoplay: {
        type: Boolean,
        required: false,
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: '',
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        reset: 0,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          draggable: false,
          autoplay: this.autoplay,
          autoplaySpeed: 6000,
        },
      }
    },

    methods: {
      /** Go to previous slide */
      showPrev() {
        this.$refs.slider.prev()
        this.$refs.prev.blur()
      },
      /** Go to next slide */
      showNext() {
        this.$refs.slider.next()
        this.$refs.next.blur()
      },
    },
  }
</script>

<template>
  <section
    v-show="slides.length"
    :id="anchor"
    class="relative overflow-hidden ml-12 || section slider"
    :aria-label="label"
  >
    <VueSlickCarousel ref="slider" :key="reset" v-bind="settings">
      <div v-for="(slide, index) in slides" :key="index" class="slide" draggable="false">
        <BuilderBaseImage :small-image="slide.smallImage" :large-image="slide.largeImage" />
      </div>
    </VueSlickCarousel>

    <button ref="prev" class="slide-ctrl slide-ctrl--prev" @click="showPrev">
      <span>Previous Slide</span>
      <IconChevronLeft />
    </button>

    <button ref="next" class="slide-ctrl slide-ctrl--next" @click="showNext">
      <span>Next Slide</span>
      <IconChevronRight />
    </button>
  </section>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .slide-ctrl {
    position: absolute;
    top: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding-top: 1px;
    overflow: hidden;
    text-align: center;
    background-color: #fff;
    border-radius: 17px;
    opacity: 1;
    transition: all 300ms;
    transform: translate(0, -50%);

    span {
      position: absolute;
      left: -9999px;
    }

    svg {
      display: inline-block;
      width: 9px;
      height: 15px;
      fill: #000;
      transition: fill 300ms;
    }
  }

  .slide-ctrl--prev {
    left: 16px;
    padding-right: 2px;
  }

  .slide-ctrl--next {
    right: 16px;
    padding-left: 2px;
  }

  >>> .slick-dots {
    right: 16px;
    bottom: 32px;
    left: 16px;
    justify-content: center;
    pointer-events: none;

    li {
      margin-right: 24px;
      pointer-events: auto;

      &:last-child {
        margin-right: 0;
      }

      &.slick-active {
        button {
          @apply bg-white;
        }
      }
    }

    button {
      @apply border border-white;

      width: 10px;
      height: 10px;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 5px;
    }
  }

  @screen lg {
    .slide-ctrl {
      opacity: 0;

      &:hover {
        background-color: #000;

        svg {
          fill: #fff;
        }
      }
    }

    .slide-ctrl--prev {
      left: 32px;
      transform: translate(-60px, 0);
    }

    .slide-ctrl--next {
      right: 32px;
      transform: translate(60px, 0);
    }

    .slider:hover,
    .slider:focus,
    .slider:active {
      .slide-ctrl {
        opacity: 1;
      }

      .slide-ctrl--prev,
      .slide-ctrl--next {
        transform: translate(0, 0);
      }
    }

    >>> .slick-dots {
      right: 32px;
      bottom: 38px;
      left: 32px;

      li {
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  /* purgecss end ignore */
</style>
