<script>
  import { createNamespacedHelpers } from 'vuex'
  import VLazyImage from 'v-lazy-image'
  import IconColorChecked from '@/images/icons/checked-icon.svg'

  const { mapActions } = createNamespacedHelpers('products')

  /** This is a single selectable swatch. It must be placed in an unordered list with
   * `role="radiogroup"`. See https://www.w3.org/TR/wai-aria-practices/examples/radio/radio-2/radio-2.html.
   * It must be passed a tabindex property
   */

  export default {
    name: 'BaseSwatch',

    components: {
      VLazyImage,
      IconColorChecked,
    },

    model: {
      prop: 'selectedSwatchId',
      event: 'change',
    },

    props: {
      /** SwatchImage */
      image: {
        type: Object,
        required: true,
      },
      /** SwatchLabel */
      label: {
        type: String,
        required: true,
      },
      /** Whether this is inline or in the modal */
      context: {
        type: String,
        required: true,
      },
      /** To control whether the element can be interacted with by keyboard */
      tabindex: {
        type: Number,
        required: true,
      },
      /** Unique identifier from allSwatches */
      id: {
        type: [String, Number],
        required: true,
      },
      /** The v-model is based on selectedSwatchId */
      selectedSwatchId: {
        type: [String, Number],
        required: true,
      },
      type: {
        type: String,
        default: '',
      },
      text: {
        type: String,
        default: '',
      },
      scrollHeight: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      /** Figure out whether this swatch is selected */
      isSelected: function () {
        return this.selectedSwatchId === this.id
      },

      /** Label text for when the swatch is selected. */
      addLabel: function () {
        return this.isSelected ? 'Added to product' : 'Add to product'
      },

      /** Dynamic classes */
      classObject: function () {
        return {
          'border-black': this.isSelected,
          'is-selected': this.isSelected,
          'swatch--small': this.context === 'inline',
        }
      },

      /** Get image properties based on whether we have an image.
       * TODO: Can we set this in prop defaults instead?
       */
      imageObject: function () {
        const image = this.image
        if (image.urls.length) {
          return {
            srcset: image.urls[0] + ' 1x,' + image.urls[1] + ' 2x',
            src: image.urls[0],
          }
        } else {
          return {
            srcset:
              'https://via.placeholder.com/90x90.png 1x, https://via.placeholder.com/180x180.png 2x',
            src: 'https://via.placeholder.com/90x90.png',
          }
        }
      },
    },

    methods: {
      ...mapActions(['setSelectedLabel', 'setColorInfo']),
      setInfoOnHover() {
        this.setColorInfo({
          title: this.label,
          text: this.text,
          image: this.image.urls.length !== 0 ? this.image.urls[1] : '',
          alt: this.image.alt,
          top: this.$el.offsetTop + 210 - this.scrollHeight,
          left: this.$el.offsetLeft - (400 - this.$el.offsetWidth + 4),
        })
      },
      removeInfoOnHover() {
        this.setColorInfo({})
      },
      /** Tell the parent which swatch is selected */
      setSelected() {
        this.$emit('change', this.id)
        this.setSelectedLabel(this.id)
      },
      setSelectedOnHover() {
        if (this.type !== 'collections') {
          this.setSelected()
        }
      },
    },
  }
</script>

<template>
  <!-- <li
    role="radio"
    :aria-checked="isSelected"
    :tabindex="tabindex"
    class="swatch inline-block"
    @click="setSelected"
    @mouseover="setSelectedOnHover"
    @keyup.enter="setSelected"
    @keyup.space="setSelected"
  > -->
  <li
    role="radio"
    :aria-checked="isSelected"
    :tabindex="tabindex"
    class="swatch inline-block relative"
    @click="setSelected"
    @mouseover="setInfoOnHover"
    @mouseout="removeInfoOnHover"
  >
    <figure>
      <div
        :class="classObject"
        class="swatch__container cursor-pointer relative overflow-hidden transition-colors duration-200"
      >
        <span class="colorChecked" v-if="isSelected">
          <IconColorChecked class="w-6 h-6 fill-current" />
        </span>
        <VLazyImage
          class="min-w-full"
          :srcset="imageObject.srcset"
          :src="imageObject.src"
          :sizes="'90px'"
          alt=""
          :style="`${
            type === 'collections'
              ? 'height:100%;object-fit:contain;'
              : 'height:100%;object-fit:cover;'
          }`"
        />

        <div class="swatch__actions">
          <div class="swatch__add">
            <span class="sr-only">{{ addLabel }}</span>
            <!--<IconPlus class="fill-current" />-->
          </div>
        </div>
      </div>
      <!-- <figcaption
        class="mt-2 text-xxs text-center uppercase"
        :class="{ 'sr-only': context === 'inline' }"
        >{{ label }}</figcaption
      > -->
      <figcaption class="captionBlock">{{ label }}</figcaption>
    </figure>
  </li>
</template>

<style scoped lang="postcss">
  .captionBlock {
    height: 25px;
    padding-top: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    @media (max-width: 767px) {
      line-height: 12px;
    }
  }

  .swatch__actions {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    height: 14px;
    opacity: 0;
    transition: opacity 200ms;
    transform: translate(-50%, -50%);
  }

  .swatch {
    width: 14.28%;
    padding: 0 4px;
    margin-right: 0 !important;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      width: 20%;
    }
  }

  .colorChecked {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .swatch:hover .swatch__actions,
  .swatch:focus .swatch__actions {
    opacity: 100;
  }

  .swatch.selectedRadio {
    .swatch__container {
      border: 1px solid #fff;
      outline: 2px solid #000;
    }
  }

  .swatch__add svg {
    width: 14px;
    height: 14px;
  }

  .swatch--small {
    width: 100%;
    height: 56px;
    @media (max-width: 600px) {
      height: 48px;
    }
  }

  .v-lazy-image {
    opacity: 0;
    transition: opacity 0.7s;
  }

  .v-lazy-image-loaded {
    opacity: 1;
  }
</style>
