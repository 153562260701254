<script>
  /** A Reusable Callout Banner Info component */

  export default {
    name: 'BuilderBannerInfo',

    props: {
      /** Heading */
      heading: {
        type: String,
        required: true,
      },
      /** Summary */
      summary: {
        type: String,
        required: true,
      },
      /** Text Colour */
      txColor: {
        type: String,
        default: 'white',
        validator: (value) => {
          // The value must match one of these strings
          return ['white', 'black'].indexOf(value) !== -1
        },
      },
    },
  }
</script>

<template>
  <div>
    <h2
      class="banner-heading text-2xl lg:text-4xl leading-tight tracking-wide mb-4"
      :class="`text-${txColor}`"
      v-html="heading"
    >
    </h2>
    <p class="banner-summary lg:text-md leading-5" :class="`text-${txColor}`" v-html="summary"> </p>
  </div>
</template>

<style lang="postcss">
  .banner-heading {
    code,
    em {
      padding: 2px 12px;
      background: rgba(255, 255, 255, 0.1);

      @apply font-normal tracking-widest text-xs not-italic border rounded-full  border-white;
    }
  }

  .banner-summary {
    code,
    em {
      padding: 2px 12px;
      background: rgba(255, 255, 255, 0.1);

      @apply font-normal tracking-widest text-xs not-italic border rounded-full  border-white;
    }
  }
</style>
