<script>
  /** A featured projects components to display projects */
  import VLazyImage from 'v-lazy-image'

  export default {
    name: 'BuilderOffsetGallery',

    components: {
      VLazyImage,
    },
    props: {
      /** The heading of the section */
      heading: {
        type: String,
        required: false,
        default: null,
      },
      /** The summary of the section  */
      summary: {
        type: String,
        required: false,
        default: null,
      },
      /** The summary of the section  */
      projects: {
        type: Array,
        required: true,
        default: () => [],
      },
    },

    computed: {
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZwogIHZpZXdCb3g9IjAgMCA1NjAgNzQ4IgogIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKPgogIDxyZWN0CiAgICB4PSIwIgogICAgeT0iMCIKICAgIHdpZHRoPSIxMDAlIgogICAgaGVpZ2h0PSIxMDAlIgogICAgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiCiAgICBzdHlsZT0nZmlsbDogdXJsKCIjZmlsbCIpOycKICAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSI1NjAiIGhlaWdodD0iNzQ4IiAvPgogICAgPC9jbGlwUGF0aD4KICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZmlsbCI+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIwLjU5OTk2NCIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9Ii0yOyAtMjsgMSIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgICAgPHN0b3AKICAgICAgICBvZmZzZXQ9IjEuNTk5OTYiCiAgICAgICAgc3RvcC1jb2xvcj0iI2VjZWJlYiIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMTsgLTE7IDIiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICAvPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIyLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNmM2YzZjMiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iMDsgMDsgMyIKICAgICAgICAgIGtleVRpbWVzPSIwOyAwLjI1OyAxIgogICAgICAgICAgZHVyPSIycyIKICAgICAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICAgIC8+CiAgICAgIDwvc3RvcD4KICAgIDwvbGluZWFyR3JhZGllbnQ+CiAgPC9kZWZzPgo8L3N2Zz4K'
      },
      getProjectImage() {
        return (project) => (project.image.urls.length > 0 ? project.image : project.thumbnail)
      },
    },

    methods: {},
  }
</script>

<template>
  <section>
    <header class="space-y-3 pb-6 lg:pb-10 flex flex-col md:flex-row justify-between">
      <h2 v-if="heading" class="block mb-0">{{ heading }} </h2>
      <div v-if="summary" class="w-full md:w-1/2 text-black leading-relaxed">
        <p class="max-w-lg" v-html="summary"></p>
      </div>
    </header>
    <div class="w-full lg:flex lg:flex-row lg:flex-nowrap space-x-0 lg:space-x-12">
      <article v-for="(project, i) in projects" :key="i" class="w-full lg:w-1/2 mb-8 lg:mb-0">
        <a :href="project.url">
          <div class="w-full imageWrapper">
            <VLazyImage
              :srcset="
                getProjectImage(project).urls[0] + ' 1x,' + getProjectImage(project).urls[1] + ' 2x'
              "
              :src="getProjectImage(project).urls[1]"
              :src-placeholder="srcPlaceholder"
              :sizes="'(max-width: 767) 100vw, (min-width: 768) 50vw'"
              :alt="getProjectImage(project).alt"
            />
          </div>
          <div class="mt-2 md:mt-6 space-y-0 md:space-y-1 textWrapper">
            <p class="tracking-wider text-black uppercase">{{ project.author }}</p>
            <h3 class="tracking-wider text-black">{{ project.title }}</h3>
          </div>
        </a>
      </article>
    </div>
  </section>
</template>

<style scoped lang="scss">
  article {
    .imageWrapper {
      width: 100%;
      height: 450px;
    }

    .textWrapper {
      p {
        font-size: 12px;
        @media(max-width:768px) {
          font-size: 10px;
        }
      }
      h3 {
        font-size: 16px;
        @media(max-width:768px) {
          font-size: 14px;
        }
      }
    }

    h2 {
      font-family: 'Cormorant', sans-serif;
      font-weight: 700;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      .imageWrapper {
        height: 300px;
      }
    }
  }
</style>
