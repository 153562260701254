<script>
  export default {
    name: 'BaseColumnList',

    props: {
      /** A grouped list of items as an array  */
      list: {
        type: Array,
        required: false,
        default: () => [],
      },
      itemLabel: {
        type: String,
        required: false,
        default: 'label',
      },
      setLettersSize: {
        type: String,
        default: '',
      },
      itemCount: {
        type: String,
        required: false,
        default: 'count',
      },
      /** If the items have a count, this label will be used in the aria-label (ex; '12 products') */
      countLabel: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        previewItem: null,
        topPosition: 0,
      }
    },
    mounted() {
      if (this.type === 'brand') {
        this.previewItem = this.$store.getters['brands/getPreviewItem'](this.list[0].items[0].title)
        this.$emit('previewItem', this.previewItem)
      } else {
        this.previewItem = this.$store.getters['designers/getPreviewItem'](
          this.list[0].items[0].title
        )
        this.$emit('previewItem', this.previewItem)
      }
      this.previewItem.description = this.previewItem.description.substring(0, 270) + '...'
      console.log(this.previewItem.description)
      this.$nextTick(() => {
        window.addEventListener('scroll', this.onScroll)
      })
    },
    methods: {
      onScroll() {
        const noticeElement = document.querySelector('#notice')
        const headerHeight = document.querySelector('#ref-header').offsetHeight
        const alphaElement = document.querySelector('.alphaSection')
        const columnElement = document.querySelector('.columnList')
        const mainElement = document.querySelector('.previewBrand')
        let partWidth = 0
        let heightSum = 0
        let noticeHeight = 0
        let offsetLeft = 0
        if (noticeElement !== null) {
          noticeHeight = noticeElement.offsetHeight
          heightSum = noticeHeight + headerHeight + alphaElement.offsetHeight - 5
        } else {
          noticeHeight = 0
          heightSum = headerHeight + alphaElement.offsetHeight - 5
        }
        if (!alphaElement.classList.contains('fixed')) {
          this.topPosition = alphaElement.offsetTop + alphaElement.offsetHeight
        }
        if (columnElement !== null) {
          offsetLeft = columnElement.getBoundingClientRect().left
        }
        if (alphaElement !== null) {
          partWidth = alphaElement.offsetWidth / 4
        }
        if (mainElement !== null) {
          mainElement.style.height = window.innerHeight - (headerHeight + noticeHeight - 10) + 'px'
          if (window.scrollY > this.topPosition + alphaElement.offsetHeight + 50) {
            if (noticeElement !== null) {
              if (noticeElement.classList.contains('is--closed')) {
                alphaElement.classList.add('fixed', 'smallFixed')
                alphaElement.classList.remove('bigFixed')
              } else {
                alphaElement.classList.remove('smallFixed')
                alphaElement.classList.add('fixed', 'bigFixed')
              }
            } else {
              alphaElement.classList.add('fixed', 'smallFixed')
            }
            mainElement.classList.add('pt-6', 'fixed')
            mainElement.classList.remove('absolute')
            mainElement.style.top = heightSum + 'px'
            mainElement.style.right = offsetLeft + 'px'
            mainElement.style.width = partWidth + 'px'
          } else {
            alphaElement.classList.remove('fixed', 'bigFixed', 'smallFixed')
            mainElement.style.top = 0 + 'px'
            mainElement.classList.remove('pt-6', 'fixed')
            mainElement.classList.add('absolute')
            mainElement.style.width = '25%'
            mainElement.style.right = 0 + 'px'
          }
        }
      },
      getPreviewItem(item) {
        this.onScroll()
        if (this.type === 'brand') {
          this.previewItem = this.$store.getters['brands/getPreviewItem'](item)
        } else {
          this.previewItem = this.$store.getters['designers/getPreviewItem'](item)
        }
        this.previewItem.description = this.previewItem.description.substring(0, 270) + '...'
        this.$emit('previewItem', this.previewItem)
      },
      removePreviewItem() {
        this.previewItem = {}
        this.$emit('previewItem', this.previewItem)
      },
    },
  }
</script>

<template>
  <ol v-if="list.length" class="columnList">
    <li
      v-for="(parent, x) in list"
      :key="x"
      :id="`item-${parent.heading}`"
      class="mb-8 w-full md:w-4/12 listItem"
    >
      <h4
        class="mb-4 font-bold leading-none font-cormorant"
        >{{ parent.heading }}</h4
      >
      <ol v-if="parent.items.length" class="space-y-1">
        <li v-for="(child, y) in parent.items" :key="y">
          <a
            :href="child.url"
            class="flex justify-between items-center | focus:outline-none | md:relative md:inline-block md:pr-3"
            @mouseover="getPreviewItem(child[itemLabel])"
          >
            <em class="relative inline-block pr-3 not-italic | md:mr-1 md:pr-0">{{
              child[itemLabel]
            }}</em>
            <!--<span
              v-if="typeof child[itemCount] !== 'undefined'"
              class="inline-block text-gray-600 transition-colors duration-300 || count"
              :aria-label="child[itemCount] + ' ' + countLabel"
            >
              {{ child[itemCount] }}
            </span>-->
          </a>
        </li>
      </ol>
    </li>
  </ol>
</template>

<style scoped lang="postcss">
  h4 {
    font-size: 40px;
    @media(max-width:768px) {
      font-size: 36px;
    }
  }
  .columnList {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      column-count: 1;
    }
  }

  a {
    em::after {
      top: 8px;
      width: 6px;
      height: 6px;
      content: '';
      border-radius: 3px;
      @apply absolute right-0 inline-block bg-black opacity-0 transition-opacity duration-300;
    }

    &:hover,
    &:focus {
      em::after {
        @apply opacity-100;
      }

      .count {
        @apply text-black;
      }
    }
  }

  @screen md {
    a {
      em::after {
        display: none;
      }

      &::after {
        top: 8px;
        width: 6px;
        height: 6px;
        content: '';
        border-radius: 3px;
        @apply absolute right-0 inline-block bg-black opacity-0 transition-opacity duration-300;
      }

      &:hover::after,
      &:focus::after {
        @apply opacity-100;
      }
    }

    .listItem,
    .listItem li {
      page-break-inside: avoid;
      break-inside: avoid-column;
      overflow: hidden;
    }

    @supports (break-inside: avoid-column) {
      .listItem,
      .listItem li {
        overflow: visible;
      }
    }
  }

  /*
  @screen 2xl {
    .columnList {
      column-count: 6;
    }
  }
  */
</style>
