<script>
  /** A component to render profile (brand or designer) detail page headers */

  export default {
    name: 'ProfilePageHeader',

    props: {
      /** The page title */
      title: {
        type: String,
        required: true,
      },
      /** Summary passed from parent template */
      summary: {
        type: String,
        required: false,
        default: null,
      },
    },
  }
</script>

<template>
  <header class="w-full flex flex-col lg:flex-row flex-wrap py-8 lg:py-12">
    <div class="w-full">
      <h1 class="mb-4 tracking-01 font-bold font-cormorant">{{ title }}</h1>
      <div v-if="summary" class="w-full summary-container font-unica77 font-normal tracking-02 w-full lg:w-3/4">
        <div class="text-black || summary" v-html="summary"></div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .summary {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
  /* purgecss end ignore */
</style>
