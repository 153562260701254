<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseCountButton from '@/components/BaseCountButton'

  const { mapGetters, mapMutations } = createNamespacedHelpers('search')

  /** A group of toggle buttons for switching views in search results pages */

  export default {
    name: 'SearchViewToggle',

    components: {
      BaseCountButton,
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getView']),
      /** Gets the total number of product results in state */
      productsCount: function () {
        return this.$store.getters['products/getTotalItems']
      },
      /** Gets the total number of brand results in state */
      brandsCount: function () {
        return this.$store.getters['brands/getTotalItems']
      },
      /** Gets the total number of designer results in state */
      designersCount: function () {
        return this.$store.getters['designers/getTotalItems']
      },
      /** Gets the total number of article results in state */
      articlesCount: function () {
        return this.$store.getters['articles/getTotalItems']
      },
    },

    methods: {
      /** Vuex mutator methods */
      ...mapMutations(['setView', 'setViewSelected']),
      /** Switches the view */
      switchView(view) {
        this.setView(view)
        this.setViewSelected(true)
      },
    },
  }
</script>

<template>
  <ul
    class="z-50 sticky flex flex-wrap justify-between bg-white -mx-3 | md:justify-start || toggle md:-mx-5"
  >
    <li
      v-if="productsCount"
      class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
    >
      <BaseCountButton
        :label="'Products'"
        :count="productsCount"
        :value="'products'"
        :is-active="getView === 'products'"
        @button-click="switchView"
      />
    </li>

    <li
      v-if="brandsCount"
      class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
    >
      <BaseCountButton
        :label="'Brands'"
        :count="brandsCount"
        :value="'brands'"
        :is-active="getView === 'brands'"
        @button-click="switchView"
      />
    </li>

    <li
      v-if="designersCount"
      class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
    >
      <BaseCountButton
        :label="'Designers'"
        :count="designersCount"
        :value="'designers'"
        :is-active="getView === 'designers'"
        @button-click="switchView"
      />
    </li>

    <li
      v-if="articlesCount"
      class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
    >
      <BaseCountButton
        :label="'Inspiration'"
        :count="articlesCount"
        :value="'articles'"
        :is-active="getView === 'articles'"
        @button-click="switchView"
      />
    </li>
  </ul>
</template>

<style scoped lang="postcss">
  .toggle {
    top: calc(theme('spacing.header-mobile') - 1px);

    @screen lg {
      top: theme('spacing.header-desktop');
    }
  }
</style>
