<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseButton from '@/components/BaseButton'
  import CartLineItemsFull from '@/components/cart/CartLineItemsFull'
  import CartTotals from '@/components/cart/CartTotals'
  import api from '@/api'

  const { mapGetters } = createNamespacedHelpers('cart')
  const { mapGetters: mapCheckoutGetters } = createNamespacedHelpers('checkout')

  /**
   * The main Cart page component
   */
  export default {
    name: 'CartPage',

    components: {
      BaseButton,
      CartLineItemsFull,
      CartTotals,
    },
    computed: {
      /** Checks the window width on load to set a boolean to know if it's okay to chow full labels in the buttons */
      fullLabels: function () {
        return window.matchMedia('(min-width: 1024px)').matches
      },
      ...mapGetters(['getItems']),
      ...mapCheckoutGetters(['getEmail']),
    },

    created() {
      // Load the user's cart
      this.$store.dispatch('cart/loadCart')
    },

    methods: {
      // Syncs the 'Started Checkout' event with the user profile in Klaviyo
      // TODO: Temporary conditional in place here as it was failing on the checkout process if a user was not logged in (thus, no email address)
      handleCheckoutClick() {
        if (this.getEmail) {
          api.startedCheckout(this.getEmail).then(() => {
            window.location.href = '/shop/checkout'
          })
        } else {
          window.location.href = '/shop/checkout'
        }
      },
    },
  }
</script>

<template>
  <CheckoutLayout class="mx-auto lg:max-w-screen-2xl w-full">
    <template v-slot:header>
      <h2 class="flex items-end h-full pb-4 text-md font-medium truncate">
        <span>In Your Cart</span>
      </h2>
    </template>
    <template v-slot:left>
      <div class="pb-14">
        <CartLineItemsFull :editable="true" />
        <div v-if="getItems.length === 0" class="pl-4 md:pl-6 py-3">
          <h3 class="flex items-end h-full pb-4 text-lg truncate" role="alert"
            >Your cart is empty.</h3
          >

          <BaseButton capitalize :url="'/lighting?s=buynow'" :color="'black'">Shop Now</BaseButton>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="flex flex-col justify-between w-full h-full top-0 bottom-0">
        <div class="overflow-y-scroll">
          <h2
            class="px-4 py-5 text-md font-bold border-gray-200 border-t border-b | lg:px-6 lg:border-t-0"
          >
            Order Summary
          </h2>
          <CartTotals :total-label="'Estimated Total'" :compact="true" />
        </div>
        <div>
          <BaseButton
            :color="'black'"
            :full-width="true"
            capitalize
            :disabled="getItems.length === 0"
            class="fixed lg:relative left-0 bottom-0 right-0"
            @button-click="handleCheckoutClick"
          >
            Checkout
          </BaseButton>
        </div>
      </div>
    </template>
  </CheckoutLayout>
</template>
