<script>
  /*
   * The thank you page on a successful user sign up
   */

  export default {
    name: 'RegisterThanks',
    data() {
      return {
        firstName: '',
        message: ''
      }
    },
    mounted() {
      this.firstName = localStorage.getItem('name')
      this.message = localStorage.getItem('message')
    },
    destroyed() {
      localStorage.removeItem('name')
      localStorage.removeItem('message')
    },
  }
</script>

<template>
  <div class="max-w-6xl container mx-auto mt-20 mb-32 px-4">
    <h1 class="text-2xl lg:text-4xl text-center mb-12 lg:mb-16"> Haute Living Account </h1>

    <p class="text-center">
      Thank you, <span id="firstName" class="font-bold">{{ firstName }}</span
      >. {{ message }}
    </p>
  </div>
</template>
