<script>
  import BuilderBannerInfo from '@/components/builder/BuilderBannerInfo'
  import BaseLink from '@/components/BaseLink'

  /** A Reusable Callout Banner component */

  export default {
    name: 'BuilderCalloutBanner',

    components: {
      BaseLink,
      BuilderBannerInfo,
    },

    props: {
      /** Heading of banner */
      heading: {
        type: String,
        required: true,
      },
      /** Summary of banner */
      summary: {
        type: String,
        required: true,
      },
      /** URL of banner */
      url: {
        type: String,
        default: null,
      },
      /** Target attribute of link */
      target: {
        type: String,
        default: '',
        validator: (value) => {
          // The value must match one of these strings
          return ['_blank', ''].indexOf(value) !== -1
        },
      },
      /** Background Color of banner */
      bgColor: {
        type: String,
        default: '#000000',
      },
      /** Text Colour of banner */
      txColor: {
        type: String,
        default: 'white',
        validator: (value) => {
          // The value must match one of these strings
          return ['white', 'black'].indexOf(value) !== -1
        },
      },
      /** Component label */
      label: {
        type: String,
        default: null,
      },
      /** Component anchor */
      anchor: {
        type: String,
        default: null,
      },
    },
  }
</script>

<template>
  <div :id="anchor" class="flex || section" :aria-label="label">
    <BaseLink
      v-if="url !== null"
      :href="url"
      :target="target"
      class="callout-banner hover w-full text-center px-4 py-20 md:px-6 md:py-24 | focus:outline-none"
      :style="`background-color: ${bgColor}`"
    >
      <BuilderBannerInfo :heading="heading" :summary="summary" :tx-color="txColor" />
    </BaseLink>
    <div
      v-else
      class="callout-banner w-full text-center px-4 py-20 md:px-6 md:py-24"
      :style="`background-color: ${bgColor}`"
    >
      <BuilderBannerInfo :heading="heading" :summary="summary" :tx-color="txColor" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .callout-banner {
    &.hover {
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.9;
      }

      &:focus {
        opacity: 0.9;
      }
    }
  }
</style>
