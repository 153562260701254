<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import BuilderHeroSliderSlide from '@/components/builder/BuilderHeroSliderSlide'

  /** Content builder hero slider block. Uses VueSlickCarousel for interactions
   * and the BuilderHeroSliderSlide component for slides.
   * See: https://github.com/gs-shop/vue-slick-carousel */

  export default {
    name: 'BuilderHeroSlider',

    components: {
      VueSlickCarousel,
      BuilderHeroSliderSlide,
    },

    props: {
      /** An array of objects of slides to display */
      slides: {
        type: Array,
        required: false,
        default: () => ([]),
      },
      linkText:{
        type:String,
        default:''
      },  
      title:{
        type:String,
        default:''
      },  
      text:{
        type:String,
        default:''
      },  
      url:{
        type:String,
        default:''
      },  
      /** Whether or not to autoplay the slider on load */
      autoplay: {
        type: Boolean,
        required: false,
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: '',
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        reset: 0,
        noticeHeight: '0px',
        headerHeight: '0px',
        /** If you change the padding of the slide, you have to change these to match */
        slidePadding: 'p-4 md:p-16 lg:p-25',
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          draggable: true,
          swipe: true,
          autoplay: this.autoplay, /* this.autoplay */
          autoplaySpeed: 6000,
          pauseOnFocus: true,
          adaptiveHeight:true
        },
      }
    },

    computed: {
      slideHeight: function () {
        /** Offsets the height of the slide to account for a notice bar at the bottom of the screen */
        // @TODO: Make sure this works with a notice still - KF Mar 4 2022
        return `height: calc(100vh - (${this.headerHeight}))`
      },
    },

    mounted() {
      const notice = document.getElementById('notice')
      this.noticeHeight = notice ? notice.offsetHeight + 'px' : '0px'

      const header = document.getElementById('ref-header')
      this.headerHeight = header ? header.offsetHeight + 'px' : '0px'

      // Start on a random slide when mounted
      this.startSlideshow()
    },

    methods: {
      startSlideshow() {
        this.reset = this.reset + 1
      },
    },
  }
</script>

<template>
  <section v-show="slides.length" :id="anchor" class="h-100vh bg--grey relative overflow-hidden">
    <div class="flex relative pt-20 lg:pt-0 flex-col lg:items-center lg:flex-row justify-between h-full">
      <div class="flex flex-col pl-4 pr-4 lg:pl-6 lg:pr-12 w-full lg:w-6/12 pb-4 lg:pb-0 heroSliderTextSection">
        <h3 v-if="title" class="block leading-tight uppercase font-semibold text-4xl xl:text-7xl mb-5">{{ title }}</h3>
        <div v-if="text" class="text-xl leading-tight w-full mb-8 heroSliderContent lg:mb-12">
          <p>{{ text }}</p>
        </div>
        <div v-if="url" class="flex items-start justify-start">
          <a :href="url" class="font-semibold py-5 px-20 | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider text-center w-full sm:text-left sm:w-auto">{{ linkText }}</a>
        </div>
      </div>
      <div class="w-full lg:w-6/12 lg:h-full relative -mb-2 lg:-mb-0">
        <VueSlickCarousel
          v-if="slides.length"
          ref="slider"
          :key="reset"
          v-bind="settings"
        >
          <div
            v-for="(slide, index) in slides"
            :key="index"
            class="relative"
            draggable="true"
          >
            <BuilderHeroSliderSlide :imageUrl="slide.image.urls[0]" :imageAlt="slide.image.alt" :parent-ref="$refs" v-bind="slide" :slide-height="slideHeight" :all-slides="slides.length" :index="index" />
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .slick-dots {
    bottom: 40px;
    li {
      margin-right: 18px;
      &.slick-active {
        button {
          background-color:#FFFFFF;
          opacity: 1;
        }
      }
    }
    button {
      width: 16px;
      height: 16px;
      background-color:#FFFFFF;
      opacity: 0.3;
    }
  }
  .bg--grey {
    background: #EEEEEE;
  }
  .heroSliderTextSection {
    padding-top: 50px;
    @media(max-height:850px) {
      h3 {
        line-height: 78px;
      }
    }
    @media(max-height:750px) {
      h3 {
        font-size: 65px;
        line-height: 68px;
      }
    }
    @media(max-height:650px) {
      .heroSliderContent {
        margin-bottom: 1.5rem;
      }
    }
    @media(max-width:1024px) {
      padding-top: 0;
    }
    @media(max-width:767px) {
      h3 {
        font-size: 52px;
        line-height: 56px;
      }
    }
  }
  @media(min-width:1024px) {
    .h-100vh {
      height: calc(100vh - 112px);
    }
  }
</style>
