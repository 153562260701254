<script>
  import { formatCurrency } from '@/utilities/strings'

  /** A line item prices component used within line items */

  export default {
    name: 'CartLineItemPrices',

    props: {
      /** The item base price (sans sales and discounts) */
      price: {
        type: [Number, String],
        required: true,
      },
      /** An array of sales objects that include a label, price, and whether or not it is a trade discount */
      sales: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** Whether the user is logged in as a trade account user */
      tradeAccount: {
        type: Boolean,
        required: false,
      },
      /** Text alignment */
      align: {
        type: String,
        required: false,
        default: 'right',
      },
      /** Whether the component should be compact (reduced for use in sidebar) */
      compact: {
        type: Boolean,
        required: false,
      },
    },

    computed: {
      /** Gets the base price and then prepends all the sales prices into one array */
      priceArray: function () {
        const priceArr = [
          {
            label: this.tradeAccount ? 'MSRP' : '',
            price: this.price,
            isTrade: false,
          },
        ]

        if (this.tradeAccount) {
          return this.sales.concat(priceArr)
        } else {
          return this.sales.filter((sale) => !sale.isTrade).concat(priceArr)
        }
      },
    },

    methods: {
      /** Creates a nice readable label for each price */
      priceLabel: function (priceObj) {
        return priceObj.label ? priceObj.label : 'Price'
      },
      // Formats a number as a currency string
      formatCurrency,
    },
  }
</script>

<template>
  <dl class="text-xs text-gray-600" :class="{ 'xl:text-base': !compact }">
    <div
      v-for="(priceObj, index) in priceArray"
      :key="index"
      class="flex"
      :class="{
        'text-black': index === 0,
        'line-through': index !== 0,
        'justify-end': align === 'right',
        'justify-start': align === 'left',
        'justify-center': align === 'center',
      }"
    >
      <dt
        :id="priceLabel(priceObj)"
        class="inline-block mr-1"
        :class="{ 'sr-only': priceObj.label === '' }"
      >
        {{ priceLabel(priceObj) }}
      </dt>
      <dd :aria-labelledby="priceLabel(priceObj)" class="inline">
        {{ formatCurrency(priceObj.price, 0) }}
      </dd>
    </div>
  </dl>
</template>
