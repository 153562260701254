<script>
  import PlusIcon from '@/images/icons/plus-sign.svg'
  import MinusIcon from '@/images/icons/minus-sign.svg'
  import ProductTile from '@/components/products/ProductTile'
  export default {
    name: 'CategoryBuilderDefaultContent',
    components: {
      ProductTile,
      PlusIcon,
      MinusIcon,
    },
    props: {
      sections: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      toggleAccordeion(index) {
        const accordeon = this.$refs.accordeon[index]
        const accordeonContent = accordeon.nextElementSibling
        accordeon.classList.toggle('active')
        accordeonContent.classList.toggle('hidden')
      },
    },
  }
</script>

<template>
  <div v-if="sections.length > 0" class="accordeonWrapper py-5 flex flex-col">
    <div v-for="(section, index) in sections" :key="index" class="accordeonWrapper__block">
      <h3
        v-if="section.title"
        ref="accordeon"
        class="accordeonWrapper__block__title"
        @click="toggleAccordeion(index)"
      >
        {{ section.title }}
        <span>
          <PlusIcon class="plusIcon" />
          <MinusIcon class="minusIcon" />
        </span>
      </h3>
      <div class="accordeonWrapper__block__content hidden">
        <div v-if="section.text" class="accordeonWrapper__block__text" v-html="section.text"></div>
        <div v-if="section.brands.length > 0" class="brandsWrapper py-5">
          <aside class="related-products">
            <div>
              <div class="grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-8">
                <ProductTile
                  v-for="brand in section.brands"
                  :key="brand.id"
                  :types="'Brands'"
                  v-bind="brand"
                />
              </div>
            </div>
          </aside>
        </div>
        <div v-if="section.products.length > 0" class="brandsWrapper py-5">
          <aside class="related-products">
            <div>
              <div class="grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-8">
                <ProductTile
                  v-for="product in section.products"
                  :key="product.id"
                  :types="'Products'"
                  v-bind="product"
                />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .accordeonWrapper {
    &__block {
      border-top: 1px solid #dfdfdf;

      &__title {
        position: relative;
        display: block;
        width: 100%;
        padding: 30px 0;
        margin: 0;
        font: 600 20px/24px 'Inter', sans-serif;
        text-transform: uppercase;
        cursor: pointer;

        span {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }

        svg.plusIcon {
          display: block;
        }

        svg.minusIcon {
          display: none;
        }

        &.active {
          svg.plusIcon {
            display: none;
          }

          svg.minusIcon {
            display: block;
          }
        }
        @media (max-width: 1024px) {
          padding: 20px 0;
          font: 600 16px/19px 'Inter', sans-serif;
        }
      }

      &__content {
        padding-bottom: 30px;
      }

      &__text {
        >>> h2 {
          display: block;
          margin: 0 0 30px 0;
          font: 500 36px/43px 'Inter', sans-serif;
          @media (max-width: 1024px) {
            margin-bottom: 20px;
            font: 500 24px/29px 'Inter', sans-serif;
          }
        }

        >>> h3 {
          display: block;
          margin: 0 0 30px 0;
          font: 500 36px/43px 'Inter', sans-serif;
          @media (max-width: 1024px) {
            margin-bottom: 20px;
            font: 500 24px/29px 'Inter', sans-serif;
          }
        }

        >>> p {
          display: block;
          margin: 0 0 20px 0;

          &:last-child {
            margin-bottom: 0;
          }
        }

        >>> a {
          font-weight: 600;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
</style>
