<script>
  import VLazyImage from 'v-lazy-image'

  /** A base image component used in the BuilderHeroImage, BuilderHeroSliderSlide, and BuilderImageSlider
   * components */

  export default {
    name: 'BuilderBaseImage',

    components: {
      VLazyImage,
    },

    props: {
      /** Small mobile image object */
      smallImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** Large image object */
      largeImage: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
    },

    computed: {
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTM2MCA2NzAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgY2xpcC1wYXRoPSJ1cmwoI2NsaXAtcGF0aCkiIHN0eWxlPSdmaWxsOiB1cmwoIiNmaWxsIik7JyAvPgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwLXBhdGgiPgogICAgICA8cmVjdCB4PSIwIiB5PSIwIiByeD0iMCIgcnk9IjAiIHdpZHRoPSIxMzYwIiBoZWlnaHQ9IjY3MCIgLz4KICAgIDwvY2xpcFBhdGg+CiAgICA8bGluZWFyR3JhZGllbnQgaWQ9ImZpbGwiPgogICAgICA8c3RvcAogICAgICAgIG9mZnNldD0iMC41OTk5NjQiCiAgICAgICAgc3RvcC1jb2xvcj0iI2YzZjNmMyIKICAgICAgICBzdG9wLW9wYWNpdHk9IjEiCiAgICAgID4KICAgICAgICA8YW5pbWF0ZQogICAgICAgICAgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IgogICAgICAgICAgdmFsdWVzPSItMjsgLTI7IDEiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICA+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICAgIDxzdG9wCiAgICAgICAgb2Zmc2V0PSIxLjU5OTk2IgogICAgICAgIHN0b3AtY29sb3I9IiNlY2ViZWIiCiAgICAgICAgc3RvcC1vcGFjaXR5PSIxIgogICAgICA+CiAgICAgICAgPGFuaW1hdGUKICAgICAgICAgIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIKICAgICAgICAgIHZhbHVlcz0iLTE7IC0xOyAyIgogICAgICAgICAga2V5VGltZXM9IjA7IDAuMjU7IDEiCiAgICAgICAgICBkdXI9IjJzIgogICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiCiAgICAgICAgPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcAogICAgICAgIG9mZnNldD0iMi41OTk5NiIKICAgICAgICBzdG9wLWNvbG9yPSIjZjNmM2YzIgogICAgICAgIHN0b3Atb3BhY2l0eT0iMSIKICAgICAgPgogICAgICAgIDxhbmltYXRlCiAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJvZmZzZXQiCiAgICAgICAgICB2YWx1ZXM9IjA7IDA7IDMiCiAgICAgICAgICBrZXlUaW1lcz0iMDsgMC4yNTsgMSIKICAgICAgICAgIGR1cj0iMnMiCiAgICAgICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIKICAgICAgICA+PC9hbmltYXRlPgogICAgICA8L3N0b3A+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KPC9zdmc+Cg=='
      },
    },
  }
</script>

<template>
  <VLazyImage
    :srcset="largeImage.urls[0] + ' 1x,' + largeImage.urls[1] + ' 2x'"
    :src="srcPlaceholder"
    :sizes="'100vw'"
    :alt="largeImage.alt"
    use-picture
  >
    <source
      media="(max-width: 767px)"
      :srcset="smallImage.urls[0] + ' 1x,' + smallImage.urls[1] + ' 2x'"
    />
    <source
      media="(min-width: 768px)"
      :srcset="largeImage.urls[0] + ' 1x,' + largeImage.urls[1] + ' 2x'"
    />
  </VLazyImage>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  picture,
  img {
    object-fit: cover;
    height: 100%;

    @apply block w-full;
  }

  .v-lazy-image {
    opacity: 0;
    transition: opacity 0.7s;
  }

  .v-lazy-image-loaded {
    opacity: 1;
  }
  /* purgecss end ignore */
</style>
