<script>
  import { createNamespacedHelpers } from 'vuex'
  import BasePageHeader from '@/components/BasePageHeader'
  import SearchViewToggle from '@/components/search/SearchViewToggle'
  import ProductFilter from '@/components/products/ProductFilter'
  import ProductTileGrid from '@/components/products/ProductTileGrid'
  import BrandTileGrid from '@/components/brands/BrandTileGrid'
  import DesignerTileGrid from '@/components/designers/DesignerTileGrid'
  import ArticleTileGrid from '@/components/articles/ArticleTileGrid'

  /** The search results main app component for the search results page */

  const { mapGetters, mapMutations } = createNamespacedHelpers('search')
  const {
    mapActions: mapProductsActions,
    mapState: mapProductsState,
    mapGetters: mapProductsGetters,
  } = createNamespacedHelpers('products')

  export default {
    name: 'SearchResultsPage',

    components: {
      BasePageHeader,
      SearchViewToggle,
      ProductFilter,
      ProductTileGrid,
      BrandTileGrid,
      DesignerTileGrid,
      ArticleTileGrid,
    },

    props: {
      /** The query string that is passed in for the search */
      query: {
        type: String,
        required: false,
        default: '',
      },
      /** A Craft product category slug */
      category: {
        type: String,
        default: null,
      },
      /** The options for the product filters */
      filterOptions: {
        type: Object,
        default: null,
      },
      /** The handles of product filters to hide */
      hiddenFilters: {
        type: Array,
        default: null,
      },
      /** Is the user logged in as a trade account user */
      isTradeAccount: {
        type: Boolean,
        required: false,
      },
      /** Is this a collection */
      isCollection: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        filterBarOpen: false,
        moreWidth:false
      }
    },

    computed: {
      /** Vuex getters */
      ...mapGetters(['getView', 'getViewSelected']),
      ...mapProductsGetters(['getPaginationData', 'getIsLoading']),
      ...mapProductsState(['sortOptions', 'sort']),
      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
      /** Total product results */
      productsCount: function () {
        return this.$store.getters['products/getTotalItems']
      },
      /** Total brand results */
      brandsCount: function () {
        return this.$store.getters['brands/getTotalItems']
      },
      /** Total designer results */
      designersCount: function () {
        return this.$store.getters['designers/getTotalItems']
      },
      /** Total article results */
      articlesCount: function () {
        return this.$store.getters['articles/getTotalItems']
      },
      /** Sets the context for the product filters */
      filterContext: function () {
        if (this.productsCount || this.brandsCount || this.designersCount || this.articlesCount) {
          return 'search'
        } else {
          return 'plp'
        }
      },
      /** Display the product results */
      showProducts: function () {
        return this.productsCount && this.getView === 'products'
      },
      /** Display the brand results */
      showBrands: function () {
        return this.brandsCount && this.getView === 'brands'
      },
      /** Display the designer results */
      showDesigners: function () {
        return this.designersCount && this.getView === 'designers'
      },
      /** Display the article results */
      showArticles: function () {
        return this.articlesCount && this.getView === 'articles'
      },
    },

    created() {
      // Initialize the product filters and set the trade account
      this.initProductFilters()
      this.setTradeAccount()
      // Search each based on the the query string
      this.searchProducts(this.query)
      this.searchBrands(this.query)
      this.searchDesigners(this.query)
      this.searchArticles(this.query)
    },

    mounted() {
      // Save a browsing snapshot so we can use the back button to get back to these results
      this.$store.dispatch('products/applyBrowsingSnapshot')

      // Send the appropriate GTM event
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'Search',
          email: this.$store.getters['global/currentUser']
            ? this.$store.getters['global/currentUser'].email
            : '',
          search_query: this.query,
        })
      }
    },

    updated() {
      // Initialize the View Toggle
      this.initView()
    },

    methods: {
      /** Vuex mutators */
      ...mapMutations(['setView', 'setSort']),
      ...mapProductsActions(['setSort']),
      /** Initializes the product filters */
      initProductFilters() {
        this.$store.dispatch('products/initFilters', {
          filterOptions: this.filterOptions,
          hiddenFilters: this.hiddenFilters,
        })
      },
      /** Initializes the view toggle (so first section with results gets displayed) */
      initView() {
        if (!this.getViewSelected) {
          if (this.productsCount) {
            this.setView('products')
            return false
          }
          if (this.brandsCount) {
            this.setView('brands')
            return false
          }
          if (this.designersCount) {
            this.setView('designers')
            return false
          }
          if (this.articlesCount) {
            this.setView('articles')
            return false
          }
        }
      },
      /** Sets the trade account var to hide/show trade account prices */
      setTradeAccount() {
        this.$store.commit('products/setTradeAccount', this.isTradeAccount)
      },
      /** Vuex Algolia search method for products */
      searchProducts(query) {
        this.$store.dispatch('products/searchProducts', {
          query: query,
        })
      },
      /** Vuex Algolia search method for brands */
      searchBrands(query) {
        this.$store.dispatch('brands/searchBrands', {
          query: query,
        })
      },
      /** Vuex Algolia search method for designers */
      searchDesigners(query) {
        this.$store.dispatch('designers/searchDesigners', {
          query: query,
        })
      },
      /** Vuex Algolia search method for articles */
      searchArticles(query) {
        this.$store.dispatch('articles/searchArticles', {
          query: query,
        })
      },
      showSidebarFilters(data) {
        this.moreWidth = data
      },
    },
  }
</script>

<template>
  <main id="main-content">
    <article>
      <BasePageHeader :heading="query" :prefix="isCollection ? 'Collection' : 'Search'" />

      <div class="hidden mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 lg:block">
        <div class="border-gray-300 border-t md:border-b md:border-t-0 -mb-24 lg:-mb-0">
          <SearchViewToggle />
        </div>
      </div>

      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div
          class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-12"
          :class="filterBarOpen ? 'z-99' : ''"
        >
          <div 
            v-if="moreWidth"
            @click="moreWidth = !moreWidth"
            class="filterActionBtn"
          >
            <span>+Filter</span>
          </div>
          <ProductFilter
            :open="filterBarOpen"
            :context="filterContext"
            :moreWidth="moreWidth"
            @showSidebarFilters="showSidebarFilters"
            @toggle="filterBarOpen = $event"
            :isTradeAccount="isTradeAccount"
          />
          <div
            v-if="filterBarOpen"
            class="z-20 absolute inset-0 lg:hidden bg-black bg-opacity-25"
            @click="filterBarOpen = false"
          ></div>
          <div 
            class="transition--all"
            :class="`${ moreWidth ? 'w-full pl-0 lg:pl-2' : 'w-full lg:w-9/12 xl:w-4/5' }`"
          >
            <!-- Pagination indicator small screens -->
            <!--<nav ref="pagination" aria-label="Product pagination" class="block md:hidden mt-4">
              <span
                v-if="!getIsLoading"
                class="block text-center md:hidden text-sm text-gray-500 || mobile"
                aria-current="true"
                aria-label="current products"
                v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
              />
            </nav>-->
            <div class="hidden lg:flex justify-end w-full">
              <!--<nav ref="pagination" aria-label="Product pagination" class="hidden md:block mt-6 ml-6">
                <span
                  :class="!getIsLoading ? 'visible' : 'invisible'"
                  class="md:text-sm lg:text-base whitespace-no-wrap text-gray-500 || mobile"
                  aria-current="true"
                  aria-label="current products"
                  v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
                />
              </nav>-->
              <BaseFilterSelect
                v-if="isTradeAccount"
                label="Sort by"
                :options="sortOptions"
                :value="sort"
                class="lg:pr-6 mt-3"
                @change="setSort($event)"
              />
            </div>
            <div v-if="!isTradeAccount" class="h-4 w-full"></div>
            <ProductTileGrid class="md:pt-1" :hide-price="true" />
          </div>
        </div>

        <div v-if="showBrands">
          <BrandTileGrid />
        </div>

        <div v-if="showDesigners">
          <DesignerTileGrid />
        </div>

        <div v-if="showArticles">
          <ArticleTileGrid />
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped lang="scss">
  .appendFilters {
    position: relative;
    top: 45px;
    z-index: 99;
    width: calc(100% - 300px);
    @media(max-width:1024px) {
      width: 100%;
    }
  }
  .filterActionBtn {
    position: absolute;
    top: 75px;
    cursor: pointer;
    left: -14px;
    z-index: 999;
    transform: rotate(-90deg);
    span {
      color:#000;
      font:500 14px/14px 'Inter';
    }
  }
  .transition--all {
    transition: all 0.25s ease-in-out;
  }
  .filterButtons {
    margin-right: 20px;
    display: flex;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 10px;
        span {
          display: inline-flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          padding: 0 37px 0 15px;
          height: 26px;
          color: #000;
          font-family: 'Inter';
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          border: 1px solid #000;
          svg {
            position: absolute;
            right: 18px;
            top:50%;
            transform: translateY(-50%);
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
