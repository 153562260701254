<script>
  /** Reusable button component meant to be used in filter bars. Can optionally be a label that is styled the same way. */

  export default {
    name: 'BaseFilterButton',

    props: {
      /** The button's label */
      label: {
        type: String,
        required: true,
      },
      /** The button's ARIA label */
      ariaLabel: {
        type: String,
        default: undefined,
      },
      /** The HTML tag of the component, which is a `button` by default, but can also be a `label` */
      tag: {
        type: String,
        default: 'button',
        validator: (value) => ['button', 'label'].includes(value),
      },
      /** The button's background color, either 'white' or 'gray' */
      backgroundColor: {
        type: String,
        default: 'white',
        validator: (value) => ['white', 'gray'].includes(value),
      },
      /** Whether to display a gray border around the button */
      border: {
        type: Boolean,
        default: false,
      },
      /** The alignment of the button's label, either `left` or `center` */
      textAlign: {
        type: String,
        default: 'left',
        validator: (value) => ['left', 'center', 'end'].includes(value),
      },
      /** Whether to put emphasis (bold text) on the button to catch the user's attention */
      emphasis: {
        type: Boolean,
        default: false,
      },
      /** Whether the button is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Number of items available for filter */
      availableItems: {
        type: [Number, Boolean],
        default: 0,
      },
    },

    computed: {
      typeAttr() {
        return this.tag === 'button' ? 'button' : undefined
      },
      disabledAttr() {
        return this.tag === 'button' ? this.disabled : undefined
      },
    },
  }
</script>

<template>
  <component
    :is="tag"
    :type="typeAttr"
    class="relative flex items-center w-full h-12 text-sm bg-white border cursor-pointer transition-colors duration-300 | focus:outline-none pr-0"
    :class="{
      'justify-between': textAlign === 'left',
      'justify-center': textAlign === 'center',
      'justify-end': textAlign === 'end',
      'bg-gray-200': backgroundColor === 'gray',
      'border-gray-300': border,
      'border-transparent': !border,
      //'font-bold': emphasis,
      'pointer-events-none': disabled,
    }"
    :disabled="disabledAttr"
    :aria-label="ariaLabel"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="before" />
    <span class="flex items-center min-w-0 text-gray-600 font-unica77">
      <slot name="icon" />
      <!-- Weird trick to make sure the content's width remains the same regardless of `emphasis`, to prevent reflow -->
      <span
        class="relative min-w-0"
        :class="{
          'text-left': textAlign === 'left',
          'text-center': textAlign === 'center',
        }"
      >
        <span class="truncate absolute left-0 top-0 text-black font-medium tracking-02">{{ label }}</span>
        <span class="truncate invisible font-normal text-black font-medium tracking-02">{{ label }}</span>
        <!--<span v-if="availableItems" class="font-normal">({{ availableItems }})</span>-->
      </span>
    </span>
    <slot name="after" />
  </component>
</template>

<style lang="scss">
  span {
    font-size: 16px;
  }
</style>
