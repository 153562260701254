<script>
  import ProductTile from '@/components/products/ProductTile'
  export default {
    name: 'CategoryBuilderBrandsRelation',
    components: {
      ProductTile,
    },
    props: {
      products: {
        type: Array,
        default: () => [],
      },
    },
  }
</script>

<template>
  <div v-if="products.length > 0" class="brandsWrapper py-5">
    <aside class="related-products">
      <div>
        <div class="grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-8">
          <ProductTile
            v-for="product in products"
            :key="product.id"
            :types="'Products'"
            v-bind="product"
          />
        </div>
      </div>
    </aside>
  </div>
</template>
