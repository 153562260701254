<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseButton from '@/components/BaseButton'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** Pagination button that appears below the PLP listing grid to allow users to load more products.
   * Displays the total amount, total showing and amount left on next click. */

  export default {
    name: 'FeaturedPagination',

    components: {
      BaseButton,
    },

    props: {
      count: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginationFeaturedData', 'isLastFeaturedPage']),

      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' +
          this.getPaginationFeaturedData.current +
          '</span>'
        )
      },

      totalQuantity: function () {
        return (
          '<span data-cy="totalNumberOfProducts">' + this.getPaginationFeaturedData.total + '</span>'
        )
      },

      moreOrLess: function() {
        return this.isLastFeaturedPage ? 'See Less' : 'See More'
      }
    },

    methods: {
      /** Vuex action methods */
      ...mapActions(['nextFeaturedPage']),
    },
  }
</script>

<template>
  <nav
    v-if="getPaginationFeaturedData.total > 4"
    aria-label="Product pagination"
    class="flex flex-col justify-center items-center pb-0 pt-4 underline underline-2 hover:no-underline | md:flex-row || wrapper"
  >
    <span
      class="text-gray-500 || desktop invisible"
      v-html="'Showing ' + shownQuantity + ' Products'"
    />

    <transition name="fade">
      <BaseButton
        :color="'white'"
        :rounded="true"
        class="mb-2 | md:mb-0 bg-transparent border-0 underline hover:no-underline"
        :button-class="`font-bold tracking-150 uppercase border-0 underline hover:no-underline`"
        :featured="true"
        :additional-btn-classes="`font-bold tracking-150 uppercase border-0 underline hover:no-underline border-none`"
        @button-click="nextFeaturedPage"
      >
      <span class="border-solid border-0 border-b border-black hover:border-transparent tracking-150 font-unica77 font-bold" style="border-bottom-width: 2px;">
        {{ moreOrLess }}
      </span>
      </BaseButton>
    </transition>

    <span
      class="text-gray-500 || desktop invisible"
      aria-label="total products"
      v-html="'Total ' + totalQuantity + ' Products'"
    />

    <span
      class="block text-xs text-gray-500 || mobile invisible"
      aria-current="true"
      aria-label="current products"
      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
    />
  </nav>
  <div v-else class="h-12" />
</template>

<style scoped lang="postcss">
  nav {
    margin-top: 0;
  }
  .wrapper {
    min-height: 123px;

    &.hasNoLoadMore {
      height: 0;
      min-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
  button span {
    font-size: 16px;
  }
</style>
