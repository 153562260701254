export default {
  namespaced: true,

  state: {
    sections: [],
    currentSection: {
      label: '',
      anchor: '',
    },
  },

  mutations: {
    setSections(state, value) {
      state.sections = value
    },
    setCurrentSection(state, value) {
      state.currentSection = value
    },
  },

  getters: {
    getSections(state) {
      return state.sections
    },
    getCurrentSection(state) {
      return state.currentSection
    },
  },

  actions: {
    activateSection(context, { section }) {
      context.commit('setCurrentSection', section)
    },
  },
}
