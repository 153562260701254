<script>
  /** A reusable base slide panel that displays/hides its content by sliding into view.
   * Uses a slot to inject it's content into the panel. */

  export default {
    name: 'BaseSlidePanel',

    props: {
      /** Whether or not the component's panel should have a transparent background */
      transparent: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Whether or not the component's panel should have a gray border around it */
      border: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      /** Emits a 'close' event to the parent component */
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<template>
  <transition name="panel-slide">
    <div class="panel z-20" :class="{ 'panel--transparent': transparent }">
      <div class="panel__space"></div>

      <div class="panel__content" :class="{ 'lg:border-l lg:border-gray-300': border }">
        <div class="flex justify-between items-center p-6 border-b border-gray-200 bg-white">
          <slot name="header"></slot>
          <button class="panel__close" type="button" @click="close">
            <span>Close Panel</span>
          </button>
        </div>

        <div class="p-6">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
  .panel {
    position: fixed;
    top: theme('spacing.header-mobile');
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-color: #fff;
  }

  .panel__content {
    height: calc(100vh - theme('spacing.header-mobile'));
    overflow-y: scroll;
    pointer-events: auto;
    background-color: #fff;
  }

  .panel--transparent {
    background-color: transparent;

    .panel__content {
      pointer-events: none;
      background-color: transparent;

      > * {
        pointer-events: auto;
      }
    }
  }

  .panel__close {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    overflow: hidden;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 2px;
      display: block;
      width: 21px;
      height: 2px;
      content: '';
      background-color: #000;
    }

    &::before {
      transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(45deg);
    }

    span {
      position: absolute;
      left: -9999px;
    }
  }

  @screen lg {
    .panel {
      top: theme('spacing.header-desktop');
      display: grid;
      grid-template-areas: 'blank blank blank content content';
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      pointer-events: none;
      background-color: transparent;
    }

    .panel__space {
      grid-area: blank;
    }

    .panel__content {
      grid-area: content;
      height: calc(100vh - theme('spacing.header-desktop'));
      pointer-events: auto;
    }

    .panel--transparent {
      background-color: transparent;
    }
  }

  @screen xl {
    .panel {
      grid-template-areas: 'blank blank content';
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .panel-slide-enter,
  .panel-slide-leave-active {
    transform: translate3d(100%, 0, 0);
  }

  .panel-slide-enter-active,
  .panel-slide-leave-active {
    transition: transform 0.5s ease;
  }
</style>
