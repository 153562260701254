<script>
  import BaseFeaturettePanel from '@/components/BaseFeaturettePanel'

  /** Content builder Featurette block. */

  export default {
    name: 'BuilderFeaturette',
    components: {
      BaseFeaturettePanel,
    },
    props: {
      /** An array of objects of panels to display */
      panels: {
        type: Array,
        required: false,
        default: () => [],
      },
      heading: {
        type: String,
        required: false,
        default: '',
      },
    },
    created() {
      window.addEventListener('resize', this.handleResize)
    },
    mounted() {
      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        const thisComponent = document.querySelectorAll('.general-text-row')
        const lastGridElements = this.$refs.featurette.querySelectorAll('.panel')
        let lastTwoElementsWidthSum = 0
        let styleString = ''

        if (lastGridElements.length > 1 && window.innerWidth > 768) {
          lastTwoElementsWidthSum =
            lastGridElements[0].offsetWidth + lastGridElements[1].offsetWidth
          styleString = `max-width: calc(${lastTwoElementsWidthSum}px + 4.5rem)`
        } else {
          lastTwoElementsWidthSum = 100
          styleString = `max-width: 100%`
        }

        if (lastTwoElementsWidthSum > 0) {
          thisComponent.forEach((element) => {
            element.setAttribute('style', `${styleString}`)
          })
        }
      },
    },
  }
</script>

<template>
  <section class="mx-auto max-w-screen-2xl w-full px-4 md:px-6 md:mt-6">
    <header 
      v-if="heading"
      class="mb-8 space-y-3 md:mb-16 lg:pb-4 flex flex-col md:flex-row justify-between"
    >
      <h3
        class="w-full md:w-1/2 text-3xl md:text-4xl lg:text-5xl capitalize lg:-ml-1"
        >{{ heading }}</h3
      >
    </header>
    <div ref="featurette" class="panels-wrapper" :dir="panels.length > 3 ? 'ltr' : 'rtl'">
      <BaseFeaturettePanel
        v-for="(panel, panelIndex) of panels"
        :key="`panel_${panelIndex}`"
        :title="panel.panelTitle"
        :image="panel.panelImage"
        :summary="panel.panelSummary"
        :link="panel.panelLink"
        :aspect-ratio="panel.panelImageAspectRatio"
        :color="panel.panelSummaryTextColor"
        class="panel"
      />
    </div>
  </section>
</template>

<style lang="postcss">
  .panels-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap:1.5rem 2.5rem;
  }

  @screen md {
    .panels-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @screen md {
    .panels-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @screen lg {
    .panels-wrapper {
      grid-template-columns: repeat(4, 1fr);

      >>> article {
        @apply px-4;
      }

      >>> a {
        @apply px-4;
      }
    }
  }
</style>
