<script>
  import BuilderTextBlockHeading from '@/components/builder/BuilderTextBlockHeading'
  import BuilderTextBlockRichText from '@/components/builder/BuilderTextBlockRichText'
  import BuilderTextBlockButton from '@/components/builder/BuilderTextBlockButton'
  import BuilderTextBlockDivider from '@/components/builder/BuilderTextBlockDivider'

  /** A text in columns content block for the content builder. Uses text sub-blocks dynamically to render
   * the text elements in the column */

  export default {
    name: 'BuilderTextColumn',

    components: {
      BuilderTextBlockHeading,
      BuilderTextBlockRichText,
      BuilderTextBlockButton,
      BuilderTextBlockDivider,
    },

    props: {
      /** The heading of the block section */
      heading: {
        type: String,
        required: true,
      },
      /** An array of text block types from the nested matrix field */
      textBlocks: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** The label of the block section (used by TOC block) */
      label: {
        type: String,
        required: false,
        default: null,
      },
      /** An ID anchor of the block section (used by TOC block) */
      anchor: {
        type: String,
        required: false,
        default: null,
      },
    },

    methods: {
      /** Uses the returned blocks "type" handle property and appends it with 'Builder'  */
      resolveComponent(typeHandle) {
        return 'BuilderTextBlock' + typeHandle
      },
    },
  }
</script>

<template>
  <section
    :id="anchor"
    class="px-4 py-12 border-b border-gray-300 | md:grid md:grid-cols-2 md:px-5 md:py-16 || section"
    :aria-label="label"
  >
    <header>
      <h3 class="mb-3 text-xl | md:pr-6 md:text-2xl || heading">{{ heading }}</h3>
    </header>

    <div class="">
      <div class="content-blocks">
        <component
          :is="resolveComponent(block.type)"
          v-for="(block, index) in textBlocks"
          :key="index"
          v-bind="block.content"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .content-blocks {
    >>> .heading {
      @apply mb-2 font-bold;
    }

    >>> .rich-text {
      @apply mb-7;
    }

    >>> .button {
      @apply mb-4;
    }

    >>> .divider {
      @apply mb-7;
    }

    >>> *:last-child {
      margin-bottom: 0 !important;
    }
  }

  @screen md {
    .heading {
      max-width: 550px;
    }

    .content-blocks {
      max-width: 550px;

      >>> .rich-text {
        @apply mb-8;
      }

      >>> .divider {
        @apply mb-8;
      }
    }
  }
  /* purgecss end ignore */
</style>
