<script>
  import BaseFilterDropdown from '@/components/BaseFilterDropdown'
  import BaseCheckbox from '@/components/BaseCheckbox'
  import IconSearch from '@/images/icons/search.svg'
  import IconXThin from '@/images/icons/x-thin.svg'

  let uniqueId = 0

  /** A reusable search-select component that allows users to select options from a list of checkboxes. Includes
   * functionality for searching/filtering the list of options while the user types in the search field. */

  export default {
    name: 'BaseFilterSelectSearchable',

    components: {
      BaseFilterDropdown,
      BaseCheckbox,
      IconSearch,
      IconXThin,
    },

    props: {
      /** The dropdown's label, shown in the toggle button */
      label: {
        type: String,
        required: true,
      },
      /** An array of option objects. Each option must have two properties: `label` and `value` */
      options: {
        type: Array,
        required: true,
      },
      /** The values of the options that should be selected */
      value: {
        type: Array,
        default: undefined,
      },
      /** Whether to display a gray border around the toggle button */
      border: {
        type: Boolean,
        default: false,
      },
      /** Whether the dropdown is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Classes to pass to the toggle button component */
      buttonClasses: {
        type: [String, Array, Object],
        default: undefined,
      },
    },

    data() {
      uniqueId++

      return {
        uniqueId: uniqueId,
        search: '',
        searchFocused: false,
        orderedOptionValues: [],
        selectedOptionValues: [],
        showClearSelected: false,
      }
    },

    computed: {
      /** Generate a random ID for the search field to tie the label and input together */
      inputId() {
        return 'search_select_' + uniqueId
      },
      /** Determine if the user has typed anything into the search field */
      isSearching() {
        return this.search !== ''
      },
      /** The options ordered by orderedOptionValues */
      orderedOptions() {
        if (this.orderedOptionValues.length === 0) {
          return this.options
        }
        return this.options
          .concat()
          .sort(
            (a, b) =>
              this.orderedOptionValues.indexOf(a.value) - this.orderedOptionValues.indexOf(b.value)
          )
      },
      /** The options returned by the current search */
      filteredOptions() {
        if (this.search === '') {
          return this.orderedOptions
        }
        return this.orderedOptions.filter(
          (option) => option.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        )
      },
      /** The count shown next to the label of the dropdown button */
      count() {
        if (this.selectedOptionValues.length > 0) {
          return this.selectedOptionValues.length
        }
        return this.options.length
      },
    },

    watch: {
      value() {
        if (this.value) {
          this.selectedOptionValues = this.value
        }
      },
      selectedOptionValues() {
        if (this.selectedOptionValues.length === 0) {
          this.showClearSelected = false
        }
        this.$emit('change', this.selectedOptionValues)
      },
    },

    methods: {
      /** Clears out the search query */
      clearSearch() {
        this.search = ''
      },
      /** Sets the data param for searchFocused (used for styling) */
      setSearchFocused(value) {
        this.searchFocused = value
      },
      /** Marks an option as selected or not selected */
      setSelected(option, selected) {
        if (selected) {
          this.selectedOptionValues.push(option.value)
        } else {
          const selectedOptionValueIndex = this.selectedOptionValues.indexOf(option.value)
          if (selectedOptionValueIndex !== -1) {
            this.selectedOptionValues.splice(selectedOptionValueIndex, 1)
          }
        }
      },
      /** Used to test if an option is selected. This is necessary as the checkboxes are created and destroyed
       * dynamically when the filtered options are being looped */
      isSelected(option) {
        return this.selectedOptionValues.indexOf(option.value) !== -1
      },
      /** Used to clear all selected options */
      clearSelected() {
        this.selectedOptionValues = []
        this.orderedOptionValues = []
        this.showClearSelected = false
      },
      /** Orders the options so the selected ones appear first */
      orderOptions() {
        this.orderedOptionValues = [
          ...this.selectedOptionValues,
          ...this.options
            .filter((option) => this.selectedOptionValues.includes(option.value) === false)
            .map((option) => option.value),
        ]
      },
      /** Pass the dropdown's toggle event to the parent, and do some things when the dropdown is opened or closed */
      toggleDropdown(open) {
        if (open) {
          this.search = ''
          this.orderOptions()
          if (this.selectedOptionValues.length > 0) {
            this.showClearSelected = true
          }
        }
        this.$emit('toggle', open)
      },
    },
  }
</script>

<template>
  <BaseFilterDropdown
    :label="label"
    :count="count"
    :border="border"
    :emphasis="selectedOptionValues.length > 0"
    :disabled="disabled"
    :button-classes="buttonClasses"
    @toggle="toggleDropdown"
  >
    <div class="flex flex-no-wrap justify-between mb-2">
      <label
        :for="inputId"
        class="relative mt-1"
        :class="{
          'text-black': isSearching || searchFocused,
          'text-gray-500': !isSearching && !searchFocused,
        }"
        aria-label="Type to search options"
      >
        <IconSearch class="fill-current" />
      </label>

      <input
        :id="inputId"
        v-model="search"
        type="search"
        class="block w-full px-2 -my-1 py-1 text-sm focus:outline-none"
        placeholder="Search"
        @focus="setSearchFocused(true)"
        @blur="setSearchFocused(false)"
      />

      <button
        v-if="isSearching"
        type="button"
        class="flex justify-center items-center text-gray-500 | focus:outline-none focus:text-black"
        aria-label="Clear option search terms"
        @click="clearSearch"
      >
        <IconXThin class="fill-current" />
      </button>
    </div>

    <div v-if="showClearSelected && !isSearching">
      <button
        type="button"
        class="relative pl-5 py-2 text-sm leading-tight | focus:outline-none"
        @click="clearSelected"
      >
        <IconXThin class="absolute left-0 fill-current" style="top: 11px" />
        <span>Clear selected</span>
      </button>
    </div>

    <div v-if="filteredOptions.length > 0">
      <BaseCheckbox
        v-for="(option, index) in filteredOptions"
        :key="index"
        :label="option.label"
        :value="option.value"
        :checked="isSelected(option)"
        @change="setSelected(option, $event)"
      />
    </div>
    <div v-else class="pt-2 pb-1 text-sm text-gray-600 leading-snug">
      No matching options found.
    </div>
  </BaseFilterDropdown>
</template>

<style scoped lang="postcss">
  label svg {
    width: 12px;
    height: 11px;
  }

  button {
    svg {
      width: 10px;
      height: 10px;
    }

    &:focus span {
      @apply underline;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
</style>
