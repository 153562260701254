<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  export default {
    name: 'BuilderBrandSlider',

    components: {
      VueSlickCarousel,
    },

    props: {
      brands: {
        type: Array,
        required: true,
        default: () => [],
      },
    },

    data() {
      return {
        options:{
          autoplay:true,
          autoplaySpeed:1000,
          infinite:true,
          slidesToShow: 9,
          dots:false,
          arrows:false,
          responsive:[
            {
              breakpoint: 1500,
              settings: {
                slidesToShow: 8,
              }
            },
            {
              breakpoint: 1300,
              settings: {
                slidesToShow: 7,
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 6,
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
              }
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 3,
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow:2,
              }
            },
          ]
        }
      }
    },

    methods: {
      
    },
  }
</script>

<template>
  <section v-show="brands.length" class="relative overflow-hidden">
    <div class="py-8 md:py-20 md:mt-1.5">
        <VueSlickCarousel
          v-bind="options"
          class="brandSlider"
        >
          <div 
            v-for="brand in brands"
            :key="brand.url"
            class="mx-auto w-full h-full px-8 md:px-8 brandSliderSlide"
          >
            <a :href="brand.url" class="flex items-center justify-center w-full h-28">
              <img :src="brand.logo">
            </a>
          </div>
        </VueSlickCarousel>
    </div>
  </section>
</template>

<style scoped lang="postcss">
  .brandSliderSlide {
    a {
      img {
        max-height: 75px;
        object-fit: contain;
      }
    }
  }
</style>
