<!--See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox for all attributes-->
<script>
  import BaseCheckbox from '@/components/BaseCheckbox.vue'

  /** Reusable base checkbox group component.
   * Emits an 'input' event which contains an array of selected checkbox values */

  export default {
    name: 'BaseCheckboxGroup',

    components: {
      BaseCheckbox,
    },

    props: {
      /** Title describing checkbox group */
      title: {
        type: String,
        required: true,
      },
      /** Array containing active checkbox values */
      value: {
        type: Array,
        default: () => [],
      },
      /** Array containing checkbox options */
      options: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        checkedOptions: [],
      }
    },

    watch: {
      /** Watches the the checkedOptions array and emits the array to the parent when it changes */
      checkedOptions() {
        this.$emit('input', this.checkedOptions)
      },
    },

    created() {
      // set value of checkboxes if already set from parent
      this.checkedOptions = this.value
    },

    methods: {
      /** Handle checkbox input value change */
      handleChange(option) {
        const optionPos = this.checkedOptions.indexOf(option)
        if (optionPos === -1) {
          this.checkedOptions.push(option)
        } else {
          this.checkedOptions = this.checkedOptions.filter(
            (checkedOption) => checkedOption !== option
          )
        }
      },
      /** Determine if checkbox option is checked */
      optionChecked(option) {
        const optionInactive = this.checkedOptions.indexOf(option) === -1
        return !optionInactive
      },
    },
  }
</script>

<template>
  <div>
    <h5 class="label text-black">{{ title }}</h5>
    <BaseCheckbox
      v-for="(option, index) in options"
      :key="index"
      :label="option"
      :checked="optionChecked(option)"
      class="quote-checkbox mb-3"
      @change="handleChange(option)"
    />
  </div>
</template>
