<script>
  import { createNamespacedHelpers } from 'vuex'
  import { formatCurrency } from '@/utilities/strings'
  import BaseButton from '@/components/BaseButton'
  import BaseInputText from '@/components/BaseInputText'

  /** The cart total amounts component
   * See https://www.digitala11y.com/definition-role/ for HTML tips.
   */

  const { mapGetters: mapCartGetters, mapActions: mapCartActions } = createNamespacedHelpers('cart')

  export default {
    name: 'CartTotals',
    components: {
      BaseButton,
      BaseInputText,
    },
    props: {
      /** An optional label for the grand total */
      totalLabel: {
        type: String,
        required: false,
        default: 'Total',
      },
      /** Whether or not to display a simplified version (without subtotals and price modifiers) */
      compact: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        couponCode: '',
      }
    },

    computed: {
      /** Vuex getters */
      ...mapCartGetters([
        'getCouponCode',
        'getCouponVerifying',
        'getCouponVerified',
        'getCouponDescription',
        'getDiscount',
        'getSaleAmount',
        'getShipping',
        'getTax',
        'getTotal',
      ]),
    },

    mounted() {
      this.couponCode = this.getCouponCode ?? ''
    },

    methods: {
      // Formats a number as a currency string
      formatCurrency,
      ...mapCartActions(['verifyCoupon']),
    },
  }
</script>

<template>
  <div class="px-4 py-5 bg-white | lg:px-6" :class="{ 'lg:border-gray-200 lg:border-t': !compact }">
    <div class="flex flex-nowrap justify-between items-end mb-6 pb-6 border-b border-gray-200">
      <div class="flex-grow">
        <BaseInputText v-model="couponCode" label="Coupon Code" type="text" />
      </div>
      <BaseButton
        color="black"
        capitalize
        :loading="getCouponVerifying"
        @button-click="verifyCoupon(couponCode)"
        >Apply</BaseButton
      >
    </div>

    <dl>
      <div v-if="getCouponVerified" class="flex justify-between items-start mb-2 text-gray-600">
        <dt id="coupon">Coupon</dt>
        <dd class="text-right" aria-labelledby="coupon">
          <span class="block">{{ getCouponDescription }}</span>
          <span v-if="compact && getDiscount" class="block"
            >Your Savings: {{ formatCurrency(getDiscount, 0) }}</span
          >
        </dd>
      </div>

      <div v-if="!compact" class="space-y-2">
        <div v-if="getDiscount" class="flex justify-between items-center text-gray-600">
          <dt id="discount">Your Savings</dt>
          <dd aria-labelledby="discount">{{ getDiscount ? formatCurrency(getDiscount, 0) : '—' }}
          </dd>
        </div>

        <div v-if="getSaleAmount" class="flex justify-between items-center text-gray-600">
          <dt id="saleAmount">Sale Amount</dt>
          <dd aria-labelledby="saleAmount">{{
            getSaleAmount ? '- ' + formatCurrency(getSaleAmount, 0) : '—'
          }}
          </dd>
        </div>

        <div class="flex justify-between items-center text-gray-600">
          <dt id="shipping">Shipping</dt>
          <dd aria-labelledby="shipping">{{
            !compact ? formatCurrency(getShipping, 0) : 'To be calculated'
          }}
          </dd>
        </div>
        <div class="flex justify-between items-center text-gray-600">
          <dt id="tax">Tax</dt>
          <dd aria-labelledby="tax">{{
            !compact ? formatCurrency(getTax, 0) : 'To be calculated'
          }}
          </dd>
        </div>
      </div>

      <div
        class="flex justify-between items-center"
        :class="{ 'mt-4 pt-4 border-gray-200 border-t': !compact }"
      >
        <dt id="total" role="label" class="font-bold">{{ totalLabel }}</dt>
        <dd class="font-bold" aria-labelledby="total">{{
          formatCurrency(getTotal, 0)
        }}</dd>
      </div>
    </dl>
  </div>
</template>
