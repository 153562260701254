<script>
  /** Visible/Invisible text divider sub-block used in the Text Column block content area */

  export default {
    name: 'BuilderTextBlockDivider',

    props: {
      /** Whether the divider should be visible or not */
      visible: {
        type: Boolean,
        required: false,
      },
    },
  }
</script>

<template>
  <hr class="divider" :class="{ 'border-gray-300': visible, 'border-transparent': !visible }" />
</template>
