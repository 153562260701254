import { render, staticRenderFns } from "./ProductCategoryHeader.vue?vue&type=template&id=7069f08e&scoped=true&"
import script from "./ProductCategoryHeader.vue?vue&type=script&lang=js&"
export * from "./ProductCategoryHeader.vue?vue&type=script&lang=js&"
import style0 from "./ProductCategoryHeader.vue?vue&type=style&index=0&id=7069f08e&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7069f08e",
  null
  
)

export default component.exports