<script>
  import IconChevronRight from '@/images/icons/chevron-right.svg'

  export default {
    name: 'BasePillLink',

    components: {
      IconChevronRight,
    },

    props: {
      /** The link URL */
      url: {
        type: String,
        required: true,
      },
      /** The link target */
      target: {
        type: String,
        required: false,
        default: '',
      },
      /** The button text */
      text: {
        type: String,
        required: true,
      },
      /** The text color */
      txColor: {
        type: String,
        required: false,
        default: 'white',
        validator: (propValue) => {
          const validTypes = ['white', 'black']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** The background color */
      bgColor: {
        type: String,
        required: false,
        default: '#000000',
      },
      /** The border color */
      brColor: {
        type: String,
        required: false,
        default: '#000000',
      },
    },

    computed: {
      buttonColor: function () {
        return `background-color: ${this.bgColor}; border-color: ${this.brColor};`
      },
    },
  }
</script>

<template>
  <a
    :href="url"
    :target="target"
    :style="buttonColor"
    class="inline-flex items-center px-4 py-2 rounded-full border | focus:outline-none"
    :class="`text-${txColor}`"
  >
    <span class="inline-block leading-tight border-b border-transparent">{{ text }}</span>
    <IconChevronRight class="inline-block ml-2 fill-current" />
  </a>
</template>

<style scoped lang="postcss">
  a.text-white:focus,
  a.text-white:hover {
    span {
      @apply border-white;
    }
  }

  a.text-black:focus,
  a.text-black:hover {
    span {
      @apply border-black;
    }
  }

  >>> svg {
    width: 6px;
    height: 9px;
  }
</style>
