<script>
  import CategoryBuilderDefaultContent from '@/components/builder/categorybuilder/CategoryBuilderDefaultContent'
  import CategoryBuilderBrandsRelation from '@/components/builder/categorybuilder/CategoryBuilderBrandsRelation'
  import CategoryBuilderAccordeonBlocks from '@/components/builder/categorybuilder/CategoryBuilderAccordeonBlocks'

  export default {
    name: 'CategoryBuilderBaseBlock',

    components: {
      CategoryBuilderDefaultContent,
      CategoryBuilderBrandsRelation,
      CategoryBuilderAccordeonBlocks,
    },

    props: {
      /** The type of child block to render */
      type: {
        type: String,
        required: true,
      },
      /** The content of the block (to feed to it's props) */
      content: {
        type: Object,
        required: true,
      },
    },

    computed: {
      /** Uses the returned blocks "type" handle property and appends it with 'Builder'  */
      resolveComponent: function () {
        return 'CategoryBuilder' + this.type
      },
      /** Checks to see if the component is imported and available in this component */
      isAvailable: function () {
        const componentName = this.resolveComponent
        return typeof this.$options.components[componentName] !== 'undefined'
      },
    },
  }
</script>

<template>
  <div class="relative">
    <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
      <div class="categoryBuilderWrapper">
        <component :is="resolveComponent" v-if="isAvailable" v-bind="content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .categoryBuilderWrapper {
    width: 980px;
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
</style>
