<script>
  /**
   * Use this instead of a `<a>` because it lets us apply special properties and methods as needed.
   * You can still pass normal link attributes like `target` or
   * [`download`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a) to it.
   **/
  export default {
    inheritAttrs: false,

    props: {
      /** A normal href. */
      href: {
        type: String,
        default: '',
        required: true,
      },
    },
  }
</script>

<template>
  <a v-if="href" :href="href" v-bind="$attrs">
    <!-- @slot Anything you want! -->
    <slot />
  </a>
</template>

<style scoped lang="postcss">
  svg {
    height: auto;
  }
</style>
