<script>
  import ProductTile from '@/components/products/ProductTile'
  export default {
    name: 'CategoryBuilderBrandsRelation',
    components: {
      ProductTile,
    },
    props: {
      brands: {
        type: Array,
        default: () => [],
      },
    },
    methods:{
      removeHTMLTabs(item) {
        return item.replace(/(<([^>]+)>)/gi, '').substring(0, 150)
      }
    }
  }
</script>

<template>
  <div v-if="brands.length > 0" class="brandsWrapper py-5">
    <aside class="related-products">
      <div v-if="brands.length > 0" class="scroller-wrapper">
        <div 
          class="related-products-inner"
        >
          <ProductTile
            v-for="brand in brands"
            :key="brand.id"
            :types="'Brands'"
            :seoDescription="removeHTMLTabs(brand.description)"
            v-bind="brand"
            class="related-products__item"
          />
        </div>
      </div>
    </aside>
  </div>
</template>

<style scoped lang="postcss">
  .scroller-wrapper {
    padding-bottom: 30px;
    overflow-x: auto;
  }

  .scroller-wrapper::-webkit-scrollbar {
    height: 2.5px;
  }

  .scroller-wrapper::-webkit-scrollbar-track {
    background: #dfdfdf;
  }

  .scroller-wrapper::-webkit-scrollbar-thumb {
    background: #000;
  }

  .related-products {
    overflow: hidden;
  }

  .related-products-inner {
    display: flex;
    flex-wrap: wrap;
    width: 1700px;
    padding-bottom: 3px;
    margin: 0 -15px;
    overflow-y: hidden;
  }

  .related-products__item {
    width: 305px;
    margin: 0 15px;
  }
  @media (max-width: 992px) {
    .related-products__item {
      width: 200px;
      margin: 0 10px;
    }

    .related-products-inner {
      width: 1120px;
      margin: 0 -10px;
    }
  }
</style>
