<script>
  // eslint-disable-next-line import/no-relative-parent-imports
  import { eventBus } from '@/main.js'
  import api from '@/api'
  import LegacyMobileNav from '@/components/legacy/LegacyMobileNav'
  import LegacySearch from '@/components/legacy/LegacySearch'

  export default {
    name: 'LegacyHeader',

    components: {
      LegacyMobileNav,
      LegacySearch,
    },

    props: {
      siteUrl: {
        type: String,
        required: false,
        default: 'javascript:void(0)',
      },
      productNav: {
        type: Array,
        required: false,
        default: () => [],
      },
      pageNav: {
        type: Array,
        required: false,
        default: () => [],
      },
      iconNav: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    data() {
      return {
        navOpen: false,
        searchOpen: false,
        cartCount: 0,
      }
    },

    created() {
      eventBus.$on('cart-updated', () => {
        this.updateCounts()
      })
    },

    mounted() {
      this.updateCounts()
    },

    methods: {
      toggleNav() {
        this.navOpen = !this.navOpen
      },

      toggleSearch() {
        // Hide/show the notification element if it exists
        const noticeElem = document.getElementById('notice')
        if (noticeElem) {
          if (!this.searchOpen) {
            noticeElem.classList.add('is-hidden')
          } else {
            noticeElem.classList.remove('is-hidden')
          }
        }
        // Toggle the search
        this.searchOpen = !this.searchOpen
      },

      updateCart() {
        api.post('commerce/cart/get-cart').then((response) => {
          this.cartCount = response.data.cart.totalQty
        })
      },

      updateCounts() {
        this.updateCart()
      },
    },
  }
</script>

<template>
  <div>
    <!-- http://web-accessibility.carnegiemuseums.org/code/skip-link/ -->
    <a
      id="skip-to-search"
      class="sr-only focus:not-sr-only"
      href="javascript:void(0)"
      @click.prevent="toggleSearch"
    >
      Skip to search
    </a>
    <a id="skip-nav" class="sr-only focus:not-sr-only" href="#main-content">
      Skip to main content
    </a>

    <header class="site-header">
      <div class="wrapper">
        <nav class="site-nav">
          <a class="logo" :href="siteUrl">
            <slot name="logo"></slot>
          </a>

          <ul class="desktop-nav">
            <li v-for="parent in productNav" :key="parent.id">
              <a
                :href="parent.url"
                :class="{ 'is-current': parent.current, sale: parent.highlighted }"
                >{{ parent.label }}</a
              >
              <ul v-if="parent.children.length">
                <li v-for="child in parent.children" :key="child.id">
                  <a
                    :href="child.url"
                    :class="{ 'is-current': child.current, sale: child.highlighted }"
                    >{{ child.label }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <nav class="site-nav">
          <ul class="desktop-nav">
            <li v-for="parent in pageNav" :key="parent.id">
              <a
                :href="parent.url"
                :class="{ 'is-current': parent.current, sale: parent.highlighted }"
                >{{ parent.label }}</a
              >
              <ul v-if="parent.children.length">
                <li v-for="child in parent.children" :key="child.id">
                  <a
                    :href="child.url"
                    :class="{ 'is-current': child.current, sale: child.highlighted }"
                    >{{ child.label }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>

          <ul class="icon-nav">
            <li class="icon-nav__search">
              <a href="javascript:void(0)" aria-label="search" @click.prevent="toggleSearch">
                <slot name="icon-search"></slot>
              </a>
            </li>
            <li class="icon-nav__cart">
              <a :href="iconNav.cart.url" aria-label="cart">
                <slot name="icon-cart"></slot>
                <span class="counter">({{ cartCount }})</span>
              </a>
            </li>
            <li class="icon-nav__menu" aria-label="menu">
              <a href="javascript:void(0)" @click.prevent="toggleNav">
                <slot name="icon-nav"></slot>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>

    <LegacyMobileNav
      :product-nav="productNav"
      :page-nav="pageNav"
      :nav-open="navOpen"
      @close-nav="toggleNav"
    >
      <template v-slot:icon-nav><slot name="icon-nav"></slot></template>
    </LegacyMobileNav>

    <LegacySearch :search-open="searchOpen" @close-search="toggleSearch">
      <template v-slot:icon-search><slot name="icon-search"></slot></template>
    </LegacySearch>
  </div>
</template>

<style lang="scss" scoped>
  .site-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    border-top: 4px solid #0a0a0a;
    border-bottom: 1px solid #dfdfdf;

    a {
      color: #919297;
      transition: color 0.2s;

      &:hover,
      &.is-current {
        color: #0a0a0a;
      }

      &.sale {
        color: rgb(215, 0, 0) !important;
      }
    }
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    padding: 0 23px;
  }

  .desktop-nav {
    display: none;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 23px;
    display: inline-block;
    margin-top: -16px;

    svg {
      width: 70px;
      height: auto;
    }
  }

  .icon-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2px;

    > * {
      margin: 0 16px 0 0;

      &:last-child {
        margin: 0;
      }
    }

    a {
      position: relative;
      display: inline-block;

      .counter {
        position: absolute;
        top: -6px;
        right: -10px;
        display: inline-block;
        font-size: 10px;
        text-align: center;
      }
    }

    svg {
      width: 22px;
      height: 35px;
    }

    .icon-nav__search svg {
      width: 19px;
      height: 25px;
    }
  }

  @screen sm {
    .wrapper {
      position: relative;
      justify-content: flex-end;
      height: 95px;
      padding: 0 40px;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -20px 0 0 -35px;
    }
  }

  @screen md {
    .logo {
      margin-left: -45px;

      svg {
        width: 90px;
      }
    }
  }

  @screen xl {
    .wrapper {
      justify-content: space-between;
    }

    .site-nav {
      display: flex;
      align-items: center;
    }

    .desktop-nav {
      position: relative;
      display: flex;

      a {
        display: inline-block;
        font-size: 12px; // 13
        text-transform: uppercase;
        letter-spacing: 1.3px;
      }

      li {
        flex-shrink: 0;
        margin: 0 16px 0 0;

        &:last-child {
          margin: 0;
        }

        ul {
          position: absolute;
          top: 20px;
          left: 0;
          z-index: -1;
          display: flex;
          min-width: 575px;
          opacity: 0;
          transition: opacity 0.2s;

          a {
            font-style: italic;
          }
        }

        &:hover {
          > a {
            color: #0a0a0a;
          }

          ul {
            z-index: 100;
            opacity: 1;
          }
        }
      }
    }

    .icon-nav {
      margin-left: 16px;
    }

    .logo {
      margin-left: -55px;

      svg {
        width: 110px;
      }
    }

    .icon-nav__cart {
      margin: 0;
    }

    .icon-nav__menu {
      display: none;
    }
  }

  @media screen and (min-width: 1330px) {
    .desktop-nav a {
      font-size: 13px;
    }
  }
</style>
