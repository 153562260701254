<script>
  import bus from '@/components/legacy/bus.js'

  export default {
    name: 'LegacyLoadMoreButton',

    props: {
      type: {
        type: String,
        required: false,
        default: () => 'load-more-product',
      },
    },

    data() {
      return {
        loading: false,
      }
    },

    mounted() {
      bus.$on('loaded-more', () => {
        if (this.type === 'load-more-product') {
          this.loading = false
        }
        if (this.type === 'load-more-other') {
          this.loading = false
        }
      })
    },

    methods: {
      loadMore() {
        this.loading = true
        bus.$emit(this.type)
      },
    },
  }
</script>

<template>
  <div :class="{ 'is-loading': loading }">
    <button
      class="btn btn--w-icon btn--black"
      data-next-page="https://haute-living.com/search/p2?searchTerm=chair"
      @click="loadMore"
    >
      Load More
      <span class="icon icon--load-more">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="13px"
          height="31.5px"
          viewBox="0 0 13 31.5"
          style="enable-background: new 0 0 13 31.5"
          xml:space="preserve"
        >
          <g>
            <polygon style="fill: #fff" points="6.5,31.5 0,25 13,25  "></polygon>
            <rect
              x="-6.5"
              y="12"
              transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 19.5732 6.5389)"
              style="fill: #fff"
              width="26.1"
              height="2.2"
            ></rect>
          </g>
        </svg>
      </span>
      <span class="icon icon--loading">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="20px"
          height="20px"
          viewBox="0 0 19.5 19.5"
          style="enable-background: new 0 0 19.5 19.5"
          xml:space="preserve"
        >
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="9.75"
              x2="19.5"
              y2="9.75"
            >
              <stop offset="0" style="stop-color: #fff"></stop>
              <stop offset="1" style="stop-color: #fff"></stop>
            </linearGradient>
            <path
              style="fill: url(#SVGID_1_)"
              d="M9.8,19.5C4.4,19.5,0,15.1,0,9.8S4.4,0,9.8,0s9.8,4.4,9.8,9.8H18c0-4.5-3.7-8.2-8.2-8.2S1.5,5.2,1.5,9.8 S5.2,18,9.8,18V19.5z"
            ></path>
          </g>
        </svg>
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
  .btn {
    position: relative;
    padding: 24px 28px;
    font-size: 13px;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    border: none;
    border-radius: 0;
    outline: none;
  }

  .btn--w-icon {
    width: 100%;
    padding: 28px 112px 28px 28px;

    @media (min-width: 30em) {
      max-width: 280px;
    }

    > .icon {
      position: absolute;
      top: 50%;
      right: 24px;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: right;
      transform: translateY(-50%);

      svg {
        top: 50%;
        right: 0;
        display: inline-block;
        width: auto;
        max-height: 24px;
        vertical-align: middle;
      }
    }
  }

  .btn--black {
    color: #fff;
    background-color: #0a0a0a;
  }

  .icon {
    display: inline-block;
  }

  .icon--loading:not(.icon--hover) {
    opacity: 0;
    transition: opacity 0.1s linear;

    svg {
      transform-origin: 50% 50%;
    }
  }

  .icon--load-more {
    transition: opacity 0.1s linear;
  }

  @keyframes loadingIconRotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .is-loading {
    > .btn > .icon--load-more {
      opacity: 0;
    }

    > .btn > .icon--loading {
      opacity: 1;

      svg {
        animation: loadingIconRotate 1s linear infinite;
      }
    }
  }
</style>
