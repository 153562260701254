<script>
  /** Control button to remove line items in the cart */

  export default {
    name: 'CartRemoveButton',

    props: {
      /** The line item ID */
      id: {
        type: [Number, String],
        required: true,
      },
    },

    methods: {
      /** Emit an event with the line item ID */
      removeItem() {
        this.$emit('removeItem', this.id)
      },
    },
  }
</script>

<template>
  <button
    type="button"
    class="inline-block text-center text-gray-600 transition-colors duration-300 | hover:text-black | focus:outline-none focus:text-black"
    aria-label="Remove Item from Cart"
    @click="removeItem"
  >
    <svg viewBox="0 0 14 15" class="inline-block fill-current" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.457 13.4922L11.9219 3.94238H12.79C13.0225 3.94238 13.207 3.75781 13.207 3.52539C13.207 3.2998 13.0225 3.1084 12.79 3.1084H9.77539V2.11035C9.77539 1.22852 9.16699 0.640625 8.20996 0.640625H5.77637C4.81934 0.640625 4.21094 1.22852 4.21094 2.11035V3.1084H1.20312C0.984375 3.1084 0.792969 3.2998 0.792969 3.52539C0.792969 3.75781 0.984375 3.94238 1.20312 3.94238H2.07812L2.54297 13.499C2.58398 14.3604 3.18555 14.9346 4.06055 14.9346H9.94629C10.8076 14.9346 11.416 14.3535 11.457 13.4922ZM5.09961 2.16504C5.09961 1.76172 5.39355 1.46777 5.83789 1.46777H8.15527C8.59277 1.46777 8.89355 1.76172 8.89355 2.16504V3.1084H5.09961V2.16504ZM4.13574 14.1006C3.73926 14.1006 3.43164 13.7998 3.41113 13.3965L2.94629 3.94238H11.0332L10.5889 13.3965C10.5684 13.7998 10.2676 14.1006 9.86426 14.1006H4.13574Z"
      />
    </svg>
  </button>
</template>

<style scoped lang="postcss">
  button {
    width: 32px;
    height: 32px;
  }

  svg {
    width: 14px;
    height: 15px;
  }
</style>
