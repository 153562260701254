export default {
  namespaced: true,

  state: {
    siteUrl: null,
    primaryNavigation: null,
    pages: null,
    currentUser: null,
    tradeAccount: false,
    social: null,
    orderNumber: null,
  },

  getters: {
    loggedIn(state) {
      return state.currentUser !== null
    },
    currentUser(state) {
      return state.currentUser
    },
    isTradeAccount(state) {
      return state.tradeAccount
    },
  },

  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
  },
}
