<script>
  import VLazyImage from 'v-lazy-image'
  // eslint-disable-next-line import/no-relative-parent-imports
  import { createNamespacedHelpers } from 'vuex'
  import { formatCurrency } from '@/utilities/strings'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** A reusable product tile preview. Makes use of VLazyImage to load preview images when they are in the viewport and
   * provides a loading animated SVG in base64 before the image loads to prevent layout jank.
   * See: https://github.com/alexjoverm/v-lazy-image */

  export default {
    name: 'FeaturedTile',

    components: {
      VLazyImage,
    },

    props: {
      /** The product's URL */
      url: {
        type: String,
        required: false,
      },
      linkToPage: {
        type: String,
        required: false,
      },
      newTab:{
        type:Boolean,
        default:false
      },
      /** The product's main title */
      title: {
        type: String,
        required: true,
      },
      /** The product's primary image as an object including params for alternate text, low rez and high rez image URLs */
      image: {
        type: Object,
        required: false,
        default: () => ({
          alt: '',
          urls: [],
        }),
      },
    },

    methods: {
      
    },
  }
</script>

<template>
  <article class="relative">
      <a v-if="linkToPage !== null" :href="linkToPage" class="absolute w-full h-full z-10 block" :target="`${ newTab ? '_blank' : '' }`"></a>
      <figure
        v-if="image"
        class="relative block border border-gray-300 mb-3 image offsetImageFigure"
      >
        <VLazyImage
          :src="image.urls[0]"
          :alt="image.alt"
        />
      </figure>
      <div class="product--listing">
        <h4
            class="text-black text-sm font-unica77 font-medium tracking-wider leading-none product--title"
            >{{ title }}
        </h4>
      </div>
    </article>
</template>

<style scoped lang="postcss">

  .product--listing {
    h3 {
      font-size: 16px;
      line-height: 110%;
      @media(max-width:768px) {
        font-size: 14px;
      }
    }
    h4, h5 {
      font-size: 16px;
      line-height: 110%;
      @media(max-width:768px) {
        font-size:14px;
      }
    }
  }
  .product--tile {
    h3 {
      @media(max-width:768px) {
        font-size: 10px;
      }
    }
  }
  .product--title {
    font-size: 16px;
    line-height: 110%;
    @media(max-width:768px) {
      font-size: 14px;
    }
  }
  .productLink {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
  }

  .offsetImageFigure {
    height: 450px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 992px) {
      height: 240px;
    }
  }

  .productTileLink {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
  }

  .image img {
    @apply min-w-full;
  }

  .image::after {
    content: '';
    @apply absolute inset-0 block bg-gray-900 opacity-0 transition-opacity duration-300;
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 3px;
  }

  .anchor {
    .hover-image {
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s linear;
    }
    @media screen and (min-width: 800px) {
      &:hover {
        .hover-image {
          visibility: visible;
          opacity: 1;
        }

        .indicator {
          @apply opacity-100;
        }
      }
    }
  }
</style>
