<script>
  export default {
    name: 'ProductNotAvailable',
    props:{
      title:{
        type:String,
        default:''
      },
    },
  }
</script>

<template>
    <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 pt-16 pb-12 lg:pt-24 lg:pb-20">
        <h2 class="block">{{ title }} is currently not available</h2>    
    </div> 
</template>