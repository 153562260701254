<script>
  import { eventBus } from '@/main.js'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'NewBuilderTwoColumnsText',
    props: {
      /** The heading for the block, if defined */
      leftText: {
        type: String,
        default: '',
      },
      rightText: {
        type: String,
        default: '',
      },
      removePadding: {
        type: Boolean,
        default: false,
      },
      link: {
        type: Object,
        default: () => ({}),
      },
      linkText: {
        type: String,
        default: '',
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      mailOrLink: {
        type: Boolean,
        default: false,
      },
      mailtoLink: {
        type: String,
        default: '',
      },
      currentPageUrl: {
        type: String,
        default: '',
      },
      scrollToForm: {
        type: Boolean,
        default: false,
      },
      image:{
        type:Object,
        default:() => {}
      },
      withBackground:{
        type:Boolean,
        default:false
      },
      backgroundYellowOnMobileOnly:{
        type:Boolean,
        default:false
      },
      textLayout:{
        type:Boolean,
        default:false
      }
    },
    computed: {
      resizedImage() {
        return this.bannerImage.urls[0]
      },
      backgroundType() {
        if(this.backgroundYellowOnMobileOnly) {
          return 'my-10 py-16 bg-yellows md:bg-transparent'
        }
        else if(this.withBackground) {
          return 'bg-yellows my-10 py-16'
        }
        else {
          return 'py-12'
        }
      }
    },
    mounted() {
      console.log(this.withBackground)
      eventBus.$on('sendFormId', (data) => {
        this.formId = data
      })
      const hash = window.location.hash
      if (hash === '#scrollToForm') {
        this.showFormOnScroll()
      }
    },
    methods: {
      showFormOnScroll() {
        const element = document.getElementById('tradeAccountForm')
        const noticeElement = document.getElementById('notice')
        const notHeight = noticeElement ? noticeElement.offsetHeight : 0
        const headerHeight = document.getElementById('ref-header').offsetHeight
        this.showForm = true
        if (element) {
          eventBus.$emit('showFormOnScroll', this.showForm)
          setTimeout(() => {
            const sum = notHeight + headerHeight - 20
            if (element) {
              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - sum
              window.scrollTo({ top: elementPosition, behavior: 'smooth' })
            }
          }, 200)
        } else {
          window.location.href = '/trade#scrollToForm'
        }
      },
    },
  }
</script>

<template>
  <div
    class="relative"
    :class="`${ backgroundType } ${removePadding ? 'noPadding mt-0 -mb-8 md:-mb-16' : ''}`"
  >
    <div v-if="image.urls.length > 0" class="w-full md:w-2/4 hidden lg:block sectionImage">
      <img :src="image.urls[0]" :alt="image.alt">
    </div>
    <div class="twoColumnsSectionWrapper mx-auto px-4 md:px-6" :class="`${image.urls.length > 0 ? '' : 'lg:max-w-screen-2xl w-full'} ${removePadding ? 'noPadding' : ''}`">
      <div class="w-full flex flex-row relative z-10" :class="{ 'justify-start lg:justify-end': !textAlign }">
        <div
          class="w-full lg:flex lg:flex-row"
          :class="`${ !textAlign ? 'lg:pl-0' : ''  } ${ image.urls.length > 0 ? 'md:w-2/4' : '' } ${ !textLayout && image.urls.length === 0 ? 'lg:w-8/12 lg:space-x-16 lg:pl-4' : '' }`"
        >
          <div
            v-if="!textLayout && image.urls.length === 0"
            class="w-full lg:flex lg:flex-row"
          >
            <div
              class="w-full mb-6 lg:mb-0 leftTextWrapper"
              :class="`${ image.urls.length > 0 ? 'md:pl-8' : 'lg:w-5/12' }`"
            >
              <div v-if="leftText" v-html="leftText" class="mb-4 pr-0 md:pr-12"></div>
            </div>
            <div class="w-full lg:w-7/12">
              <div v-if="rightText" v-html="rightText" class="mb-4 rightText"></div>
              <div v-if="linkText">
                <a
                  v-if="!mailOrLink"
                  class="font-semibold text-sm w-full md:w-auto px-0 md:px-24 block text-center button-height md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                  href="#"
                  @click.prevent="showFormOnScroll"
                  >{{ linkText }}</a
                >
                <a
                  v-else
                  class="font-semibold text-sm block text-center px-0 md:px-24 button-height md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                  :href="`mailto:${mailtoLink}`"
                  >{{ linkText }}</a
                >
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="w-full pl-0 lg:mb-0 leftTextWrapper"
              :class="`${ image.urls.length > 0 ? 'lg:w-3/5 xl:w-2/4 mx-auto' : 'md:w-2/5' }`"
            >
              <div v-if="leftText" v-html="leftText" class="mb-4"></div>
              <div v-if="rightText" v-html="rightText" class="mb-4 rightText"></div>
              <div v-if="linkText">
                <a
                  v-if="!mailOrLink"
                  class="font-semibold text-sm w-full md:w-auto px-0 md:px-24 block text-center button-height md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                  href="#"
                  @click.prevent="showFormOnScroll"
                  >{{ linkText }}</a
                >
                <a
                  v-else
                  class="font-semibold text-sm block text-center px-0 md:px-24 button-height md:inline-block | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
                  :href="`mailto:${mailtoLink}`"
                  >{{ linkText }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .sectionImage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .twoColumnsSectionWrapper {
    &.noPadding {
      padding-top: 0;
      padding-bottom: 0;
    }
    @media (max-width: 1024px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  >>> .leftTextWrapper {
    h2,
    h3,
    h4 {
      display: block;
      margin: 0 0 38px 0;
      font-family: 'Cormorant', sans-serif;
      font-family: 'Cormorant', sans-serif;
      font-size: 36px;
      font-weight: 700;
      line-height: 43px;
      letter-spacing: 0.01em;
    }

    .rightText {
      p {
        text-transform: initial;
        font-size: 16px;
        font-weight: 400;
      }
    }

    h3 {
      margin-bottom: 0;
    }

    p {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    @media (max-width: 1024px) {
      h2,
      h3,
      h4 {
        margin-bottom: 30px;
        font-size: 30px;
        line-height: 110%;
      }
    }
  }
</style>
