import api from '@/api'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    order: {},
  },

  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setOrder(state, value) {
      state.order = value
    },
  },

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getOrder(state) {
      return state.order
    },
  },

  actions: {
    /** Loads order from craft using order number */
    loadOrder(context, number) {
      context.commit('setIsLoading', true)
      return api
        .post('hauteliving/site/get-order', {
          number: number,
        })
        .then((response) => {
          if (response.status === 200) {
            context.commit('setOrder', JSON.parse(response.data))
            context.commit('setIsLoading', false)
          } else {
            context.commit('setIsLoading', false)
            throw response.data?.error ?? `Could not find any cart with order number ${number}`
          }
        })
    },
  },
}
