<script>
  /* collection logic */

  import { createNamespacedHelpers } from 'vuex'
  import CollectionHeader from '@/components/collections/CollectionHeader'
  import ProductTileGrid from '@/components/products/ProductTileGrid'

  const { mapActions, mapState, mapGetters } = createNamespacedHelpers('products')

  /** The PLP main app component. Uses Vuex action to load the products on page load based on the Craft category
   * slug. If no slug is present or the slug is invalid it will get all products.
   * See (Craft module controller): /modules/hauteliving/controllers/SiteController.php actionGetProducts()
   * See (Craft module service): /modules/hauteliving/services/SiteService.php getProductList() */

  export default {
    name: 'ProductListingPage',

    components: {
      CollectionHeader,
      ProductTileGrid,
    },

    props: {
      /** The title to display above the listing */
      title: {
        type: String,
        default: '',
        required: false,
      },
      brandInfo: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {}
    },

    computed: {
      ...mapState(['filterValues', 'sortOptions', 'sort', 'appendFilters', 'firstSelectedBrand']),
      /** Vuex getter methods */
      ...mapGetters(['getPaginationData', 'getIsLoading', 'getSelectedOption']),
      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
      categoryString() {
        let catString = this.title
        if (this.parentTitle) {
          catString = this.parentTitle + '/' + this.title
        }
        return catString
      },
    },
    async created() {
      await this.$store.dispatch('products/setCollectionPage', true)
      await this.$store.dispatch('products/initProducts', {
        collection: this.title,
      })
    },

    mounted() {
      this.applyBrowsingSnapshot()
      // Send the appropriate GTM event
      if (this.$gtm.enabled()) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'View_Category',
          email: this.$store.getters['global/currentUser']
            ? this.$store.getters['global/currentUser'].email
            : '',
          product_category: this.categoryString,
        })
      }
    },

    methods: {
      ...mapActions([
        'setFilterValue',
        'applyBrowsingSnapshot',
        'setSort',
        'clearFilters',
        'clearAllFilters',
        'remoteSelectedFilter',
      ]),
      ...mapGetters(['getUsedFilters']),
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="plp">
      <CollectionHeader v-if="title !== ''" :title="title" :brand="brandInfo" />
      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div class="static flex flex-col md:flex-row md:relative flex-wrap overflow-hidden mb-16 md:mb-8">
          <div class="w-full pb-4">
            <ProductTileGrid
              class="md:pt-1"
              :destination="'ProductsListingPage'"
              :moreWidth="true"
            />
          </div>
        </div>
      </div>
    </article>
  </main>
</template>

<style scoped lang="scss">
  @media (max-width: 1024px) {
    .stickySidebar {
      margin-left: 0 !important;
    }
  }
</style>
