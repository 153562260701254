<script>
  export default {
    name: 'ProductQuickship',

    props: {
      id: {
        type: Number,
        required: true,
      },
      title: {
        type: String,
        required: true,
      },
      image: {
        type: Object,
        required: false,
        default: null,
      },
      config: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<template>
  <a class="info-option" href="#">
    <figure v-if="image" class="info-option__media">
      <img :src="image.url" :alt="image.alt" />
    </figure>
    <div class="info-option__content">
      <h3 class="info-option__heading">{{ title }}</h3>
      <dl class="info-option__data">
        <div>
          <dt>Orientation</dt>
          <dd>{{ config.orientation.label }}</dd>
          <dt>Dimensions</dt>
          <dd>{{ config.dimensions.label }}</dd>
        </div>
        <div>
          <dt>Arm and seat</dt>
          <dd>{{ config.materials.armAndSeat.label }}</dd>
          <dt>Leg</dt>
          <dd>{{ config.materials.legs.label }}</dd>
        </div>
      </dl>
    </div>
  </a>
</template>
