<script>
  /** Base Featurette Panel component. */

  export default {
    name: 'BaseFeaturettePanel',

    props: {
      /** Title of the panel */
      title: {
        type: String,
        required: true,
      },
      image: {
        type: [Object, Array],
        required: false,
        default: () => {},
      },
      summary: {
        type: String,
        required: true,
      },
      link: {
        type: String,
        required: false,
        default: '',
      },
      aspectRatio: {
        type: String,
        required: true,
        default: '',
      },
      color: {
        type: String,
        default: 'text-black',
      },
    },
    data() {
      return {
        isMobile: true,
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 768
      },
    },
  }
</script>

<template>
  <Article v-if="!link" class="mb-4">
    <header v-if="image !== null" class="mb-5" :class="`ar-${aspectRatio}`">
      <BaseImage
        :source="isMobile ? image.urls[0] : image.urls[1]"
        :alt="image.alt"
        img-class="header-image"
        position="full"
      />
    </header>
    <h3 class="text-black leading-snug text-lg font-bold mb-2 text-left">
      {{ title }}
    </h3>
    <div class="wysiwyg text-left" :class="color" v-html="summary"></div>
  </Article>
  <a v-else :href="link" class="panel-link mb-4">
    <Article>
      <header v-if="image !== null" class="relative mb-5">
        <BaseImage
          :source="isMobile ? image.urls[0] : image.urls[1]"
          :alt="image.alt"
          img-class="header-image"
          position="full"
        />
        <div
          class="panel-overlay absolute h-full w-full top-0 left-0 transition ease-linear delay-150"
          >&nbsp;</div
        >
      </header>
      <h3 class="text-black leading-snug text-lg font-bold mb-2 text-left">
        {{ title }}
      </h3>
      <div class="wysiwyg text-left" :class="color" v-html="summary"></div>
    </Article>
  </a>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */

  .panel-link:hover {
    >>> .panel-overlay {
      background-color: rgba(34, 34, 34, 0.4);
    }
  }

  /* >>> .ar-4-3 {
    padding-bottom: 75%;
  }

  >>> .ar-16-9 {
    padding-bottom: 56.25%;
  }

  >>> .ar-16-5 {
    padding-bottom: 31.25%;
  } */

  .panel-image-wrapper {
    @apply relative;

    >>> img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  /* purgecss end ignore */
</style>
