<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters } = createNamespacedHelpers('builder')

  /** Table of contents content builder component */

  export default {
    name: 'BuilderTableOfContents',

    props: {
      /** A list of sections that are available on the page */
      sections: {
        type: Array,
        required: true,
      },
      /** Boolean to make the table of contents bar stick at the top */
      sticky: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapGetters(['getCurrentSection']),
    },

    mounted() {
      // We need to add this class to the wrapping div element so it can be sticky
      if (this.sticky) {
        const parentDiv = this.$refs.toc.parentElement
        parentDiv.classList.add('toc')
      }
    },
  }
</script>

<template>
  <nav ref="toc" class="w-full py-4 border-b bg-white" :class="{ 'top-0 sticky': sticky }">
    <div class="w-full px-4 overflow-x-auto | md:px-6 || scroller">
      <div class="flex justify-start items-center space-x-4 | md:space-x-5 | lg:space-x-6">
        <a
          v-for="section in sections"
          :key="section.anchor"
          :href="'#' + section.anchor"
          class="relative block pr-3 text-gray-600 leading-4 | md:text-base | focus:outline-none | hover:text-gray-700"
          :class="{ 'is-active': getCurrentSection.anchor === section.anchor }"
        >
          {{ section.label }}
          <span
            class="absolute right-0 inline-block bg-black opacity-0 transition-opacity duration-300 || indicator"
          ></span>
        </a>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="postcss">
  .scroller::-webkit-scrollbar {
    display: none;
  }

  .scroller {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .indicator {
    top: 50%;
    width: 6px;
    height: 6px;
    margin-top: -3px;
    border-radius: 3px;
  }

  a {
    flex-grow: 1;
    flex-shrink: 0;

    &.is-active {
      @apply text-gray-700;

      .indicator {
        @apply opacity-100;
      }
    }
  }

  @screen sm {
    a {
      flex-grow: 0;
    }
  }
</style>
