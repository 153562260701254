<script>
  /** Simple error message list item. Include in a ul. */

  export default {
    name: 'BaseError',

    props: {
      /** The HTML tag to use */
      tag: {
        type: String,
        default: 'div',
      },
      /** Error message */
      error: {
        type: [Object, String],
        required: true,
      },
    },
    computed: {
      isObject() {
        return typeof this.error === 'object';
      }
    }
  }
</script>

<template>
  <component :is="tag" v-if="isObject" class="inline-block mr-1 text-xs text-red-500 mt-2" role="alert">
    <span v-for="(err, index) of Object.keys(error)" :key="`error_${index}`" class="block">
      {{ error[err][0] }}
    </span>
  </component>
  <component :is="tag" v-else class="inline-block mr-1 text-xs text-red-500 mt-2" role="alert">
    {{ error }}
  </component>
</template>
