<script>
  import VLazyImage from 'v-lazy-image'

  /** The line item preview component used in line items of the cart) */

  export default {
    name: 'CartPreview',

    components: {
      VLazyImage,
    },

    props: {
      /** The product title */
      title: {
        type: String,
        required: true,
      },
      /** The product brand */
      brand: {
        type: String,
        required: false,
        default: null,
      },
      /** The URL of the product detail page */
      url: {
        type: String,
        required: true,
      },
      /** Key value pair array of options */
      options: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** An image object with multiple URLs */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** Whether or not the component should stay compact at larger screen sizes (eg: when used in side-bar) */
      compact: {
        type: Boolean,
        required: false,
      },
    },

    computed: {
      /** Creates the image srcset string based on the high and low rez images provided */
      imageSrcSet: function () {
        let srcString = ''
        if (this.image.urls[0] && this.image.urls[1]) {
          srcString = this.image.urls[0] + ' 1x, ' + this.image.urls[1] + ' 2x '
        }
        return srcString
      },
      /** Create the image sizes string */
      srcSizes: function () {
        return '50vw, (min-width: 768px) 33vw, (min-width: 1024px) 25vw, (min-width: 1560px) 20vw, (min-width: 1920px) 17vw'
      },
      /** Create the base64 SVG loading image */
      srcPlaceholder: function () {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMTUgMzE1IiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0Ij4KICA8cmVjdCBjbGlwLXBhdGg9InVybCgjY2xpcFBhdGgtMTMpIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzE1IiBoZWlnaHQ9IjMxNSIgc3R5bGU9ImZpbGw6IHVybCgmcXVvdDsjZ3JhZGllbnQtMTMmcXVvdDspOyI+PC9yZWN0PgogIDxkZWZzPgogICAgPGNsaXBQYXRoIGlkPSJjbGlwUGF0aC0xMyI+CiAgICAgIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIzMTUiIGhlaWdodD0iMzE1Ij48L3JlY3Q+CiAgICA8L2NsaXBQYXRoPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkaWVudC0xMyI+CiAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMjsgMSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2UwZTBlMCI+CiAgICAgICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0ib2Zmc2V0IiB2YWx1ZXM9Ii0xLjU7IDEuNSIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmMGYwZjAiPgogICAgICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9Im9mZnNldCIgdmFsdWVzPSItMTsgMiIgZHVyPSIycyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZT4KICAgICAgPC9zdG9wPgogICAgPC9saW5lYXJHcmFkaWVudD4KICA8L2RlZnM+Cjwvc3ZnPgo='
      },
    },
  }
</script>

<template>
  <article :class="{ full: !compact, compact: compact }">
    <a
      :href="url"
      class="block | focus:outline-none | sm:flex sm:items-start || hit-area"
      :class="{ 'lg:block xl:flex': compact }"
    >
      <figure
        v-if="image.urls.length"
        class="block mr-4 mb-2 rounded overflow-hidden | sm:mb-0 | lg:rounded-md"
        :class="{ 'xl:mr-6': !compact, 'lg:mb-2 xl:mb-0': compact }"
      >
        <VLazyImage
          :srcset="imageSrcSet"
          :src="image.urls[0]"
          :src-placeholder="srcPlaceholder"
          :sizes="srcSizes"
          :alt="image.alt"
        />
      </figure>
      <div>
        <h3
          class="border-transparent border-b text-xs transition-colors duration-300 || heading"
          :class="{ 'xl:text-base': !compact }"
        >
          {{ title }}
          <span v-if="brand">by {{ brand }}</span>
        </h3>
        <dl
          v-if="options.length"
          class="text-xs leading-snug"
          :class="{ 'xl:text-base xl:leading-normal': !compact }"
        >
          <div v-for="(option, index) in options" :key="index" class="text-gray-600">
            <dt class="inline mr-1">{{ option.label }}</dt>
            <dd class="inline">{{ option.value }}</dd>
          </div>
        </dl>
      </div>
    </a>
  </article>
</template>

<style scoped lang="postcss">
  figure {
    width: 60px;
  }

  dt::after {
    content: ':';
  }

  .hit-area:focus,
  .hit-area:hover {
    .heading {
      @apply border-black;
    }
  }

  @screen xl {
    article {
      &.full {
        figure {
          width: 112px;
        }
      }

      &.compact {
        figure {
          width: 87px;
        }
      }
    }
  }
</style>
