import { render, staticRenderFns } from "./BasePageHeader.vue?vue&type=template&id=1a56d253&scoped=true&"
import script from "./BasePageHeader.vue?vue&type=script&lang=js&"
export * from "./BasePageHeader.vue?vue&type=script&lang=js&"
import style0 from "./BasePageHeader.vue?vue&type=style&index=0&id=1a56d253&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a56d253",
  null
  
)

export default component.exports