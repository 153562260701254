<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'
  /** The PLP listing header for categories */
  export default {
    name: 'ProductCategoryHeader',

    props: {
      /** The title/name of the category */
      title: {
        type: String,
        required: true,
      },
      /** The breadcrumb of product category */
      breadCrumb: {
        type: Array,
        default: () => [],
        required: false,
      },
      /** PLP summary passed from parent template */
      summary: {
        type: String,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
    computed: {
      trimmedHtml() {
        return trimHtml(this.summary, { limit: 360 })
      },
      /** The trimmed HTML text with indicator toggle added inline */
      trimmedText() {
        const addedContent =
          '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> + </span>'
        return insertIntoLastElement(this.trimmedHtml.html, addedContent)
      },
      // Expanded Text
      fullText() {
        if (this.hasMore) {
          const addedContent =
            '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> - </span>'
          return insertIntoLastElement(this.summary, addedContent)
        } else {
          return this.summary
        }
      },
      hasMore() {
        return this.trimmedHtml.more
      },
      // Aria-label text
      toggleLabel() {
        return this.isOpen ? 'Collapse text' : 'Expand text'
      },
    },
    methods: {
      toggleCollapsable() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <header class="pt-12 z-15 lg:pb-4 mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
    <div class="flex flex-column xl:flex-row flex-wrap">
      <div class="w-full">
        <h1 class="block w-full uppercase mb-4 font-bold">
          <span class="uppercase leading-tight tracking-tight">
            {{ title }}
          </span>
        </h1>
        <div class="summary font-unica77 font-normal tracking-02 w-full lg:w-3/4" v-html="summary"></div>
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .heading {
    max-width: 480px;
  }

  h1 {
    span {
      font-size: 40px;
      line-height: 110%;
    }

    span.break-words {
      font-size: 24px;
      line-height: 28px;

      span {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
  @media (max-width: 768px) {
    h1 {
      span {
        font-size: 36px;
      }
    }
  }

  .summary {
    max-width: 1040px;
    font-size: 18px;
    line-height: 22px;

    >>> p {
      display: inline-block;
      margin-bottom: 20px;
    }
  }

  .summary-expanded {
    >>> p {
      display: inline-block;
      margin-bottom: 10px;
    }
  }

  .summary >>> * > * {
    @apply mb-6;

    &:last-child {
      @apply mb-0;
    }
  }

  @screen md {
    .summary-expanded {
      columns: 2;
      column-gap: 20px;

      p {
        break-inside: avoid;
        page-break-inside: avoid;
      }
    }
  }

  /* purgecss end ignore */
</style>
