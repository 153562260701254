<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters, mapActions } = createNamespacedHelpers('brands')

  /** Pagination button that appears below the brand tile grid to allow users to load more brands.
   * Displays the total amount, total showing and amount left on next click. */

  export default {
    name: 'BrandPagination',
    data() {
      return {
        observer: null,
      }
    },
    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginationData', 'isLastPage']),
      /** Injects HTML for the current number of brands */
      shownQuantity: function () {
        return '<span data-cy="currentNumberOfBrands">' + this.getPaginationData.current + '</span>'
      },
      /** Injects HTML for the total number of brands */
      totalQuantity: function () {
        return '<span data-cy="totalNumberOfBrands">' + this.getPaginationData.total + '</span>'
      },
    },
    mounted() {
      this.initPagination()
    },
    destroyed() {
      this.observer.disconnect()
    },
    methods: {
      /** Vuex action methods */
      ...mapActions(['nextPage']),
      initPagination() {
        // initialize intersection observer
        this.observer = new IntersectionObserver((entries) => {
          const firstEntry = entries[0]

          if (firstEntry.isIntersecting) {
            // Paginate to next page when the component is in the viewport
            this.nextPage()
          }
        })

        // Obeserve the root element of this component
        this.observer.observe(this.$refs.pagination)
      },
    },
  }
</script>

<template>
  <nav
    ref="pagination"
    aria-label="Product pagination"
    class="flex flex-col justify-center invisible items-center pt-8 pb-4 border-t border-gray-300 | md:flex-row md:justify-between || wrapper"
  >
    <span class="text-gray-500 || desktop" v-html="'Showing ' + shownQuantity + ' Brands'" />

    <span
      class="text-gray-500 || desktop"
      aria-label="total products"
      v-html="'Total ' + totalQuantity + ' Brands'"
    />

    <span
      class="block text-xs text-gray-500 || mobile"
      aria-current="true"
      aria-label="current products"
      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Brands'"
    />
  </nav>
</template>

<style scoped lang="postcss">
  .wrapper {
    min-height: 123px;
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
</style>
