<script>
  /** Reusable base checkbox input with label. Emits a 'change' event when checked/unchecked with its current state */
  import IconCheckbox from '@/images/icons/checked.svg'
  import IconAngleDown from '@/images/icons/chevron-down.svg'

  let uniqueId = 0

  export default {
    name: 'BaseCheckbox',

    components: {
      IconCheckbox,
      IconAngleDown,
    },

    model: {
      prop: 'checked',
      event: 'change',
    },

    props: {
      /** The checkbox’s label */
      label: {
        type: String,
        required: true,
      },
      /** The checkbox’s value */
      value: {
        type: [String, Number],
        default: null,
      },
      /** Should this checkbox be checked */
      checked: {
        type: Boolean,
      },
      defaultChecked: {
        type: Boolean,
      },
      /** This will be used to toggle children checkboxs */
      isParent: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: undefined,
      },
    },

    data() {
      uniqueId++

      return {
        isOpen: false,
        uniqueId: uniqueId,
        isChecked: this.defaultChecked,
      }
    },

    computed: {
      /** Generate a random ID for the checkbox to tie the label and input together */
      inputId() {
        return 'checkbox_' + this.uniqueId
      },
    },

    watch: {
      /** Watches the checked prop to set the internal isChecked data */
      checked() {
        this.isChecked = this.checked
      },
    },

    methods: {
      /** When the checkbox is checked or unchecked this method emits its new state */
      handleChange() {
        this.$emit('change', this.isChecked)
      },
      clickToggle() {
        this.$emit('clicked')
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <div class="flex items-center mb-3 checkbox-container">
    <input
      :id="inputId"
      v-model="isChecked"
      type="checkbox"
      :name="name"
      class="checkbox-input cursor-pointer"
      role="checkbox"
      aria-checked="isChecked"
      :value="value"
      @change="handleChange"
    />
    <label
      :for="isParent ? '' : inputId"
      class="relative block pl-2 text-sm leading-tight cursor-pointer font-normal"
      @click="clickToggle"
    >
      {{ label }}
    </label>
    <IconCheckbox width="16px" height="16px" class="absolute top-0px left-0 checkbox-icon" />
    <IconAngleDown
      v-if="isParent"
      width="15px"
      height="15px"
      class="transform origin-center transition duration-300 icon-down prodDropdown"
      :class="{ 'rotate-180': isOpen }"
    />
  </div>
</template>

<style scoped lang="postcss">
  .prodDropdown {
    position: absolute;
    top: 2px;
    right: 0;
    width: 15px;
  }

  .checkbox-input:checked + svg {
    display: none;
  }

  .checkbox-input:checked {
    border-color: transparent;
  }

  .checkbox-container {
    position: relative;
  }

  .checkbox-icon {
    display: none;
  }

  input {
    min-width: 16px;
    height: 16px;
    border: 1px solid #dfdfdf;

    &:checked + label + svg {
      display: block;
    }

    &:checked + label {
      color: #000;
    }

    &:focus {
      outline: none;
    }
  }

  .checkbox-input:hover {
    border-color: #000;
  }

  label {
    position: relative;
    z-index: 5;
    display: block;
    width: 100%;
    font-size: 16px;
    text-overflow: ellipsis;
    @apply overflow-hidden;
  }

  .quote-checkbox {
    input {
      position: relative;
      z-index: 10;
      width: 16px;
      height: 16px;

      &:checked {
        width: 17px;
        height: 17px;
        border-color: transparent;
      }
    }
  }
</style>
