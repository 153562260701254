<script>
  export default {
    name: 'LegacyProductThumb',
    props: {
      product: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
  }
</script>

<template>
  <article class="product-thumb">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <a :href="product.url" class="product-thumb__photo" v-html="product.image"></a>
    <div class="product-thumb__info">
      <div class="product-thumb__title-and-brand">
        <a class="product-thumb__title" :href="product.url">
          {{ product.title }}
        </a>
        <div v-if="product.brand" class="product-thumb__brand">
          <span>by</span> <a :href="product.brand.url">{{ product.brand.title }}</a>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped>
  .product-thumb__photo {
    display: block;
    border: 1px solid rgb(221, 221, 221);
  }

  .product-thumb__title-and-brand {
    padding: 0 10px;
  }

  .product-thumb__title {
    display: block;
    padding: 15px 0 0 0;
    font-size: 15px;
    line-height: 1.5;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .product-thumb__brand {
    display: inline-block;
    font-size: 15px;

    span {
      color: #919297;
    }

    a {
      display: inline;
      line-height: 1.2;
      vertical-align: baseline;
      border-bottom: 1px solid #0a0a0a;
      transition: border 0.1s linear;

      &:hover {
        border-color: transparent;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .product-thumb {
      position: relative;

      &:hover {
        .product-thumb__photo {
          opacity: 0.2;
        }

        .product-thumb__info {
          opacity: 1;
        }
      }
    }

    .product-thumb__photo {
      background-color: #e5e7ef;
      opacity: 1;
      transition: all 0.3s;
    }

    .product-thumb__info {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
      border: 1px solid rgb(221, 221, 221);
      opacity: 0;
      transition: opacity 0.2s;
    }

    .product-thumb__title-and-brand {
      padding: 0 15px 10px 15px;
      pointer-events: auto;
    }
  }
</style>
