<script>
  import BaseImage from '../BaseImage.vue'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'BuilderGeneralTextRow',
    components: { BaseImage },
    props: {
      /** The heading for the block, if defined */
      image: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
  }
</script>

<template>
  <div class="mx-auto max-w-screen-2xl w-full py-6 md:py-10 lg:py-12 px-4 md:px-6 relative">
    <BaseImage
      :source="image.urls[0]"
      :alt="image.alt"
      :attribution="image.attribution"
      :attribution-color="image.attributionColor"
      img-class="block-image w-full h-full object-cover object-center"
    />
  </div>
</template>
