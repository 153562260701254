<script>
  /** Base text input field.
   * NOTE: Not in use */

  export default {
    name: 'BaseInputText',

    props: {
      /** The input type */
      type: {
        type: String,
        default: 'text',
        required: false,
        // Only allow types that essentially just render text boxes.
        validator(propValue) {
          const propValues = ['email', 'number', 'password', 'search', 'tel', 'text', 'url', 'file']
          return propValues.indexOf(propValue) > -1
        },
      },
      /** The inputs label */
      label: {
        type: String,
        required: false,
        default: null,
      },
      /** Whether the label should be hidden (aria-label) */
      labelIsHidden: {
        type: Boolean,
        default: false,
      },
      /** Initial value */
      value: {
        type: String,
        required: false,
      },
      /** Placeholder text in the input */
      placeholder: {
        type: String,
        required: false,
        default: null,
      },
      name: {
        type: String,
        required: false,
        default: undefined,
      },
    },

    computed: {
      listeners() {
        const { input, ...listeners } = this.$listeners
        return listeners
      },
    },
  }
</script>

<template>
  <div>
    <label
      :class="`block text-sm text-black ${
        type === 'file'
          ? 'btn border-black border mb-0 cursor-pointer hover:bg-black hover:text-white transition-colors duration-150 ease-in-out'
          : 'w-full'
      }`"
    >
      <input
        :type="type"
        :value="value"
        :placeholder="label"
        :aria-label="labelIsHidden ? label : undefined"
        class="text-input"
        :name="name"
        v-on="listeners"
        @input="$emit('input', $event.target.value)"
      />
    </label>
  </div>
</template>

<style lang="postcss" scoped>
  .input {
    border: 1px solid black;
  }

  input[type='file'] {
    display: none;
  }

  .text-input {
    padding-right: 0;
    padding-bottom: 10px;
    padding-left: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
  }
</style>
