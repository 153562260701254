<script>
  /** Heading sub-block used in the Text Column block content area */

  export default {
    name: 'BuilderTextBlockHeading',

    props: {
      /** The text of the heading */
      text: {
        type: String,
        required: true,
      },
    },
  }
</script>

<template>
  <h3 class="heading" v-html="text"></h3>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .heading >>> a {
    @apply inline-block border-black border-b;
  }

  .heading >>> em {
    @apply italic;
  }

  .heading >>> strong {
    @apply font-bold;
  }
  /* purgecss end ignore */
</style>
