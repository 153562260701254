<script>
  import VLazyImage from 'v-lazy-image'
  import IconGlobe from '@/images/icons/globe.svg'
  import IconArrow from '@/images/icons/arrow-ne.svg'
  import BaseLink from '@/components/BaseLink'

  /** A component to render profile (brand or designer) main page content */

  export default {
    name: 'ProfileDescription',

    components: {
      VLazyImage,
      IconGlobe,
      IconArrow,
      BaseLink,
    },

    props: {
      /** The profile image data as an object with multiple URLs and alternate text */
      image: {
        type: Object,
        required: false,
        default: () => ({
          urls: [],
          alt: '',
        }),
      },
      /** The profiles city */
      city: {
        type: String,
        required: false,
        default: null,
      },
      /** The profiles website URL */
      website: {
        type: String,
        required: false,
        default: null,
      },
      downloads: {
        type: Array,
        required: false,
        default: () => [],
      },
      heading:{
        type: String,
        required: false,
        default: '',
      },
      textLength:{
        type: Number,
        default: 0,
      }
    },

    data() {
      return {
        textOrCut:false
      }
    },

    computed: {
      /** Formats the URL string to remove the http(s):// for display purposes */
      displayUrl: function () {
        return this.website
          ? this.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
          : ''
      },
    },
  }
</script>

<template>
  <div
    class="mx-auto lg:max-w-screen-2xl px-4 md:px-6 pb-10 flex flex-col lg:flex-row flex-wrap"
  >
    <figure v-if="image.urls.length" class="block w-full mb-8 lg:mb-0 w-440 h-440">
      <VLazyImage
        :srcset="image.urls[0] + ' 1x,' + image.urls[1] + ' 2x'"
        :src="image.urls[0]"
        :sizes="'100vw, (min-width: 768px) 50vw'"
        :alt="image.alt"
        class="h-full w-full object-cover object-center"
      />
    </figure>

    <div class="content pl-0 lg:pl-10">
      <h1 
        v-if="heading"
        class="-ml-1"
      >{{ heading }}</h1>
      <dl v-if="city" class="text-gray-500 mb-8">
        <div class="flex justify-start items-center">
          <!--<dt class="inline-block mr-2">
            <span class="sr-only">Location</span>
            <IconGlobe class="inline-block" />
          </dt>-->
          <dd class="inline-block">{{ city }}</dd>
        </div>
      </dl>

      <div
        class="space-y-4 font-normal font-unica77"
        :class="`${ textOrCut ? '' : 'read--more' } ${ textLength < 900 ? 'no--layout' : '' }`"
      >
        <div class="space-y-4 | wysiwyg || font-normal tracking-02 text-lg">
          <slot />
        </div>

        <BaseLink
          v-if="website"
          :href="website"
          target="_blank"
          class="flex justify-start text-md underline text-gray-600 | hover:text-gray-500 | focus:text-gray-500 focus:outline-none"
        >
          <span class="inline-block">{{ displayUrl }}</span>
          <IconArrow class="inline-block ml-1 fill-current max-w-2 mt-1" />
        </BaseLink>

        <dt v-if="downloads.length" class="mb-2 font-bold">Downloads</dt>
        <dd v-if="downloads.length" class="text-gray-600 leading-snug">
          <ul>
            <li v-for="(file, index) in downloads" :key="index">
              <a :href="file.url"> {{ file.label }} ({{ file.extension.toUpperCase() }}) </a>
            </li>
          </ul>
        </dd>
      </div>
      <span 
        v-if="textLength > 900"
        class="cursor-pointer font-medium text-sm text-black mt-8 inline-block"
        @click="$emit('readmore', textOrCut = !textOrCut)"
      >
        {{ textOrCut ? '- Less' : '+ More' }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  h1 {
    margin-bottom: 10px;
  }
  .read--more {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
    &.no--layout {
      &:after {
        display: none;
      }
    }
  }
  
  .w-440 {
    width: 440px;
  }
  .h-440 {
    height: 440px;
  }
  .content {
    width: calc(100% - 440px);
    @media(max-width:1024px) {
      width: 100%;
    }
  }
  @media(max-width:1024px) {
    .w-440 {
      width: 100%;
    }
    .h-440 {
      height: 320px;
    }
  }

  .icon-list {
    svg {
      height: 15px;
      margin-top: -4px;
    }
  }
</style>
