<script>
  export default {
    name: 'NoticeElement',
    props: {
      running: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      nothash: {
        type: String,
        default: '',
      },
      backhex: {
        type: String,
        default: '#000',
      },
    },
    data() {
      return {
        styles: {
          opacity: 0,
          'background-color': this.backhex,
        },
      }
    },
    mounted() {
      const appElement = document.getElementById('app')
      const initialHeaderHeight = document.getElementById('ref-header')
      const noticeElement = document.getElementById('notice')

      if (appElement) {
        if (noticeElement) {
          appElement.classList.add('has--notice')
        }
      }

      noticeElement.style.opacity = 1
      noticeElement.style.top = initialHeaderHeight.clientHeight + 'px'
      window.onscroll = function () {
        if (window.scrollY > 1) {
          document.getElementById('notice').style.top = initialHeaderHeight.clientHeight - 4 + 'px'
        } else {
          setTimeout(function () {
            document.getElementById('notice').style.top = initialHeaderHeight.clientHeight + 'px'
          }, 200)
        }
      }
    },
    methods: {
      getNotice() {
        const appElement = document.getElementById('app')
        const initialHeaderHeight = document.getElementById('ref-header')
        const noticeElement = document.getElementById('notice')

        console.log(initialHeaderHeight)

        if (appElement) {
          if (noticeElement) {
            appElement.classList.add('has--notice')
          }
        }

        document.getElementById('notice').style.opacity = 1
        document.getElementById('notice').style.top = initialHeaderHeight.clientHeight + 'px'
        window.onscroll = function () {
          if (window.scrollY > 1) {
            document.getElementById('notice').style.top =
              initialHeaderHeight.clientHeight - 4 + 'px'
          } else {
            setTimeout(function () {
              document.getElementById('notice').style.top = initialHeaderHeight.clientHeight + 'px'
            }, 200)
          }
        }
      },
      closeNotice() {
        document.cookie = `notice=${this.nothash}; expires=0`
        document.getElementById('notice').style.display = 'none'
        document.getElementById('notice').classList.add('is--closed')
        document.getElementById('app').classList.remove('has--notice')
      },
    },
  }
</script>

<template>
  <div id="notice" :style="styles">
    <div :class="`${running ? 'running--text' : ''}`">
      <div v-html="text"></div>
      <button id="close-notice" @click="closeNotice">
        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 12 12">
          <path
            d="M0.609375 10.165C0.294434 10.4873 0.279785 11.0586 0.616699 11.3882C0.953613 11.7178 1.51758 11.7104 1.83984 11.3955L6 7.23535L10.1528 11.3955C10.4824 11.7178 11.0464 11.7251 11.376 11.3882C11.7056 11.0513 11.7129 10.4946 11.3833 10.165L7.22314 6.00488L11.3833 1.85205C11.7129 1.52246 11.7129 0.958496 11.376 0.628906C11.0391 0.299316 10.4824 0.299316 10.1528 0.621582L6 4.78174L1.83984 0.621582C1.51758 0.306641 0.946289 0.291992 0.616699 0.628906C0.287109 0.96582 0.294434 1.52979 0.609375 1.84473L4.76953 6.00488L0.609375 10.165Z"
          />
        </svg>
        <span>Close notice</span>
      </button>
    </div>
  </div>
</template>
