<script>
  import IconChevronSmallDown from '@/images/icons/chevron-small-down.svg'

  /** This is a generic dropdown select component.
   * See https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model. */

  export default {
    name: 'BaseSelect',

    components: {
      IconChevronSmallDown,
    },

    /** Disable automatic attribute inheritance, so that $attrs are
     * passed to the <select>, even if it's not the root element.
     * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance */
    inheritAttrs: false,

    props: {
      /** Label for the field */
      label: {
        type: String,
        required: true,
      },
      /** Whether this field is required */
      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Options for the dropdown */
      options: {
        type: Array,
        required: true,
      },
      /** For binding the value */
      value: {
        type: [String, Number],
        required: true,
      },
      /** For disabling select */
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      /** Create a unique label id for matching the label and field */
      fieldId() {
        const slugify = require('@sindresorhus/slugify')
        return slugify(this.label)
      },
      listeners() {
        const { input, ...listeners } = this.$listeners
        return listeners
      },
    },

    methods: {
      /** Pass the option's id or label to the parent */
      updateSelectedOption(event) {
        this.$emit(
          'input',
          this.options.find((option) => String(option.id) === String(event.target.value)).id
        )
        // Blur field after selection
        this.$refs.selectField.blur()
      },
    },
  }
</script>

<template>
  <div :class="disabled ? 'pointer-events-none opacity-25' : ''">
    <div class="block relative">
      <select
        :id="fieldId"
        ref="selectField"
        :required="isRequired"
        class="block appearance-none w-full pl-0 border border-gray-400 py-custom pt-4 pb-2 leading-tight focus:outline-none"
        v-bind="$attrs"
        v-on="listeners"
        @input="updateSelectedOption"
      >
        <option v-if="!isRequired" value="" selected disabled>{{ label }}</option>
        <option
          v-for="option in options"
          :key="option.id"
          :value="option.id"
          :selected="value === option.id"
        >
          {{ option.label }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center text-black-700"
      >
        <IconChevronSmallDown class="fill-current h-7 w-7 mt-2" />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .py-custom {
    padding-left: 0 !important;
    border-left: none;
    border-right: none;
    border-top: none;
    font-weight: 400 !important;
  }
</style>
