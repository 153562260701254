<script>
  import BaseFilterButton from '@/components/BaseFilterButton.vue'

  /** Reusable dropdown component that opens and closes on click, meant to be used in filter bars.
   * Uses a slot for the content to display in the dropdown content area. */

  export default {
    name: 'BaseFilterDropdown',

    components: {
      BaseFilterButton,
    },

    props: {
      /** The dropdown's label, shown in the toggle button */
      label: {
        type: String,
        required: true,
      },
      /** An optional count number that resides next to the label */
      count: {
        type: [String, Number],
        default: null,
      },
      /** Whether the dropdown menu is open */
      open: {
        type: Boolean,
        default: false,
      },
      /** Whether to display a gray border around the toggle button */
      border: {
        type: Boolean,
        default: false,
      },
      /** Whether to put emphasis (bold text) on the toggle button, which can indicate that this filter is being used */
      emphasis: {
        type: Boolean,
        default: false,
      },
      /** Whether the dropdown is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Classes to pass to the toggle button component */
      buttonClasses: {
        type: [String, Array, Object],
        default: undefined,
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
      /** The toggle button's ARIA label */
      ariaLabel() {
        return this.isOpen ? 'Close' : 'Open'
      },
    },

    watch: {
      /** Watches the open prop to set the internal isOpen data */
      open() {
        this.isOpen = this.open
      },
    },

    created() {
      const escapeHandler = (event) => {
        if (event.key === 'Escape') {
          this.closeIfOpen()
        }
      }
      document.addEventListener('keydown', escapeHandler)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', escapeHandler)
      })
    },

    methods: {
      /** Opens/closes the dropdown and emits a 'toggle' event to the parent component along with
       * a Boolean of whether the dropdown is open or not */
      toggle() {
        this.isOpen = !this.isOpen
        if (this.isOpen) {
          this.$nextTick(() => {
            this.$refs.content.scrollTop = 0
          })
        }
        this.$emit('toggle', this.isOpen)
      },
      closeIfOpen() {
        if (this.isOpen) {
          this.toggle()
        }
      },
    },
  }
</script>

<template>
  <div v-click-outside="closeIfOpen" class="relative">
    <BaseFilterButton
      :label="label"
      :aria-label="ariaLabel"
      :background-color="isOpen ? 'gray' : 'white'"
      :border="border"
      :emphasis="emphasis"
      :disabled="disabled"
      :class="buttonClasses"
      @click="toggle"
    >
      <template v-slot:after>
        <span v-if="count !== null" class="min-w-6 flex-shrink-0 pl-1 text-right text-gray-600">{{
          count
        }}</span>
      </template>
    </BaseFilterButton>
    <transition name="fade">
      <div
        v-show="isOpen"
        ref="content"
        :class="`z-40 absolute inset-x-0 ${
          border ? '-mt-px' : '-mx-px'
        } px-4 py-3 bg-white border border-gray-300 || content`"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .content {
    max-height: 340px;
    overflow-y: auto;
  }

  @screen lg {
    /* width */
    ::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      @apply rounded-none bg-white;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      @apply rounded-none bg-gray-500 border-0;
    }
  }
</style>
