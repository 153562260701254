<script>
  /** A reusable button type with a count indicator in it that emits a value whenever it is clicked. */

  export default {
    name: 'BaseCountButton',

    props: {
      /** The buttons label */
      label: {
        type: String,
        required: true,
      },
      /** The value that will be emitted to the parent element when the button is clicked */
      value: {
        type: [Number, String, Boolean, Object],
        required: false,
        default: null,
      },
      /** The number that should be displayed in the count indicator */
      count: {
        type: [Number, String],
        required: false,
        default: null,
      },
      /** If the button should be in an active state */
      isActive: {
        type: Boolean,
        required: false,
      },
    },

    methods: {
      /** Emits a click event to the parent component */
      handleClick() {
        this.$emit('button-click', this.value)
      },
    },
  }
</script>

<template>
  <button
    type="button"
    class="flex justify-between w-full px-3 py-3 text-sm bg-white transition-colors duration-300 | focus:outline-none | md:px-5"
    :class="{ 'is-active': isActive }"
    @click="handleClick"
  >
    <span class="inline-block || focus-indicator">{{ label }}</span>
    <span v-if="count !== null" class="inline-block text-gray-600 | md:ml-4">{{ count }}</span>
  </button>
</template>

<style scoped lang="postcss">
  button {
    &.is-active,
    &:hover {
      @apply bg-gray-300;
    }

    &:focus {
      .focus-indicator {
        @apply underline;
      }
    }
  }

  @screen md {
    button {
      &.is-active,
      &:hover {
        @apply bg-white;

        .focus-indicator {
          @apply underline;
        }
      }
    }
  }
</style>
