<script>
  /** Reusable base image component to use within content areas.
   * NOTE: Currently not in use */

  export default {
    name: 'BaseImage',

    props: {
      /** The images URL source */
      source: {
        type: String,
        required: true,
        default: '',
      },
      /** The image alternate text */
      alt: {
        type: String,
        required: true,
        default: '',
      },
      /** The image attribution */
      attribution: {
        type: String,
        default: null,
      },
      /** The image attribution text color */
      attributionColor: {
        type: String,
        default: '#FFFFFF',
      },
      /** An optional image caption */
      caption: {
        type: String,
        required: false,
        default: null,
      },
      /** Position of the image in context to it's siblings */
      position: {
        type: String,
        required: false,
        default: 'left',
        validator: (propValue) => {
          const validProps = ['left', 'right', 'full', 'center']
          return validProps.indexOf(propValue) > -1
        },
      },
      imgClass: {
        type: String,
        required: false,
        default: '',
      },
    },

    computed: {
      /** Creates the Tailwind classes to add to the element for it's positioning */
      positionClass: function () {
        let cssClass = 'mb-2'
        switch (this.position) {
          case 'right':
            cssClass += ' ml-2 text-right'
            break
          case 'full':
            cssClass += ' block w-full'
            break
          case 'center':
            cssClass += ' mx-2 text-center'
            break
          default:
            cssClass += ' mr-0 text-left'
        }
        return cssClass
      },
    },
  }
</script>

<template>
  <figure class="relative" :class="positionClass">
    <img
      class="mx-auto"
      :class="`${imgClass} ${position === 'full' ? 'block min-w-full' : ''}`"
      :src="source"
      :alt="alt"
    />
    <div
      v-if="attribution"
      class="absolute bottom-0 right-0 py-1 text-xs inline px-4 rounded-tl z-10"
      :style="{ color: attributionColor }"
      v-html="attribution"
    ></div>
    <figcaption v-if="caption" class="mt-2">
      {{ caption }}
    </figcaption>
  </figure>
</template>

<style scoped>
  img {
    display: inline;
    max-width: 100%;
    height: auto;
  }
</style>
