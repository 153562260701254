<script>
  /** A base quantity selector for products */

  export default {
    name: 'BaseQuantityField',

    props: {
      /** The variant or line item ID */
      id: {
        type: [Number, String],
        required: false,
        default: null,
      },
      /** The initial value */
      value: {
        type: [Number, String],
        required: false,
        default: 0,
      },
      /** Minimum amount */
      min: {
        type: [Number, String],
        required: false,
        default: 0,
      },
      /** Maximum amount */
      max: {
        type: [Number, String],
        required: false,
        default: 100,
      },
      /** Disable the component */
      disabled: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        count: parseInt(this.value),
        outOfRange: false,
        isDisabled: this.disabled,
      }
    },

    watch: {
      value: function (val) {
        this.count = parseInt(val)
      },
      disabled: function (val) {
        this.isDisabled = val
      },
    },

    created() {
      // Check the min value to see if the value is out of range
      if (parseInt(this.value) < parseInt(this.min)) {
        this.count = parseInt(this.min)
        this.outOfRange = true
        this.$emit('outOfRange')
      }
      // Check the max value to see if it is out of range
      if (parseInt(this.value) > parseInt(this.max)) {
        this.count = parseInt(this.max)
        this.outOfRange = true
        this.$emit('outOfRange')
      }
    },

    methods: {
      /** Increments the value and emits success or out of rage events */
      increment() {
        if (this.count + 1 <= this.max) {
          this.isDisabled = true
          this.count++
          this.$emit('increment', { count: this.count, id: this.id })
          this.outOfRange = false
        } else {
          this.outOfRange = true
          this.$emit('outOfRange')
        }
      },
      /** Decrements the value and emits success or out of rage events */
      decrement() {
        if (this.count - 1 >= this.min) {
          this.isDisabled = true
          this.count--
          this.$emit('decrement', { count: this.count, id: this.id })
          this.outOfRange = false
        } else {
          this.outOfRange = true
          this.$emit('outOfRange')
        }
      },
    },
  }
</script>

<template>
  <div
    class="inline-flex items-center border bg-gray-200 border-gray-200 rounded-full"
    :class="{ 'border-red-600': outOfRange, 'opacity-50': isDisabled }"
  >
    <h4
      class="inline-block order-2 text-center"
      :class="{ 'text-red-600': outOfRange }"
      aria-label="Current quantity"
      >{{ count }}</h4
    >
    <button
      type="button"
      :disabled="isDisabled"
      class="order-1 | focus:outline-none"
      aria-label="Decrement quantity"
      @click="decrement"
      @blur="outOfRange === false"
    >
      <span
        class="inline-block leading-tight border-b border-transparent transition-colors duration-300"
        >-</span
      >
    </button>
    <button
      type="button"
      :disabled="isDisabled"
      class="order-3 | focus:outline-none"
      aria-label="Increment quantity"
      @click="increment"
      @blur="outOfRange === false"
    >
      <span
        class="inline-block leading-tight border-b border-transparent transition-colors duration-300"
        >+</span
      >
    </button>
  </div>
</template>

<style scoped lang="postcss">
  h4 {
    min-width: 16px;
  }

  button {
    width: 32px;
    height: 32px;

    &:focus span,
    &:hover span {
      @apply border-black;
    }
  }
</style>
