<script>
  import { debounce } from 'lodash'
  import algoliasearch from 'algoliasearch/lite'
  import BaseCountButton from '@/components/BaseCountButton'
  import ProductTile from '@/components/products/ProductTile'
  import BrandTile from '@/components/brands/BrandTile'
  import DesignerTile from '@/components/designers/DesignerTile'
  import ArticleTile from '@/components/articles/ArticleTile'

  export default {
    name: 'LegacySearch',

    components: {
      BaseCountButton,
      ProductTile,
      BrandTile,
      DesignerTile,
      ArticleTile,
    },

    props: {
      searchOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
      term: {
        type: String,
        required: false,
        default: '',
      },
      thick: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        previews: 24,
        results: {
          products: {
            previews: [],
            total: 0,
          },
          brands: {
            previews: [],
            total: 0,
          },
          designers: {
            previews: [],
            total: 0,
          },
          articles: {
            previews: [],
            total: 0,
          },
        },
        view: 'products',
        viewSelected: false,
        isLoading: false,
      }
    },

    computed: {
      productsCount: function () {
        return this.results.products.total
      },
      brandsCount: function () {
        return this.results.brands.total
      },
      designersCount: function () {
        return this.results.designers.total
      },
      articlesCount: function () {
        return this.results.articles.total
      },
      totalCount: function () {
        return this.productsCount + this.brandsCount + this.designersCount + this.articlesCount
      },
      showProducts: function () {
        return this.productsCount && this.view === 'products'
      },
      showBrands: function () {
        return this.brandsCount && this.view === 'brands'
      },
      showDesigners: function () {
        return this.designersCount && this.view === 'designers'
      },
      showArticles: function () {
        return this.articlesCount && this.view === 'articles'
      },
      showSearchUrl: function () {
        return (
          this.productsCount > this.previews ||
          this.brandsCount > this.previews ||
          this.designersCount > this.previews ||
          this.articlesCount > this.previews
        )
      },
      searchUrl: function () {
        return '/search?searchTerm=' + this.term
      },
    },

    watch: {
      term() {
        this.search()
      },
    },

    methods: {
      closeSearch() {
        this.$emit('close-search')
      },
      resetSearch() {
        this.$refs.searchInput.focus()
      },
      switchView(view) {
        this.view = view
        this.viewSelected = true
      },
      initView() {
        if (!this.viewSelected) {
          if (this.productsCount) {
            this.switchView('products')
            return false
          }
          if (this.brandsCount) {
            this.switchView('brands')
            return false
          }
          if (this.designersCount) {
            this.switchView('designers')
            return false
          }
          if (this.articlesCount) {
            this.switchView('articles')
            return false
          }
        }
      },
      search: debounce(function () {
        if (this.term) {
          // Send a search event to Google Analytics
          if (window.ga) {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'Global',
              eventAction: 'Search',
              eventLabel: this.term,
            })
          }

          // Send the appropriate GTM event
          if (this.$gtm.enabled()) {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'Search',
              email: this.$store.getters['global/currentUser']
                ? this.$store.getters['global/currentUser'].email
                : '',
              search_query: this.term,
            })
          }

          /* Get the keys and list to operate on from the packages/fwork/.env file */
          const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
          const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
          const productsIndex = process.env.VUE_APP_ALGOLIA_PRODUCTS_INDEX
          const brandsIndex = process.env.VUE_APP_ALGOLIA_BRANDS_INDEX
          const designersIndex = process.env.VUE_APP_ALGOLIA_DESIGNERS_INDEX
          const articlesIndex = process.env.VUE_APP_ALGOLIA_ARTICLES_INDEX

          const queries = [
            {
              indexName: productsIndex,
              query: this.term,
              params: {
                hitsPerPage: this.previews,
              },
            },
            {
              indexName: brandsIndex,
              query: this.term,
              params: {
                hitsPerPage: this.previews,
              },
            },
            {
              indexName: designersIndex,
              query: this.term,
              params: {
                hitsPerPage: this.previews,
              },
            },
            {
              indexName: articlesIndex,
              query: this.term,
              params: {
                hitsPerPage: this.previews,
              },
            },
          ]

          this.isLoading = true

          // Initialize the search
          const client = algoliasearch(appId, apiKey)

          client.multipleQueries(queries).then(({ results }) => {
            this.results.products.previews = results.filter(
              (result) => result.index === productsIndex
            )[0].hits
            this.results.products.total = results.filter(
              (result) => result.index === productsIndex
            )[0].nbHits

            this.results.brands.previews = results.filter(
              (result) => result.index === brandsIndex
            )[0].hits
            this.results.brands.total = results.filter(
              (result) => result.index === brandsIndex
            )[0].nbHits

            this.results.designers.previews = results.filter(
              (result) => result.index === designersIndex
            )[0].hits
            this.results.designers.total = results.filter(
              (result) => result.index === designersIndex
            )[0].nbHits

            this.results.articles.previews = results.filter(
              (result) => result.index === articlesIndex
            )[0].hits
            this.results.articles.total = results.filter(
              (result) => result.index === articlesIndex
            )[0].nbHits

            this.viewSelected = false
            this.initView()
            this.isLoading = false
          })
        } else {
          this.results.products.previews = []
          this.results.products.total = 0

          this.results.brands.previews = []
          this.results.brands.total = 0

          this.results.designers.previews = []
          this.results.designers.total = 0

          this.results.articles.previews = []
          this.results.articles.total = 0

          this.isLoading = false
        }
      }, 700),
    },
  }
</script>

<template>
  <div
    v-show="term"
    class="search-modal"
    :class="`${searchOpen ? 'is-open' : ''} ${thick ? 'thick' : ''}`"
  >
    <div class="search-modal__content">
      <ul
        class="top-0 z-30 sticky flex flex-wrap justify-between bg-white border-gray-200 border-t | md:justify-start md:border-b md:border-t-0 || toggle"
      >
        <li
          v-if="productsCount"
          class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
        >
          <BaseCountButton
            :label="'Products'"
            :count="productsCount"
            :value="'products'"
            :is-active="view === 'products'"
            @button-click="switchView"
          />
        </li>

        <li
          v-if="brandsCount"
          class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
        >
          <BaseCountButton
            :label="'Brands'"
            :count="brandsCount"
            :value="'brands'"
            :is-active="view === 'brands'"
            @button-click="switchView"
          />
        </li>

        <li
          v-if="designersCount"
          class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
        >
          <BaseCountButton
            :label="'Designers'"
            :count="designersCount"
            :value="'designers'"
            :is-active="view === 'designers'"
            @button-click="switchView"
          />
        </li>

        <li
          v-if="articlesCount"
          class="block w-1/2 border-r border-b border-gray-300 | md:w-auto md:border-0"
        >
          <BaseCountButton
            :label="'Inspiration'"
            :count="articlesCount"
            :value="'articles'"
            :is-active="view === 'articles'"
            @button-click="switchView"
          />
        </li>
      </ul>

      <div class="p-4 | md:p-6">
        <div
          v-if="showProducts"
          class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-6"
        >
          <ProductTile
            v-for="product in results.products.previews"
            :key="product.id"
            v-bind="product"
            :hide-price="true"
          />
        </div>

        <div
          v-if="showBrands"
          class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-6"
        >
          <BrandTile v-for="brand in results.brands.previews" :key="brand.id" v-bind="brand" />
        </div>

        <div
          v-if="showDesigners"
          class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-6"
        >
          <DesignerTile
            v-for="designer in results.designers.previews"
            :key="designer.id"
            v-bind="designer"
          />
        </div>

        <div
          v-if="showArticles"
          class="grid grid-cols-2 gap-4 | md:grid-cols-3 md:gap-6 | lg:grid-cols-4 | 2xl:grid-cols-6"
        >
          <ArticleTile
            v-for="article in results.articles.previews"
            :key="article.id"
            v-bind="article"
          />
        </div>
      </div>
    </div>

    <a
      v-if="showSearchUrl"
      :href="searchUrl"
      class="z-30 absolute bottom-0 left-0 right-0 block w-full p-3 text-center text-white bg-black"
    >
      See all results <span class="text-gray-200">{{ totalCount }}</span>
    </a>
  </div>
</template>

<style scoped lang="postcss">
  .search-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 300;
    margin-top: 56px;
    background-color: white;
    opacity: 0;
    transition: all 0.2s linear;
    transform: translate3d(100%, 0, 0);

    &.is-open {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.thick {
      margin-top: 112px;
    }
  }

  .search-modal__content {
    position: absolute;
    top: -1px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
  }

  @screen md {
    .search-modal {
      margin-top: 72px;
    }

    .search-modal__content {
      top: -1px;
    }
  }
</style>
