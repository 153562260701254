<script>
  /** This is a visual loader for column lists */
  import { vueWindowSizeMixin } from 'vue-window-size'
  import { VclList } from 'vue-content-loading'

  /** The loading animation that appears when column lists into to the app. It emits the animation size to the
   * parent element when it is mounted. Make use of the VueContentLoading component to create animated SVG
   * placeholders and vue-window-size as a mixin to determine the layout based on screen size.
   * See: https://github.com/LucasLeandro1204/vue-content-loading
   * See: https://github.com/mya-ake/vue-window-size */

  export default {
    name: 'BaseColumnListLoader',

    components: {
      VclList,
    },

    mixins: [vueWindowSizeMixin],

    created() {
      /** Add a resize event listener when created */
      window.addEventListener('resize', this.handleResize)
    },

    mounted() {
      /** When mounted, emit the size of the animation (width and height) */
      this.handleResize()
    },

    destroyed() {
      /** Clean up and remove the resize event when the component is destroyed */
      window.removeEventListener('resize', this.handleResize)
    },

    methods: {
      /** Emits a 'resize' event with the size of the animation (width and height) to the parent element */
      handleResize() {
        this.$emit('resize', {
          width: this.$refs.loader.clientWidth,
          height: this.$refs.loader.clientHeight,
        })
      },
    },
  }
</script>

<template>
  <div>
    <div ref="loader" class="md:grid md:grid-cols-3 md:gap-4 lg:grid-cols-4 xl:grid-cols-5">
      <VclList></VclList>
      <VclList></VclList>
      <VclList></VclList>
      <VclList></VclList>
      <VclList v-if="windowWidth >= 1280"></VclList>
    </div>
  </div>
</template>
