<script>
  import api from '@/api'
  import CategoryBuilderBaseBlock from '@/components/builder/categorybuilder/CategoryBuilderBaseBlock'

  export default {
    name: 'CategoryBuilderPage',

    components: {
      CategoryBuilderBaseBlock,
    },

    props: {
      /** The page's entry ID */
      entryId: {
        type: [String, Number],
        required: true,
      },
      /** The content builder field's ID */
      fieldId: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        builderBlocks: [],
        isLoading: false,
      }
    },
    computed: {
      location() {
        return window.location.pathname
      },
    },

    created() {
      // Set the loading flag
      this.isLoading = true

      const query = location.search.substring(1)
      let preview = false
      let token = false

      if (query) {
        const queryParams = JSON.parse(
          '{"' +
            decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
            '"}'
        )
        preview = queryParams['x-craft-live-preview'] ?? false
        token = queryParams.token ?? false
      }

      // Make the request
      api
        .post(`builder-module/default/builder-content`, {
          entryId: this.entryId,
          fieldId: this.fieldId,
          preview: !!(preview && token),
        })
        .then((response) => {
          // Save the results into the data store and un-flag loading
          this.builderBlocks = response.data
          this.isLoading = false
        })
    },

    mounted() {},

    methods: {},
  }
</script>

<template>
  <main 
    v-if="builderBlocks.length > 0"
    id="main-content"
    style="z-index: 9;"
  >
    <article
      class="relative || builder pb-20 lg:pb-32 pt-8 lg:pt-0"
      :class="{ 'is-loading': isLoading }"
    >
      <transition name="fade">
        <div
          v-show="isLoading"
          class="absolute inset-0 z-30 flex flex-col justify-center items-center bg-white"
        >
          <p class="btn btn--rounded">Loading</p>
        </div>
      </transition>
      <transition name="fade">
        <div v-show="!isLoading">
          <CategoryBuilderBaseBlock
            v-for="(block, index) in builderBlocks"
            :key="index"
            :type="block.type"
            :content="block.content"
            :is-last="index + 1 === builderBlocks.length"
          />
        </div>
      </transition>
    </article>
  </main>
</template>
