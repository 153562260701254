<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import api from '@/api'

  export default {
    name: 'LoginPage',
    components: { ValidationProvider, ValidationObserver },
    props: {
      rememberedUser: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        error: '',
        loginName: '',
        password: '',
      }
    },
    mounted() {
      if (this.rememberedUser !== null) {
        this.loginName = this.rememberedUser
      }
    },
    methods: {
      async handleFormSubmission() {
        if ((await this.$refs.loginForm.validate()) !== true) {
          this.isSending = false
          return
        }

        const { data } = await api.post('hauteliving/site/get-account-pending', {
          email: this.loginName,
        })

        api
          .post('users/login', {
            loginName: this.loginName,
            password: this.password,
          })
          .then((res) => {
            if ((res.status === 200 && res.data.error) || res.status !== 200) {
              if (!data) {
                this.error = res.data.error
              } else {
                this.error =
                  'Your account is pending approval. We will send you an activation link soon.'
              }
            } else {
              window.location.href = '/account'
            }
          })
      },
    },
  }
</script>

<template>
  <div class="max-w-6xl container mx-auto mt-20 mb-32 px-4">
    <h1 class="text-2xl lg:text-4xl text-center mb-12 lg:mb-16"> Haute Living Account </h1>

    <div class="lg:grid grid-cols-2 gap-24">
      <div class="mb-20 lg:mb-0">
        <h2 class="text-lg tracking-widest mb-6">Login</h2>

        <div v-if="error" class="mb-4">
          <BaseError v-if="error" :error="error" />
        </div>

        <ValidationObserver ref="loginForm">
          <form
            id="login"
            method="post"
            accept-charset="UTF-8"
            @submit.prevent="handleFormSubmission"
          >
            <div class="mb-4">
              <ValidationProvider v-slot="{ errors }" name="username or email" rules="required">
                <BaseInputText
                  v-model="loginName"
                  label="Username or email"
                  value=""
                  type="text"
                  name="loginName"
                />
                <BaseError v-if="errors.length" :error="errors[0]" />
              </ValidationProvider>
            </div>

            <div class="mb-4">
              <ValidationProvider v-slot="{ errors }" name="password" rules="required">
                <BaseInputText
                  v-model="password"
                  label="Password"
                  type="password"
                  value=""
                  name="password"
                />
                <BaseError v-if="errors.length" :error="errors[0]" />
              </ValidationProvider>
            </div>

            <div class="mb-4">
              <BaseCheckbox label="Remember Me" name="rememberMe" value="1" />
            </div>

            <div class="mb-4">
              <BaseButton color="yellows" capitalize full-width type="submit">Login</BaseButton>
            </div>

            <BaseLink href="/forgotpassword">Forgot Your Password?</BaseLink>
          </form>
        </ValidationObserver>
      </div>

      <div>
        <h2 class="text-lg tracking-widest">Register</h2>
        <p class="mt-3 mb-6 opacity-50">Don't have an account with us yet? Register Below!</p>

        <div class="flex flex-col lg:max-w-1/2 justify-center">
          <BaseButton url="/register/customer" color="black" full-width>
            <span class="text normal-case text-sm">I am a new customer</span>
          </BaseButton>
          <span class="my-6 text-center">-or-</span>
          <BaseButton url="/register/trade" color="black" full-width>
            <span class="text normal-case text-sm">I am a trade professional</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
