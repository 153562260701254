import pick from 'lodash/pick'
import api from '@/api'
import { ADDRESS_FIELDS } from '@/modules/checkout'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    hasLoaded: false,
    items: [],
    qty: 0,
    coupon: {
      code: null,
      verifying: false,
      verified: false,
      description: null,
    },
    totals: {
      price: 0,
      discount: 0,
      saleAmount: 0,
      subtotal: 0,
      shipping: 0,
      tax: 0,
      total: 0,
    },
  },

  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setHasLoaded(state, value) {
      state.hasLoaded = value
    },
    setItems(state, value) {
      state.items = value
    },
    setQty(state, value) {
      state.qty = value
    },
    setCouponCode(state, value) {
      state.coupon.code = value
    },
    setCouponVerifying(state, value) {
      state.coupon.verifying = value
    },
    setCouponVerified(state, value) {
      state.coupon.verified = value
    },
    setCouponDescription(state, value) {
      state.coupon.description = value
    },
    setPrice(state, value) {
      state.totals.price = value
    },
    setDiscount(state, value) {
      state.totals.discount = value
    },
    setSaleAmount(state, value) {
      state.totals.saleAmount = value
    },
    setSubtotal(state, value) {
      state.totals.subtotal = value
    },
    setShipping(state, value) {
      state.totals.shipping = value
    },
    setTax(state, value) {
      state.totals.tax = value
    },
    setTotal(state, value) {
      state.totals.total = value
    },
  },

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getHasLoaded(state) {
      return state.hasLoaded
    },
    getItems(state) {
      return state.items
    },
    getQty(state) {
      return state.qty
    },
    getCouponCode(state) {
      return state.coupon.code
    },
    getCouponVerified(state) {
      return state.coupon.verified
    },
    getCouponVerifying(state) {
      return state.coupon.verifying
    },
    getCouponDescription(state) {
      return state.coupon.description
    },
    getPrice(state) {
      return state.totals.price
    },
    getDiscount(state) {
      return state.totals.discount
    },
    getSaleAmount(state) {
      return state.totals.saleAmount
    },
    getSubtotal(state) {
      return state.totals.subtotal
    },
    getShipping(state) {
      return state.totals.shipping
    },
    getTax(state) {
      return state.totals.tax
    },
    getTotal(state) {
      return state.totals.total
    },
  },

  actions: {
    /** Calls the custom endpoint to load the cart data */
    loadCart(context) {
      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Make the request to the custom module
      return api.post('hauteliving/site/get-active-cart').then((response) => {
        if (response.status === 200) {
          // eslint-disable-next-line no-console
          context.commit('setItems', response.data.items)
          context.commit('setQty', response.data.qty)
          context.commit('setCouponCode', response.data.coupon.code)
          context.commit('setCouponVerified', response.data.coupon.valid)
          context.commit('setCouponDescription', response.data.coupon.description)
          context.commit('setPrice', response.data.totals.price)
          context.commit('setDiscount', response.data.totals.discount)
          context.commit('setSaleAmount', response.data.totals.saleAmount)
          context.commit('setSubtotal', response.data.totals.subtotal)
          context.commit('setShipping', response.data.totals.shipping)
          context.commit('setTax', response.data.totals.tax)
          context.commit('setTotal', response.data.totals.total)
          context.commit('checkout/setEmail', response.data.email, { root: true })
          context.commit('checkout/setHasAccount', response.data.hasAccount, { root: true })
          context.commit(
            'checkout/setShippingAddress',
            pick(response.data.shippingAddress, ADDRESS_FIELDS),
            { root: true }
          )
          context.commit(
            'checkout/setBillingAddress',
            pick(response.data.billingAddress, ADDRESS_FIELDS),
            { root: true }
          )
        } else {
          // TODO: What do we do if the cart does not load?
        }

        // Set the isLoading and hasLoaded state
        context.commit('setIsLoading', false)
        context.commit('setHasLoaded', true)
      })
    },

    // TODO: Looks like this is never called. Should it be removed?
    addItem(context, { purchasableId, qty = 1 }) {
      // Make the request to the custom module
      return api
        .post('commerce/cart/update-cart', {
          purchasableId: purchasableId,
          qty: qty,
        })
        .then((response) => {
          if (response.status === 200) {
            // Reload the cart from our custom endpoint
            context.dispatch('loadCart')
          } else {
            // TODO: What do we do if the cart does not load?
          }
        })
    },

    changeItemQty(context, { id, qty }) {
      // Make the request to the custom module
      return api
        .post('commerce/cart/update-cart', {
          lineItems: { [id]: { qty: qty } },
        })
        .then((response) => {
          if (response.status === 200) {
            // Reload the cart from our custom endpoint
            context.dispatch('loadCart')
          } else {
            // TODO: What do we do if the cart does not load?
          }
        })
    },

    verifyCoupon(context, payload) {
      // Set the verifying state
      context.commit('setCouponVerifying', true)

      // Make the request to commerce
      return api
        .post('commerce/cart/update-cart', {
          couponCode: payload,
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            // If the response returns a coupon code, it is valid
            if (response.data.cart.couponCode) {
              context.commit('setCouponCode', response.data.cart.couponCode)
              context.commit('setCouponVerified', true)
            } else {
              context.commit('setCouponVerified', false)
            }
          } else {
            context.commit('setCouponVerified', false)
            throw response.data?.error ?? 'Unable to verify coupon code, please try again.'
          }

          // Reload the cart from our custom endpoint and reset the verifying state
          context.dispatch('loadCart')
          context.commit('setCouponVerifying', false)
        })
    },
  },
}
