<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import api from '@/api'
  import BaseInputText from '@/components/BaseInputText'
  import BaseDateTimePicker from '@/components/BaseDateTimePicker'

  export default {
    name: 'MakeAnAppointmentPopup',
    components: { BaseInputText, ValidationProvider, ValidationObserver, BaseDateTimePicker },
    props: {
      formObj: {
        type: Object,
        required: true,
      },
      bannerTitle: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        error: '',
        stateDisabled: false,
        isSending: false,
        isSent: false,
        errorMsg: '',
        success: null,
        submitCount: 0,
        location: '',
        dateTime: '',
        form: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          honeypot: '',
          showroom: '',
        },
        hideAfterSubmit: true,
      }
    },
    watch: {
      'form.showroom'(event) {
        if (event == 'New York Showroom') {
          this.location = 'NYC'
        } else {
          this.location = 'Chicago'
        }
      },
    },
    computed: {
      getCurrentUserTimeZone() {
        const timeZoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone
        return timeZoneInfo
      },
    },
    methods: {
      async handleFormSubmission() {
        this.isSending = true

        const formData = new FormData()

        const params = {
          formHash: this.formObj.hash,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          phone: this.form.phone,
          preferredAppointment: this.dateTime,
          sentFromShowroom: this.form.showroom,
          senderTimeZone: this.getCurrentUserTimeZone,
        }

        if (this.formObj.honeypot) {
          params[this.formObj.honeypot.name] = this.formObj.honeypot.hash
        }

        for (const param in params) {
          formData.append(param, params[param])
        }

        if ((await this.$refs.makeAnAppointmentForm.validate()) !== true) {
          this.isSending = false
          this.errorMsg = 'Please fill out all required fields.'
          return
        }

        if (this.form.honeypot !== '') {
          this.isSending = false
          this.errorMsg = 'You are a bot.'
          return
        }

        this.errorMsg = ''

        api
          .post('freeform/submit', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              this.errorMsg = 'Oops! Something went wrong. Please try again.'
              this.success = false
            } else if (!response.data.success) {
              this.submitCount++
              this.success = false
              this.isSending = false

              // Prevent Form has Expired error

              if (this.submitCount < 2) {
                this.handleFormSubmission()
              } else {
                const errorHandles = Object.keys(response.data.errors)
                if (errorHandles.length > 0) {
                  errorHandles.forEach((name) => {
                    this.errorMsg =
                      response.data.errors[name][0] || response.data.formErrors[name][0]
                  })
                }
              }
            } else {
              api
                .post('/klaviyoconnect/api/track', {
                  event: {
                    name: 'Make An Appointment',
                  },
                  list: 'RRrbrf',
                  email: this.form.email,
                })
                .then((klaviyoRes) => {
                  if (klaviyoRes.status === 200) {
                    this.isSending = false
                    this.isSent = true
                    this.success = true
                    this.hideAfterSubmit = false
                    localStorage.setItem('name', this.form.firstName)
                    localStorage.setItem('location', this.location)
                    window.location.href = '/appointment/thanks'
                  } else if (res.status !== 200) {
                    this.error = 'Oops! Something went wrong. Please try again.'
                  }
                })
                .catch(() => {
                  this.error = 'Oops! Something went wrong. Please try again.'
                })
            }
          })
      },
    },
  }
</script>

<template>
  <div>
    <div v-if="hideAfterSubmit" class="formInnerWrapper">
      <h2 v-if="bannerTitle" class="font-bold font-cormorant">{{ bannerTitle }}</h2>
      <div v-if="error" class="mb-4">
        <BaseError v-if="error" :error="error" class="text-center" />
      </div>
      <ValidationObserver ref="makeAnAppointmentForm">
        <form
          method="post"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          @submit.prevent="handleFormSubmission"
        >
          <input type="text" id="honeypot" v-model="form.honeypot" style="display: none;" />
          <div class="appointmentRadios">
            <ValidationProvider v-slot="{ errors }" name="Showroom" rules="required">
              <ul class="radioButtons">
                <li>
                  <input
                    type="radio"
                    v-model="form.showroom"
                    name="showroom"
                    value="Chicago Showroom"
                    checked
                  />
                  <span>Chicago Showroom</span>
                </li>
                <li>
                  <input
                    type="radio"
                    v-model="form.showroom"
                    name="showroom"
                    value="New York Showroom"
                  />
                  <span>New York Showroom</span>
                </li>
              </ul>
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>
          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider v-slot="{ errors }" name="First Name" rules="required">
              <BaseInputText
                v-model="form.firstName"
                :value="form.firstName"
                label="First Name *"
                type="text"
                name="firstName"
                class="mb-4 md:mb-0"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="Last Name" rules="required">
              <BaseInputText
                v-model="form.lastName"
                :value="form.lastName"
                label="Last Name *"
                type="text"
                name="lastName"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider v-slot="{ errors }" name="Email" rules="required|email">
              <BaseInputText
                v-model="form.email"
                :value="form.email"
                label="Email *"
                type="email"
                name="email"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" name="Phone" rules="required">
              <BaseInputText
                v-model="form.phone"
                :value="form.phone"
                label="Phone *"
                type="text"
                name="phone"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-slot="{ errors }"
              name="Preferred Appointment day/time"
              rules="required"
            >
              <BaseDateTimePicker
                format="MM/DD/YYYY h:i"
                width="100%"
                v-model="dateTime"
                :value="dateTime"
                :placeholder="'Preferred Appointment day/time *'"
                name="preferredAppointment"
              ></BaseDateTimePicker>
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div>
            <BaseButton capitalize type="submit" color="yellows" :disabled="isSending">{{
              isSending ? 'Sending' : 'Submit'
            }}</BaseButton>

            <div v-if="error || errorMsg">
              <BaseError
                v-if="error || errorMsg"
                :error="errorMsg ? errorMsg : error"
                class="text-center absolute mt-4 text-sm"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
      <div class="formDisclaymer">
        <p>
          * All appointment requests must be confirmed by one of our design associates. <br />
          We are closed on Saturdays and Sundays.
        </p>
      </div>
    </div>
    <p v-if="success" class="uppercase text-green-600 text-center mt-4 text-lg">
      Form has submitted successfully!
    </p>
  </div>
</template>

<style lang="scss" scoped>
  .appointmentRadios {
    margin-bottom: 24px;
    overflow: hidden;
  }

  .radioButtons {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    overflow: hidden;

    li {
      position: relative;
      height: 20px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
      }

      input:checked + span::after {
        display: block;
        background: #ffdf00;
      }

      span {
        position: relative;
        z-index: 1;
        display: block;
        padding-left: 20px;

        &::before {
          position: absolute;
          top: 3px;
          left: 0;
          width: 13px;
          height: 13px;
          content: '';
          border: 1px solid #000;
          border-radius: 100%;
        }

        &::after {
          position: absolute;
          top: 5px;
          left: 2px;
          display: none;
          width: 8px;
          height: 8px;
          content: '';
          background: #000;
          border-radius: 100%;
        }
      }
    }
  }

  h2,
  h3 {
    display: block;
    margin: 0 0 38px 0;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    @media (max-width: 992px) {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: 30px;
    }
  }

  h3 {
    font-weight: 500;
  }

  .formDisclaymer {
    width: 100%;
    padding-top: 40px;
    font-weight: 500;

    p {
      font-size: 15px;
      line-height: 20px;
      color: #000;
      text-indent: -11px;
    }
  }

  .formInnerWrapper {
    width: 100%;
  }
</style>
