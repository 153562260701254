<script>
  /** Google Maps component */

  export default {
    name: 'BuilderGoogleMap',

    components: {},

    props: {
      /** Address 1 */
      address1: {
        type: String,
        required: true,
      },
      /** Address 2 */
      address2: {
        type: String,
        required: false,
        default: '',
      },
      /** City */
      city: {
        type: String,
        required: true,
      },
      /** State */
      state: {
        type: String,
        required: true,
      },
      /** Zip */
      zip: {
        type: String,
        required: true,
      },
      /** Size width of map */
      maxWidth: {
        type: String,
        required: true
      },
      aspectRatio: {
        type: String,
        required:true
      }
    },

    computed: {
      formattedAddress() {
        const address1 = this.address1.replace(/\s/gi, '+')
        const address2 = this.address2 ? this.address2.replace(' ', '+') : null
        const city = this.city.replace(' ', '+')
        const state = this.state.replace(' ', '+')
        const zip = this.zip.replace(' ', '+')
        const address = address2 ? `${address1}+${address2}` : address1

        return encodeURIComponent(`${address}+${city}+${state}+${zip}`)
      },
    },
  }
</script>

<template>
  <div class="mx-auto max-w-screen-2xl py-6 md:py-10 lg:py-16 px-6 md:px-6" :class="maxWidth">
    <div class="map-wrapper" :class="`ar-${aspectRatio}`">
      <iframe
        :src="`https://www.google.com/maps?&amp;q=${formattedAddress}&amp;output=embed`"
        width="100%"
        height="100%"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  >>> .ar-4-3 {
    padding-bottom: 75%;
  }

  >>> .ar-16-9 {
    padding-bottom: 56.25%;
  }

  >>> .ar-16-5 {
    padding-bottom: 31.25%;
  }

  .map-wrapper {    
    @apply relative h-0;

    >>> iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  /* purgecss end ignore */
</style>
