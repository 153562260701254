<script>
  import CartPreview from '@/components/cart/CartPreview'
  import CartLineItemPrices from '@/components/cart/CartLineItemPrices'

  /** A full version of the cart line items that takes in a prop of items (use on confirmation page) */

  export default {
    name: 'CartLineItemsFullConfirmation',

    components: {
      CartLineItemPrices,
      CartPreview,
    },

    props: {
      /** Array of cart items */
      items: {
        type: Array,
        required: true,
      },
    },

    data() {
      return {
        tradeAccount: false,
      }
    },

    created() {
      // See if the user is logged in as a trade account
      this.tradeAccount = this.$store.getters['global/isTradeAccount']
    },
  }
</script>

<template>
  <table class="w-full table-auto">
    <thead>
      <tr>
        <th
          class="pl-4 py-3 pr-2 text-xxs font-normal text-left uppercase text-gray-600 border-gray-200 border-t | lg:px-6"
          >Item</th
        >
        <th
          class="px-6 py-3 text-xxs font-normal text-right uppercase text-gray-600 border-gray-200 border-t || hidden-col"
          >Price</th
        >
        <th
          class="py-3 text-xxs font-normal text-center uppercase text-gray-600 border-gray-200 border-t | lg:px-6"
          >Quantity</th
        >
        <th
          class="pl-2 py-3 pr-4 text-xxs font-normal text-right uppercase text-gray-600 border-gray-200 border-t | lg:px-6"
          >Subtotal</th
        >
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td class="pl-4 py-4 pr-2 align-top border-gray-200 border-t | lg:px-6">
          <CartPreview v-bind="item" :compact="true" />
        </td>
        <td class="px-6 py-4 align-top text-right border-gray-200 border-t || hidden-col">
          <CartLineItemPrices
            :price="item.unitPrice"
            :sales="item.unitSalePrices"
            :trade-account="tradeAccount"
          />
        </td>
        <td class="py-4 align-top text-center border-gray-200 border-t | lg:px-6">
          <span class="text-xs | xl:text-base">{{ item.qty }}</span>
        </td>
        <td class="pl-2 py-4 pr-4 align-top text-right border-gray-200 border-t | lg:px-6">
          <CartLineItemPrices
            :price="item.lineItemPrice"
            :sales="item.lineItemSalePrices"
            :trade-account="tradeAccount"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="postcss">
  .hidden-col {
    display: none;
  }

  @screen xl {
    .hidden-col {
      display: table-cell;
    }
  }
</style>
