<script>
  import LegacyProductThumb from '@/components/legacy/LegacyProductThumb'
  import LegacyArticleThumb from '@/components/legacy/LegacyArticleThumb'
  import LegacyLoadMoreButton from '@/components/legacy/LegacyLoadMoreButton'

  export default {
    name: 'LegacySearchResults',

    components: {
      LegacyProductThumb,
      LegacyArticleThumb,
      LegacyLoadMoreButton,
    },

    props: {
      results: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    data() {
      return {}
    },

    computed: {
      hasMoreProducts() {
        return (
          this.results.products.meta.pagination.current_page <
          this.results.products.meta.pagination.total_pages
        )
      },
      hasMoreArticles() {
        return (
          this.results.other.meta.pagination.current_page <
          this.results.other.meta.pagination.total_pages
        )
      },
    },

    methods: {
      closeSearch() {
        this.$emit('close-search')
      },

      resetSearch() {
        this.$emit('reset-search')
      },
    },
  }
</script>

<template>
  <div
    class="results"
    :class="{ columns: results.products.data.length > 0 && results.other.data.length > 0 }"
  >
    <section class="results__content">
      <h4 class="results__header">
        Search Results
        <span>{{ results.products.meta.pagination.total }}</span>
      </h4>

      <ul v-if="results.products.data.length > 0" class="results__grid">
        <li v-for="product in results.products.data" :key="product.id">
          <LegacyProductThumb :product="product"></LegacyProductThumb>
        </li>
      </ul>

      <div v-else class="results__none">
        <p>
          Your search returned no results for products. Try
          <a href="javascript:void(0)" @click.prevent="resetSearch">modifying your search</a>.
        </p>
      </div>

      <footer v-if="hasMoreProducts" class="results__footer">
        <LegacyLoadMoreButton type="load-more-product"></LegacyLoadMoreButton>
      </footer>
    </section>

    <section v-show="results.other.data.length > 0" class="results__content">
      <h4 class="results__header">
        Other Results
        <span>{{ results.other.meta.pagination.total }}</span>
      </h4>

      <ul class="results__list">
        <li v-for="article in results.other.data" :key="article.id">
          <LegacyArticleThumb :article="article"></LegacyArticleThumb>
        </li>
      </ul>

      <footer v-if="hasMoreArticles" class="results__footer">
        <LegacyLoadMoreButton type="load-more-other"></LegacyLoadMoreButton>
      </footer>
    </section>
  </div>
</template>

<style scoped>
  .results {
    max-width: 1440px;
    padding: 0 23px;
    margin: 30px auto;
  }

  .results__content {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  .results__header {
    padding-bottom: 23px;
    margin-bottom: 23px;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border-bottom: 1px solid #e5e7ef;

    span {
      display: inline-block;
      margin-left: 0.2em;
      font-size: 8px;
      vertical-align: super;
    }
  }

  .results__grid,
  .results__list {
    > * {
      margin: 50px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }

  .results__none {
    p {
      font-size: 15px;
      line-height: 27px;
      color: #6f6f75;
    }

    a {
      display: inline;
      line-height: 1.2;
      color: #0a0a0a;
      vertical-align: baseline;
      border-bottom: 1px solid #0a0a0a;
      transition: border 0.1s linear;

      &:hover {
        border-color: transparent;
      }
    }
  }

  .results__footer {
    margin-top: 40px;
  }

  @screen sm {
    .results {
      padding: 0 40px;
    }

    .results__content {
      margin-top: 40px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .results__grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px;

      > * {
        margin: 0;
      }
    }
  }

  @screen lg {
    .results.columns {
      display: grid;
      grid-template-columns: 66% 1fr;
      grid-gap: 50px;
    }

    .results__content {
      margin: 0 !important;
    }

    .results__footer {
      margin-top: 50px;
    }
  }

  @screen xl {
    .results__none {
      p {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
</style>
