import Vue from 'vue'
import Vuex from 'vuex'
import global from '@/modules/global'
import products from '@/modules/products'
import brands from '@/modules/brands'
import designers from '@/modules/designers'
import articles from '@/modules/articles'
import search from '@/modules/search'
import builder from '@/modules/builder'
import cart from '@/modules/cart'
import lists from '@/modules/lists'
import checkout from '@/modules/checkout'
import order from '@/modules/order'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    global,
    products,
    brands,
    designers,
    articles,
    search,
    builder,
    cart,
    lists,
    checkout,
    order,
  },
})

export default store
