<script>
  import { trimHtml, insertIntoLastElement } from '@/utilities/strings'

  /** The product text description component. Displays truncated summary HTML text when
   * the text is too long, and allows for toggling to the full text. */

  export default {
    name: 'ProductDescription',

    props: {
      /** The actual description */
      text: {
        type: String,
        required: false,
        default: '',
      },
      /** For the collection line */
      productTitle: {
        type: String,
        required: false,
        default: '',
      },
      /** The collection name this product belongs to */
      collectionName: {
        type: String,
        required: false,
        default: '',
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    computed: {
      /** Generates a URL or collections */
      collectionLink: function () {
        const noWhitespace = this.collectionName.replace(/\s/g, '+')
        return '/search/?collection=' + noWhitespace
      },
      /** The full text content + collection link */
      fullContent: function () {
        let addedContent = ''
        if (this.collectionName && this.productTitle) {
          addedContent = `<p class="mt-4">
            The ${this.productTitle} is part of the
            <a href="${this.collectionLink}">${this.collectionName} collection.</a>
          </p>`
        }
        return this.text + addedContent
      },
      /** The text content trimmed down for the summary */
      trimmedContent: function () {
        return trimHtml(this.fullContent, { limit: 400 })
      },
      /** The full HTML text with indicator toggle added inline */
      fullText: function () {
        if (this.hasMore) {
          const addedContent =
            '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> - </span>'
          return insertIntoLastElement(this.fullContent, '')
        } else {
          return this.fullContent
        }
      },
      /** The trimmed HTML text with indicator toggle added inline */
      trimmedText: function () {
        const addedContent =
          '<span class="relative inline-block ml-1 text-gray-500 | content-clamp-toggle"> + </span>'
        // return insertIntoLastElement(this.trimmedContent.html, addedContent)
        return insertIntoLastElement(this.trimmedContent.html, '')
      },
      /** Returns a boolean of true if there is more text than the trimmed summary */
      hasMore: function () {
        return this.trimmedContent.more
      },
      /** Aria label for toggle button */
      toggleLabel: function () {
        return this.isOpen ? 'Collapse text' : 'Expand text'
      },
    },

    mounted() {
      /** On mount prevent any links in the content areas from propagating a click */
      const contentLinks = this.$refs.content.querySelectorAll('a')
      for (let i = 0; i < contentLinks.length; i++) {
        contentLinks[i].addEventListener('click', (event) => {
          event.stopPropagation()
        })
      }
    },

    methods: {
      /** Toggles the summary and full text */
      toggleClamp() {
        this.isOpen = !this.isOpen
      },
    },
  }
</script>

<template>
  <div v-if="text">
    <!-- <button
      v-show="hasMore"
      ref="toggle"
      type="button"
      class="text-left text-black | focus:outline-none || content-clamp"
      :aria-label="toggleLabel"
      @click="toggleClamp"
    >
      <div v-show="!isOpen" ref="content" class="wysiwyg" v-html="trimmedText"></div>
      <div v-show="isOpen" ref="content" class="wysiwyg" v-html="fullText"></div>
    </button>
    <div v-show="!hasMore" class="text-left">
      <div class="wysiwyg font-unica77 tracking-02 font-normal" v-html="fullText"></div>
    </div> -->
    <div 
      class="read--more"
      :class="`${ isOpen ? 'no--layout' : '' } ${ hasMore ? '' : 'no--more--text' }`"
    >
      <div v-show="!isOpen" ref="content" class="wysiwyg font-unica77 tracking-02 font-normal" v-html="trimmedText"></div>
      <div v-show="isOpen" ref="content" class="wysiwyg font-unica77 tracking-02 font-normal" v-html="fullText"></div>
    </div>
    <span 
      v-if="hasMore"
      @click="toggleClamp" 
      class="cursor-pointer font-medium text-sm text-black mt-8 inline-block"
    >{{ isOpen ? '- Less' : '+ More' }}</span>
  </div>
</template>

<style scoped lang="postcss">
.read--more {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    }
    &.no--more--text {
      &::after {
        display: none;
      }
    }
    &.no--layout {
      &:after {
        display: none;
      }
    }
  }
  /* purgecss start ignore */
  .content-clamp {
    >>> .content-clamp-toggle {
      display: none;
    }
    &:hover,
    &:focus {
      >>> .content-clamp-toggle {
        @apply underline;
      }
    }
  }
  /* purgecss end ignore */
</style>
