<script>
  import { createNamespacedHelpers } from 'vuex'
  import { debounce } from 'lodash'
  import api from '@/api'
  export default {
    name: 'SetPassword',
    props: {
      code: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        form: {
          newPassword: '',
          confirmPassword: '',
        },
        errors: [],
        isSending: false,
        isSent: false,
        errorMsg: '',
        success: null,
      }
    },
    methods: {
      getErrors(property) {
        const errors = []
        if (!this.errors.length) {
          return errors
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message)
          }
        }
        return errors
      },

      validate(property) {
        if (!property) {
          this.errors = []
        } else {
          this.errors = this.errors.filter((item) => item.key !== property)
        }
        if ((!property || property === 'newPassword') && !this.form.newPassword) {
          this.errors.push({ key: 'newPassword', message: 'New Password field is required' })
        }
        if ((!property || property === 'confirmPassword') && !this.form.confirmPassword) {
          this.errors.push({
            key: 'confirmPassword',
            message: 'Password Confirm field is required',
          })
        }
      },

      debounceValidation: debounce(function (property) {
        this.validate(property)
      }, 500),
      submitForm() {
        this.validate()
        if (this.form.newPassword != this.form.confirmPassword) {
          this.errorMsg = 'Password and Confirm Password must be the same'
          return
        }
        if (this.errors.length === 0) {
          this.isSending = true
          const params = {
            newPassword: this.form.newPassword,
            code: this.code,
            id: this.id,
          }
          api.post('users/set-password', params).then((response) => {
            if (!response.data.success) {
              this.errorMsg = response.data.message
              this.isSending = false
              return
            }
            this.errorMsg = ''
            this.isSending = false
            this.isSent = true
            setTimeout(() => {
              window.location.href = '/'
            }, 2500)
          })
        }
      },
    },
  }
</script>

<template>
  <div class="mt-36 px-4 mx-auto setPasswordWrapper">
    <h1 class="block w-full text-2xl md:text-4xl mt-0 mb-12 text-center">Create Password</h1>
    <form @submit.prevent="submitForm">
      <input type="hidden" name="code" :value="code" />
      <input type="hidden" name="id" :value="id" />

      <div class="mb-4">
        <BaseInputText
          :type="'password'"
          v-model="form.newPassword"
          label="New Password"
          @input="debounceValidation('newPassword')"
        />
        <ul v-if="getErrors('newPassword').length">
          <BaseError
            v-for="(error, index) in getErrors('newPassword')"
            :key="index"
            :error="error"
          />
        </ul>
      </div>

      <div class="mb-8">
        <BaseInputText
          :type="'password'"
          v-model="form.confirmPassword"
          label="Confirm Password"
          @input="debounceValidation('confirmPassword')"
        />
        <ul v-if="getErrors('confirmPassword').length">
          <BaseError
            v-for="(error, index) in getErrors('confirmPassword')"
            :key="index"
            :error="error"
          />
        </ul>
      </div>

      <div class="mx-auto w-full flex justify-center items-start md:w-60">
        <button
          class="btn btn--black w-full text-center text-md"
          type="submit"
          data-cy="quote-form-submit"
          :disabled="isSending"
        >
          {{ isSending ? 'Sending' : 'Submit Password' }}
        </button>
      </div>
      <div v-if="errorMsg" class="w-full text-center my-2">
        <BaseError :error="errorMsg" />
      </div>
      <div v-if="isSent" class="w-full text-center mt-8">
        <p class="uppercase text-green-600 text-xs">Password Created Succesfully</p>
      </div>
    </form>
  </div>
</template>

<style lang="scss">
  .setPasswordWrapper {
    width: 100%;
    @media (min-width: 768px) {
      width: 500px;
    }
  }
</style>
