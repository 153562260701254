<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import api from '@/api'

  /*
   * This page allows basic users (non-trade) to create accounts
   */

  export default {
    name: 'RegisterCustomer',
    components: { ValidationProvider, ValidationObserver },
    data() {
      return {
        isSending: false,
        error: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      }
    },
    methods: {
      async handleFormSubmission() {
        this.isSending = true

        if ((await this.$refs.customerRegistrationForm.validate()) !== true) {
          this.isSending = false
          return
        }

        api
          .post('users/save-user', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            if ((res.status === 200 && res.data.errors) || res.status !== 200) {
              this.error = res.data.errors ?? res.data.error
            } else if (res.status !== 200) {
              this.error = 'Oops! Something went wrong. Please try again.'
            } else {
              localStorage.setItem('name', this.firstName)
              localStorage.setItem('message', 'Please check your email to confirm your account.')

              api
                .post('/klaviyoconnect/api/track', {
                  list: 'RRrbrf',
                  email: this.email,
                  useSubscribeEndpoint: true,
                  CRAFT_CSRF_TOKEN: res.data.csrfTokenValue,
                })
                .then((klaviyoRes) => {
                  if (klaviyoRes.status === 200) {
                    window.location.href = '/register/thanks'
                  } else if (res.status !== 200) {
                    this.error = 'Oops! Something went wrong. Please try again.'
                  }
                })
                .catch(() => {
                  this.error = 'Oops! Something went wrong. Please try again.'
                })
            }
            this.isSending = false
          })
          .catch(() => {
            this.error = 'Oops! Something went wrong. Please try again.'
          })
      },
    },
  }
</script>

<template>
  <div class="max-w-6xl container mx-auto mt-20 mb-32 px-4">
    <h1 class="text-2xl lg:text-4xl text-center mb-12 lg:mb-16"> Haute Living Account </h1>

    <div class="max-w-lg mx-auto">
      <h2 class="text-lg mb-8">Register</h2>

      <div v-if="error" class="mb-4">
        <BaseError v-if="error" :error="error" />
      </div>

      <ValidationObserver ref="customerRegistrationForm">
        <form
          id="register"
          method="post"
          accept-charset="UTF-8"
          @submit.prevent="handleFormSubmission"
        >
          <div class="mb-4">
            <ValidationProvider v-slot="{ errors }" name="first name" rules="required">
              <BaseInputText
                v-model="firstName"
                label="First Name"
                :value="firstName"
                type="text"
                name="firstName"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <ValidationProvider v-slot="{ errors }" name="last name" rules="required">
              <BaseInputText
                v-model="lastName"
                label="Last Name"
                :value="lastName"
                type="text"
                name="lastName"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
              <BaseInputText
                v-model="email"
                label="Email"
                :value="email"
                type="email"
                name="email"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <ValidationProvider v-slot="{ errors }" name="password" rules="required">
              <BaseInputText
                v-model="password"
                label="Password"
                :value="password"
                type="password"
                name="password"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div>
            <BaseButton capitalize color="yellows" full-width type="submit">Register</BaseButton>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
