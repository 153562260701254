<script>
  import BaseFilterButton from '@/components/BaseFilterButton.vue'
  import DownArrow from '@/images/icons/menu-arrow.svg'

  /** Reusable select component meant to be used in filter bars. Emits a 'change' event when the selected option changes */

  export default {
    name: 'BaseFilterSelect',

    components: {
      DownArrow,
      BaseFilterButton,
    },

    inheritAttrs: false,

    props: {
      /** The select's label */
      label: {
        type: String,
        required: true,
      },
      /** An array of option objects. Each option must have two properties: `label` and `value` */
      options: {
        type: Array,
        required: true,
      },
      /** The select's value */
      value: {
        type: String,
        required: true,
      },
      /** Whether to display a gray border around the select */
      border: {
        type: Boolean,
        default: false,
      },
      /** Whether the select is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      selectedOption() {
        return this.options.find((option) => option.value === this.value)
      },
      selectedLabel() {
        const selectedOption = this.selectedOption
        if (!selectedOption) {
          return 'Select one'
        }
        return selectedOption.label
      },
      longestLabel() {
        const longestOption = this.options.reduce((a, b) => (a.length > b.length ? a : b))
        if (!longestOption) {
          return 'Select one'
        }
        return longestOption.label
      },
    },

    methods: {
      /** When the selected option changes, this method emits its value */
      handleChange(event) {
        this.$emit('change', event.target.value)
      },
    },
  }
</script>

<template>
  <BaseFilterButton tag="label" :label="label" :border="border" :disabled="disabled" class="filterRightSelect">
    <template v-slot:before>
      <select
        class="z-10 absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
        v-bind="$attrs"
        :disabled="disabled"
        @change="handleChange"
      >
        <template v-for="option in options">
          <option
            v-if="option.show === undefined || option.show === true"
            :key="option.value"
            :value="option.value"
            :selected="option.value === value"
            class="text-lg"
          >
            {{ option.label }}
          </option>
        </template>
      </select>
    </template>
    <template v-slot:after>
      <!-- Weird trick to make sure the content's width remains the same regardless of the selected option, to prevent reflow -->
      <span class="relative flex-1 text-left text-gray-600 font-unica77">
        <span class="w-full pl-3 truncate absolute left-0 top-0 text-black text-sm font-medium tracking-02">{{ selectedLabel }}</span>
        <span class="w-full pl-2 truncate invisible text-black text-sm font-medium tracking-02">{{ longestLabel }}</span>
        <DownArrow class="absolute right-0 top-5px"/>
      </span>
    </template>
  </BaseFilterButton>
</template>

<style scoped lang="postcss">
  .filterRightSelect {
    padding-right: 0;
    margin-right:5px;
    width:210px;
    margin-top: 0;
    @media(max-width: 1024px) {
      float: right;
    }
  }
</style>


