<script>
  import BasePillLink from '@/components/BasePillLink'

  /** Button sub-block used in the Text Column block content area.
   * Acts as a wrapper element for the BasePillLink component */

  export default {
    name: 'BuilderTextBlockButton',

    components: {
      BasePillLink,
    },

    props: {
      /** The URL of the button link */
      url: {
        type: String,
        required: true,
      },
      /** The link target */
      target: {
        type: String,
        required: false,
        default: '',
        validator: (propValue) => {
          const validTypes = ['', '_blank']
          return validTypes.indexOf(propValue) > -1
        },
      },
      /** The text label of the button */
      text: {
        type: String,
        required: true,
      },
    },
  }
</script>

<template>
  <BasePillLink
    :url="url"
    :target="target"
    :text="text"
    :tx-color="'black'"
    :bg-color="'#ffffff'"
    :br-color="'#666666'"
  />
</template>
