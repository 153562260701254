<script>
  import BuilderPage from '@/components/views/BuilderPage'

  export default {
    name: 'GenericPage',
    components: {
      BuilderPage,
    },
    props: {
      headerImage: {
        type: Object,
        default: () => {},
      },
      entry: {
        type: Object,
        default: () => {},
      },
      field: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        isMobile: true,
      }
    },
    created() {},
    mounted() {
      window.addEventListener('resize', this.handleResize)
      this.handleResize()
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    methods: {
      handleResize() {
        this.isMobile = window.innerWidth < 768
      },
    },
  }
</script>

<template>
  <article class="generic-page">
    <header v-if="headerImage.mobile || headerImage.desktop">
      <BaseImage
        :source="isMobile ? headerImage.mobile : headerImage.desktop"
        :alt="headerImage.alt"
        img-class="header-image"
        position="full"
        :attribution="headerImage.attribution"
        :attribution-color="headerImage.attributionColor"
      />
    </header>
    <div
      class="page-content mx-auto flex flex-col lg:flex-row py-8 md:py-19 px-0 lg:px-8 overflow-x-hidden"
      style="max-width: 1400px"
    >
      <aside class="w-full lg:w-2/5 px-4 lg:px-8">
        <h1 class="mb-4 font-bold font-cormorant">
          {{ entry.title }}
        </h1>
      </aside>
      <div class="w-full lg:w-3/5">
        <BuilderPage :entry-id="entry.id" :field-id="field.id" />
      </div>
    </div>
  </article>
</template>

<style lang="scss">
  h1 {
    font-size: 40px;
    line-height: 120%;
    @media(max-width:768px) {
      font-size: 36px;
    }
  }
</style>
