<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseInputQuantity from '@/components/BaseInputQuantity'
  import CartPreview from '@/components/cart/CartPreview'
  import CartRemoveButton from '@/components/cart/CartRemoveButton'
  import CartLineItemPrices from '@/components/cart/CartLineItemPrices'

  /** A compact version of the cart line items (used in checkout sidebar) */

  const { mapGetters } = createNamespacedHelpers('cart')
  const { mapState } = createNamespacedHelpers('products')

  export default {
    name: 'CartLineItemsCompact',

    components: {
      BaseInputQuantity,
      CartLineItemPrices,
      CartPreview,
      CartRemoveButton,
    },

    props: {
      /** Whether or not the item quantities should be editable */
      editable: {
        type: Boolean,
        required: false,
      },
    },

    data() {
      return {
        tradeAccount: false,
      }
    },

    watch: {
      getItems() {
        console.log(this.getItems)
      },
    },

    computed: {
      /** Vuex getters */
      ...mapGetters(['getIsLoading', 'getItems']),
      ...mapState(['salePrice']),
    },

    created() {
      // See if the user is logged in as a trade account
      this.tradeAccount = this.$store.getters['global/isTradeAccount']
    },

    methods: {
      /** Change the line item qty */
      changeLineItemQty(event) {
        this.$store.dispatch('cart/changeItemQty', { id: event.id, qty: event.count })
      },
      /** Remove the line item */
      removeLineItem(event) {
        this.$store.dispatch('cart/changeItemQty', { id: event, qty: 0 })
      },
    },
  }
</script>

<template>
  <table class="w-full table-auto">
    <thead>
      <tr>
        <th
          class="pl-4 py-3 pr-2 text-xxs font-normal text-left uppercase text-gray-600 border-gray-200 border-t | lg:pl-6"
          >Item</th
        >
        <th
          class="py-3 text-xxs font-normal text-center uppercase text-gray-600 border-gray-200 border-t"
          >Quantity</th
        >
        <th
          class="pl-2 py-3 pr-4 text-xxs font-normal text-right uppercase text-gray-600 border-gray-200 border-t | lg:pr-6"
          >Subtotal</th
        >
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in getItems" :key="index">
        <td class="pl-4 py-4 pr-1 align-top border-gray-200 border-t">
          <CartPreview v-bind="item" :compact="true" />
        </td>
        <td class="py-4 align-top text-center border-gray-200 border-t">
          <div v-if="editable" class="flex flex-col justify-start items-center">
            <BaseInputQuantity
              :id="item.id"
              :value="item.qty"
              :min="0"
              :max="99"
              :disabled="getIsLoading"
              @increment="changeLineItemQty"
              @decrement="changeLineItemQty"
            />
            <CartRemoveButton :id="item.id" @removeItem="removeLineItem" />
          </div>
          <span v-else class="text-xs">{{ item.qty }}</span>
        </td>
        <td class="pl-1 py-4 pr-4 align-top text-right border-gray-200 border-t | lg:pr-6">
          <CartLineItemPrices
            :price="item.lineItemPrice"
            :sales="
              tradeAccount
                ? [
                    item.lineItemSalePrices
                      .filter((sale) => sale.isTrade)
                      .reduce((prev, current) => (prev.price > current.price ? prev : current)),
                  ]
                : []
            "
            :trade-account="tradeAccount"
            :compact="true"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
