<script>
  /*
   * Pre-footer component. Renders a responsive grid of service cards displayed above the footer on every page.
   */
  import VLazyImage from 'v-lazy-image'
  import BaseButton from '@/components/BaseButton'
  import ChevronRight from '../../images/icons/chevron-right.svg'

  export default {
    name: 'GlobalPreFooter',
    components: {
      ChevronRight,
      VLazyImage,
      BaseButton,
    },
    props: {
      services: {
        type: Array,
        required: true,
      },
    },
  }
</script>

<template>
  <div class="z-10 relative grid grid-cols-2 bg-white">
    <article
      v-for="service in services"
      :key="service.id"
      class="border-b border-r border-gray-300"
    >
      <div class="img-wrapper flex items-center overflow-hidden">
        <div class="w-full">
          <VLazyImage
            :srcset="service.desktopImage.url + ' 1x,'"
            :src="service.desktopImage.url"
            :alt="service.desktopImage.alt"
            use-picture
          >
            <source media="(max-width: 767px)" :srcset="service.mobileImage.url + ' 1x'" />

            <source media="(min-width: 768px)" :srcset="service.desktopImage.url + ' 1x'" />
          </VLazyImage>
        </div>
      </div>

      <div class="px-4 py-10">
        <h2 class="text-xl mb-4">{{ service.title }}</h2>

        <p class="mb-6 md:w-8/12">{{ service.teaser }}</p>

        <div class="hidden md:block">
          <BaseButton :url="service.cta.link" rounded capitalize>
            <span class="flex items-center">
              {{ service.cta.text }} <ChevronRight class="ml-3 w-2" />
            </span>
          </BaseButton>
        </div>

        <a class="flex md:hidden items-center hover:underline" :href="service.cta.link">
          {{ service.cta.text }} <ChevronRight class="ml-3 w-2" />
        </a>
      </div>
    </article>
  </div>
</template>

<style lang="postcss" scoped>
  @screen md {
    .img-wrapper {
      height: 200px;
    }
  }
</style>
