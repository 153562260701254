<script>
  import { createNamespacedHelpers } from 'vuex'
  import { eventBus } from '@/main.js'
  import CheckoutLink from '@/components/checkout/CheckoutLink'
  import CheckoutStepContact from '@/components/checkout/CheckoutStepContact'
  import CheckoutStepShipping from '@/components/checkout/CheckoutStepShipping'
  import CheckoutStepPayment from '@/components/checkout/CheckoutStepPayment'
  import CartLineItemsCompact from '@/components/cart/CartLineItemsCompact'
  import CartTotals from '@/components/cart/CartTotals'
  import IconCheck from '@/images/icons/check.svg'
  import IconSpinner from '@/images/icons/spinner-2.svg'

  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart')

  const {
    mapGetters: mapCheckoutGetters,
    mapMutations: mapCheckoutMutations,
  } = createNamespacedHelpers('checkout')

  /**
   * The main Checkout page component
   */
  export default {
    name: 'CheckoutPage',

    components: {
      CartLineItemsCompact,
      CartTotals,
      CheckoutLink,
      CheckoutStepContact,
      CheckoutStepShipping,
      CheckoutStepPayment,
      IconCheck,
      IconSpinner,
    },

    props: {
      /** An array of state objects, each state with an `id` and a `name` */
      states: {
        type: Array,
        required: true,
      },
      /** An object containing the confirmation checkbox field properties 'label, required, checked' */
      confirmationCheckbox: {
        /** When type is array form has not been created */
        type: [Object, Array],
        required: true,
      },
      /** Does logged in user belong to the trade accounts group */
      tradeAccountUser: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        isShippingValid: false,
      }
    },
    computed: {
      ...mapGlobalGetters(['loggedIn']),
      ...mapCheckoutGetters([
        'getAllSteps',
        'getCurrentStep',
        'getIsShippingValid',
        'getIsPaymentValid',
        'getShippingIsSubmitting',
        'getPaymentIsSubmitting',
        'getProcessingPayment',
        'getOrderComplete',
        'getCardComplete',
        'getBillingSameAsShipping',
        'getConfirmTradeUserCheckbox',
      ]),
      ...mapCartGetters(['getItems']),
      billingValid() {
        return this.getIsPaymentValid || this.getBillingSameAsShipping
      },
      steps() {
        return this.getAllSteps.filter(
          (step) =>
            ['contact', 'shipping', 'payment'].includes(step.value) &&
            !(step.value === 'contact' && this.loggedIn)
        )
      },
      tradeCheckboxConfirmed() {
        /** Checks if confirmTradeUserCheckbox is valid based on its custom field requirements */
        let confirmed = false
        if (this.getConfirmTradeUserCheckbox.required && this.getConfirmTradeUserCheckbox.checked) {
          confirmed = true
        }
        if (
          !this.getConfirmTradeUserCheckbox.required &&
          (this.getConfirmTradeUserCheckbox.checked || !this.getConfirmTradeUserCheckbox.checked)
        ) {
          confirmed = true
        }
        return confirmed
      },
    },

    watch: {
      getItems() {
        if (this.getItems.length === 0) {
          window.location.href = '/shop/cart'
        }
      },
    },

    created() {
      // Set the checkout current step initial value
      this.$store.commit('checkout/setStates', this.states)

      // Set the confirmation checkout checkbox field initial value
      if (!Array.isArray(this.confirmationCheckbox) && this.tradeAccountUser) {
        this.$store.commit('checkout/setConfirmTradeUserCheckbox', this.confirmationCheckbox)
      } else {
        this.setConfirmTradeUserCheckbox({
          checked: false,
          required: false,
          label: '',
        })
      }

      // Load the user’s cart
      this.$store.dispatch('cart/loadCart').then(() => {
        if (this.getItems.length === 0) {
          window.location.href = '/shop/cart'
        }
      })

      // Load the checkout
      this.$store.dispatch('checkout/loadCheckout')
    },

    methods: {
      ...mapCheckoutMutations(['setCurrentStep', 'setConfirmTradeUserCheckbox']),
      stepIsClickable(step) {
        // Disable the contact step if the user is logged in
        if (step.value === 'contact' && this.loggedIn) {
          return false
        }
        for (const thisStep of this.getAllSteps) {
          // If the step we're checking for is the current step or after it, it's not clickable
          if (thisStep.value === this.getCurrentStep.value) {
            return false
          }
          // If the step we're checking for is before the current step, it's clickable
          if (thisStep.value === step.value) {
            return true
          }
        }
      },
      clickOnStep(step) {
        if (!this.stepIsClickable(step)) {
          return
        }
        this.setCurrentStep(step.value)
      },
      submitShipping() {
        eventBus.$emit('submit-shipping')
      },
      submitPaymentDetails() {
        if (this.tradeCheckboxConfirmed) {
          // must be required and checked
          eventBus.$emit('submit-payment-details')
        }
      },
    },
  }
</script>

<template>
  <CheckoutLayout>
    <template v-slot:header>
      <nav class="grid grid-cols-3 gap-3 md:gap-6 items-end pb-4">
        <CheckoutLink
          v-for="step in steps"
          :key="step.value"
          :active="step.value === getCurrentStep.value"
          :clickable="stepIsClickable(step)"
          @click="clickOnStep(step)"
        >
          {{ step.label }}
        </CheckoutLink>
      </nav>
    </template>
    <template v-slot:left>
      <div class="py-7 px-3 lg:px-6">
        <keep-alive>
          <CheckoutStepContact v-if="getCurrentStep.value === 'contact'" />
          <CheckoutStepShipping v-if="getCurrentStep.value === 'shipping'" />
          <CheckoutStepPayment
            v-if="getCurrentStep.value === 'payment'"
            :confirmation-checkbox="getConfirmTradeUserCheckbox"
          />
        </keep-alive>
      </div>
    </template>
    <template v-slot:right>
      <div class="flex flex-col justify-between w-full h-full top-0 bottom-0">
        <div class="overflow-y-scroll">
          <h2 class="px-4 py-5 text-md font-bold border-gray-200 border-t | lg:px-6 lg:border-t-0">
            Order Summary
          </h2>
          <div class="overflow-y-scroll">
            <CartLineItemsCompact :editable="true" />
          </div>
        </div>
        <div>
          <CartTotals class="pb-17 lg:pb-5" :total-label="'Subtotal'" :compact="false" />
          <BaseButton
            v-if="getCurrentStep.value === 'shipping'"
            :color="'black'"
            :full-width="true"
            class="fixed lg:relative left-0 bottom-0 right-0"
            :disabled="!getIsShippingValid"
            :loading="getShippingIsSubmitting"
            @click.native="submitShipping"
          >
            {{ getShippingIsSubmitting ? 'Processing...' : 'Continue' }}
          </BaseButton>
          <BaseButton
            v-if="getCurrentStep.value === 'payment'"
            color="black"
            type="submit"
            :full-width="true"
            class="fixed lg:relative left-0 bottom-0 right-0"
            :loading="getPaymentIsSubmitting"
            :disabled="
              !billingValid ||
              !getCardComplete ||
              getPaymentIsSubmitting ||
              getOrderComplete ||
              !tradeCheckboxConfirmed
            "
            @click.native="submitPaymentDetails"
          >
            <span v-if="getOrderComplete" class="flex items-center">
              <span>Completing Order</span>
              <IconSpinner class="inline-block ml-4 fill-current || spinner" />
            </span>
            <template v-else-if="getPaymentIsSubmitting">
              <span
                v-if="getProcessingPayment || getBillingSameAsShipping"
                class="flex items-center"
              >
                <span>Processing</span>
                <IconSpinner class="inline-block ml-4 fill-current || spinner" />
              </span>
              <span v-else class="flex items-center">
                <span>Saving Address</span>
                <IconSpinner class="inline-block ml-4 fill-current || spinner" />
              </span>
            </template>
            <span v-else class="flex items-center">
              <span>Complete Checkout</span>
              <IconCheck
                v-if="getCardComplete"
                class="inline-block ml-4 -mt-px fill-current || check"
              />
            </span>
          </BaseButton>
        </div>
      </div>
    </template>
  </CheckoutLayout>
</template>

<style scoped lang="postcss">
  .check,
  >>> .check {
    width: 10px;
    height: 10px;
  }

  >>> .label {
    @apply mb-0;
  }

  >>> .text-input {
    @apply mt-2 font-light text-base;
  }
</style>
