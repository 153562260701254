<script>
  /** Footer component to render rich text */

  export default {
    name: 'GlobalFooterRichText',

    props: {
      heading: {
        type: String,
        required: true,
      },
      content: {
        type: String,
        required: true,
      },
    },
  }
</script>

<template>
  <div class="">
    <h6 class="text-left text-base text-gray-500 font-normal">
      {{ heading }}
    </h6>
    <div class="rich-text-items mt-1 lg:mt-5 font-light text-white" v-html="content"> </div>
  </div>
</template>

<style lang="postcss" scoped>
  .rich-text-items {
    >>> ul {
      li {
        @apply mb-2;

        &:marker {
          display: none;
        }

        a {
          &:hover {
            @apply text-gray-500;
          }
        }
      }
    }
  }
</style>
