<!--See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea for all attributes-->
<script>
  /** A reusable base textarea input. */

  export default {
    name: 'BaseTextarea',

    props: {
      /** The input's ID */
      id: {
        type: String,
        required: true,
        default: 'blah',
      },
      /** A label for the input */
      label: {
        type: String,
        required: true,
      },
      /** Is the input required? */
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** Placeholder text for the input */
      placeholder: {
        type: String,
        required: false,
        default: null,
      },
      /** Number of cols (width) */
      cols: {
        type: Number,
        required: false,
        default: 30,
      },
      /** Number of rows (height) */
      rows: {
        type: Number,
        required: false,
        default: 5,
      },
    },
  }
</script>

<template>
  <div>
    <label class="label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :required="required"
      :placeholder="placeholder"
      :cols="cols"
      :rows="rows"
      class="text-area"
      @input="$emit('input', $event.target.value)"
    >
    </textarea>
  </div>
</template>

<style>
  textarea {
    border: 1px solid black;
  }
</style>
