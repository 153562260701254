// Polyfills
// http://cli.vuejs.org/guide/browser-compatibility.html
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import setUpVue from '@/setUpVue'
import '@/utilities/validation'
import '@/app.css'

// eslint-disable-next-line no-unused-vars
import Components from '@/components'

// eslint-disable-next-line no-unused-vars
import Legacy from '@/components/legacy/footer'

// Data
import store from '@/store'

setUpVue()

export const eventBus = new Vue()

const rootElement = document.getElementById('app')

// eslint-disable-next-line no-new
new Vue({
  el: rootElement,
  delimiters: ['${', '}'],
  store,
  props: {
    globalData: {
      type: Object,
      required: true,
    },
  },
  propsData: {
    globalData: JSON.parse(rootElement.dataset.global),
  },
  created() {
    rootElement.classList.add('is-loaded')
    this.$store.commit('global/setData', this.globalData)
    window.addEventListener('resize', this.handleResize)
  },
  mounted() {
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      const footerheight = document.querySelector('.global-footer')
        ? document.querySelector('.global-footer').offsetHeight
        : 0
      const noticeheight = document.getElementById('notice')
        ? document.getElementById('notice').offsetHeight
        : 0
      const appWrapper = document.querySelector('.app')

      if (appWrapper) {
        if (window.innerWidth > 768) {
          appWrapper.style.marginBottom = footerheight + noticeheight + 'px'
        } else {
          appWrapper.style.marginBottom = '0px'
        }
      }
    },
  },
})
