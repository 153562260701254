<script>
  import { createNamespacedHelpers } from 'vuex'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import api from '@/api'

  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')
  const {
    mapGetters: mapCheckoutGetters,
    mapActions: mapCheckoutActions,
    mapMutations: mapCheckoutMutations,
  } = createNamespacedHelpers('checkout')

  /**
   * Contact email address step of the checkout flow
   */
  export default {
    name: 'CheckoutStepContact',

    components: {
      ValidationObserver,
      ValidationProvider,
    },

    data() {
      return {
        email: '',
        submitting: false,
      }
    },

    computed: {
      ...mapGlobalGetters(['loggedIn']),
      ...mapCheckoutGetters(['getEmail']),
    },

    watch: {
      loggedIn: {
        immediate: true,
        handler() {
          if (this.loggedIn) {
            this.setCurrentStep('shipping')
          }
        },
      },
      getEmail: {
        immediate: true,
        handler() {
          this.email = this.getEmail
        },
      },
    },

    methods: {
      ...mapCheckoutActions(['saveEmail']),
      ...mapCheckoutMutations(['setCurrentStep']),
      async submit() {
        if (this.submitting) {
          return
        }
        if ((await this.$refs.validationObserver.validate()) !== true) {
          return
        }
        this.submitting = true
        this.saveEmail(this.email).then(() => {
          this.submitting = false
          this.setCurrentStep('shipping')

          // Syncs the 'Started Checkout' event with the user profile in Klaviyo
          api.startedCheckout(this.email)
        })
      },
    },
  }
</script>

<template>
  <div class="lg:w-1/2">
    <h1 class="text-base font-normal">Please enter your email</h1>
    <p class="text-base font-light my-5">
      We’ll use it to send purchase and tracking information, or sign you in if you already have an
      account.
    </p>
    <ValidationObserver ref="validationObserver" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <ValidationProvider v-slot="{ errors }" name="Email Address" rules="required|email">
          <BaseInputText
            v-model="email"
            label="Email Address"
            :label-is-hidden="true"
            placeholder="Your Email Address"
            type="email"
          />
          <BaseError v-if="errors.length" :error="errors[0]" />
        </ValidationProvider>
        <div class="mt-2">
          <BaseButton color="black" type="submit" :disabled="invalid" :loading="submitting">
            Continue
          </BaseButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
