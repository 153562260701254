<script>
  export default {
    name: 'LegacyMobileNav',

    props: {
      productNav: {
        type: Array,
        required: false,
        default: () => [],
      },
      pageNav: {
        type: Array,
        required: false,
        default: () => [],
      },
      navOpen: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        openChild: null,
        drawerNav: [],
      }
    },

    mounted() {
      this.drawerNav = this.productNav
    },

    methods: {
      closeNav() {
        this.$emit('close-nav')
      },

      openChildNav(parent) {
        this.openChild = parent
      },

      closeChildNav() {
        this.openChild = null
      },
    },
  }
</script>

<template>
  <div>
    <nav class="mobile-nav" :class="{ 'is-open': navOpen }">
      <header class="mobile-nav__header">
        <div v-if="openChild" class="mobile-nav__header-child">
          <h3 class="mobile-nav__header-label">{{
            openChild.label ? openChild.label : 'Products'
          }}</h3>
          <a class="mobile-nav__back" href="javascript:void(0)" @click.prevent="closeChildNav">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="24.2px"
              height="12.5px"
              viewBox="0 0 24.2 12.5"
              style="enable-background: new 0 0 24.2 12.5"
              xml:space="preserve"
            >
              <polygon
                style="fill: #fff"
                points="24.2,5.2 8.2,5.2 8.2,0 0,6.3 8.2,12.5 8.2,7.2 24.2,7.2 "
              ></polygon>
            </svg>
          </a>
        </div>

        <div v-else class="mobile-nav__header-parent">
          <h3 class="mobile-nav__header-label">Navigation</h3>
        </div>

        <a class="mobile-nav__close" href="javascript:void(0)" @click.prevent="closeNav"
          ><span>Close Nav</span></a
        >
      </header>

      <div class="mobile-nav__main">
        <ul class="mobile-nav__menu">
          <li v-for="parent in drawerNav" :key="parent.id">
            <a
              v-if="parent.children.length"
              class="has-children"
              href="javascript:void(0)"
              @click.prevent="openChildNav(parent)"
            >
              {{ parent.label }}
              <span class="mobile-nav__icon--menu">
                <slot name="icon-nav"></slot>
              </span>
            </a>
            <a v-else :href="parent.url">
              {{ parent.label }}
              <span class="mobile-nav__icon--arrow">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="23.3px"
                  height="9.6px"
                  viewBox="0 0 23.3 9.6"
                  style="enable-background: new 0 0 23.3 9.6"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill: #231f20"
                    points="23.3,4.8 18.5,0 18.5,4 0,4 0,5.6 18.5,5.6 18.5,9.6 "
                  ></polygon>
                </svg>
              </span>
            </a>
            <ul v-if="parent.children.length" :class="{ 'is-open': parent === openChild }">
              <li><a :href="parent.url">View All</a></li>
              <li v-for="child in parent.children" :key="child.id">
                <a
                  :href="child.url"
                  :class="{ 'is-current': child.current, sale: child.highlighted }"
                  >{{ child.label }}</a
                >
              </li>
            </ul>
          </li>
          <li v-for="page in pageNav" :key="page.id" class="mobile-nav__mobile-only">
            <a :href="page.url">
              {{ page.label }}
              <span class="mobile-nav__icon--arrow">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="23.3px"
                  height="9.6px"
                  viewBox="0 0 23.3 9.6"
                  style="enable-background: new 0 0 23.3 9.6"
                  xml:space="preserve"
                >
                  <polygon
                    style="fill: #231f20"
                    points="23.3,4.8 18.5,0 18.5,4 0,4 0,5.6 18.5,5.6 18.5,9.6 "
                  ></polygon>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="nav-overlay" :class="{ 'is-open': navOpen }" @click="closeNav"></div>
  </div>
</template>

<style lang="scss" scoped>
  .mobile-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    width: 90vw;
    background-color: blue;
    transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translate3d(100%, 0, 0);

    &.is-open {
      transform: translate3d(0, 0, 0);
    }
  }

  .mobile-nav__header {
    position: relative;
    color: #fff;
    background-color: #0a0a0a;

    a {
      color: #fff;
    }
  }

  .mobile-nav__close {
    position: absolute;
    top: 50%;
    right: 23px;
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-top: -12px;

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      right: 2px;
      display: block;
      width: 21px;
      height: 2px;
      content: '';
      background-color: #fff;
    }

    &::before {
      transform: translateY(-50%) rotate(-45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(45deg);
    }

    span {
      display: none;
    }
  }

  .mobile-nav__back {
    position: absolute;
    top: 50%;
    left: 23px;
    display: inline-block;
    margin-top: -6px;
  }

  .mobile-nav__header-label {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .mobile-nav__header-parent,
  .mobile-nav__header-child {
    position: relative;
    height: 65px;
  }

  .mobile-nav__header-parent {
    .mobile-nav__header-label {
      left: 23px;
    }
  }

  .mobile-nav__header-child {
    .mobile-nav__header-label {
      right: 23px;
      left: 23px;
      text-align: center;
    }
  }

  .mobile-nav__main {
    height: 100%;
    padding: 12px 0;
    background-color: #fff;
  }

  .mobile-nav__menu {
    position: relative;
    display: block;

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 23px;
      font-size: 16px;
      line-height: 28.8px;

      .mobile-nav__icon--menu svg {
        width: 18.8px;
        height: 13.8px;
      }

      .mobile-nav__icon--arrow {
        opacity: 0;
      }
    }

    ul {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate3d(100%, 0, 0);

      &.is-open {
        transform: translate3d(0, 0, 0);
      }
    }
  }

  .nav-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -100;
    pointer-events: none;
    background-color: rgba(10, 10, 10, 0.4);
    opacity: 0;
    transition: opacity 0.25s linear;

    &.is-open {
      z-index: 50;
      pointer-events: auto;
      opacity: 1;
    }
  }

  @media screen and (min-width: 480px) {
    .mobile-nav {
      width: 420px;
    }
  }

  @screen sm {
    .mobile-nav__close {
      right: 40px;
    }

    .mobile-nav__back {
      left: 40px;
    }

    .mobile-nav__header-label {
      font-size: 15px;
    }

    .mobile-nav__header-parent,
    .mobile-nav__header-child {
      height: 100px;
    }

    .mobile-nav__header-parent {
      .mobile-nav__header-label {
        left: 40px;
      }
    }

    .mobile-nav__header-child {
      .mobile-nav__header-label {
        right: 40px;
        left: 40px;
      }
    }

    .mobile-nav__menu a {
      padding: 12px 40px;
      font-size: 18px;
      line-height: 32.4px;
    }
  }

  @media screen and (min-width: 900px) {
    .mobile-nav__menu a {
      .mobile-nav__icon--menu,
      .mobile-nav__icon--arrow {
        opacity: 0;
        transition: opacity 0.2s;
      }

      &:hover {
        .mobile-nav__icon--menu,
        .mobile-nav__icon--arrow {
          opacity: 1;
        }
      }
    }
  }
</style>
