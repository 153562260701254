<script>
  import IconExternalLink from '@/images/icons/external-link.svg'

  /*
   * Renders the account dashboard for logged in users
   */

  export default {
    name: 'AccountDashboardLayout',
    components: { IconExternalLink },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<template>
  <section class="wrapper">
    <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
      <div class="border-gray-200 border-b py-6">
        <h2 class="font-bold text-lg uppercase">Account Dashboard</h2>
      </div>
      <div class="grid lg:grid-cols-4">
        <aside class="col-span-1 py-6 pr-4">
          <ul class="info-list">
            <h3 class="mb-4 font-bold">Your Information</h3>

            <li>
              <label>Name</label>
              <p>{{ user.firstName }} {{ user.lastName }}</p>
            </li>

            <li>
              <label>Email</label>
              <p>{{ user.email }}</p>
            </li>

            <!-- <li>
              <p>
                <a href="/account/saved-products" class="underline"> Saved Products </a>
              </p>
            </li> -->

            <li> <a :href="user.passwordResetUrl" class="underline">Reset Password</a></li>

            <template v-if="user.isTradeAccount">
              <h3 class="border-t border-gray-200 pt-4 mb-4 font-bold">Trade Account Information</h3>

              <li v-if="user.tradeAccount.address">
                <label>Address</label>
                <address class="not-italic">
                  <p>{{ user.tradeAccount.address }}</p>
                  <p> {{ user.tradeAccount.city }}, {{ user.tradeAccount.state }} </p>
                  <p>{{ user.tradeAccount.zip }}</p>
                </address>
              </li>

              <li v-if="user.tradeAccount.website">
                <label>Website</label>
                <p class="flex items-center">
                  <a :href="user.tradeAccount.website">
                    {{ user.tradeAccount.website }}
                  </a>
                  <span class="text-gray-500 -mt-1 ml-2">
                    <IconExternalLink />
                  </span>
                </p>
              </li>

              <li v-if="user.tradeAccount.commercialFocus">
                <label>Commercial Focus</label>
                <p>{{ user.tradeAccount.commercialFocus }} </p>
              </li>
            </template>
          </ul>

          <p class="my-6">
            To change your account information, please
            <a class="underline" href="mailto:info@hauteliving.com">contact us.</a>
          </p>

          <a href="/logout" class="btn btn--black mt-6 lg:mt-10">Logout</a>
        </aside>

        <div class="col-span-3 lg:border-gray-200 lg:border-l py-6 pl-0 md:pl-4">
          <slot name="right" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss" scoped>
  .wrapper {
    min-height: calc(100vh - 72px);
  }

  .info-list {
    & li {
      @apply mb-4;

      & label {
        @apply opacity-50 text-sm uppercase;
      }
    }
  }
</style>
