<script>
  /*
   * A list of logos of accepted payment options
   */
  import AmericanExpress from '@/images/icons/american-express.svg'
  import ApplePay from '@/images/icons/apple-pay.svg'
  import GooglePay from '@/images/icons/google-pay.svg'
  import Paypal from '@/images/icons/paypal.svg'
  import Mastercard from '@/images/icons/mastercard.svg'
  import Visa from '@/images/icons/visa.svg'

  export default {
    name: 'GlobalAcceptedPayments',
    components: {
      AmericanExpress,
      ApplePay,
      GooglePay,
      Paypal,
      Mastercard,
      Visa,
    },
  }
</script>

<template>
  <div class="grid grid-cols-6">
    <Visa />
    <Mastercard />
    <AmericanExpress />
    <Paypal />
    <ApplePay />
    <GooglePay />
  </div>
</template>
