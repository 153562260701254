<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import api from '@/api'
  import BaseInputText from '../BaseInputText'
  /*
   * This page allows for user to create trade accounts. Trade accounts must be reviewed by HL admin and then the craft user is created manually.
   */

  export default {
    name: 'RegisterTrade',
    components: { BaseInputText, ValidationProvider, ValidationObserver },
    props: {
      formObj: {
        type: Object,
        required: true,
      },
      formFields: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        error: '',
        stateDisabled: false,
        isSending: false,
        isSent: false,
        errorMsg: '',
        success: null,
        submitCount: 0,
        checkedOptions: [],
        form: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirm: '',
          businessName: '',
          businessPhone: '',
          businessAddress: '',
          city: '',
          state: '',
          zipCode: '',
          country: '',
          province: '',
          businessWebsite: '',
          proofOfBusiness: null,
          typeOfBusiness: '',
          honeypot: '',
          groupList: this.formFields.groupList.options[0].value,
          tradeProfessionalType: false,
        },
        fileName: '',
        isVisible: false,
        validateError: '',
        showAdditionalOptions: false,
      }
    },
    computed: {
      country() {
        return this.form.country
      },
      countryOptions() {
        return this.formFields.country.options.map((option) => ({
          id: option.value,
          label: option.label,
        }))
      },
      stateOptions() {
        return this.formFields.state.options.map((option) => ({
          id: option.value,
          label: option.label,
        }))
      },
      provinceOptions() {
        return this.formFields.province.options.map((option) => ({
          id: option.value,
          label: option.label,
        }))
      },
      businessTypeOptions() {
        return this.formFields.typeOfBusiness.options.map((option) => ({
          id: option.value,
          label: option.label,
        }))
      },
    },
    watch: {
      'form.groupList'(value) {
        if (value === 'contract') {
          this.showAdditionalOptions = true
        } else {
          this.showAdditionalOptions = false
          this.validateError = ''
        }
      },
      country() {
        if (this.country !== 'US') {
          this.stateDisabled = true
          this.form.state = ''
        } else {
          this.stateDisabled = false
        }

        if (this.country !== 'CA') {
          this.form.province = ''
        }
      },
    },
    created() {
      this.form.password = this.generateRandomPassword()
    },
    methods: {
      validateTrade() {
        if (this.showAdditionalOptions) {
          if (this.checkedOptions.length === 0) {
            this.validateError = 'Trade Professional Type is required'
          } else {
            this.validateError = ''
          }
        }
      },
      handleChange(option) {
        const optionPos = this.checkedOptions.findIndex((item) => item.value === option.value)
        if (optionPos === -1) {
          this.checkedOptions.push({
            value: option.label,
            checked: true,
          })
        } else {
          this.checkedOptions = this.checkedOptions.filter(
            (checkedOption) => checkedOption.value !== option.value
          )
        }
      },
      generateRandomPassword() {
        const length = 12
        const charset =
          'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+='

        let password = ''
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length)
          password += charset[randomIndex]
        }
        return password
      },
      handleEvent(file) {
        this.form.proofOfBusiness = file
        this.fileName = file.name
      },
      async handleFormSubmission() {
        this.validateTrade()
        this.isSending = true

        const formData = new FormData()

        this.form.password = this.generateRandomPassword()

        const params = {
          formHash: this.formObj.hash,
          username: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
          confirm: this.form.password,
          businessName: this.form.businessName,
          businessPhone: this.form.businessPhone,
          businessAddress: this.form.businessAddress,
          city: this.form.city,
          state: this.form.state,
          zipCode: this.form.zipCode,
          country: this.form.country,
          businessWebsite: this.form.businessWebsite,
          proofOfBusiness: this.form.proofOfBusiness,
          selectedGroup: this.form.groupList,
        }

        if (this.formObj.honeypot) {
          params[this.formObj.honeypot.name] = this.formObj.honeypot.hash
        }

        this.checkedOptions.forEach((item) => {
          formData.append('tradeProfessionalType[]', item.value)
        })

        for (const param in params) {
          formData.append(param, params[param])
        }

        if ((await this.$refs.tradeRegistrationForm.validate()) !== true) {
          this.isSending = false
          this.errorMsg = 'Please fill out all required fields.'
          return
        } else {
          if (this.validateError.length !== 0) {
            this.isSending = false
            this.errorMsg = 'Please fill out all required fields.'
            return
          }
        }

        if (this.form.honeypot !== '') {
          this.isSending = false
          this.errorMsg = 'You are a bot.'
          return
        }

        this.errorMsg = ''

        api
          .post('freeform/submit', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'X-Requested-With': 'XMLHttpRequest',
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              this.errorMsg = 'Oops! Something went wrong. Please try again.'
              this.success = false
            } else if (!response.data.success) {
              this.submitCount++
              this.success = false
              this.isSending = true

              if (this.submitCount < 2) {
                this.handleFormSubmission()
              } else {
                const errorHandles = Object.keys(response.data.errors)
                if (errorHandles.length > 0) {
                  errorHandles.forEach((name) => {
                    this.errorMsg =
                      response.data.errors[name][0] || response.data.formErrors[name][0]
                  })
                }
              }
            } else {
              window.location.href = '/register/trade/thanks'
              localStorage.setItem('name', this.form.firstName)
              localStorage.setItem(
                'message',
                'Your trade application will be reviewed shortly and an email will appear in your inbox upon confirmation.'
              )
              this.success = true
              this.error = ''
              api.post('/klaviyoconnect/api/track', {
                event: {
                  name: 'Trade Registration',
                },
                profile: {
                  'User Type': 'Company',
                  usertype: 'Company',
                  'Are You Trade': 'Yes',
                  'Consumer or Trade': 'Trade',
                  Website: this.form.businessWebsite,
                  'Type of Business': this.form.typeOfBusiness,
                  Organization: this.form.businessName,
                  'Product Inquiry Type': 'Company',
                },
                list: 'XZT2sW',
                email: this.form.email,
              })
              this.isSending = false
            }
          })
      },
    },
  }
</script>

<template>
  <div class="max-w-6xl container mx-auto mt-20 mb-32 px-4">
    <div class="mb-12 lg:mb-16">
      <h1 class="text-2xl lg:text-4xl text-center mb-6"> Trade Registration </h1>

      <p class="text-center text-md">
        Sign up and enjoy the benefits and unmatched service you will receive with Haute Living's
        trade program
      </p>
    </div>

    <div class="max-w-5xl mx-auto">
      <div v-if="error" class="mb-4">
        <BaseError v-if="error" :error="error" class="text-center" />
      </div>

      <ValidationObserver ref="tradeRegistrationForm">
        <form
          id="trade-registration-form"
          method="post"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          @submit.prevent="handleFormSubmission"
        >
          <input type="hidden" name="individualOrCompany" value="Company" />
          <input id="honeypot" v-model="form.honeypot" type="text" style="display: none" />
          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.firstName.label"
              rules="required"
            >
              <BaseInputText
                v-model="form.firstName"
                :value="form.firstName"
                :label="formFields.firstName.label + ' *'"
                type="text"
                :name="formFields.firstName.handle"
                class="mb-4 md:mb-0"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.lastName.label"
              rules="required"
            >
              <BaseInputText
                v-model="form.lastName"
                :value="form.lastName"
                :label="formFields.lastName.label + ' *'"
                type="text"
                :name="formFields.lastName.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.email.label"
              rules="required|email"
            >
              <BaseInputText
                v-model="form.email"
                :value="form.email"
                :label="formFields.email.label + ' *'"
                type="email"
                :name="formFields.email.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <!-- <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.password.label"
              rules="required"
              vid="password"
            >
              <BaseInputText
                v-model="form.password"
                :value="form.password"
                :label="formFields.password.label + ' *'"
                type="password"
                :name="formFields.password.handle"
                class="mb-4 md:mb-0"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.confirm.label"
              rules="required|confirmed:password"
            >
              <BaseInputText
                v-model="form.confirm"
                :value="form.confirm"
                :label="formFields.confirm.label + ' *'"
                type="password"
                :name="formFields.confirm.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div> -->

          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.businessName.label"
              rules="required"
            >
              <BaseInputText
                v-model="form.businessName"
                :value="form.businessName"
                :label="formFields.businessName.label + ' *'"
                type="text"
                :name="formFields.businessName.handle"
                class="mb-4 md:mb-0"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider v-slot="{ errors }" :name="formFields.businessPhone.label">
              <BaseInputText
                v-model="form.businessPhone"
                :value="form.businessPhone"
                :label="`${formFields.businessPhone.label}`"
                type="text"
                :name="formFields.businessPhone.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.businessWebsite.label"
              rules="required"
            >
              <BaseInputText
                v-model="form.businessWebsite"
                :value="form.businessWebsite"
                :label="formFields.businessWebsite.label"
                type="text"
                :name="formFields.businessWebsite.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.businessAddress.label"
              rules="required"
              class="mb-4"
            >
              <BaseInputText
                v-model="form.businessAddress"
                :value="form.businessAddress"
                :label="formFields.businessAddress.label + ' *'"
                type="text"
                :name="formFields.businessAddress.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.country.label"
              rules="required"
            >
              <BaseSelect
                v-model="form.country"
                :value="form.country"
                :label="formFields.country.label"
                class="select my-4 md:mt-0 md:mb-0"
                :options="countryOptions"
                :name="formFields.country.handle"
              ></BaseSelect>
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" :name="formFields.city.label" rules="required">
              <BaseInputText
                v-model="form.city"
                :value="form.city"
                :label="formFields.city.label"
                type="text"
                class="mb-4 md:mb-0"
                :name="formFields.city.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8 md:grid grid-cols-2 gap-12">
            <ValidationProvider
              v-show="country !== 'CA'"
              v-slot="{ errors }"
              :name="formFields.state.label"
              :rules="country === 'US' ? required : ''"
            >
              <BaseSelect
                v-model="form.state"
                :value="form.state"
                :label="formFields.state.label"
                class="select mb-4 md:mb-0"
                :options="stateOptions"
                :name="formFields.state.handle"
                :disabled="stateDisabled"
              ></BaseSelect>
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :name="formFields.zipCode.label"
              rules="required"
            >
              <BaseInputText
                v-model="form.zipCode"
                :value="form.zipCode"
                :label="formFields.zipCode.label"
                type="text"
                :name="formFields.zipCode.handle"
              />
              <BaseError v-if="errors.length" :error="errors[0]" />
            </ValidationProvider>
          </div>

          <div class="mb-8 radioButtonsWrapper">
            <label class="block mb-4 tradeFormLabel">{{ formFields.groupList.label }}</label>
            <div class="flex flex-col md:flex-row flex-wrap">
              <div
                v-for="(option, idx) in formFields.groupList.options"
                :key="idx"
                class="radioButtonsWrapper__button"
              >
                <label>
                  <input
                    type="radio"
                    :name="formFields.groupList.handle"
                    :value="option.value"
                    v-model="form.groupList"
                  />
                  <span></span>
                  {{ option.label }}
                </label>
              </div>
            </div>
          </div>

          <div class="mb-10" v-if="showAdditionalOptions">
            <label class="block mb-4 tradeFormLabel">{{
              formFields.tradeProfessionalType.label
            }}</label>
            <div class="flex flex-col md:flex-row flex-wrap">
              <BaseCheckbox
                v-for="(option, idx) in formFields.tradeProfessionalType.options"
                :key="idx"
                :label="option.label"
                :value="option.value"
                :checked="false"
                @change="handleChange(option)"
                class="quote-checkbox mr-4"
              ></BaseCheckbox>
            </div>
            <BaseError v-if="validateError" :error="validateError" />
          </div>

          <div class="mb-12">
            <div class="flex flex-col justify-between mb-8 md:mb-0">
              <div>
                <p class="font-medium mb-2 text-xl">{{ formFields.proofOfBusinessList.label }}</p>

                <ul class="list-disc ml-4 mb-10 pl-2">
                  <li
                    v-for="(option, index) in formFields.proofOfBusinessList.options"
                    :key="index"
                    class="text-gray mb-2 font-normal text-md"
                    >{{ option.label }}</li
                  >
                </ul>
              </div>

              <div class="flex items-center">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="formFields.proofOfBusiness.label"
                  rules="required"
                >
                  <BaseInputFileUpload
                    v-model="form.proofOfBusiness"
                    label="Choose a file"
                    class="mr-4"
                    :name="formFields.proofOfBusiness.handle"
                    @input="handleEvent"
                  />
                  <BaseError v-if="errors.length" :error="errors[0]" />
                </ValidationProvider>
                <div v-if="fileName.length" class="text-black opacity-50">
                  <p>Selected:</p>
                  <p>{{ fileName }}</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <BaseButton capitalize type="submit" color="yellows" :disabled="isSending">{{
              isSending ? 'Sending' : 'Submit'
            }}</BaseButton>

            <p v-if="success" class="uppercase text-green-600 absolute mt-4 text-xs"
              >Form has submitted successfully!</p
            >

            <div v-if="error || errorMsg">
              <BaseError
                v-if="error || errorMsg"
                :error="errorMsg ? errorMsg : error"
                class="text-center absolute mt-4 text-sm"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .tradeFormLabel {
    font-size: 12px;
    line-height: 12px;
    color: #6f6f6f;
  }

  .radioButtonsWrapper {
    &__button {
      margin-right: 43px;

      label {
        position: relative;
        display: inline-block;
        height: 14px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 14px;
        color: #000;

        span {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          display: block;
          width: 14px;
          height: 14px;
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            content: '';
            border: 1px solid #000;
            border-radius: 100%;
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: none;
            width: 100%;
            height: 100%;
            content: '';
            background: #000;
            border: 3px solid #fff;
            border-radius: 100%;
          }
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          opacity: 0;
        }

        input:checked + span::after {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .select {
    & >>> label {
      @apply mb-2 text-sm text-black;
    }

    & >>> select {
      @apply block w-full px-5 py-3 border border-gray-300 bg-white shadow-none font-light text-base leading-normal;

      &:focus {
        @apply shadow-outline;
      }
    }
  }
</style>
