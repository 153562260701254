import JQuery from 'jquery'
const $ = JQuery

$(document).ready(function () {
  $('.js-newsletter-form form').on({
    submit: function () {
      var $self = $(this)
      $('.alert.alert--success').remove()
      $('button[type=submit]', $self).prop('disabled', true)
      $('.js-form-submit', $self).addClass('is-loading')

      $.ajax({
        url: $('input[name=action]', $(this)).val(),
        type: 'post',
        dataType: 'json',
        data: $(this).serialize(),
        success: function (response) {
          $('ul.errors.help-block', $self).remove()
          $('.has-error', $self).removeClass('has-error')

          if (response.success && response.finished) {
            $self[0].reset()

            var $successMessage = $('<div>', { class: 'alert alert--success' }).append(
              '<p>Thanks for signing up!</p>'
            )

            $self.before($successMessage)

            $self.remove()

            // GA Custom Newsletter Event
            // eslint-disable-next-line no-undef
            if (typeof ga !== 'undefined') {
              // eslint-disable-next-line no-undef
              ga('send', 'event', {
                eventCategory: 'Newsletter',
                eventAction: 'submit',
              })
            }
          } else if (response.errors) {
            for (var key in response.errors) {
              // eslint-disable-next-line no-prototype-builtins
              if (!response.errors.hasOwnProperty(key)) continue
              var messages = response.errors[key]
              var $errors = $('<ul>', { class: 'errors help-block' })
              for (var i = 0; i < messages.length; i++) {
                var $li = $('<li>')
                $li.html(messages[i])
                $errors.append($li)
              }

              var $input = $('*[name=' + key + "], *[name='" + key + "[]']")

              const $formGroup = $input.parents('.form-group')
              $formGroup.addClass('has-error')

              $formGroup.append($errors)
            }
          }

          $('button[type=submit]', $self).prop('disabled', false)
          $('.js-form-submit', $self).removeClass('is-loading')
        },
      })

      return false
    },
  })

  $('.js-text-input-field').change(function () {
    var $thisField = $(this)

    if ($thisField.val()) {
      $thisField.addClass('is-filled')
    } else {
      $thisField.removeClass('is-filled')
    }
  })
})
