<script>
  /** Footer component to render a single link list column */

  export default {
    name: 'GlobalFooterLinkList',

    props: {
      heading: {
        type: String,
        required: true,
      },
      links: {
        type: Array,
        required: true,
      },
    },
  }
</script>

<template>
  <div>
    <h6 class="text-left text-base text-gray-500 font-normal">
      {{ heading }}
    </h6>
    <nav class="lg:mt-5">
      <ul>
        <li v-for="link of links" :key="link.text" class="lg:mb-2 font-light text-white">
          <BaseLink :href="link.url" class="hover:text-gray-500">
            {{ link.text }}
          </BaseLink>
        </li>
      </ul>
    </nav>
  </div>
</template>
