<script>
  import IconChevronUp from '@/images/icons/chevron-up.svg'
  import IconPlus from '@/images/icons/plus.svg'

  export default {
    name: 'ProductQuoteMenu',

    components: {
      IconChevronUp,
      IconPlus,
    },

    props: {
      label: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    data() {
      return {
        isOpen: false,
      }
    },

    methods: {
      toggleMenu() {
        this.isOpen = !this.isOpen
      },

      selectItem(id) {
        // console.log('Quote ID is: ' + id)
      },

      addItem() {
        // console.log('Adding item called')
      },
    },
  }
</script>

<template>
  <div class="relative">
    <button
      class="flex justify-between items-center w-full px-6 py-4 border-t border-gray-300 bg-white"
      type="button"
      @click="toggleMenu"
    >
      <span class="text-xs uppercase tracking-wide text-black">{{ label }}</span>
      <IconChevronUp class="w-3 h-2 fill-current" :class="{ 'rotate-180': isOpen }" />
    </button>

    <ul
      v-if="isOpen"
      class="absolute left-0 right-0 border-l border-r border-gray-300 md:border-l-0"
      style="bottom: 50px"
    >
      <li v-for="(option, index) in options" :key="index">
        <button
          class="flex justify-between items-center w-full px-6 py-4 border-t border-gray-300 bg-white hover:bg-gray-100"
          type="button"
          @click="selectItem(option.id)"
        >
          <span class="text-xs uppercase tracking-wide text-black">{{ option.label }}</span>
          <IconPlus class="fill-current" style="width: 10px; height: 10px" />
        </button>
      </li>

      <li>
        <button
          class="flex justify-between items-center w-full px-6 py-4 border-t border-b border-gray-300 bg-white hover:bg-gray-100"
          type="button"
          @click="addItem"
        >
          <span class="text-xs uppercase tracking-wide text-black">Create new</span>
          <IconPlus class="fill-current" style="width: 10px; height: 10px" />
        </button>
      </li>
    </ul>
  </div>
</template>
