<script>
  /** Base Floating label text input field used in footer. */

  export default {
    name: 'BaseFloatingLabelTextInput',

    props: {
      /** The input type */
      type: {
        type: String,
        default: 'text',
        required: false,
        // Only allow types that essentially just render text boxes.
        validator(propValue) {
          const propValues = ['email', 'number', 'password', 'search', 'tel', 'text', 'url']
          return propValues.indexOf(propValue) > -1
        },
      },
      /** The inputs label */
      label: {
        type: String,
        required: true,
      },
      /** Initial value */
      value: {
        type: String,
        required: true,
      },
      /** Placeholder text in the input */
      placeholder: {
        type: String,
        required: false,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFilled: false,
      }
    },
    methods: {
      handleInput(event) {
        const value = event.target.value
        this.isFilled = value.length > 0
        this.$emit('input', value)
      },
    },
  }
</script>

<template>
  <div class="relative">
    <input
      :id="'input-' + _uid"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      class="floating-input relative bg-transparent z-10 border-b border-gray-500 focus:border-gray-500 focus:outline-none w-full py-1"
      @input="handleInput"
    />
    <label
      class="floating-label left-0 absolute bottom-2 z-0 w-full font-light text-white text-md"
      :class="{ 'is-filled': isFilled }"
      :for="'input-' + _uid"
    >
      {{ label }}
    </label>
  </div>
</template>

<style scoped lang="postcss">
  .floating-input {
    &:focus {
      & + .floating-label {
        font-size: 0.75rem;
        transform: translate(0, -1.025rem) scale(1);
      }
    }
  }

  .floating-label {
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

    &.is-filled {
      font-size: 0.75rem;
      transform: translate(0, -1.025rem) scale(1);
    }
  }
</style>
