<script>
  export default {
    name: 'BasePageHeader',

    props: {
      heading: {
        type: String,
        required: true,
      },
      prefix: {
        type: String,
        required: false,
        default: null,
      },
    },
  }
</script>

<template>
  <header class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 flex flex-col justify-center items-start py-5 | md:py-8 | lg:py-16">
    <h1 class="leading-snug">
      <span v-if="prefix" class="block text-lg text-gray-600 mb-1 | lg:text-2xl font-semibold">
        {{ prefix }}
        <span class="hidden | md:inline">/</span>
      </span>
      <span class="block uppercase font-semibold text-4xl leading-tight | lg:text-6xl">{{ heading }}</span>
    </h1>
  </header>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  header {
    /*min-height: 170px;*/
  }

  h1 {
    max-width: 1080px;
  }

  @screen md {
    header {
      /*min-height: 230px;*/
    }
  }

  @screen lg {
    header {
      /*min-height: 330px;*/
    }
  }
  /* purgecss end ignore */
</style>
