export function compInt(a, b, order = 'asc') {
  // used to invert the return value for diff orders.
  const orderMod = order === 'asc' ? 1 : -1

  return (a - b) * orderMod
}

export function compStr(a, b, order = 'asc') {
  // used to invert the return value for diff orders.
  const orderMod = order === 'asc' ? 1 : -1

  // case insensitive comp
  const A = a.toUpperCase()
  const B = b.toUpperCase()

  if (A < B) {
    return -1 * orderMod
  } else if (A > B) {
    return 1 * orderMod
  } else {
    return 0
  }
}

export function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
