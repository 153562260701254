<script>
  import { createNamespacedHelpers } from 'vuex'
  import VueSlickCarousel from 'vue-slick-carousel'
  import VLazyImage from 'v-lazy-image'
  import VuePlyr from 'vue-plyr'
  import 'plyr/dist/plyr.css'
  import IconChevronLeft from '@/images/icons/chevron-left.svg'
  import IconChevronRight from '@/images/icons/chevron-right.svg'

  const { mapState } = createNamespacedHelpers('products')

  /** A Reusable slide carousel. Uses VueSlickCarousel for interactions, VLazyImage for lazing loading images, and VuePlyr for playing videos.
   * - https://github.com/gs-shop/vue-slick-carousel
   * - https://github.com/alexjoverm/v-lazy-image
   * - https://github.com/redxtech/vue-plyr
   * - test test
   **/

  export default {
    name: 'BaseSlider',

    components: {
      VueSlickCarousel,
      VLazyImage,
      VuePlyr,
      IconChevronLeft,
      IconChevronRight,
    },

    props: {
      /**
       * An array of slide objects containing the following keys:
       * - type: 'image' or 'video'
       *
       * If type === 'image':
       * - urls: array of two image objects, 1x and 2x respectively, both containing a `url` field
       * - alt: alternate text for the image
       * - width: the images’s width in pixels
       * - height: the image’s height in pixels
       *
       * If type === 'video':
       * - url: the video’s embed URL
       */
      slides: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    data() {
      return {
        currentSlide: 0,
        reset: 0,
        events: false,
        settings: {
          slidesToShow: 1,
          slidersToScroll: 1,
          infinite: false,
          arrows: false,
          edgeFriction: 0.35,
          focusOnSelect: true,
          dots: true,
          speed: 500,
          swipe: true,
          touchThreshold: 100,
        },
      }
    },

    computed: {
      ...mapState(['selectedLabel', 'firstOptionSelected']),
      /** Whether the "previous" button is enabled/visible */
      prevEnabled() {
        return this.currentSlide > 0
      },
      /** Whether the "next" button is enabled/visible */
      nextEnabled() {
        return this.currentSlide < this.slides.length - 1
      },
      /** The Plyr component in the current slide, if any */
      currentPlyr() {
        return this.$refs[`plyr${this.currentSlide}`]
      },
    },

    watch: {
      selectedLabel() {
        const found = this.slides.findIndex((slide) => +slide.option === this.selectedLabel)
        if (found !== -1) {
          this.$refs.slider.goTo(found)
        }
      },
      /** Watch the slides prop to re-init Vue Slick Carousel */
      slides: function () {
        this.reset += 1
      },
    },

    mounted() {
      setTimeout(() => {
        if (this.firstOptionSelected !== null) {
          const found = this.slides.findIndex((slide) => +slide.option === this.firstOptionSelected)
          if (found !== -1) {
            this.$refs.slider.goTo(found)
          }
        }
      }, 100)
    },

    methods: {
      /** Go to the previous slide */
      showPrev() {
        this.$refs.slider.prev()
        this.$refs.prev.blur()
      },
      /** Go to the next slide */
      showNext() {
        this.$refs.slider.next()
        this.$refs.next.blur()
      },
      /** Runs before the slide changes */
      beforeSlideChange(currentSlide, nextSlide) {
        this.pauseVideo()
        this.events = true
        this.currentSlide = nextSlide
        setTimeout(() => {
          this.events = false
        }, 150)
      },
      /** Pause the video in the current slide, if any */
      pauseVideo() {
        if (this.currentPlyr?.player) {
          this.currentPlyr.player.pause()
        }
      },
      /** Focus on the video in the current slide, if any */
      focusVideo() {
        if (this.currentPlyr?.$el) {
          this.currentPlyr.$el.querySelector('.plyr__control.plyr__control--overlaid').focus()
          this.currentPlyr.$el.querySelector('.plyr__poster').classList.add('pointer-events-none')
        }
      },
      handleSwipe(currentSlide, next) {
        this.beforeSlideChange()
      },
    },
  }
</script>

<template>
  <div class="w-full">
    <div v-if="slides.length" class="slider relative overflow-hidden">
      <VueSlickCarousel
        v-bind="settings"
        ref="slider"
        :key="reset"
        @swipe="handleSwipe"
        @beforeChange="beforeSlideChange"
      >
        <div
          v-for="(slide, index) in slides"
          :id="index"
          :key="index"
          class="slide border border-gray-300 position-relative"
        >
          <VLazyImage
            v-if="slide.type === 'image'"
            class="w-full h-full object-cover"
            :class="events ? 'pointer-events-none' : 'pointer-events-auto'"
            :srcset="slide.urls[0].url + ' 1x,' + slide.urls[1].url + ' 2x'"
            :src="slide.urls[0].url"
            :alt="slide.alt"
          />
          <VuePlyr v-if="slide.type === 'video'" :ref="`plyr${index}`" @click.native="focusVideo">
            <div class="plyr__video-embed">
              <iframe :src="slide.url" allowfullscreen allowtransparency allow="autoplay" />
            </div>
          </VuePlyr>
        </div>
      </VueSlickCarousel>

      <button v-show="prevEnabled" ref="prev" class="slide-ctrl slide-ctrl--prev" @click="showPrev">
        <span>Previous Slide</span>
        <IconChevronLeft />
      </button>

      <button v-show="nextEnabled" ref="next" class="slide-ctrl slide-ctrl--next" @click="showNext">
        <span>Next Slide</span>
        <IconChevronRight />
      </button>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .download--icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 20;
    margin: 0;

    a {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .previewSlider {
    position: relative;
    width: 110px;
    height: 800px;

    .slide {
      position: relative;
      width: 100%;
      height: 110px;
      overflow: hidden;
      cursor: pointer;
      border: none;
    }

    .slick-current {
      .slide {
        border-color: #000;
      }
    }
    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      margin-bottom: 30px;

      .slide {
        height: 100px;
      }
    }
    @media (max-width: 768px) {
      width: 100%;
      height: 100px;

      .slide {
        padding: 0 5px;
      }
    }
  }

  >>> .slider {
    width: 100%;

    .slick-slide {
      div:first-child {
        margin-right: 1px;
      }
    }

    .slide {
      position: relative;
      width: 100%;
      height: 600px;
      background: transparent;
      outline: none;
    }
    @media (max-width: 1024px) {
      width: 100%;
      padding-left: 0;
      margin-top: 0;

      .slide {
        height: 350px;
      }
    }
    @media (max-width: 600px) {
      .slide {
        height: 215px;
      }
    }
  }

  .slide-ctrl {
    position: absolute;
    top: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding-top: 1px;
    overflow: hidden;
    text-align: center;
    background-color: transparent;
    border: 1px solid #6f6f6f;
    border-radius: 100%;
    opacity: 1;
    transition: all 300ms;
    transform: translateY(-50%);

    span {
      position: absolute;
      left: -9999px;
    }

    svg {
      display: inline-block;
      width: 20px;
      height: 20px;
      fill: #000;
      transition: fill 300ms;
    }
  }

  .slide-ctrl--prev {
    left: 20px;
    padding-right: 2px;
  }

  .slide-ctrl--next {
    right: 20px;
    padding-left: 2px;
  }

  .v-lazy-image {
    opacity: 0;
    transition: opacity 0.7s;
  }

  .v-lazy-image-loaded {
    opacity: 1;
  }

  @screen lg {
    .slide-ctrl {
      &:hover {
        background-color: #000;

        svg {
          fill: #fff;
        }
      }
    }

    .slider:hover,
    .slider:focus,
    .slider:active {
      .slide-ctrl {
        opacity: 1;
      }
    }
  }
  @media (max-width: 1024px) {
    .slide-ctrl {
      display: none !important;
    }
  }
</style>
