<script>
  import { vueWindowSizeMixin } from 'vue-window-size'
  import VueContentLoading from 'vue-content-loading'

  /** The loading animation that appears when loading grid tiles into to the app. It emits the animation size to the
   * parent element when it is mounted. Make use of the VueContentLoading component to create animated SVG
   * placeholders and vue-window-size as a mixin to determine the layout based on screen size.
   * See: https://github.com/LucasLeandro1204/vue-content-loading
   * See: https://github.com/mya-ake/vue-window-size */

  export default {
    name: 'BaseTileGridLoader',

    components: {
      VueContentLoading,
    },

    mixins: [vueWindowSizeMixin],

    created() {
      /** Add a resize event listener when created */
      window.addEventListener('resize', this.handleResize)
    },

    mounted() {
      /** When mounted, emit the size of the animation (width and height) */
      this.handleResize()
    },

    destroyed() {
      /** Clean up and remove the resize event when the component is destroyed */
      window.removeEventListener('resize', this.handleResize)
    },

    methods: {
      /** Emits a 'resize' event with the size of the animation (width and height) to the parent element */
      handleResize() {
        this.$emit('resize', {
          width: this.$refs.loader.$el.clientWidth,
          height: this.$refs.loader.$el.clientHeight,
        })
      },
    },
  }
</script>

<template>
  <div>
    <VueContentLoading v-if="windowWidth < 768" ref="loader" :width="346" :height="320">
      <rect x="0" y="15" width="150" height="10" rx="2" />
      <rect x="165" y="15" width="150" height="10" rx="2" />
      <rect x="0" y="50" width="165" height="165" />
      <rect x="0" y="230" width="100" height="5" rx="2" />
      <rect x="0" y="245" width="70" height="5" rx="2" />
      <rect x="0" y="260" width="100" height="5" rx="2" />
      <rect x="181" y="50" width="165" height="165" />
      <rect x="181" y="230" width="100" height="5" rx="2" />
      <rect x="181" y="245" width="70" height="5" rx="2" />
      <rect x="181" y="260" width="100" height="5" rx="2" />
      <rect x="0" y="300" width="160" height="8" rx="2" />
    </VueContentLoading>

    <VueContentLoading
      v-if="windowWidth >= 768 && windowWidth < 1024"
      ref="loader"
      :width="720"
      :height="420"
    >
      <rect x="0" y="15" width="150" height="15" rx="2" />
      <rect x="165" y="15" width="150" height="15" rx="2" />
      <rect x="0" y="50" width="232" height="232" />
      <rect x="0" y="300" width="100" height="5" rx="2" />
      <rect x="0" y="315" width="70" height="5" rx="2" />
      <rect x="0" y="330" width="100" height="5" rx="2" />
      <rect x="248" y="50" width="232" height="232" />
      <rect x="248" y="300" width="100" height="5" rx="2" />
      <rect x="248" y="315" width="70" height="5" rx="2" />
      <rect x="248" y="330" width="100" height="5" rx="2" />
      <rect x="496" y="50" width="232" height="232" />
      <rect x="496" y="300" width="100" height="5" rx="2" />
      <rect x="496" y="315" width="70" height="5" rx="2" />
      <rect x="496" y="330" width="100" height="5" rx="2" />
      <rect x="0" y="380" width="160" height="8" rx="2" />
    </VueContentLoading>

    <VueContentLoading
      v-if="windowWidth >= 1024 && windowWidth < 1560"
      ref="loader"
      :width="976"
      :height="400"
    >
      <rect x="0" y="15" width="200" height="15" rx="2" />
      <rect x="215" y="15" width="200" height="15" rx="2" />
      <rect x="0" y="50" width="226" height="226" />
      <rect x="0" y="300" width="100" height="5" rx="2" />
      <rect x="0" y="315" width="70" height="5" rx="2" />
      <rect x="0" y="330" width="100" height="5" rx="2" />
      <rect x="250" y="50" width="226" height="226" />
      <rect x="250" y="300" width="100" height="5" rx="2" />
      <rect x="250" y="315" width="70" height="5" rx="2" />
      <rect x="250" y="330" width="100" height="5" rx="2" />
      <rect x="500" y="50" width="226" height="226" />
      <rect x="500" y="300" width="100" height="5" rx="2" />
      <rect x="500" y="315" width="70" height="5" rx="2" />
      <rect x="500" y="330" width="100" height="5" rx="2" />
      <rect x="750" y="50" width="226" height="226" />
      <rect x="750" y="300" width="100" height="5" rx="2" />
      <rect x="750" y="315" width="70" height="5" rx="2" />
      <rect x="750" y="330" width="100" height="5" rx="2" />
      <rect x="0" y="380" width="200" height="8" rx="2" />
    </VueContentLoading>

    <!-- Make below 4 slides as number of slick slides on below resolutions -->
    <VueContentLoading v-if="windowWidth >= 1560" ref="loader" :width="1872" :height="420">
      <rect x="0" y="15" width="250" height="15" rx="2" />
      <rect x="265" y="15" width="250" height="15" rx="2" />
      <rect x="0" y="50" width="292" height="292" />
      <rect x="0" y="352" width="100" height="5" rx="2" />
      <rect x="0" y="367" width="70" height="5" rx="2" />
      <rect x="0" y="382" width="100" height="5" rx="2" />
      <rect x="316" y="50" width="292" height="292" />
      <rect x="316" y="352" width="100" height="5" rx="2" />
      <rect x="316" y="367" width="70" height="5" rx="2" />
      <rect x="316" y="382" width="100" height="5" rx="2" />
      <rect x="632" y="50" width="292" height="292" />
      <rect x="632" y="352" width="100" height="5" rx="2" />
      <rect x="632" y="367" width="70" height="5" rx="2" />
      <rect x="632" y="382" width="100" height="5" rx="2" />
      <rect x="948" y="50" width="292" height="292" />
      <rect x="948" y="352" width="100" height="5" rx="2" />
      <rect x="948" y="367" width="70" height="5" rx="2" />
      <rect x="948" y="382" width="100" height="5" rx="2" />
      <rect x="1264" y="50" width="292" height="292" />
      <rect x="1264" y="352" width="100" height="5" rx="2" />
      <rect x="1264" y="367" width="70" height="5" rx="2" />
      <rect x="1264" y="382" width="100" height="5" rx="2" />
      <rect x="1580" y="50" width="292" height="292" />
      <rect x="1580" y="352" width="100" height="5" rx="2" />
      <rect x="1580" y="367" width="70" height="5" rx="2" />
      <rect x="1580" y="382" width="100" height="5" rx="2" />
      <rect x="0" y="400" width="400" height="8" rx="2" />
    </VueContentLoading>
  </div>
</template>
