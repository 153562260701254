<script>
  /*
   * This components renders an input with the file type for handling file uploads
   */

  export default {
    name: 'BaseInputFileUpload',
    props: {
      label: {
        type: String,
        required: false,
        default: null,
      },
      name: {
        type: String,
        required: false,
        default: undefined,
      },
    },
    computed: {
      listeners() {
        const { input, ...listeners } = this.$listeners
        return listeners
      },
    },
    methods: {
      fileChange(fieldName, fileList) {
        this.$emit('input', fileList[0])
      },
    },
  }
</script>

<template>
  <div>
    <label
      class="mb-0 cursor-pointer"
    >
      <span class="fileButton uppercase font-semibold tracking-wider">{{ label }}</span>
      <input
        type="file"
        :aria-label="label"
        class="text-input"
        :name="name"
        v-on="listeners"
        @change="fileChange($event.target.name, $event.target.files)"
      />
    </label>
  </div>
</template>

<style lang="postcss" scoped>
  .input {
    border: 1px solid black;
  }

  .fileButton {
    border-bottom: 1px solid #000;
  }

  input[type='file'] {
    display: none;
  }
</style>
