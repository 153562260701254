<template>
  <!-- calendar component -->
  <div
    :style="{ width: width }"
    class="datetime-picker"
    @click="calendarClicked($event)"
    @blur="toggleCal"
  >
    <div>
      <input
        type="text"
        :readonly="readonly"
        id="tj-datetime-input"
        :required="required"
        :value="date"
        :name="name"
        @click="toggleCal"
        autocomplete="off"
        :placeholder="placeholder"
      />
      <div class="calender-div" :class="`${hideCal ? 'hidden' : 'inline-block'}`">
        <div :class="`${hideDate ? 'hidden' : 'block'}`">
          <div class="year-month-wrapper">
            <div class="month-setter">
              <button type="button" class="nav-l" @click="leftYear" v-if="!notHidden"
                >&#x3C;</button
              >
              <span class="year">{{ year }}</span>
              <button type="button" class="nav-r" @click="rightYear" v-if="!notHidden"
                >&#x3E;</button
              >
            </div>
            <div class="month-setter">
              <button type="button" class="nav-l" @click="leftMonth">&#x3C;</button>
              <span class="month">{{ month }}</span>
              <button type="button" class="nav-r" @click="rightMonth">&#x3E;</button>
            </div>
          </div>
          <div class="headers">
            <span class="days" v-for="port in days" :key="port">{{ port }}</span>
          </div>
          <div>
            <div class="week" v-for="(week, weekIndex) in weeks" :key="weekIndex">
              <span
                class="port"
                v-for="(day, dayIndex) in week"
                :id="day"
                :key="dayIndex"
                @click="setDay(weekIndex * 7 + dayIndex, day, dayIndex)"
                :class="{
                  activePort: weekIndex * 7 + dayIndex === activePort,
                  notActive:
                    dayIndex === 0 ||
                    dayIndex === 6 ||
                    (monthIndex === currentMonth && day < activePort && year === thisYear) ||
                    monthIndex < currentMonth && year === thisYear ||
                    // disabled 22dev and 2jan
                    (month === 'Dec' && day > 22) ||
                    (month === 'Nov' && day == 28) ||
                    (month === 'Nov' && day == 29) ||
                    (month === 'May' && day == 27) ||
                    (month === 'Jul' && day == 4) ||
                    (month === 'Sep' && day == 2) ||
                    (month === 'Jan' && day < 2 && year === thisYear + 1),
                }"
                :style="`${
                  dayIndex === 0 ||
                  dayIndex === 6 ||
                  (monthIndex === currentMonth && day < currentDay) ||
                  monthIndex < currentMonth && year === thisYear ||
                  // disabled 22dev and 2jan
                  (month === 'Dec' && day > 22) ||
                  (month === 'Nov' && day == 28) ||
                  (month === 'Nov' && day == 29) ||
                  (month === 'May' && day == 27) ||
                  (month === 'Jul' && day == 4) ||
                  (month === 'Sep' && day == 2) ||
                  (month === 'Jan' && day < 2 && year === thisYear + 1)
                    ? 'color:#bebebe;cursor: default;'
                    : ''
                }`"
              >
                {{ day }}
              </span>
            </div>
          </div>
        </div>
        <div class="time-picker" :class="{ noDisplay: hideTime }">
          <div class="hour-selector">
            <div @click="showHourSelector" id="j-hour">{{
              periodStyle === 12 && hour > 12 ? hour - 12 : hour
            }}</div>
            <div
              class="scroll-hider"
              ref="hourScrollerWrapper"
              :class="`${hourSelectorVisible ? 'inline-block' : 'hidden'}`"
            >
              <ul ref="hourScroller">
                <li
                  v-for="(h, index) in hours"
                  :key="index"
                  :id="`${period}-${index}`"
                  :class="{ active: index === hourIndex }"
                  :style="`${
                    (index < 9 && period == 'AM') || (index > inactiveHours && period == 'PM')
                      ? 'color:#999;pointer-events:none'
                      : ''
                  }`"
                  @click="setHour(index, true)"
                  >{{ h }}</li
                >
              </ul>
            </div>
          </div>
          <div class="time-separator">
            <span>:</span>
          </div>
          <div class="minute-selector">
            <div @click="showMinuteSelector" id="j-minute">{{ minute }}</div>
            <div
              class="scroll-hider"
              ref="minuteScrollerWrapper"
              :class="`${minuteSelectorVisible ? 'inline-block' : 'hidden'}`"
            >
              <ul ref="minuteScroller">
                <li
                  v-for="(m, index) in minutes"
                  :key="index"
                  :class="{ active: index === minuteIndex }"
                  @click="setMinute(index, true)"
                  >{{ m }}</li
                >
              </ul>
            </div>
          </div>
          <div class="time-separator" v-if="periodStyle === 12">
            <span>:</span>
          </div>
          <div class="minute-selector" v-if="periodStyle === 12">
            <div @click="changePeriod">{{ period }}</div>
          </div>
        </div>
        <button type="button" @click="clearDate" class="okButton">Clear</button>
        <button type="button" @click="setDate" class="okButton ok">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
  import endOfMonth from 'date-fns/end_of_month'
  import eachDay from 'date-fns/each_day'
  import getDay from 'date-fns/get_day'
  import format from 'date-fns/format'
  import startOfDay from 'date-fns/start_of_day'
  import isEqual from 'date-fns/is_equal'

  const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const AM = 'AM'
  const PM = 'PM'
  const currentYear = new Date().getFullYear()
  export default {
    name: 'datetime-picker',
    props: {
      format: {
        type: String,
        default: 'YYYY-MM-DD h:i:s',
      },
      name: {
        type: String,
      },
      width: {
        type: String,
      },
      value: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      firstDayOfWeek: {
        default: 0,
        validator: function (value) {
          try {
            const val = parseInt(value, 10)
            return val >= 0 && val <= 1
          } catch (e) {
            console.warn(e.message)
            return false
          }
        },
        message: 'Only 0 (Sunday) and 1 (Monday) are supported.',
      },
    },
    data() {
      return {
        inactiveHours: 6,
        currentDay: null,
        notHidden: false,
        date: this.value,
        hideCal: true,
        activePort: null,
        timeStamp: new Date(),
        thisYear: currentYear,
        notActive: false,
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        days: [],
        monthIndex: 0,
        hourIndex: 0,
        minuteIndex: 0,
        year: currentYear,
        portsHolder: [],
        minute: '00',
        hour: '01',
        day: 1,
        minuteSelectorVisible: false,
        hourSelectorVisible: false,
        period: AM,
        periodStyle: 12,
        currentMonth: 0,
      }
    },
    methods: {
      disabledRedDays(dayIndex) {
        if (dayIndex === 6) {
          this.day = this.timeStamp.getDate() + 3
        } else if (dayIndex === 0) {
          this.day = this.timeStamp.getDate() + 4
        } else {
          this.day = this.timeStamp.getDate() + 1
        }
      },
      disableSpecDays() {
        let dayIndex = 0
        if (this.months[this.timeStamp.getMonth()] === 'May' && this.timeStamp.getDate() === 27) {
          dayIndex = this.days.findIndex((day) => day === '27')
          this.disabledRedDays(dayIndex)
        }
        if (this.months[this.timeStamp.getMonth()] === 'Jul' && this.timeStamp.getDate() === 4) {
          dayIndex = this.days.findIndex((day) => day === '4')
          this.disabledRedDays(dayIndex)
        }
        if (this.months[this.timeStamp.getMonth()] === 'Sep' && this.timeStamp.getDate() === 2) {
          dayIndex = this.days.findIndex((day) => day === '2')
          this.disabledRedDays(dayIndex)
        }
        if (
          (this.months[this.timeStamp.getMonth()] === 'Nov' && this.timeStamp.getDate() === 28) ||
          (this.months[this.timeStamp.getMonth()] === 'Nov' && this.timeStamp.getDate() === 29)
        ) {
          dayIndex = this.days.findIndex((day) => day === '29')
          this.disabledRedDays(dayIndex)
        }
      },
      leftMonth() {
        const index = this.months.indexOf(this.month)
        if (index === 0) {
          this.monthIndex = 11
        } else {
          this.monthIndex = index - 1
        }
        this.updateCalendar()
      },
      rightMonth() {
        const index = this.months.indexOf(this.month)
        if (index === 11) {
          this.monthIndex = 0
        } else {
          this.monthIndex = index + 1
        }
        this.updateCalendar()
      },
      rightYear() {
        this.year++
        if (this.year >= currentYear + 1) {
          this.year = currentYear + 1
        }
        this.updateCalendar()
      },
      leftYear() {
        this.year--
        if (this.year <= currentYear) {
          this.year = currentYear
        }
        this.updateCalendar()
      },
      updateActivePortFromWeek(week, weekIndex) {
        const currentActive = startOfDay(this.timeStamp)
        const index = week.findIndex((day) => isEqual(currentActive, day))
        if (index !== -1) {
          this.activePort = weekIndex * 7 + index
        }
      },
      updateCalendar() {
        const date = new Date(this.year, this.monthIndex, 1, 0, 0, 0)
        const weeks = []
        let week = null
        let weekDays = new Array(7)
        // let index = 0;
        this.activePort = null
        eachDay(date, endOfMonth(date)).forEach((day) => {
          const weekday = getDay(day)
          if (weekday === this.normalizedFirstDayOfWeek) {
            if (week) {
              weeks.push(week)
              // Add those days that were not part of the month to the index
              // index += week.filter(d => d === null).length;
              this.updateActivePortFromWeek(weekDays, weeks.length - 1)
              weekDays = new Array(7)
            }
            week = new Array(7)
          } else if (week === null) {
            week = new Array(7)
          }
          const idx = (weekday - this.normalizedFirstDayOfWeek + 7) % 7
          week[idx] = format(day, 'DD')
          weekDays[idx] = day
        })
        if (week && week.some((n) => n)) {
          weeks.push(week)
          this.updateActivePortFromWeek(weekDays, weeks.length - 1)
        }
        this.weeks = weeks
      },
      setDay(index, port, dayIndex, event) {
        if (
          dayIndex === 0 ||
          dayIndex === 6 ||
          (this.monthIndex === this.currentMonth && Number(port) <= this.currentDay) ||
          (this.monthIndex === this.currentMonth && Number(port) < this.currentDay + 1) ||
          (this.monthIndex === this.currentMonth && Number(port) < this.currentDay + 2) ||
          (this.month === 'Dec' && this.year === this.thisYear && index - 4 > 22) ||
          (this.month === 'Jan' && this.year === this.thisYear + 1 && index < 2) ||
          (this.month === 'Nov' && Number(port) === 28) ||
          (this.month === 'Nov' && Number(port) === 29) ||
          (this.month === 'Sep' && Number(port) === 2) ||
          (this.month === 'Jul' && Number(port) === 4) ||
          (this.month === 'May' && Number(port) === 27)
        ) {
          return
        } else {
          if (port) {
            this.activePort = index
            this.day = parseInt(port, 10)
            this.timeStamp = new Date(this.year, this.monthIndex, this.day)
            if (
              this.month === 'Dec' &&
              this.year === this.thisYear &&
              index - 4 === 22 &&
              this.period === 'PM'
            ) {
              this.hourIndex = 1
              this.hour = 2
            }
          }
        }
      },
      setMinute(index, closeAfterSet) {
        this.minuteIndex = index
        this.minute = this.minutes[index]
        if (closeAfterSet) {
          this.minuteSelectorVisible = false
        }
      },
      setHour(index, closeAfterSet) {
        this.hourIndex = index
        this.hour = this.hours[index]
        if (closeAfterSet) {
          this.hourSelectorVisible = false
        }
        if (
          this.month === 'Dec' &&
          this.activePort === 26 &&
          this.year === 2023 &&
          this.day === 22
        ) {
          if (index + 1 < 10 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (index + 1 > 2 && this.period == 'PM') {
            this.hourIndex = 1
            this.hour = 2
          } else if (index === 11 && this.period == 'AM') {
            this.period = 'PM'
          }
        } else if (this.year === 2023 && this.activePort !== 26 && this.day !== 22) {
          if (index + 1 < 10 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (index + 1 > 6 && this.period == 'PM') {
            this.hourIndex = 5
            this.hour = 6
          } else if (index === 11 && this.period == 'AM') {
            this.period = 'PM'
          }
        }
      },
      showHourSelector() {
        this.hourSelectorVisible = true
        this.minuteSelectorVisible = false
      },
      showMinuteSelector() {
        this.minuteSelectorVisible = true
        this.hourSelectorVisible = false
      },
      // keyIsDown (event) {
      //   const key = event.which || event.keycode
      //   if (key === 38) {
      //     if (this.minuteSelectorVisible && this.minuteIndex > 0) {
      //       this.setMinute(this.minuteIndex - 1, false)
      //       this.scrollTopMinute()
      //     } else if (this.hourSelectorVisible && this.hourIndex > 0) {
      //       this.setHour(this.hourIndex - 1, false)
      //       this.scrollTopHour()
      //     }
      //   } else if (key === 40) {
      //     if (this.minuteSelectorVisible && this.minuteIndex < this.minutes.length - 1) {
      //       this.setMinute(this.minuteIndex + 1, false)
      //       this.scrollTopMinute()
      //     } else if (this.hourSelectorVisible && this.hourIndex < this.hours.length - 1) {
      //       this.setHour(this.hourIndex + 1, false)
      //       this.scrollTopHour()
      //     }
      //   } else if (key === 13) {
      //     this.minuteSelectorVisible = false
      //     this.hourSelectorVisible = false
      //   }
      //   if (this.minuteSelectorVisible || this.hourSelectorVisible) {
      //     event.preventDefault()
      //     this.minuteSelectorVisible = false
      //     this.hourSelectorVisible = false
      //   }
      // },
      scrollTopMinute() {
        const mHeight = this.$refs.minuteScroller.scrollHeight
        const wHeight = this.$refs.minuteScrollerWrapper.clientHeight
        const top = mHeight * (this.minuteIndex / (this.minutes.length - 1)) - wHeight / 2
        this.$refs.minuteScroller.scrollTop = top
      },
      scrollTopHour() {
        const mHeight = this.$refs.hourScroller.scrollHeight
        const wHeight = this.$refs.hourScrollerWrapper.clientHeight
        const top = mHeight * (this.hourIndex / (this.hours.length - 1)) - wHeight / 2
        this.$refs.hourScroller.scrollTop = top
      },
      changePeriod() {
        this.period = this.period === AM ? PM : AM
        if (
          this.month === 'Dec' &&
          this.activePort === 26 &&
          this.year === 2023 &&
          this.day === 22
        ) {
          if (this.hourIndex + 1 < 10 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (this.hourIndex + 1 > 2 && this.period == 'PM') {
            this.hourIndex = 1
            this.hour = 2
          } else if (this.hourIndex === 0 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (this.hourIndex === 0 && this.period == 'PM') {
            this.hourIndex = 1
            this.hour = 2
          }
        } else if (this.activePort !== 26 && this.day !== 22) {
          if (this.hourIndex + 1 < 10 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (this.hourIndex + 1 > 6 && this.period == 'PM') {
            this.hourIndex = 5
            this.hour = 6
          } else if (this.hourIndex === 0 && this.period == 'AM') {
            this.hourIndex = 9
            this.hour = 10
          } else if (this.hourIndex === 0 && this.period == 'PM') {
            this.hourIndex = 5
            this.hour = 6
          }
        }
      },
      calendarClicked(event) {
        if (event.target.id !== 'j-hour' && event.target.id !== 'j-minute') {
          this.minuteSelectorVisible = false
          this.hourSelectorVisible = false
        }
        event.cancelBubble = true
        if (event.stopPropagation) {
          event.stopPropagation()
        }
      },
      documentClicked(event) {
        if (event.target.id !== 'tj-datetime-input') {
          this.hideCal = true
        }
      },
      toggleCal() {
        this.hideCal = !this.hideCal
      },
      setPeriodStyle() {
        if (this.dateFormat.indexOf('H') !== -1) {
          this.periodStyle = 24
          this.period = null
        } else {
          this.periodStyle = 12
        }
      },
      setDate() {
        let d = null
        if(this.year === this.thisYear && (this.monthIndex < this.currentMonth || this.monthIndex === this.currentMonth && this.activePort < this.currentDay)) {
          return false
        }
        if (this.month === 'Dec') {
          setTimeout(() => {
            if (this.activePort + 2 > 22) {
              this.day = 2
              this.monthIndex = 0
              this.activePort = 1
              this.year = this.thisYear + 1
              this.notHidden = true
            }
          }, 10)
        }

        this.disableSpecDays()

        setTimeout(() => {
          this.setPeriodStyle()

          let h = this.hour + ''

          if (this.periodStyle === 12) {
            if (h === '12') {
              h = '12'
              this.period = 'PM'
            } else if (this.period === AM && parseInt(h) > 12) {
              h = parseInt(h) - 12
              h = '' + h
            }
          }
          d = this.dateFormat
          d = d.replace('YYYY', this.year)
          d = d.replace('DD', this.day < 10 ? '0' + this.day : this.day)
          const m = this.monthIndex + 1
          d = d.replace('MM', m < 10 ? '0' + m : m)
          this.minute += ''
          d = d.replace(this.periodStyle === 24 ? 'H' : 'h', h.length < 2 ? '0' + h : '' + h)
          d = d.replace('i', this.minute.length < 2 ? '0' + this.minute : '' + this.minute)
          d = d.replace('s', '00')
          this.$emit('input', d + ' ' + this.period)
          this.date = d + ' ' + this.period
          this.hideCal = true
        }, 50)
      },
      /**
      `*Creates a date object from a given date string
      */
      makeDateObject(val) {
        // handle support for eu date format
        const dateAndTime = val.split(' ')
        let arr = []
        if (this.format.indexOf('-') !== -1) {
          arr = dateAndTime[0].split('-')
        } else {
          arr = dateAndTime[0].split('/')
        }
        let year = 0
        let month = 0
        let day = 0
        if (this.format.indexOf('DD/MM/YYYY') === 0 || this.format.indexOf('DD-MM-YYYY') === 0) {
          year = arr[2]
          month = arr[1]
          day = arr[0]
        } else if (
          this.format.indexOf('YYYY/MM/DD') === 0 ||
          this.format.indexOf('YYYY-MM-DD') === 0
        ) {
          year = arr[0]
          month = arr[1]
          day = arr[2]
        } else {
          year = arr[2]
          month = arr[0]
          day = arr[1]
        }

        let date = new Date()
        if (this.hideDate) {
          // time only
          const splitTime = dateAndTime[0].split(':')
          // handle date format without seconds
          const secs = splitTime.length > 2 ? parseInt(splitTime[2]) : 0
          date.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]), secs, 0)
        } else if (this.hideTime) {
          // date only
          date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
        } else {
          // we have both date and time
          const splitTime = dateAndTime[1].split(':')
          // handle date format without seconds
          const secs = splitTime.length > 2 ? parseInt(splitTime[2]) : 0
          date = new Date(
            parseInt(year),
            parseInt(month) - 1,
            parseInt(day),
            parseInt(splitTime[0]),
            parseInt(splitTime[1]),
            secs
          )
        }

        return date
      },
      clearDate() {
        this.date = ''
        this.$emit('input', '')
        this.toggleCal()
      },
    },
    created() {
      setTimeout(() => {
        if (this.month === 'Dec' && this.day === 20) {
          this.inactiveHours = 1
        }
      }, 150)
      setTimeout(() => {
        this.currentMonth = this.months.findIndex((item) => item === this.month)
      }, 150)
      // if (this.value) {
      //   try {
      //     this.timeStamp = this.makeDateObject(this.value)

      //     // set #period (am or pm) based on date hour
      //     if (this.timeStamp.getHours() >= 12) {
      //       this.period = PM
      //     } else {
      //       this.period = AM
      //     }
      //   } catch (e) {
      //     this.timeStamp = new Date()
      //   }
      // }

      this.year = this.timeStamp.getFullYear()
      this.monthIndex = this.timeStamp.getMonth()
      const currentDate = new Date()
      const futureDate = new Date(currentDate)
      futureDate.setDate(currentDate.getDate() + 2)
      const dayOfWeek = futureDate.getDay()
      if (dayOfWeek === 6) {
        this.day = this.timeStamp.getDate() + 4
      } else if (dayOfWeek === 0) {
        this.day = this.timeStamp.getDate() + 3
      } else {
        this.day = this.timeStamp.getDate() + 2
      }
      this.disableSpecDays()
      this.hour = 10
      this.hour = this.hour < 10 ? '0' + this.hour : '' + this.hour
      this.minute = 0
      this.minute = this.minute < 10 ? '0' + this.minute : '' + this.minute
      this.updateCalendar()
      days.forEach((day, idx) => {
        this.days[(idx - this.normalizedFirstDayOfWeek + 7) % 7] = day
      })
      //document.addEventListener('keydown', this.keyIsDown)
      document.addEventListener('click', this.documentClicked)
      // this.setDate()
      this.setPeriodStyle()
      this.currentDay = this.timeStamp.getDate()
    },
    watch: {
      activePort(val) {
        if (val - 4 === 22) {
          this.inactiveHours = 1
        } else {
          this.inactiveHours = 5
        }
      },
      value(newVal, oldVal) {
        if (newVal) {
          try {
            this.timeStamp = this.makeDateObject(this.value)
          } catch (e) {
            console.warn(e.message + '. Current date is being used.')
            this.timeStamp = new Date()
          }
          this.year = this.timeStamp.getFullYear()
          this.monthIndex = this.timeStamp.getMonth()
          this.day = this.timeStamp.getDate()
          this.hour = this.timeStamp.getHours()
          this.hour = this.hour < 10 ? '0' + this.hour : '' + this.hour
          this.minute = this.timeStamp.getMinutes()
          this.minute = this.minute < 10 ? '0' + this.minute : '' + this.minute
          this.updateCalendar()
          this.setDate()
        }
      },
    },
    destroyed: function () {
      //document.removeEventListener('keydown', this.keyIsDown)
      document.removeEventListener('click', this.documentClicked)
    },
    computed: {
      normalizedFirstDayOfWeek: function () {
        return parseInt(this.firstDayOfWeek, 10)
      },
      ports: {
        get: function () {
          let p = []
          if (this.portsHolder.length === 0) {
            for (let i = 0; i < 42; i++) {
              p.push('')
            }
          } else {
            p = this.portsHolder
          }
          return p
        },
        set: function (newValue) {
          this.portsHolder = newValue
        },
      },
      month() {
        return this.months[this.monthIndex]
      },
      dateTime() {
        return (
          this.timeStamp.getFullYear() +
          '-' +
          (this.timeStamp.getMonth() + 1) +
          '-' +
          this.timeStamp.getUTCDay()
        )
      },
      minutes() {
        const arr = []
        for (let i = 0; i < 60; i += 15) {
          if (i < 10) {
            arr.push('0' + i)
          } else {
            arr.push('' + i)
          }
        }
        return arr
      },
      hours() {
        const arr = []
        if (this.periodStyle === 24) {
          for (let i = 0; i < this.periodStyle; i++) {
            if (i < 10) {
              arr.push('0' + i)
            } else {
              arr.push('' + i)
            }
          }
        } else {
          for (let i = 1; i <= 12; i++) {
            if (i < 10) {
              arr.push('0' + i)
            } else {
              arr.push('' + i)
            }
          }
        }
        return arr
      },
      dateFormat() {
        let f = 'YYYY-MM-DD h:i:s'
        const allowedFormats = [
          'YYYY-MM-DD h:i:s',
          'DD-MM-YYYY h:i:s',
          'MM-DD-YYYY h:i:s',
          'YYYY-MM-DD h:i',
          'DD-MM-YYYY h:i',
          'MM-DD-YYYY h:i',
          'YYYY-MM-DD H:i:s',
          'DD-MM-YYYY H:i:s',
          'MM-DD-YYYY H:i:s',
          'YYYY-MM-DD H:i',
          'DD-MM-YYYY H:i',
          'MM-DD-YYYY H:i',
          'YYYY-MM-DD',
          'DD-MM-YYYY',
          'MM-DD-YYYY',
          'YYYY/MM/DD',
          'DD/MM/YYYY',
          'MM/DD/YYYY',
          'h:i:s',
          'H:i:s',
          'h:i',
          'H:i',
          'YYYY/MM/DD h:i:s',
          'DD/MM/YYYY h:i:s',
          'MM/DD/YYYY h:i:s',
          'YYYY/MM/DD h:i',
          'DD/MM/YYYY h:i',
          'MM/DD/YYYY h:i',
          'YYYY/MM/DD H:i:s',
          'DD/MM/YYYY H:i:s',
          'MM/DD/YYYY H:i:s',
          'YYYY/MM/DD H:i',
          'DD/MM/YYYY H:i',
          'MM/DD/YYYY H:i',
        ]
        if (this.format) {
          f = this.format
        }
        if (allowedFormats.indexOf(f) < 0) {
          console.warn('Invalid date format supplied. Current default date format is being used.')
          // return default date format if date format is invalid
          return 'YYYY-MM-DD h:i:s'
        } else {
          return f
        }
      },
      hideTime() {
        return (
          this.dateFormat.indexOf('h:i:s') === -1 &&
          this.dateFormat.indexOf('H:i:s') === -1 &&
          this.dateFormat.indexOf('h:i') === -1 &&
          this.dateFormat.indexOf('H:i') === -1
        )
      },
      hideDate() {
        return (
          this.dateFormat === 'h:i:s' ||
          this.dateFormat === 'H:i:s' ||
          this.dateFormat === 'h:i' ||
          this.dateFormat === 'H:i'
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .noDisplay {
    display: none;
  }

  .year-month-wrapper {
    background-color: #ed4d00;
  }

  .port.notActive,
  .days.notActive {
    color: #bebebe;
    cursor: default;
  }

  input {
    width: 100%;
    min-width: 226px;
    height: 30px;
    padding: 3px;
    border: 1px solid #ddd;
  }

  .datetime-picker {
    position: relative;
  }

  .calender-div {
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 100;
    min-width: 270px;
    padding-bottom: 10px;
    font-size: 14px;
    color: #444;
    background: #fff;
    box-shadow: 1px 2px 5px #ccc;
  }

  .port,
  .days {
    display: inline-block;
    width: 30px;
    height: 30px;
    padding: 5px 3px;
    margin: 2px;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    border-radius: 2px;
  }

  .port.notActive:hover {
    color: #bebebe;
  }

  .days {
    font-weight: bold;
    color: #ed4d00;
  }

  .port:hover {
    font-weight: bold;
    color: #ed4d00;
  }

  .activePort,
  .activePort:hover {
    color: white;
    background-color: #ed4d00;
  }

  .month-setter,
  .year-setter {
    display: inline-block;
    width: 48.2%;
    margin: 0 1px;
    font-weight: 900;
    color: white;
  }

  .nav-l:hover,
  .nav-r:hover {
    background-color: #dc3c00;
  }

  .nav-l,
  .nav-r {
    display: inline-block;
    width: 25px;
    padding: 7px;
    margin: 0;
    font-size: 16px;
    color: white;
    cursor: pointer;
    background-color: #ed4d00;
    border: 0;
  }

  .nav-l:focus,
  .nav-r:focus {
    outline: none;
  }

  .nav-l {
    float: left;
  }

  .nav-r {
    float: right;
  }

  .month,
  .year {
    display: inline-block;
    width: 40px;
    padding: 7px 0;
    color: white;
    text-align: right;
  }
  /* .headers>span{

} */
  .hour-selector,
  .minute-selector {
    position: relative;
    display: inline-block;
    width: 30px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
  }

  .time-separator {
    display: inline-block;
    font-weight: bold;
  }

  .time-picker {
    display: inline-block;
    margin: 10px;
  }

  .nav-t,
  .nav-d {
    font-weight: bold;
    cursor: pointer;
  }

  .scroll-hider {
    position: absolute;
    top: -40px;
    left: 0;
    overflow: hidden;
    vertical-align: top;
    background-color: white;
    border: 0;
    box-shadow: 0 0 3px #333;
  }

  .scroll-hider ul {
    width: 55px;
    height: 100px;
    padding: 5px;
    margin: -5px -13px -5px -5px;
    overflow: auto;
    overflow-x: hidden;
    color: #999;
    list-style-type: none;
  }

  li.active {
    color: white;
    background-color: #ed4d00;
  }

  li {
    width: 100%;
    padding: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .okButton {
    float: right;
    padding: 0;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #ed4d00;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
</style>
