import algoliasearch from 'algoliasearch/lite'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    items: [],
    pagination: {
      currentPage: 1,
      pageSize: 12,
    },
  },

  mutations: {
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setItems(state, value) {
      state.items = value
    },
    incrementPage(state) {
      state.pagination.currentPage++
    },
    decrementPage(state) {
      state.pagination.currentPage--
    },
  },

  getters: {
    getIsLoading(state) {
      return state.isLoading
    },
    getItems(state) {
      return state.items
    },
    getTotalItems(state) {
      return state.items.length
    },
    getPaginatedItems(state, getters) {
      const size = state.pagination.pageSize
      const current = state.pagination.currentPage
      const last = current * size
      return getters.getItems.slice(0, last)
    },
    getPaginationData(state, getters) {
      const current = getters.getPaginatedItems.length
      const total = getters.getItems.length
      const remaining = total - current
      const toLoad = remaining < state.pagination.pageSize ? remaining : state.pagination.pageSize
      return {
        current: current,
        total: total,
        remaining: remaining,
        toLoad: toLoad,
      }
    },
    isLastPage(state, getters) {
      const total = getters.getItems.length
      const pageSize = state.pagination.pageSize
      const current = state.pagination.currentPage
      return pageSize * current >= total
    },
  },

  actions: {
    initItems(context) {
      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_ARTICLES_INDEX

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)

      // Make the call to Algolia and set the results
      index
        .search('', {
          hitsPerPage: 1000,
        })
        .then(({ hits }) => {
          context.commit('setItems', hits)
          context.commit('setIsLoading', false)
        })
    },

    searchArticles(context, { query }) {
      /* Get the keys and list to operate on from the packages/fwork/.env file */
      const appId = process.env.VUE_APP_ALGOLIA_APPLICATION_ID
      const apiKey = process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY
      const indexName = process.env.VUE_APP_ALGOLIA_ARTICLES_INDEX

      // Set the isLoading state
      context.commit('setIsLoading', true)

      // Initialize the index search
      const client = algoliasearch(appId, apiKey)
      const index = client.initIndex(indexName)

      // Run the search and then set the results in the products state
      index
        .search(query, {
          hitsPerPage: 1000,
          getRankingInfo: true,
        })
        .then(({ hits }) => {
          context.commit('setItems', hits)
          context.commit('setIsLoading', false)
        })
    },
    nextPage({ commit, getters }) {
      if (!getters.isLastPage) {
        commit('incrementPage')
      }
    },
  },
}
