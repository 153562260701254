<script>
  import { createNamespacedHelpers } from 'vuex'
  import ProductTile from '@/components/products/ProductTile'
  import BaseTileRowLoader from '@/components/BaseTileRowLoader'
  import BaseCarousel from '@/components/BaseCarousel'
  import BaseTabsNavSlider from '@/components/BaseTabsNavSlider'
  import BaseLink from '@/components/BaseLink'

  import { categoryUriToTitle } from '@/utilities/strings'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** Related Products component to render products that are related to current product by category, brand and Designer. */

  export default {
    name: 'ProductRelated',

    components: {
      ProductTile,
      BaseTileRowLoader,
      BaseCarousel,
      BaseTabsNavSlider,
      BaseLink,
    },

    props: {
      /** Current product */
      product: {
        type: Object,
        required: true,
      },
      /** Craft categories Array */
      categories: {
        type: Array,
        required: true,
      },
      /** Related products brand */
      brand: {
        type: Object,
        required: true,
      },
      /** Related products designer */
      designer: {
        type: Object,
        required: true,
      },
      /** Related products collection */
      collection: {
        type: Object,
        required: true,
      },
    },
    data: function () {
      return {
        tabs: [
          {
            title: 'collection',
            value: Object.keys(this.collection).length !== 0 ? this.collection.title : null,
          },
          {
            title: 'brand',
            value: Object.keys(this.brand).length !== 0 ? this.brand.title : null,
          },
          {
            title: 'designer',
            value: Object.keys(this.designer).length !== 0 ? this.designer.title : null,
          },
          {
            title: 'category',
            value: null,
          },
        ],
        loader: {
          width: 0,
          height: 0,
        },
        activeTab: {
          title: null,
          value: null,
        },
        seeAllUrl: null,
        offsetLeft: 0,
      }
    },

    computed: {
      ...mapGetters(['getPaginatedProducts', 'getProducts', 'getIsLoading', 'getShuffledProducts']),
      availableTabs() {
        /** only show tabs that have more than one product and which also have values set through the props. */
        return this.tabs.filter((tab) => tab.value !== null)
      },
      brandAndCollTabs() {
        return this.availableTabs.filter(
          (tab) => tab.title === 'brand' || tab.title === 'collection'
        )
      },
      /** Returns link text for see all link */
      sellAllLinkText() {
        const linkText = this.activeTab.categoryTitle
          ? this.activeTab.categoryTitle
          : this.activeTab.value
        const text =
          this.activeTab.title === 'collection'
            ? `in this collection`
            : `from ${this.activeTab.title} : ${linkText}`

        return text
      },
      /** Returns the loaders emitted height */
      loaderHeight: function () {
        return this.loader.height
      },
      /** Create the loader CSS style height string */
      loaderStyles: function () {
        return 'height:' + this.loaderHeight + 'px;'
      },
    },

    created() {
      /** set related products to show 12 products */
      this.setPaginationPageSize(12)

      /** Set value for the parent category tab */
      this.tabs = this.tabs.map((tab) => {
        let category = ''

        if (tab.title === 'category') {
          category = this.categories.find((category) => category.level === 3)

          if (!category) {
            category = this.categories.find((category) => category.level === 2)
          }

          if (category) {
            tab.id = category.id
            tab.value = category.title
            tab.uri = category.uri
            this.activeTab = tab
            this.seeAllUrl = `/catalog/${tab.value}`
          }

          return tab
        }
        return tab
      })

      /** Prepare subscategories and add them to the tabs array */
      // const subcategories = this.categories
      //   .filter((category) => category.level > 1)
      //   .map((category) => {
      //     return {
      //       id: category.id,
      //       title: 'category',
      //       slug: category.slug,
      //       categoryTitle: `${this.categoryUriToTitle(category.uri, true).split('/')[0]}/ ${
      //         category.title
      //       }`,
      //       value: this.categoryUriToTitle(category.uri, true),
      //       level: category.level,
      //       uri: category.uri,
      //     }
      //   })
      /** Add subcategories after parent category in the tabs array */
      // this.tabs.splice(1, 0, ...subcategories)

      // if (subcategories.length > 0) {
      //   this.tabs[0].value = null
      //   this.setActiveTab(subcategories[0])
      // } else {
      // }
      this.setActiveTab(this.availableTabs[0])
    },

    mounted() {
      this.tabs = this.tabs.filter((tab) => tab.value !== null)
      this.getOffsetLeft()
      window.addEventListener('resize', this.getOffsetLeft)
    },

    methods: {
      ...mapActions(['setFilterValue', 'initProducts', 'setPaginationPageSize']),
      /** Sets the current Active tab and filters the products by current active tab value */
      getOffsetLeft() {
        const containerHeader = document.querySelector('.backToCatalog')
        if (containerHeader !== null) {
          this.offsetLeft = parseInt(containerHeader.getBoundingClientRect().left.toFixed(0)) - 5
        }
      },
      setActiveTab(tab) {
        this.activeTab = this.tabs.find(
          (tabItem) => tabItem.value === tab.value && tabItem.title === tab.title
        )

        switch (tab.title) {
          case 'category':
            this.seeAllUrl = tab.uri !== undefined ? `/${tab.uri}` : `/catalog/${tab.title}`
            this.initProducts({
              categoryId: tab.id,
              excludeProductId: this.product.id,
            })
            break
          case 'collection':
            this.seeAllUrl = `/search/?searchTerm=${this.collection.title}`
            this.initProducts({
              collection: tab.value,
              excludeProductId: this.product.id,
            })
            break
          case 'brand':
            this.seeAllUrl = `${this.brand.url}`
            this.initProducts({
              brand: tab.value,
              excludeProductId: this.product.id,
            })
            break
          case 'designer':
            this.seeAllUrl = `${this.designer.url}`
            this.initProducts({
              designer: tab.value,
              excludeProductId: this.product.id,
            })
            break
        }
      },
      /** Sets the loader components height */
      setLoaderHeight(loader) {
        this.loader.height = loader.height
      },
      categoryUriToTitle,
    },
  }
</script>

<template>
  <div>
    <!--<div
      ref="relatedProducts"
      class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6"
    ></div>-->
    <aside
      class="related-products relative bg-white mb-24"
      style="z-index: 9; width: 100%"
      aria-label="related products"
    >
      <transition name="fade">
        <div v-if="getIsLoading" class="absolute z-10 || loader" :style="loaderStyles">
          <BaseTileRowLoader @resize="setLoaderHeight" />
        </div>
      </transition>

      <transition name="fade">
        <div v-if="!getIsLoading">
          <div v-if="getShuffledProducts.length > 0" class="tabs">
            <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6 mb-12 md:mb-1">
              <nav class="flex lg:flex-row lg:overflow-x-hidden" role="tablist">
                <BaseTabsNavSlider>
                  <button
                    v-for="tab in brandAndCollTabs"
                    :key="tab.value + tab.title"
                    class="tabs__btn sm:text-md lg:text-l focus:outline-none text-left"
                    :class="[
                      tab.value === activeTab.value && tab.title === activeTab.title
                        ? 'tabs__btn--active'
                        : 'text-gray-600 hover:text-gray-700 focus:text-gray-700',
                    ]"
                    role="tab"
                    :aria-selected="tab.title === activeTab.title ? 'true' : 'false'"
                    @click.prevent="setActiveTab(tab)"
                  >
                    <span v-if="tab.title !== 'collection'">
                      More from {{ tab.categoryTitle ? tab.categoryTitle : tab.value }}
                    </span>
                    <span v-else> More from the {{ tab.value }} collection </span>
                  </button>
                </BaseTabsNavSlider>
              </nav>
            </div>
            <div
              role="tabpanel"
              class="tabs-content py-5"
              :style="{ 'padding-left': offsetLeft + 'px' }"
            >
              <BaseCarousel
                :class="`${getShuffledProducts.length < 5 ? 'no--center' : ''}`"
                :offset-left="offsetLeft"
              >
                <ProductTile
                  v-for="shuffledProduct in getShuffledProducts"
                  :key="shuffledProduct.id"
                  v-bind="shuffledProduct"
                />
              </BaseCarousel>
              <!--<BaseLink
                :href="`${seeAllUrl}`"
                role="link"
                class="mt-5 sm:text-md lg:text-l text-gray-600 hover:text-gray-700 focus:text-gray-700"
              >
                See all products {{ sellAllLinkText }}
              </BaseLink>-->
            </div>
          </div>
        </div>
      </transition>
    </aside>
  </div>
</template>

<style scoped>
  .tabs__btn {
    position: relative;
    width: auto !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 3rem;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    @apply cursor-pointer;
    @screen lg {
      white-space: initial;
    }
    @media (max-width: 992px) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  .tabs__btn::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    content: '';
    background: #000;
    transition: all 0.25s ease-in-out;
  }

  .tabs__btn--active::before {
    width: 100%;
  }
</style>

<style>
  .relatedHeader {
    margin-bottom: 20px;
    overflow: hidden;
  }

  .tabs-content {
    display: flex;
    flex-wrap: wrap;
  }

  .loader {
    top: 16px;
    right: 16px;
    left: 16px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  @media (max-width: 992px) {
    .tabs-content {
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
    .relatedHeader {
      margin-bottom: 24px;
    }
  }
</style>
