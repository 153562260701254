<script>
  import IconX from '@/images/icons/x.svg'

  /** A reusable modal component that uses a slot to insert its contents */

  export default {
    name: 'BaseModal',

    components: {
      IconX,
    },
    computed: {
      hasHeaderSlot() {
        return this.$slots.header
      },
      hasFooterSlot() {
        return !!this.$slots.footer
      },
    },
    methods: {
      /** Emits a 'close' event to the parent component so it can be closed from sibling components */
      close() {
        this.$emit('close')
      },
      /** Forces focus on the close button of the modal */
      focusCloseButton() {
        this.$refs.closeButton.focus()
      },
    },
  }
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-bg">
      <section
        role="dialog"
        aria-labelledby="modalTitle"
        class="bg-white overflow-hidden modal-window"
      >
        <header
          v-if="hasHeaderSlot"
          class="flex justify-between items-center pl-6 border-b border-gray-200 modal-header"
        >
          <slot name="header"></slot>

          <button
            ref="closeButton"
            type="button"
            class="flex justify-center items-center overflow-hidden modal-close"
            tabindex="0"
            @click="close"
          >
            <span>Close</span>
            <IconX class="fill-current" />
          </button>
        </header>

        <div class="p-6 scrollbox modal-body">
          <slot name="body"></slot>
        </div>

        <footer
          v-if="hasFooterSlot"
          class="flex justify-between items-center px-6 border-t border-gray-200 modal-footer"
        >
          <slot name="footer"></slot>
        </footer>
      </section>
    </div>
  </transition>
</template>

<style scoped lang="postcss">
  .modal-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.65);
  }

  .modal-window {
    position: absolute;
    top: 110px;
    right: 10px;
    left: 10px;
    overflow-y: auto;
    border-radius: 20px;
  }

  .modal-header {
    height: 56px;
  }

  .modal-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .modal-close {
    width: 56px;
    height: 56px;

    span {
      position: absolute;
      left: -9999px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .modal-body {
    max-height: 530px;
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }

  @screen sm {
    .modal-window {
      top: 110px;
    }
  }

  @screen md {
    .modal-window {
      top: 140px;
      right: 30px;
      bottom: auto;
      left: 30px;
    }
  }

  @screen lg {
    .modal-window {
      right: 60px;
      bottom: auto;
      left: 60px;
    }
  }

  @media screen and (min-width: 1280px) and (min-height: 1024px) {
    .modal-window {
      top: 220px;
      right: 120px;
      left: 120px;
    }
  }
</style>
