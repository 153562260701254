<script>
  export default {
    name: 'LegacyArticleThumb',
    props: {
      article: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
  }
</script>

<template>
  <article class="article-thumb">
    <a :href="article.url" class="article-thumb__wrap">
      <div class="article-thumb__image">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="article-thumb__image-wrap" v-html="article.image"></div>
      </div>
      <div class="article-thumb__info">
        <h4 class="article-thumb__eyebrow">{{ article.eyebrow }}</h4>
        <h3 class="article-thumb__title">{{ article.title }}</h3>
      </div>
    </a>
  </article>
</template>

<style scoped>
  .article-thumb__image {
    position: relative;
    width: 25%;
    padding-top: 25%;
    overflow: hidden;
    background-color: rgb(229, 231, 239);

    .article-thumb__image-wrap {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      transition: transform 3s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  .article-thumb__wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:hover {
      .article-thumb__image .article-thumb__image-wrap {
        transform: scale(1.1);
      }
    }
  }

  .article-thumb__info {
    flex-grow: 1;
    padding: 0 0 0 20px;
  }

  .article-thumb__eyebrow {
    font-size: 11px;
    line-height: 1;
    color: #6f6f75;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .article-thumb__title {
    margin: 8px 0 0 0;
    font-size: 16px;
    line-height: 1.3;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
</style>
