import { createToken } from 'vue-stripe-elements-plus'
import api from '@/api'

export const ADDRESS_FIELDS = [
  'firstName',
  'lastName',
  'phone',
  'alternativePhone',
  'address1',
  'address2',
  'city',
  'stateId',
  'zipCode',
]

export default {
  namespaced: true,

  state: {
    states: [],
    allSteps: [
      {
        label: 'Contact',
        value: 'contact',
      },
      {
        label: 'Shipping',
        value: 'shipping',
      },
      {
        label: 'Payment',
        value: 'payment',
      },
      {
        label: 'Confirm',
        value: 'confirm',
      },
    ],
    currentStep: 'contact',
    email: '',
    hasAccount: null,
    shippingAddress: {
      firstName: '',
      lastName: '',
      phone: '',
      alternativePhone: '',
      address1: '',
      address2: '',
      city: '',
      stateId: '',
      zipCode: '',
      isVerifying: false,
      verified: false,
    },
    billingAddress: {
      firstName: '',
      lastName: '',
      phone: '',
      alternativePhone: '',
      address1: '',
      address2: '',
      city: '',
      stateId: '',
      zipCode: '',
      isVerifying: false,
      verified: false,
    },
    billingSameAsShipping: true,
    stripeKey: null,
    processingPayment: false,
    paymentError: null,
    orderComplete: false,
    isShippingValid: false,
    shippingIsSubmitting: false,
    isPaymentValid: false,
    paymentIsSubmitting: false,
    cardComplete: false,
    confirmTradeUserCheckbox: null,
  },

  mutations: {
    setStates(state, value) {
      state.states = value
    },
    setCurrentStep(state, value) {
      state.currentStep = value
    },
    setEmail(state, value) {
      state.email = value
    },
    setHasAccount(state, value) {
      state.hasAccount = value
    },
    setShippingAddress(state, value) {
      state.shippingAddress = value
    },
    setShippingAddressIsVerifying(state, value) {
      state.shippingAddress.isVerifying = value
    },
    setShippingAddressVerified(state, value) {
      state.shippingAddress.verified = value
    },
    setBillingAddress(state, value) {
      state.billingAddress = value
    },
    setBillingAddressIsVerifying(state, value) {
      state.billingAddress.isVerifying = value
    },
    setBillingAddressVerified(state, value) {
      state.billingAddress.verified = value
    },
    setBillingSameAsShipping(state, value) {
      state.billingSameAsShipping = value
    },
    setStripeKey(state, value) {
      state.stripeKey = value
    },
    setProcessingPayment(state, value) {
      state.processingPayment = value
    },
    setPaymentError(state, value) {
      state.paymentError = value
    },
    setOrderComplete(state, value) {
      state.orderComplete = value
    },
    setIsShippingValid(state, value) {
      state.isShippingValid = value
    },
    setShippingIsSubmitting(state, value) {
      state.shippingIsSubmitting = value
    },
    setIsPaymentValid(state, value) {
      state.isPaymentValid = value
    },
    setPaymentIsSubmitting(state, value) {
      state.paymentIsSubmitting = value
    },
    setCardComplete(state, value) {
      state.cardComplete = value
    },
    setConfirmTradeUserCheckbox(state, value) {
      state.confirmTradeUserCheckbox = value
    },
  },

  getters: {
    getStates(state) {
      return state.states
    },
    getCurrentStep(state) {
      return state.allSteps.filter((step) => step.value === state.currentStep)[0]
    },
    getAllSteps(state) {
      return state.allSteps
    },
    getEmail(state) {
      return state.email
    },
    getHasAccount(state) {
      return state.hasAccount
    },
    getShippingAddress(state) {
      return state.shippingAddress
    },
    getBillingAddress(state) {
      return state.billingAddress
    },
    getBillingSameAsShipping(state) {
      return state.billingSameAsShipping
    },
    getStripeKey(state) {
      return state.stripeKey
    },
    getProcessingPayment(state) {
      return state.processingPayment
    },
    getPaymentError(state) {
      return state.paymentError
    },
    getOrderComplete(state) {
      return state.orderComplete
    },
    getIsShippingValid(state) {
      return state.isShippingValid
    },
    getShippingIsSubmitting(state, value) {
      return state.shippingIsSubmitting
    },
    getIsPaymentValid(state) {
      return state.isPaymentValid
    },
    getPaymentIsSubmitting(state) {
      return state.paymentIsSubmitting
    },
    getCardComplete(state) {
      return state.cardComplete
    },
    getConfirmTradeUserCheckbox(state) {
      return state.confirmTradeUserCheckbox
    },
  },

  actions: {
    /** Initializes the checkout process by loading the cart data  */
    loadCheckout(context) {
      // Set the stripe key
      context.commit('setStripeKey', window.stripeKey)
    },

    /** Saves email in Craft cart and then saves to state */
    saveEmail(context, email) {
      return api
        .post('commerce/cart/update-cart', {
          email: email,
        })
        .then((response) => {
          if (response.status === 200) {
            context.commit('setEmail', response.data.cart.email)
            return context.dispatch('cart/loadCart', {}, { root: true })
          } else {
            throw response.data?.error ?? 'Could not save email.'
          }
        })
    },

    /** Logs in the user and reloads the page */
    logIn(context, { email, password }) {
      return api
        .post('users/login', {
          loginName: email,
          password: password,
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            window.location.reload()
          } else {
            throw response.data?.error ?? 'Unable to log in, please try again.'
          }
        })
    },

    /** Saves the shipping address to craft and then sets it in state */
    saveShippingAddress(context, shippingAddress) {
      return api
        .post('commerce/cart/update-cart', {
          shippingAddress: {
            ...shippingAddress,
            countryId: 233, // United States
          },
          billingAddressSameAsShipping: context.getters.getBillingSameAsShipping,
        })
        .then((response) => {
          if (response.status === 200 && response.data.success) {
            context.commit('setShippingAddress', shippingAddress)
            return context.dispatch('cart/loadCart', {}, { root: true })
          } else {
            throw response.data?.error ?? 'Unable to save shipping address, please try again.'
          }
        })
    },

    /** Saves the billing address to craft and then sets it in state */
    saveBillingAddress(context, { billingSameAsShipping, billingAddress }) {
      const params = {
        billingAddressSameAsShipping: billingSameAsShipping,
      }
      if (!billingSameAsShipping) {
        params.billingAddress = {
          ...billingAddress,
          countryId: 233, // United States
        }
      }
      return api.post('commerce/cart/update-cart', params).then((response) => {
        if (response.status === 200 && response.data.success) {
          context.commit('setBillingSameAsShipping', billingSameAsShipping)
          context.commit('setBillingAddress', billingAddress)
        } else {
          throw response.data?.error ?? 'Unable to save billing address, please try again.'
        }
      })
    },

    /** Makes the payment through craft (using Stripe) and send the user to the confirmation page */
    pay(context) {
      // Create the stripe token
      return createToken()
        .then((stripeData) => {
          // Initialise the processing and error flags
          context.commit('setProcessingPayment', true)
          context.commit('setPaymentError', null)

          // Make the request
          return api.post('commerce/payments/pay', {
            gatewayId: 2,
            stripeToken: stripeData.token.id,
          })
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success) {
              context.commit('setOrderComplete', true)
              // Send the user to the confirmation page with the order number as a URL param
              window.location.replace('/shop/confirmation?number=' + response.data.cart.number)
            } else {
              context.commit('setPaymentError', response.data.error)
              context.commit('setOrderComplete', false)
            }
          } else {
            context.commit('setOrderComplete', false)
            context.commit(
              'setPaymentError',
              'Unable to connect to payment gateway. Please try again in a few minutes.'
            )
          }
          // Set the verifying flag
          context.commit('setProcessingPayment', false)
        })
    },
  },
}
