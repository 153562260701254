<script>
  import IconChevronSmallDown from '@/images/icons/chevron-small-down.svg'
  const slugify = require('@sindresorhus/slugify')

  /** This is a generic dropdown select component to handle dialing codes.
   * See https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model. */

  export default {
    name: 'BaseDialingCodeSelect',

    components: {
      IconChevronSmallDown,
    },

    /** Disable automatic attribute inheritance, so that $attrs are
     * passed to the <select>, even if it's not the root element.
     * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance */
    inheritAttrs: false,

    props: {
      /** Label for the field */
      label: {
        type: String,
        required: true,
      },
      /** Whether this field is required */
      isRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      /** For binding the value */
      value: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        // TODO: Change these to match the supported countries (if this component gets used)
        options: [
          {
            name: 'Niger',
            dial_code: '+227',
            code: 'NE',
          },
          {
            name: 'Nigeria',
            dial_code: '+234',
            code: 'NG',
          },
          {
            name: 'Niue',
            dial_code: '+683',
            code: 'NU',
          },
          {
            name: 'Norfolk Island',
            dial_code: '+672',
            code: 'NF',
          },
        ],
      }
    },

    computed: {
      /** Create a unique label id for matching the label and field */
      fieldId() {
        return slugify(this.label)
      },
    },

    methods: {
      /** Pass the option's id or label to the parent. Each option should be an object including a country `name`, a country `code`, and a `dial_code`. */
      updateSelectedOption(event) {
        this.$emit(
          'input',
          this.options.find((option) => String(option.dial_code) === String(event.target.value))
            .dial_code
        )
      },

      getOptionLabel(countryCode, dialCode) {
        return `${countryCode}  | ${dialCode}`
      },
    },
  }
</script>

<template>
  <div>
    <label class="label pb-2" :for="fieldId">{{ label }}</label>

    <div class="block relative">
      <select
        :id="fieldId"
        ref="selectField"
        :required="isRequired"
        class="block font-light text-base appearance-none w-full bg-white border rounded-none border-gray-400 hover:border-gray-500 px-3 py-3 pr-6 focus:outline-none focus:shadow-outline"
        v-bind="$attrs"
        @input="updateSelectedOption"
      >
        <option v-if="!isRequired" :value="null">Country</option>
        <option
          v-for="option in options"
          :key="option.code"
          :value="option.code"
          :selected="String(value) === String(option.code)"
        >
          {{ getOptionLabel(option.code, option.dial_code) }}
        </option>
      </select>
      <div
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
      >
        <IconChevronSmallDown class="fill-current h-4 w-4" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  label {
    white-space: nowrap;
  }
</style>
