<script>
  import { eventBus } from '@/main.js'
  import pinIcon from '@/images/icons/pin.svg'
  /** A Reusable TextRow component that can be used in content builder and standalone templates */

  export default {
    name: 'NewBuilderTextWithImages',
    components: {
      pinIcon,
    },
    props: {
      /** The heading for the block, if defined */
      title: {
        type: String,
        required: true,
        default: '',
      },
      text: {
        type: String,
        required: true,
        default: '',
      },
      linkText: {
        type: String,
        default: '',
      },
      link: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      textAlign: {
        type: Boolean,
        default: false,
      },
      imageBlocks: {
        type: Array,
        required: false,
        default: () => [],
      },
      customLink: {
        type: String,
        default: '',
      },
    },
    computed: {
      resizedImage() {
        return this.bannerImage.urls[0]
      },
    },
    methods: {
      showFormOnScroll() {
        const element = document.getElementById('tradeAccountForm')
        const noticeElement = document.getElementById('notice')
        const notHeight = noticeElement ? noticeElement.offsetHeight : 0
        const headerHeight = document.getElementById('ref-header').offsetHeight
        this.showForm = true
        if (element) {
          eventBus.$emit('showFormOnScroll', this.showForm)
          setTimeout(() => {
            const sum = notHeight + headerHeight - 20
            if (element) {
              const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - sum
              window.scrollTo({ top: elementPosition, behavior: 'smooth' })
            }
          }, 200)
        }
      },
    },
  }
</script>

<template>
  <div>
    <div ref="imageSection" class="w-full imageSections">
      <div
        class="lg:flex lg:flex-row h500 mx-0 lg:-mx-5"
        :class="{ 'lg:flex-row-reverse lg:space-x-reverse': textAlign }"
      >
        <div class="w-full lg:w-8/12" :class="{ 'lg:px-5': imageBlocks.length === 1 }">
          <div
            class="w-full lg:flex lg:flex-row"
            :class="{ 'space-y-10 lg:space-y-0': imageBlocks.length > 1 }"
          >
            <div
              v-for="(block, idx) in imageBlocks"
              :key="idx"
              class="w-full h-full"
              :class="{ 'lg:w-6/12 lg:px-5': imageBlocks.length > 1 }"
            >
              <div class="h500 w-full imgRepeatWrapper">
                <img
                  v-if="imageBlocks.length === 1"
                  class="w-full h-full"
                  :src="block.fullImage.urls[0]"
                  :alt="block.fullImage.alt"
                />
                <img
                  v-else
                  class="w-full h-full"
                  :src="block.shortImage.urls[0]"
                  :alt="block.shortImage.alt"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full pt-8 lg:pt-0 lg:w-4/12 textWrapper">
          <h2
            v-if="title"
            class="font-bold mb-4 lg:mb-0 text-3xl md:text-4xl lg:px-5 tracking-wider font-cormorant"
            v-html="title"
          ></h2>
          <div v-if="text" class="bottomText lg:px-5">
            <div class="flex flex-col space-y-4 mb-10" v-html="text"></div>
            <a
              v-if="link"
              :href="link.url"
              class="button-height font-semibold text-sm w-full flex justify-center md:inline-block md:w-auto px-20 | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
            >
              <span v-if="linkText">{{ linkText }}</span>
              <span v-else>{{ link.title }}</span>
            </a>
            <a
              v-else-if="customLink"
              :href="customLink"
              class="button-height font-semibold text-sm w-full flex justify-center md:inline-block md:w-auto px-20 | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
            >
              <span v-if="linkText">{{ linkText }}</span>
              <span v-else>{{ link.title }}</span>
            </a>
            <!-- <a
              v-else
              href="#"
              @click.prevent="showFormOnScroll"
              class="button-height font-semibold text-sm w-full flex justify-center md:inline-block md:w-auto px-20 | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
            >
              <span v-if="linkText">{{ linkText }}</span>
              <span v-else>{{ link.title }}</span>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .h500 {
    height: 500px;
  }

  .bottomPad {
    padding-bottom: 16px;
  }

  h1 {
    font-size: 80px;
    line-height: 96px;
  }

  h2 {
    line-height: 110%;
  }

  .imageSections {
    padding-top: 80px;
    padding-bottom: 0;

    img {
      object-fit: cover;
    }
    @media (max-width: 1024px) {
      padding-top: 60px;
    }
  }

  .imageWrapper {
    width: 100%;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .textWrapper {
    position: relative;

    h2 {
      display: block;
      margin-bottom: 30px;
    }

    .bottomText {
      width: 100%;

      p {
        display: block;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    .h500.imgRepeatWrapper {
      height: 450px;
    }

    .h500 {
      height: auto;
    }

    .textWrapper {
      h2 {
        position: static;
        margin-bottom: 20px;
      }

      .bottomText {
        position: static;
      }
    }

    .bottomPad {
      padding-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    .h500.imgRepeatWrapper {
      height: 320px;
    }
  }
</style>
