<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseButton from '@/components/BaseButton'

  const { mapGetters, mapActions } = createNamespacedHelpers('products')

  /** Pagination button that appears below the PLP listing grid to allow users to load more products.
   * Displays the total amount, total showing and amount left on next click. */

  export default {
    name: 'ProductPagination',

    components: {
      BaseButton,
    },

    props:{
      count:{
        type: Number,
        default: 0,
      }
    },

    computed: {
      /** Vuex getter methods */
      ...mapGetters(['getPaginationData', 'isLastPage']),

      shownQuantity: function () {
        return (
          '<span data-cy="currentNumberOfProducts">' + this.getPaginationData.current + '</span>'
        )
      },

      totalQuantity: function () {
        return '<span data-cy="totalNumberOfProducts">' + this.getPaginationData.total + '</span>'
      },
    },

    methods: {
      /** Vuex action methods */
      ...mapActions(['nextPage']),
    },
  }
</script>

<template>
  <nav
    aria-label="Product pagination"
    class="flex flex-col justify-center items-center -mb-12 md:-mb-0 pb-0 pt-4 underline underline-2 hover:no-underline | md:flex-row || wrapper"
    :class="`${ isLastPage ? 'hasNoLoadMore' : ''}`"
  >
    <span
      class="text-gray-500 || desktop invisible"
      v-html="'Showing ' + shownQuantity + ' Products'"
    />

    <transition name="fade">
      <BaseButton
        v-if="!isLastPage"
        :color="'gray'"
        :rounded="true"
        class="mb-2 | md:mb-0"
        @button-click="nextPage"
      >
        See More
      </BaseButton>
    </transition>

    <span
      class="text-gray-500 || desktop invisible"
      aria-label="total products"
      v-html="'Total ' + totalQuantity + ' Products'"
    />

    <span
      class="block text-xs text-gray-500 || mobile invisible"
      aria-current="true"
      aria-label="current products"
      v-html="'Showing ' + shownQuantity + ' of ' + totalQuantity + ' Products'"
    />
  </nav>
</template>

<style scoped lang="postcss">
.wrapper {
    min-height: 123px;
    &.hasNoLoadMore {
      min-height: 0;
      height: 0;
      overflow: hidden;
      padding: 0;
    }
  }

  .desktop {
    @apply hidden;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @screen md {
    .wrapper {
      min-height: 97px;
    }

    .mobile {
      @apply hidden;
    }

    .desktop {
      @apply inline-block;
    }
  }
</style>
