var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"max-w-6xl container mx-auto mt-20 mb-32 px-4"},[_c('h1',{staticClass:"text-2xl lg:text-4xl text-center mb-12 lg:mb-16"},[_vm._v(" Haute Living Account ")]),_vm._v(" "),_c('div',{staticClass:"max-w-lg mx-auto"},[_c('h2',{staticClass:"text-lg mb-8"},[_vm._v("Register")]),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"mb-4"},[(_vm.error)?_c('BaseError',{attrs:{"error":_vm.error}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('ValidationObserver',{ref:"customerRegistrationForm"},[_c('form',{attrs:{"id":"register","method":"post","accept-charset":"UTF-8"},on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmission.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"label":"First Name","value":_vm.firstName,"type":"text","name":"firstName"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),(errors.length)?_c('BaseError',{attrs:{"error":errors[0]}}):_vm._e()]}}])})],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"label":"Last Name","value":_vm.lastName,"type":"text","name":"lastName"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_vm._v(" "),(errors.length)?_c('BaseError',{attrs:{"error":errors[0]}}):_vm._e()]}}])})],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"label":"Email","value":_vm.email,"type":"email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),(errors.length)?_c('BaseError',{attrs:{"error":errors[0]}}):_vm._e()]}}])})],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInputText',{attrs:{"label":"Password","value":_vm.password,"type":"password","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),(errors.length)?_c('BaseError',{attrs:{"error":errors[0]}}):_vm._e()]}}])})],1),_vm._v(" "),_c('div',[_c('BaseButton',{attrs:{"capitalize":"","color":"yellows","full-width":"","type":"submit"}},[_vm._v("Register")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }