import axios from 'axios'
import { stringify } from 'qs'
import merge from 'lodash/merge'

const post = (action, params = {}, config = {}) => {
  if (params instanceof FormData) {
    params.append(window.Craft.csrfTokenName, window.Craft.csrfTokenValue)
  } else {
    params = {
      ...params,
      [window.Craft.csrfTokenName]: params.CRAFT_CSRF_TOKEN ?? window.Craft.csrfTokenValue,
    }
  }

  return axios.post(
    `/?${stringify({ action })}`,
    params,
    merge(
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          HTTP_X_REQUESTED_WITH: 'XMLHttpRequest',
        },
      },
      config
    )
  )
}

const startedCheckout = (email) => {
  return post('/klaviyoconnect/api/track', {
    event: { name: 'Started Checkout', trackOrder: true },
    email,
  })
}

export default {
  post,
  startedCheckout,
}
