<script>
  import { createNamespacedHelpers } from 'vuex'
  import ProductCategoryHeader from '@/components/products/ProductCategoryHeader'
  import IconClose from '@/images/icons/clear-filters.svg'
  import FilterIcon from '@/images/icons/filter-icon.svg'

  const { mapActions, mapState, mapGetters } = createNamespacedHelpers('products')

  /** The PLP main app component. Uses Vuex action to load the products on page load based on the Craft category
   * slug. If no slug is present or the slug is invalid it will get all products.
   * See (Craft module controller): /modules/hauteliving/controllers/SiteController.php actionGetProducts()
   * See (Craft module service): /modules/hauteliving/services/SiteService.php getProductList() */

  export default {
    name: 'ProductMainCategoryPage',

    components: {
      ProductCategoryHeader,
      IconClose,
      FilterIcon,
    },

    props: {
      title: {
        type: String,
        default: '',
        required: false,
      },
      summary: {
        type: String,
        default: null,
      },
      childCategories: {
        type: Array,
        default: () => [],
      },
    },

    created() {
      this.$store.dispatch('products/setChildCategories', this.childCategories)
      this.$store.dispatch('products/setType', 'parentCategory')
    },
    computed: {
      ...mapGetters(['getPaginatedFeaturedProducts']),
    }
  }
</script>

<template>
  <main id="main-content">
    <article class="plp">
      <ProductMainCategoryHeader v-if="title !== ''" :title="title" :summary="summary" />
      <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
        <div
          v-if="getPaginatedFeaturedProducts.length > 0"
          class="flex flex-wrap flex-col md:flex-row -mx-2 mb-4 pt-4 md:pt-8"
        >
          <div
            v-for="category in childCategories"
            :key="category.id"
            class="w-full md:w-1/3 xl:w-1/4 px-2 mb-12"
          >
            <div class="w-full">
              <div class="w-full mb-4 h-80 relative">
                <a :href="category.url" class="absolute block top-0 left-0 w-full h-full"></a>
                <img
                  :src="category.thumb.urls[0]"
                  :alt="category.thumb.alt"
                  class="w-full h-full object-cover"
                />
              </div>
              <h3 class="block w-full text-left font-unica77 tracking-wider m-0 font-medium"
                ><a :href="category.url">{{ category.title }}</a></h3
              >
            </div>
          </div>
        </div>
        <!-- <FeaturedPagination class="mt-4 | md:mt-6" :count="getPaginatedFeaturedProducts.length" /> -->
        <div class="h-4 md:h-8 w-full"></div>
      </div>
    </article>
  </main>
</template>

<style lang="scss">
  h3 {
    font-size: 16px;
    line-height: 110%;
  }
  @media(max-width:768px) {
    h3 {
      font-size: 14px;
    }
  }
</style>
