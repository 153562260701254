<script>
  export default {
    name: 'LegacyFooter',

    props: {
      data: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },
  }
</script>

<template>
  <footer class="l-site-footer">
    <div class="l-site-footer__inner">
      <div class="l-site-footer__address">
        <h6 class="site-footer__heading">Visit Our Showroom</h6>
        <div itemscope itemtype="http://schema.org/FurnitureStore">
          <address class="site-footer__body" itemprop="address">
            <slot name="address"></slot>
          </address>
        </div>
        <div class="site-footer__small-copy"> (Corner of Franklin & Institute) </div>
      </div>

      <div class="l-site-footer__contact">
        <h6 class="site-footer__heading">Get in Touch</h6>
        <div itemscope itemtype="http://schema.org/FurnitureStore">
          <p class="site-footer__body">
            <a itemprop="telephone" :href="'tel:' + data.phone.machine">{{ data.phone.human }}</a>
            <a itemprop="email" :href="'mailto:' + data.email" class="site-footer__email">{{
              data.email
            }}</a>
          </p>
        </div>
      </div>

      <div class="l-site-footer__newsletter">
        <div class="newsletter-footer">
          <div class="newsletter-footer__form js-newsletter-form">
            <slot name="newsletter"></slot>
          </div>
          <div class="newsletter-footer__description">
            {{ data.mailingList }}
          </div>
        </div>
      </div>

      <div class="l-site-footer__connect">
        <h6 class="site-footer__heading">Connect</h6>
        <a :href="data.social.instagram" class="site-footer__social" target="_blank">Instagram</a>
        <a :href="data.social.pinterest" class="site-footer__social" target="_blank">Pinterest</a>
        <a :href="data.social.linkedin" class="site-footer__social" target="_blank">LinkedIn</a>
        <a :href="data.social.houzz" class="site-footer__social" target="_blank">Houzz</a>
        <a :href="data.social.facebook" class="site-footer__social" target="_blank">Facebook</a>
        <a :href="data.social.twitter" class="site-footer__social" target="_blank">Twitter</a>
      </div>

      <div class="l-site-footer__copyright">
        <p class="site-footer__copyright">&copy; {{ data.year }} Haute Living</p>
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
  .site-footer {
    color: #fff;
    background-color: #0a0a0a;
  }
</style>
