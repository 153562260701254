<script>
  /** Reusable switch component that works with `v-model` (accepts a `value`, emits an `input` event). */

  export default {
    name: 'BaseSwitch',

    props: {
      /** The current value. Can be `null` to represent an indeterminate state. */
      value: {
        type: [Boolean, Number, String],
        default: null,
      },
      /** An array of exactly two options, which can be as simple as `[false, true]` or as complex as `[{ label: 'Option A', value: 'a' }, { label: 'Option B', value: 'b' }]`. */
      options: {
        type: Array,
        default: () => [false, true],
        validator: (value) =>
          value.length === 2 && (value[0]?.value ?? value[0]) !== (value[1]?.value ?? value[1]),
      },
    },

    computed: {
      normalizedOptions() {
        return this.options.map((option) => {
          if (typeof option === 'object') {
            return option
          }
          return {
            label: typeof option === 'string' ? option : null,
            value: option,
          }
        })
      },
      firstOptionSelected() {
        return this.value === this.normalizedOptions[0].value
      },
      secondOptionSelected() {
        return this.value === this.normalizedOptions[1].value
      },
    },

    methods: {
      getLabelForOption(option) {
        if (option.label) {
          return option.label
        }
        if (option.value === true) {
          return 'Enable'
        }
        if (option.value === false) {
          return 'Disable'
        }
        return option.value
      },
      setValue(value) {
        this.$emit('input', value)
      },
      toggle() {
        for (const option of this.normalizedOptions.slice().reverse()) {
          if (option.value !== this.value) {
            this.setValue(option.value)
            return
          }
        }
      },
    },
  }
</script>

<template>
  <div class="flex items-center">
    <label class="relative py-1 cursor-pointer">
      <input
        type="radio"
        :name="`input-${_uid}`"
        :value="normalizedOptions[0].value"
        :checked="firstOptionSelected"
        class="absolute left-0 top-0 opacity-0"
        @change="setValue(normalizedOptions[0].value)"
      />
      <span
        v-if="normalizedOptions[0].label"
        :class="`label mb-0 mr-3 ${
          firstOptionSelected ? 'text-black' : 'text-gray-600 hover:text-black'
        } transition-colors duration-200`"
      >
        {{ normalizedOptions[0].label }}
      </span>
      <span v-else class="sr-only">
        {{ getLabelForOption(normalizedOptions[0]) }}
      </span>
    </label>
    <button
      type="button"
      aria-hidden="true"
      class="group border border-gray-300 hover:border-black transition-colors duration-200"
      @click="toggle"
    >
      <span class="absolute sr-only">Toggle</span>
      <span class="flex justify-center w-12 h-6 p-1">
        <span
          :class="`w-5 h-4 ${
            firstOptionSelected || secondOptionSelected
              ? 'bg-black'
              : 'bg-gray-500 group-hover:bg-black'
          } transform ${firstOptionSelected ? '-translate-x-1/2' : ''} ${
            secondOptionSelected ? 'translate-x-1/2' : ''
          } transition duration-200`"
        ></span>
      </span>
    </button>
    <label class="relative py-1 cursor-pointer">
      <input
        type="radio"
        :name="`input-${_uid}`"
        :value="normalizedOptions[1].value"
        :checked="secondOptionSelected"
        class="absolute left-0 top-0 opacity-0"
        @change="setValue(normalizedOptions[1].value)"
      />
      <span
        v-if="normalizedOptions[1].label"
        :class="`label mb-0 ml-3 ${
          secondOptionSelected ? 'text-black' : 'text-gray-600 hover:text-black'
        } transition-colors duration-200`"
      >
        {{ normalizedOptions[1].label }}
      </span>
      <span v-else class="sr-only">
        {{ getLabelForOption(normalizedOptions[1]) }}
      </span>
    </label>
  </div>
</template>
