<script>
  import api from '@/api'
  import BuilderBaseBlock from '@/components/builder/BuilderBaseBlock'

  export default {
    name: 'NewDesignPage',

    components: {
      BuilderBaseBlock,
    },

    props: {
      /** Optional Header image */
      headerImage: {
        type: Object,
        default: () => ({}),
      },
      /** Optional header title */
      headerTitle: {
        type: String,
        default: '',
      },
      /** The page's entry ID */
      entryId: {
        type: [String, Number],
        required: true,
      },
      /** The content builder field's ID */
      fieldId: {
        type: [String, Number],
        required: true,
      },
    },

    data() {
      return {
        builderBlocks: [],
        isLoading: false,
      }
    },
    computed: {
      location() {
        return window.location.pathname
      },
    },

    created() {
      // Set the loading flag
      this.isLoading = true

      const query = location.search.substring(1)
      let preview = false
      let token = false

      if (query) {
        const queryParams = JSON.parse(
          '{"' +
            decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
            '"}'
        )
        preview = queryParams['x-craft-live-preview'] ?? false
        token = queryParams.token ?? false
      }

      // Make the request
      api
        .post(`builder-module/default/builder-content`, {
          entryId: this.entryId,
          fieldId: this.fieldId,
          preview: !!(preview && token),
        })
        .then((response) => {
          // Save the results into the data store and un-flag loading
          this.builderBlocks = response.data
          this.isLoading = false
        })
    },

    mounted() {
      /** Wait for child components to mount and then check for URL hashes */
      this.$nextTick(() => {
        if (window.location.hash) {
          /** $nextTick doesn't actually capture all the child mount lifecycles so lets add a 1sec. wait */
          setTimeout(() => {
            const section = document.getElementById(window.location.hash.substring(1))
            if (section) {
              const offset = document.documentElement.scrollWidth <= 620 ? 106 : 148
              window.scroll(0, section.getBoundingClientRect().top - offset)
            }
          }, 1500)
        }
      })
    },
  }
</script>

<template>
  <main id="main-content">
    <article class="relative || builder" :class="{ 'is-loading': isLoading }">
      <transition name="fade">
        <div
          v-show="isLoading"
          class="absolute inset-0 z-30 flex flex-col justify-center items-center bg-white"
        >
          <p class="btn btn--rounded">Loading</p>
        </div>
      </transition>
      <transition name="fade">
        <section v-if="Object.keys(headerImage).length > 0" class="section w-full relative">
            <div class="newDesignSection">
                
                <h2
                    v-if="headerTitle !== ''"
                >
                    {{ headerTitle }}
                </h2>

            </div>
          <picture class="w-full h-auto object-cover object-center">
            <source media="(max-width:560px)" :srcset="headerImage.mobile" />
            <source media="(min-width:561px)" :srcset="headerImage.desktop" />
            <img
              :src="headerImage.mobile"
              :alt="headerImage.alt"
              class="w-full h-auto object-cover object-center"
            />
          </picture>
          <div
            v-if="headerImage.attribution"
            class="absolute bottom-0 right-0 py-1 text-xs inline px-4 rounded-tl z-10"
            :style="{ color: headerImage.attributionColor }"
            v-html="headerImage.attribution"
          ></div>
        </section>
      </transition>
      <transition name="fade">
        <div v-show="!isLoading">
          <BuilderBaseBlock
            v-for="(block, index) in builderBlocks"
            :id="location === '/' && index === 1 ? 'below-hero' : ''"
            :key="index"
            :type="block.type"
            :content="block.content"
            :is-last="index + 1 === builderBlocks.length"
          />
        </div>
      </transition>
    </article>
    <div class="h-16 md:h-30 bg-white" />
  </main>
</template>

<style scoped lang="postcss">
  /* purgecss start ignore */
  .builder.is-loading {
    min-height: calc(100vh - 56px);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  >>> .toc {
    @apply sticky z-30;

    top: 56px; /* was 60 */
  }

  >>> .section {
    scroll-margin-top: 110px; /* was 110 */
  }

  @screen md {
    .builder.is-loading {
      min-height: calc(100vh - 72px);
    }

    >>> .toc {
      top: 72px; /* was 100 */
    }

    >>> .section {
      scroll-margin-top: 124px;
    }
  }
  /* purgecss end ignore */
</style>
