<script>
  import PlusIcon from '@/images/icons/plus-sign.svg'
  import MinusIcon from '@/images/icons/minus-sign.svg'

  export default {
    name: 'NewBuilderOpenPositions',
    components: {
      PlusIcon,
      MinusIcon,
    },
    props: {
      /** The heading for the block, if defined */
      title: {
        type: String,
        required: true,
        default: '',
      },
      positions: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    methods: {
      toggleAccordeion(index) {
        const accordeon = this.$refs.accordeon[index]
        const accordeonContent = accordeon.nextElementSibling
        accordeon.classList.toggle('active')
        accordeonContent.classList.toggle('hidden')
      },
    },
  }
</script>

<template>
  <div class="w-full flex flex-wrap flex-col lg:flex-row mt-24 lg:mt-40">
    <h2 v-if="title">{{ title }}</h2>
    <div v-if="positions.length > 0" class="positions">
      <div
        v-for="(item, index) in positions"
        :key="index"
        class="flex flex-col positionAccordeon items-start justify-start"
      >
        <h3
          ref="accordeon"
          class="block w-full m-0 uppercase font-medium text-lg relative cursor-pointer pr-8"
          :class="{ active: index === 0 }"
          @click="toggleAccordeion(index)"
        >
          {{ item.title }}
          <span>
            <PlusIcon class="plusIcon" />
            <MinusIcon class="minusIcon" />
          </span>
        </h3>
        <div
          :class="{ hidden: index !== 0 }"
          class="w-full pt-5 posContent"
          v-html="item.description"
        ></div>
        <a
          v-if="item.link"
          :href="'mailto:' + item.link"
          class="mt-10 block text-center w-full md:w-auto button-height md:px-26 md:inline-block font-semibold | bg-black text-white | hover:bg-gray-900 focus:bg-gray-900 uppercase tracking-wider"
        >
          Apply now
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
  .posContent {
    font-size: 16px;
    line-height: 21px;

    >>> p {
      display: block;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    >>> li {
      margin-bottom: 14px;
      font-size: 14px;
      line-height: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .positions {
    width: calc(100% - 480px);
  }

  .positionAccordeon {
    padding: 30px 0;
    overflow: hidden;
    border-top: 1px solid #dfdfdf;

    &:last-child {
      border-bottom: 1px solid #dfdfdf;
    }
  }

  h2 {
    width: 440px;
  }

  h3 {
    position: relative;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    span {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    svg.plusIcon {
      display: block;
    }

    svg.minusIcon {
      display: none;
    }

    &.active {
      svg.plusIcon {
        display: none;
      }

      svg.minusIcon {
        display: block;
      }
    }
  }
  @media (max-width: 1024px) {
    h2 {
      width: 100%;
      margin-bottom: 24px;
    }

    h3 {
      font-size: 16px;
      line-height: 20px;
    }

    .positions {
      width: 100%;
    }
  }
</style>
