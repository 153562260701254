<script>
  import { createNamespacedHelpers } from 'vuex'
  import BuilderCalloutBanner from '@/components/builder/BuilderCalloutBanner'
  import BuilderHeroSlider from '@/components/builder/BuilderHeroSlider'
  import BuilderOffsetGallery from '@/components/builder/BuilderOffsetGallery'
  import BuilderProductCarousel from '@/components/builder/BuilderProductCarousel'
  import BuilderTableOfContents from '@/components/builder/BuilderTableOfContents'
  import BuilderTextColumn from '@/components/builder/BuilderTextColumn'
  import BuilderTextRow from '@/components/builder/BuilderTextRow'
  import BuilderHeading from '@/components/builder/BuilderHeading'
  import BuilderGoogleMap from '@/components/builder/BuilderGoogleMap'
  import BuilderImagecarousel from '@/components/builder/BuilderImagecarousel'
  import BuilderFeaturedProjects from '@/components/builder/BuilderFeaturedProjects'
  import BuilderTextLeftAndImageRight from '@/components/builder/BuilderTextLeftAndImageRight'
  import BuilderBrandSlider from '@/components/builder/BuilderBrandSlider'
  import BuilderTextLeftAndSliderRight from '@/components/builder/BuilderTextLeftAndSliderRight'
  import BuilderSectionWithThreeBlocks from '@/components/builder/BuilderSectionWithThreeBlocks'
  import BuilderTwoColumnsText from '@/components/builder/newbuilder/NewBuilderTwoColumnsText'
  import BuilderTextLeftAndGoogleMapRight from '@/components/builder/TextLeftAndGoogleMapRight'

  const { mapActions } = createNamespacedHelpers('builder')

  export default {
    name: 'BuilderBaseBlock',

    components: {
      BuilderCalloutBanner,
      BuilderHeroSlider,
      BuilderOffsetGallery,
      BuilderProductCarousel,
      BuilderTableOfContents,
      BuilderTextLeftAndImageRight,
      BuilderTextColumn,
      BuilderTextRow,
      BuilderHeading,
      BuilderGoogleMap,
      BuilderImagecarousel,
      BuilderFeaturedProjects,
      BuilderBrandSlider,
      BuilderTextLeftAndSliderRight,
      BuilderSectionWithThreeBlocks,
      BuilderTwoColumnsText,
      BuilderTextLeftAndGoogleMapRight
    },

    props: {
      /** The type of child block to render */
      type: {
        type: String,
        required: true,
      },
      /** The content of the block (to feed to it's props) */
      content: {
        type: Object,
        required: true,
      },
      /** If this is the last child component in the parent content builder component */
      isLast: {
        type: Boolean,
        required: false,
      },
      currentPageUrl:{
        type:String,
        default:'',
      }
    },

    computed: {
      /** Uses the returned blocks "type" handle property and appends it with 'Builder'  */
      resolveComponent: function () {
        return 'Builder' + this.type
      },
      /** Checks to see if the component is imported and available in this component */
      isAvailable: function () {
        const componentName = this.resolveComponent
        return typeof this.$options.components[componentName] !== 'undefined'
      },
      /** Get the top offset based on screen size */
      topOffset: function () {
        return document.documentElement.scrollWidth < 768 ? 110 : 124
      },
      /** Sets the waypoint config dynamically */
      waypointConfig: function () {
        return {
          rootMargin: '-' + this.topOffset + 'px 0px 0px 0px',
          threshold: 0,
        }
      },
    },

    methods: {
      /** Vuex actions */
      ...mapActions(['activateSection']),
      /** Callback function whe waypoint is reached */
      onWaypoint({ going, direction }) {
        if (this.isLast) {
          // Last block watches the bottom waypoint when it is coming in from the bottom, or going out from the top
          if (
            (going === this.$waypointMap.GOING_IN &&
              direction === this.$waypointMap.DIRECTION_TOP) ||
            (going === this.$waypointMap.GOING_OUT &&
              direction === this.$waypointMap.DIRECTION_BOTTOM)
          ) {
            this.activateSection({
              section: { label: this.content.label, anchor: this.content.anchor },
            })
          }
        } else {
          // All other blocks watch when going out from the top, or going in from the bottom
          if (
            (going === this.$waypointMap.GOING_OUT &&
              direction === this.$waypointMap.DIRECTION_TOP) ||
            (going === this.$waypointMap.GOING_IN &&
              direction === this.$waypointMap.DIRECTION_BOTTOM)
          ) {
            this.activateSection({
              section: { label: this.content.label, anchor: this.content.anchor },
            })
          }
        }
      },
    },
  }
</script>

<template>
  <div 
    class="relative"
    :class="`${ type !== 'HeroSlider' && type !== 'ProductCarousel' && type !== 'TextLeftAndGoogleMapRight' && type !== 'TwoColumnsText' ? 'mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6' : '' }`"
  >
    <component :is="resolveComponent" v-if="isAvailable" v-bind="content" />
  </div>
</template>

<style scoped lang="postcss">
  .waypoint-top-one,
  .waypoint-top-two,
  .waypoint-bottom {
    @apply z-30 block;

    height: 8px;
  }

  .waypoint-top-one {
    position: absolute;
    top: -8px;
  }

  .waypoint-top-two {
    position: absolute;
    top: -8px;
  }

  .waypoint-bottom {
    bottom: -6px;
  }
</style>
