<script>
  import { createNamespacedHelpers } from 'vuex'
  import AccountDashboardLayout from '@/components/layouts/AccountDashboardLayout'
  import { formatCurrency } from '@/utilities/strings'

  const { mapGetters: mapListsGetters } = createNamespacedHelpers('lists')

  /*
   * This component renders the page that displays the list of users' saved product variants
   */

  export default {
    name: 'AccountSavedProducts',
    components: {
      AccountDashboardLayout,
    },
    props: {
      user: {
        type: Object,
        required: true,
      },
    },
    computed: {
      ...mapListsGetters(['getSavedProducts']),
    },
    created() {
      this.$store.dispatch('lists/loadItems')
    },
    methods: {
      removeProduct(id, options) {
        this.$store.dispatch('lists/removeItem', {
          variantId: id,
          options: options,
        })
      },
      formatCurrency,
    },
  }
</script>

<template>
  <AccountDashboardLayout :user="user">
    <template v-slot:right>
      <h3 class="font-bold uppercase mb-6 pb-3 border-b border-gray-200">Saved Products</h3>

      <ul>
        <li
          v-for="product in getSavedProducts"
          :key="product.id"
          class="mb-4 pb-4 border-b border-gray-200 flex justify-between"
        >
          <CartPreview
            :brand="product.brand"
            :url="product.url"
            :title="product.title"
            :image="product.image"
            :options="product.options"
          />

          <div class="flex flex-col justify-between items-end">
            <p v-if="product.unitPrice > 0">{{
              formatCurrency(product.unitPrice)
            }}</p>
            <p v-else>Price on request</p>

            <button
              class="opacity-50 hover:opacity-100 underline transition duration-75 ease-in-out"
              type="button"
              @click="removeProduct(product.id, product.options)"
            >
              Remove Product
            </button>
          </div>
        </li>
      </ul>
    </template>
  </AccountDashboardLayout>
</template>
