<script>
  export default {
    name: 'CategoryBuilderDefaultContent',
    props: {
      text: {
        type: String,
        default: '',
      },
    },
  }
</script>

<template>
  <div v-if="text" class="defaultCategoryContent" v-html="text"></div>
</template>

<style scoped lang="postcss">
  .defaultCategoryContent {
    >>> h2 {
      display: block;
      margin: 0 0 30px 0;
      font: 500 36px/43px 'Inter', sans-serif;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
        font: 500 24px/29px 'Inter', sans-serif;
      }
    }

    >>> h3 {
      display: block;
      margin: 0 0 30px 0;
      font: 500 36px/43px 'Inter', sans-serif;
      @media (max-width: 1024px) {
        margin-bottom: 20px;
        font: 500 24px/29px 'Inter', sans-serif;
      }
    }

    >>> p {
      display: block;
      margin: 0 0 20px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
