<script>
  import { createNamespacedHelpers } from 'vuex'
  import BaseCheckbox from '@/components/BaseCheckbox'

  const { mapGetters, mapActions, mapState } = createNamespacedHelpers('products')

  let uniqueId = 0

  /** A reusable search-select component that allows users to select options from a list of checkboxes. Includes
   * functionality for searching/filtering the list of options while the user types in the search field. */

  export default {
    name: 'BaseFilterSelectOptions',

    components: {
      BaseCheckbox,
    },

    props: {
      /** The dropdown's label, shown in the toggle button */
      label: {
        type: String,
        required: true,
      },
      /** An array of option objects. Each option must have two properties: `label` and `value` */
      options: {
        type: Array,
        required: true,
      },
      /** The values of the options that should be selected */
      value: {
        type: Array,
        default: undefined,
      },
      /** Whether to display a gray border around the toggle button */
      border: {
        type: Boolean,
        default: false,
      },
      /** Whether the dropdown is disabled */
      disabled: {
        type: Boolean,
        default: false,
      },
      /** Classes to pass to the toggle button component */
      buttonClasses: {
        type: [String, Array, Object],
        default: undefined,
      },
    },

    data() {
      uniqueId++

      return {
        openedFilters: [],
        uniqueId: uniqueId,
        search: '',
        showMoreText: '+ More',
        searchFocused: false,
        orderedOptionValues: [],
        selectedOptionValues: [],
        showClearSelected: false,
        showOptionsCount: 10,
        isLoaded: false,
      }
    },

    computed: {
      ...mapState(['currentIndex', 'getIsLoading']),
      checkArraySize() {
        return this.filteredOptions.length
      },
      ...mapGetters(['getProducts', 'getSelectedOption', 'getIsLoading']),
      /** Generate a random ID for the search field to tie the label and input together */
      inputId() {
        return 'search_select_' + uniqueId
      },
      /** Determine if the user has typed anything into the search field */
      isSearching() {
        return this.search !== ''
      },
      /** The options ordered by orderedOptionValues */
      orderedOptions() {
        if (this.orderedOptionValues.length === 0) {
          return this.options
        }
        return this.options
          .concat()
          .sort(
            (a, b) =>
              this.orderedOptionValues.indexOf(a.value) - this.orderedOptionValues.indexOf(b.value)
          )
      },
      /** The options returned by the current search */
      filteredOptions() {
        if (this.search === '') {
          return this.orderedOptions
        }
        return this.orderedOptions.filter(
          (option) => option.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        )
      },
      checkParams() {
        let filters = []
        let cloned = [...this.filteredOptions]
        if (this.showMoreText === '- Less') {
          filters = this.filteredOptions
        } else {
          filters = cloned.slice(0, this.showOptionsCount)
        }
        return filters
      },
      /** The count shown next to the label of the dropdown button */
      count() {
        if (this.selectedOptionValues.length > 0) {
          return this.selectedOptionValues.length
        }
        return this.options.length
      },

      /** Used to test if an option is selected. This is necessary as the checkboxes are created and destroyed
       * dynamically when the filtered options are being looped */
      isSelected() {
        return (option) => this.selectedOptionValues.indexOf(option.value) !== -1
      },
    },

    watch: {
      currentIndex(idx) {
        if (idx !== null) {
          const selectedOptionValueIndex = this.selectedOptionValues.indexOf(idx)
          if (selectedOptionValueIndex !== -1) {
            this.selectedOptionValues.splice(selectedOptionValueIndex, 1)
          }
        }
      },
      value() {
        if (this.value) {
          this.selectedOptionValues = this.value
        }
      },
      selectedOptionValues(item) {
        if (this.selectedOptionValues.length === 0) {
          this.showClearSelected = false
        }
        this.$emit('change', this.selectedOptionValues)
      },
    },
    mounted() {
      if (window.location.href.indexOf('brand') !== -1) {
        this.showMoreText = '- Less'
      }
      /** Open the filter subcategory if its only one type filter */
      if (this.filteredOptions.length === 1 && this.filteredOptions[0].children.length > 0) {
        this.openedFilters = [...this.openedFilters, this.filteredOptions[0].value]
      }
    },
    methods: {
      ...mapActions(['setAppendedFilters', 'remoteSelectedFilter']),
      /** Clears out the search query */
      clearSearch() {
        this.search = ''
      },
      /** get product count for a filter option to determine if to show filter or not */
      getFilterCount(handle) {
        let productCount = 2
        if (this.label === 'Type') {
          productCount = this.getProducts.filter((product) => {
            return product.categoryIds.includes(handle)
          }).length
        }
        return productCount
      },
      /** Set the current parent of the category childresn */
      setOpenedFilters(value) {
        if (!this.openedFilters.includes(value)) {
          this.openedFilters = [...this.openedFilters, value]
        } else {
          this.openedFilters = this.openedFilters.filter((item) => item !== value)
        }
      },
      /** Sets the data param for searchFocused (used for styling) */
      setSearchFocused(value) {
        this.searchFocused = value
      },
      /** Marks an option as selected or not selected */
      setSelected(option, selected, label, childrens, index) {
        if (this.label === 'Type') {
          if (option.parent) {
            if (selected) {
              this.setAppendedFilters({
                label: option.label,
                index: option.value,
                type: this.label,
              })
            } else {
              this.remoteSelectedFilter(option.value)
            }
          }
        } else {
          if (selected) {
            this.setAppendedFilters({
              label: option.label,
              index: option.value,
              type: this.label,
              slug: option.slug,
            })
          } else {
            this.remoteSelectedFilter(option.value)
          }
        }
        if (Array.isArray(option)) {
          option.forEach((item) => {
            const value = parseInt(item.value)
            if (selected) {
              this.selectedOptionValues = [...this.selectedOptionValues, value]
            } else {
              const selectedOptionValueIndex = this.selectedOptionValues.indexOf(value)
              if (selectedOptionValueIndex !== -1) {
                this.selectedOptionValues.splice(selectedOptionValueIndex, 1)
              }
            }
          })
        } else {
          const value = parseInt(option.value)
          if (selected) {
            this.selectedOptionValues = [...this.selectedOptionValues, value]
          } else {
            const selectedOptionValueIndex = this.selectedOptionValues.indexOf(value)
            if (selectedOptionValueIndex !== -1) {
              this.selectedOptionValues.splice(selectedOptionValueIndex, 1)
            }
          }
        }
      },
      /** Used to clear all selected options */
      clearSelected() {
        this.selectedOptionValues = []
        this.orderedOptionValues = []
        this.showClearSelected = false
      },
      /** Orders the options so the selected ones appear first */
      orderOptions() {
        this.orderedOptionValues = [
          ...this.selectedOptionValues,
          ...this.options
            .filter((option) => this.selectedOptionValues.includes(option.value) === false)
            .map((option) => option.value),
        ]
      },
      /** Pass the dropdown's toggle event to the parent, and do some things when the dropdown is opened or closed */
      toggleDropdown(open) {
        if (open) {
          this.search = ''
          this.orderOptions()
          if (this.selectedOptionValues.length > 0) {
            this.showClearSelected = true
          }
        }
        this.$emit('toggle', open)
      },
    },
  }
</script>

<template>
  <div>
    <div v-for="(option, index) in checkParams" :key="index" class="relative">
      <BaseCheckbox
        v-if="getFilterCount(parseInt(option.value)) > 0"
        :label="option.label"
        :count="option.length"
        :value="option.value"
        :is-parent="option.children && option.children.length > 0"
        :checked="isSelected(option)"
        @clicked="setOpenedFilters(parseInt(option.value))"
        @change="
          setSelected(
            option.children && option.children.length > 0 ? option.children : option,
            $event,
            option.label,
            option.children,
            index
          )
        "
      />
      <div v-if="option.children && option.children.length > 0" class="ml-5">
        <div v-for="(child, i) in option.children" :key="i">
          <BaseCheckbox
            v-if="
              openedFilters.includes(parseInt(child.parent)) &&
              getFilterCount(parseInt(child.value)) > 0
            "
            ref="parentCheckbox"
            :label="child.label"
            :value="child.value"
            :is-parent="child.children && child.children.length > 0"
            :checked="selectedOptionValues.includes(parseInt(child.value))"
            @clicked="
              setOpenedFilters(
                child.children && child.children.length > 0 ? parseInt(child.value) : ''
              )
            "
            @change="
              setSelected(
                child.children && child.children.length > 0 ? child.children : child,
                $event,
                child.label,
                child.children,
                i
              )
            "
          ></BaseCheckbox>
          <div v-if="child.children && child.children.length > 0" class="ml-5">
            <div v-for="(gChild, j) in child.children" :key="j">
              <BaseCheckbox
                v-if="
                  openedFilters.includes(parseInt(gChild.parent)) &&
                  getFilterCount(parseInt(gChild.value)) > 0
                "
                :label="gChild.label"
                :value="gChild.value"
                :checked="selectedOptionValues.includes(parseInt(gChild.value))"
                @change="setSelected(gChild, $event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="checkArraySize > 10"
      class="showMoreFilters"
      ref="showMoreFilters"
      @click="showMoreText === '+ More' ? (showMoreText = '- Less') : (showMoreText = '+ More')"
      >{{ showMoreText }}</div
    >
  </div>
</template>

<style scoped lang="postcss">
  .showMoreFilters {
    width: 100%;
    margin: 20px 0 15px 0;
    overflow: hidden;
    font: 500 14px/17px 'Inter', sans-serif;
    cursor: pointer;
  }

  .checkbox-input:checked ~ & span {
    display: block;
  }

  label svg {
    width: 12px;
    height: 11px;
  }

  button {
    svg {
      width: 10px;
      height: 10px;
    }

    &:focus span {
      @apply underline;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  >>> .checkbox-input {
    position: relative;
    z-index: 5;
    width: 16px;
    top: -2px;
    height: 16px;
    @apply border border-gray-600;
  }

  >>> label {
    @apply text-black font-normal font-unica77;
    font-size: 16px;
  }

  @screen lg {
    >>> label {
      @apply font-normal;
    }
  }
</style>
