<script>
  import { createNamespacedHelpers } from 'vuex'
  import ImageLogo from '@/images/logo.svg'
  import IconSpinner from '@/images/icons/spinner.svg'

  const { mapState: mapGlobalState } = createNamespacedHelpers('global')
  const { mapGetters: mapCartGetters } = createNamespacedHelpers('cart')

  /**
   * Cart/Checkout Layout. Contains slots for the header navigation, as well as left and right content panels
   */
  export default {
    name: 'CheckoutLayout',

    components: {
      ImageLogo,
      IconSpinner,
    },

    computed: {
      ...mapGlobalState(['siteUrl']),
      ...mapCartGetters(['getHasLoaded']),
    },
  }
</script>

<template>
  <div class="lg:flex lg:flex-col lg:h-screen overflow-hidden">
    <header
      class="relative flex justify-between h-header-mobile lg:h-header-desktop flex-shrink-0 pl-4 lg:px-6 transition-height duration-300"
    >
      <div class="-z-10 absolute inset-0 border-b border-gray-200 bg-white"></div>
      <div
        class="order-last ml-3 lg:mx-3 w-header-mobile flex-shrink-0 transition-width duration-300 lg:w-header-desktop"
      >
        <a
          :href="siteUrl"
          class="flex items-end w-full h-full bg-black hover:bg-gray-900 transition-colors duration-300 text-white"
          style="padding: 15%"
        >
          <ImageLogo class="w-full fill-current" />
        </a>
      </div>
      <div class="flex flex-1">
        <slot name="header" />
      </div>
      <!-- Empty div to center the logo -->
      <div class="order-last hidden lg:block flex-1"></div>
    </header>

    <div v-if="!getHasLoaded" class="lg:flex-grow lg:min-h-0">
      <IconSpinner class="absolute inset-0 m-auto stroke-current transform scale-200" />
    </div>

    <div v-else class="lg:flex lg:flex-grow lg:min-h-0">
      <div class="lg:w-2/3 lg:overflow-y-auto">
        <slot name="left" />
      </div>

      <div
        class="lg:w-1/3 lg:min-w-96 lg:overflow-y-hidden lg:border-gray-200 lg:border-l relative"
      >
        <slot name="right" />
      </div>
    </div>
  </div>
</template>
