<script>
  import BaseFilterCheckbox from '@/components/BaseFilterCheckbox.vue'
  /** Checkbox group component used on the filter that supports checkbox
   * filter types that have children checkbox filter types */

  export default {
    name: 'BaseFilterCheckboxGroup',
    components: {
      BaseFilterCheckbox
    },
    props: {
      children: {
        type: Array,
        default: () => [],
      },
      filterValues: {
        type: Object,
        default: () => ({}),
      },
      filterEnabledStates: {
        type: Object,
        default: () => ({}),
      },
      filteredProducts: {
        type: Array,
        default: () => [],
      },
      products: {
        type: Array,
        default: () => [],
      },
      showCount: {
        type: Boolean,
        default: false,
      },
      showWhenSelected: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      checkboxTypeChildren() {
        return this.children.map(filter => {
          filter.type = 'checkbox';
          return filter
        })
      },
    },
    methods: {
      /** When the checkbox is checked or unchecked this method emits its new state */
      handleChange(handle, event) {
        console.log(handle)
        this.$emit('change', {
          handle: handle,
          event: event
        });
      },
      /** Gets total number of filter types in the current catalog */
      getTypeCount(handle) {
        return this.products.filter((product) => product[handle] === true).length
      },
    },
  }
</script>

<template>
  <div>
    <!-- Parent checkbox which the group belongs to -->
    <BaseFilterCheckbox
        v-bind="$attrs"
        :available-items="showCount ? (showWhenSelected === false ? ($attrs.checked ? 0 : getTypeCount($attrs.handle)) : getTypeCount($attrs.handle)) : showCount"
        @change="handleChange($attrs.handle, $event)"
    />

    <!-- Child checkbox(s) which is a subcategory of the parent -->
    <div v-show="$attrs.checked">
        <BaseFilterCheckbox
            v-for="filter in checkboxTypeChildren"
            :key="filter.label"
            :label="`${filter.label}`"
            :available-items="filter.showCount ? getTypeCount(filter.property) : filter.showCount"
            :checked="
                filterValues[filter.property] ||
                (filterEnabledStates[filter.property] === false &&
                filteredProducts.length > 0 &&
                filteredProducts[0][filter.property] === true)
            "
            class="pl-4"
            @change="handleChange(filter.property,$event)"
        />
    </div>
  </div>
</template>
