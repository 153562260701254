<script>
  import ProductTile from '@/components/products/ProductTile'
  import BaseTileRowLoader from '@/components/BaseTileRowLoader'
  import BaseCarousel from '@/components/BaseCarousel'
  export default {
    name: 'ProductNotAvailableRelated',
    components: {
      ProductTile,
      BaseTileRowLoader,
      BaseCarousel,
    },
    props: {
      products: {
        type: Array,
        default: () => [],
      },
      type: {
        type: String,
        default: '',
      },
    },
  }
</script>

<template>
  <div class="mx-auto lg:max-w-screen-2xl w-full px-4 md:px-6">
    <div v-if="products.length > 0" class="relatedHeader">
      <h3 class="font-semibold uppercase text-black block m-0 text-xl leading-6 tracking-wide"
        >Other {{ type }} you might like</h3
      >
    </div>
    <aside class="related-products relative bg-white">
      <div v-if="products.length > 0" class="pb-12 mb-20">
        <div class="py-5 grid grid-cols-2 gap-4 lg:grid-cols-4 lg:gap-8">
          <ProductTile
            v-for="product in products"
            :key="product.id"
            :types="type"
            v-bind="product"
          />
        </div>
      </div>
    </aside>
  </div>
</template>

<style scoped>
  .relatedHeader {
    margin-bottom: 28px;
  }
</style>
